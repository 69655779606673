import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-in-details-buy-order',
  templateUrl: './in-details-buy-order.component.html',
  styleUrls: ['./in-details-buy-order.component.scss']
})
export class InDetailsBuyOrderComponent implements OnInit {

  @Input() data;
  @Output() itemClick = new EventEmitter();

  order;
  orderProducts = [];
  orderNumbers: string;

  constructor() { }

  ngOnInit(): void {
    if (this.data.order) {
      this.order = this.data.order;
      for (const p of this.data.order.products) {
        this.orderProducts.push(p);
      }
    } else {
      this.order = this.data.relatedOrders[0];

      const ocNumbersArray = [];
      for (const o of this.data.relatedOrders) {
        ocNumbersArray.push(o.number);
        for (const p of o.products) {
          this.orderProducts.push(p);
        }
      }
      this.orderNumbers = ocNumbersArray.join(', ');
    }
  }

  timestampToDate(timestamp) {
    return moment(timestamp).format('DD/MM/YYYY - HH:mm');
  }

  itemClickHandler(data) {
    this.itemClick.emit({type: 'invoice', entity:data});
  }
}
