import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { ICrateMap } from '../../../models/Crate';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { appendParams } from 'src/app/helpers/append-query-params';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  cratesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private httpClient: HttpClient) { }

  async getMap(): Promise<any> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get(environment.baseUrl + '/api/v1/admin-desktop/ground/map', { headers }).toPromise<any>();
  }

  async getMapCrates(): Promise<ICrateMap[]> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get<ICrateMap[]>(environment.baseUrl + '/api/v1/admin-desktop/map/crates', { headers }).toPromise<any>();
  }

  async getLevels(): Promise<any> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get(environment.baseUrl + '/api/v1/admin-desktop/ground/levels', { headers }).toPromise<any>();
  }

  async getPositionCount(x?: number, y?: number): Promise<any> {
    const queryParams = {
      x: x,
      y: y
    };
    const params = appendParams(queryParams);
    return this.httpClient.get(environment.baseUrl + `/api/v1/admin-desktop/map/positions-count`, { params }).pipe(
      map(list => {
        const infoList = [];
        Object.keys(list).forEach(key => {
          infoList.push({
            name: this.translatePositionInfo(key),
            value: list[key]
          });
        });
        return infoList;
      })
    ).toPromise<any>();
  }

  translatePositionInfo(text: string) {
    switch (text) {
      case 'totalLevels':
        return 'Total de posições';
      case 'totalOccupiedLevels':
        return 'Total de posições ocupadas';
      case 'totalFreeLevels':
        return 'Total de posições livres';
    }
  }
}
