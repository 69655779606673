import {Component, HostBinding, Input, OnInit, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-in-group-nfe-item',
  templateUrl: './in-group-nfe-item.component.html',
  styleUrls: ['./in-group-nfe-item.component.scss']
})
export class InGroupNfeItemComponent implements OnInit {

  @Input() nfe;
  @HostBinding('class.isOdd') @Input() isOdd;
  @HostBinding('class.danger-background') isBlocked: boolean;
  @Output() goToComments = new EventEmitter();
  @Output() goToOther = new EventEmitter();

  orderDate;
  nfeDate;

  constructor() { }

  ngOnInit(): void {
    if (this.nfe.status === 'RECEIVEMENT_BLOCKED') {
      this.isBlocked = true;
    }
    this.orderDate = this.nfe.orders.length && moment(this.nfe.orders[this.nfe.orders.length - 1].issuedAt).format('DD/MM/YYYY - HH:mm');
    this.nfeDate = moment(this.nfe.issuedAt).format('DD/MM/YYYY - HH:mm');
  }

  handleComment($event){
    this.goToComments.emit();
  }
  handleGotoOther($event){
    this.goToOther.emit();
  }


}
