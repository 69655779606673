<app-side-color-card color="primary-1">
  <div class="holder" content>
    <h3 class="title">SETORES</h3>
    <div class="table" >
      <div class="items">
        <div class="header">
          <div class="name column">Nome</div>
          <div class="type column">Tipo</div>
          <div class="slots column">Slots</div>
          <div class="occupation column">Ocupação</div>
        </div>
        <div class="item" *ngFor="let l of lines" [ngClass]="{
    'primary-1': l.type === 'STOCK',
    'secondary-3': l.type === 'DOCK',
     'out' : l.type === 'EXPEDITION',
     'purple' : l.type === 'QUALITY_CONTROL',
     'red' : l.type === 'DIVERGENT_STOCK',
     'gray' : l.type === 'STREET',
     'yellow' : l.type === 'DAMAGES',
     'picking' : l.type === 'PICKING',
     'provision' : l.type === 'PROVISION',
     'in' : l.type === 'IN',
     'black': l.type === 'EXIT'
    }">
          <div class="name column">{{l.name}}</div>
          <div class="type column">{{l.type.substring(0,3)}}</div>
          <div class="slots column">{{l.slots}}</div>
          <div class="occupation column">{{l.occupation| number : '1.2-2'}}%</div>
        </div>
      </div>
    </div>
  </div>

</app-side-color-card>
