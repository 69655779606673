import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-table-modal-header',
  templateUrl: './reports-table-modal-header.component.html',
  styleUrls: ['./reports-table-modal-header.component.scss']
})
export class ReportsTableModalHeaderComponent implements OnInit {

  @Input() displayedColumns: {
    name: string;
    label: string;
    size: 'smallest' | 'small' | 'medium' | 'large' | 'largest'
  }[];

  constructor() { }

  ngOnInit(): void {
  }

}
