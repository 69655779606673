import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroundGroupsService {

  constructor(private http: HttpClient) { }

  getGroundGroups(eager:boolean=false) {
    let endpoint = environment.baseUrl + '/api/v1/admin-desktop/ground/groups';
    if(eager){
      endpoint = `${endpoint}?eager=true`;
    }
    return this.http.get(endpoint).toPromise<any>();
  }

  getLevels() {
    const endpoint = environment.baseUrl + '/api/v1/admin-desktop/ground/groups';
    return this.http.get(endpoint).toPromise<any>();
  }

}
