<div class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Solicitação de tarefa" (closeEvent)="close()"></app-sidenav-header>

    <form class="new-inventory-task-config" [formGroup]="crateForm">
      <div class="card-header">Configurar nova tarefa de realocação</div>
      <div class="card-body">
        <div class="product-input">
          <p>Digite o que deseja realocar:</p>
          <mat-form-field>
            <input matInput
                   placeholder="SKU ou a descrição do produto"
                   formControlName="productSku"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayedString">
              <mat-option *ngFor="let product of products" [value]="product">{{ product.name + ' - ' + product.sku }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <app-sidenav-destination (changed)="destinationChanged($event)"></app-sidenav-destination>

        <mat-tab-group [(selectedIndex)]="selectedTab">
          <mat-tab label="Pallets específicos">
            <mat-form-field class="pallets">
              <mat-label>Selecione os pallets desejados</mat-label>
              <mat-select multiple formControlName="pallets">
                <mat-option *ngFor="let pallet of palletsOptions"
                            [value]="pallet"
                            [disabled]="pallet.groundPosition.type === 'PICKING'"
                            [matTooltip]=" !data.isPicking && pallet.groundPosition.type === 'PICKING' ? 'Só é possivel movimentar o pallet em Separação na tela de Separação de produtos' : null">
                  {{ 'Posição: ' + handlePosition(pallet.x, pallet.y) + ', quantidade: ' + handleQuantity(pallet.skuGroups)}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-tab>
          <mat-tab label="Quantidades" [disabled]="hasPickingCrates">
            <input placeholder="Digite a quantidade desejada" formControlName="quantity">
          </mat-tab>
        </mat-tab-group>
      </div>
    </form>

<!--    <mat-checkbox (change)="onCriticalChange($event.checked)"-->
<!--                  [checked]="isCritical">Tarefa crítica</mat-checkbox>-->

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>
    <p class="error" *ngIf="noPalletsError">Não há pallets disponíveis com este produto.</p>

    <app-operators-table device="FORKLIFT"
                         [usersHighlighted]="usersHighlighted"
                         (usersHighlightedEvent)="setUsers($event)"
                         [openedStart]="drawer.openedStart"
                         [isUserOnline]="true"></app-operators-table>
  </div>

  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()">OK</button>
  </div>
</div>
