<div class="prev-next">
  <h1>Processos</h1>
  <div class="align">
    <app-prev-next-button (click)="toEnterDocsAndScreening()" [direction]="'right'" [label]="'documentos de entrada'"></app-prev-next-button>
  </div>
</div>
<div class="content">
  <app-dashboard-groups title="Recebimento" color="in">

    <app-dashboard-item
      color="in"
      number="{{inValues.orders}}"
      label="Ordens de Compra"
      [isLoading]="isLoading"
      (click)="toEnterDocsAndScreening()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      color="in"
      number="{{inValues.invoices}}"
      label="Notas Fiscais"
      [isLoading]="isLoading"
      (click)="toEnterDocsAndScreening()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      color="in"
      number="{{inValues.screening}}"
      label="Triagem"
      [isLoading]="isLoading"
      (click)="toScreening()"
    >
    </app-dashboard-item>

  </app-dashboard-groups>
  <app-dashboard-groups title="Armazém" color="primary-1">

    <app-dashboard-item
      color="primary-1"
      number="{{stockValues.inspection}}"
      label="Conferência Física"
      [isLoading]="isLoading"
      (click)="toInspection()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      color="primary-1"
      number="{{stockValues.stock}}"
      label="Estoque"
      [isLoading]="isLoading"
      (click)="toStock()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      color="primary-1"
      number="{{stockValues.total}}"
      label="Total"
      [isLoading]="isLoading"
      (click)="toStock()"
    >
    </app-dashboard-item>

  </app-dashboard-groups>
  <app-dashboard-groups title="Separação" color="picking">

    <app-dashboard-item
      color="picking"
      number="{{pickingValues.outOrders}}"
      label="Ordens de Venda"
      [isLoading]="isLoading"
      (click)="toPicking()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      color="picking"
      number="{{pickingValues.outOrdersWithPallets}}"
      label="Ordens Reservadas"
      [isLoading]="isLoading"
      (click)="toPicking()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      color="picking"
      number="{{pickingValues.outOrdersInSeparation}}"
      label="Em Separação"
      [isLoading]="isLoading"
      (click)="toPicking()"
    >
    </app-dashboard-item>

  </app-dashboard-groups>
  <app-dashboard-groups title="Expedição" color="out">

    <app-dashboard-item
      color="out"
      number="{{outValues.issuedInvoices}}"
      label="Notas Expedidas"
      [isLoading]="isLoading"
      (click)="toDispatch()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      color="out"
      number="{{outValues.dispatching}}"
      label="Carregando"
      [isLoading]="isLoading"
      (click)="toDispatch()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      color="out"
      number="{{outValues.finalized}}"
      label="Finalizados"
      [isLoading]="isLoading"
      (click)="toDispatch()"
    >
    </app-dashboard-item>

  </app-dashboard-groups>
</div>
