<section class="body">
  <mat-form-field appearance="outline">
    <mat-label>Filtrar por descrição do produto</mat-label>
    <input matInput
           placeholder="Descrição do produto"
           [formControl]="productNameSearchFormControl"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por código do produto</mat-label>
    <input matInput
           placeholder="Código do produto"
           [formControl]="productSkuSearchFormControl"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por setor</mat-label>
    <input matInput
           placeholder="Setor"
           [formControl]="sectionSearchFormControl"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por nível</mat-label>
    <input matInput
           placeholder="Nível"
           [formControl]="levelNameSearchFormControl"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por endereço</mat-label>
    <input matInput
           placeholder="Endereço"
           [formControl]="addressFormControl"
           autocomplete="off">
    <mat-error>Endereço inválido</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="date-picker">
    <mat-label>Filtrar por data de entrada</mat-label>
    <mat-date-range-input [formGroup]="dateRangeSearchFormGroup" [rangePicker]="picker">
      <input readonly matStartDate formControlName="startDate" placeholder="Data inicial">
      <input readonly matEndDate formControlName="endDate" placeholder="Data final">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <mat-form-field class="dropdown">
    <mat-label>Filtrar por estado</mat-label>
    <mat-select [(value)]="stateValue">
      <mat-option *ngFor="let option of stateOptions" [value]="option.value">{{ option.label }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="quantity">
    <p>QUANTIDADE</p>
    <div class="counter">
      <div class="operator-button" (click)="changeQuantity('MINUS')">
        <i class="uil uil-minus"></i>
      </div>
      <span>{{ quantityCount }}</span>
      <div class="operator-button" (click)="changeQuantity('PLUS')">
        <i class="uil uil-plus"></i>
      </div>
    </div>
  </div>

  <div class="buttons">
    <button mat-stroked-button class="gray-1" (click)="clear()">LIMPAR</button>
    <button mat-raised-button class="primary-2" (click)="apply()">APLICAR</button>
  </div>
</section>
