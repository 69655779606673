import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GroundGroupsService} from '../../core/services/ground-groups.service';
import {GroundPositionType} from '../warehouse-map/warehouse-map-table/warehouse-map-table.component';
import {ActionService} from '../../core/services/action.service';

@Component({
  selector: 'app-ground-groups',
  templateUrl: './ground-groups.component.html',
  styleUrls: ['./ground-groups.component.scss']
})
export class GroundGroupsComponent implements OnInit {

  positions;
  formToggle: boolean;

  createSectorFormGroup: FormGroup;

  sectorOptions = [
    { value: 'DOCK', name: 'Doca' },
    { value: 'STREET', name: 'Rua' },
    { value: 'QUALITY_CONTROL', name: 'Controle de qualidade' },
    { value: 'STOCK', name: 'Estoque' },
    { value: 'EXPEDITION', name: 'Expedição' },
    { value: 'DIVERGENT_STOCK', name: 'Estoque divergente' },
    { value: 'DAMAGES', name: 'Danos' },
    { value: 'PICKING', name: 'Separação' },
    { value: 'PROVISION', name: 'Provisão' },
    { value: 'IN', name: 'Entrada' }
  ];

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private groundGroupsService: GroundGroupsService,
              private actionService: ActionService) {
    this.formToggle = false;
  }

  ngOnInit(): void {
    this.buildForm();
    this.getGroundGroups();
  }

  buildForm() {
    this.createSectorFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required]
    });
  }

  async getGroundGroups() {
    try {
      this.positions = await this.groundGroupsService.getGroundGroups(true);
    } catch (e) {
      console.log(e);
    }
  }

  toggleForm() {
    this.formToggle = !this.formToggle;
  }

  createSector() {
    try {
      this.createSectorFormGroup.markAllAsTouched();
      if (this.createSectorFormGroup.valid) {
        const params = {
          name: this.createSectorFormGroup.controls.name.value,
          type: this.createSectorFormGroup.controls.type.value
        };
        this.actionService.addGroundGroup(params).then(() => {
          this.getGroundGroups();
        });
      }
    }
    finally {
      this.cancel();
    }
  }

  cancel() {
    this.createSectorFormGroup.reset();
    this.formToggle = false;
  }

  translateType(type) {
    switch (type) {
      case GroundPositionType.DOCK:
        return 'Doca';
      case GroundPositionType.STREET:
        return 'Rua';
      case GroundPositionType.QUALITY_CONTROL:
        return 'Controle de qualidade';
      case GroundPositionType.STOCK:
        return 'Estoque';
      case GroundPositionType.EXPEDITION:
        return 'Expedição';
      case GroundPositionType.DIVERGENT_STOCK:
        return 'Estoque Divergente';
      case GroundPositionType.DAMAGES:
        return 'Danos';
      case GroundPositionType.PICKING:
        return 'Separação';
      case GroundPositionType.PROVISION:
        return 'Provisão';
      case GroundPositionType.IN:
        return 'Entrada';
      default:
        return type || '---';
    }
  }

  async toMap() {
    await this.router.navigate(['warehouse-map']);
  }
  async toLevels(p: any){
    await this.router.navigate(['ground-group-levels', p.id]);
  }
}
