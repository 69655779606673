<div class="table">
  <div class="table-header">
    <app-table-header-column name="posicao" size="medium">
      <span content>Posição</span>
    </app-table-header-column>
    <app-table-header-column name="quantity" size="medium">
      <span content>Quantidade</span>
    </app-table-header-column>
    <app-table-header-column name="button" size="medium">
      <span content></span>
    </app-table-header-column>
  </div>

  <div class="table-item" *ngFor="let p of pickingPositions">
    <app-table-column name="posicao" size="medium">
      <span content>{{ toExcelFormat(p.groundPosition.x, p.groundPosition.y) || '---' }}</span>
    </app-table-column>
    <app-table-column name="quantity" size="medium">
      <span content>{{ p.quantity || 0 }}</span>
    </app-table-column>
    <app-table-column name="button" size="medium">
      <button mat-raised-button class="primary-2" content (click)="onRelocateClick(p)" [disabled]="p.quantity === 0">realocar</button>
      <button mat-raised-button class="primary-2" content (click)="onClearPositionClick(p)" [disabled]="p.quantity !== 0">limpar posição</button>
    </app-table-column>
  </div>

  <div class="empty" *ngIf="pickingPositions?.length === 0">
    NÃO HÁ POSIÇÕES COM ESTE PRODUTO NESTE NO MOMENTO.
  </div>
</div>
