<div class="page-header">
  <app-prev-next-button (click)="toDashboard()" direction="left" icon="estate"></app-prev-next-button>
  <h1>Tarefas</h1>
</div>

<div class="buttons">
  <div class="update-button">
<!--      <p>Atualizado às {{ actualHour }}</p>-->
    <button mat-raised-button (click)="update()">
      <i class="uil uil-sync"></i>
      <span>Atualizar</span>
    </button>
  </div>
</div>
<app-side-color-card color="primary-1">
  <div content class="card-content">
    <div class="card-header">
      <p>TAREFAS ({{ allTasks?.length }})</p>
    </div>
    <div class="table">
      <app-tasks-table-header></app-tasks-table-header>
      <ng-container *ngIf="!isLoading">
        <app-tasks-table-item *ngFor="let task of allTasks; let odd = odd"
                              [task]="task"
                              [isOdd]="odd"
                              (deleteClick)="openConfirmDeleteModal($event)"></app-tasks-table-item>
      </ng-container>

      <div class="empty-table" *ngIf="!allTasks?.length && !isLoading">
        NÃO EXISTEM TAREFAS.
      </div>
    </div>
    <div class="loader" *ngIf="isLoading">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
  </div>
</app-side-color-card>
