import {Component, OnInit, ViewChild} from '@angular/core';
import {StockDataSourceService} from '../../../core/data-sources/stock-data-source.service';
import {GroundGroupsService} from '../../../core/services/ground-groups.service';
import {LayoutService} from '../../../core/data-sources/layout.service';
import {ActionService, TenantState} from '../../../core/services/action.service';
import {ConfirmModalComponent} from '../../../modals/confirm-modal/confirm-modal.component';
import {first} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AddGroupModalComponent} from '../../../modals/add-group-modal/add-group-modal.component';
import {TenantService} from '../../../core/services/tenant.service';


export enum GroundGroupsMapState {
  DEFAULT= 'DEFAULT',
  ADDING_TAGS= 'ADDING_TAGS',
  GROUP_HIGHLIGHT= 'GROUP_HIGHLIGHT'
}
export enum GroundGroupsMapActionName{
  SET_HIGHLIGHT_MODE= 'SET_HIGHLIGHT_MODE',
  SET_ADDING_TAGS_MODE= 'SET_ADDING_TAGS_MODE',
  SAVE_TAGS= 'SAVE_TAGS',
  REMOVE_GROUND_GROUP= 'REMOVE_GROUND_GROUP',
  RESET= 'RESET'
}
export interface GroundGroupsMapAction{
  action: GroundGroupsMapActionName;
  data?: any;
}

@Component({
  selector: 'app-setup-ground',
  templateUrl: './setup-ground.component.html',
  styleUrls: ['./setup-ground.component.scss']
})
export class SetupGroundComponent implements OnInit {

  @ViewChild('mapComponent', { static: true }) mapComponent: any;

  groups = [];
  map;
  tenantLevelsEnabled = false;

  state: GroundGroupsMapState = GroundGroupsMapState.DEFAULT;
  selectedPositionIds = new Set<string>();
  currentGroundGroupId: string;
  currentGroundGroupName: string;

  constructor( private stockService: StockDataSourceService,
               private tenantsService: TenantService,
               private groundGroupsService: GroundGroupsService,
               private layoutService: LayoutService,
               private actionService: ActionService,
               public dialog: MatDialog,
               private router: Router
               ) {}

  async processSetAddingTagsMode(data){
    this.currentGroundGroupId = data.groundGroupId;
    const group = this.getGroupById(this.currentGroundGroupId);
    this.currentGroundGroupName = group.name;
    this.selectedPositionIds.clear();
    for ( const p of data.positions){
      this.selectedPositionIds.add(p);
    }
    return GroundGroupsMapState.ADDING_TAGS;
  }

  async processReset(){
    this.currentGroundGroupId = null;
    this.currentGroundGroupName = '';

    this.selectedPositionIds.clear();
    return GroundGroupsMapState.DEFAULT;
  }

  async processRemoveGroundGroup(){
    await this.removeGroundGroup();
    this.currentGroundGroupId = null;
    this.currentGroundGroupName = '';
    this.selectedPositionIds.clear();
    await this.loadData();
    return GroundGroupsMapState.DEFAULT;
  }


  async processSaveTags(){
    const has = this.someGroundPositionHasDifferentType();
    let upload = true;
    if (has){
      const response = await this.openConfirmRemoveModal();
      upload = response === 'SUBMITTED';
    }

    if (upload){
      await this.updateGroundGroup();
    }

    this.currentGroundGroupId = null;
    this.currentGroundGroupName = '';
    this.selectedPositionIds.clear();
    await this.loadData();
    return GroundGroupsMapState.DEFAULT;
  }

  async processSetHighlightMode(data){
    this.currentGroundGroupId = data.groundGroupId;
    const group = this.getGroupById(this.currentGroundGroupId);
    this.currentGroundGroupName = group.name;
    this.selectedPositionIds.clear();
    for (const p of data.positions){
      this.selectedPositionIds.add(p);
    }
    return GroundGroupsMapState.GROUP_HIGHLIGHT;
  }

  processAction(currentState: GroundGroupsMapState, action: GroundGroupsMapAction): Promise<GroundGroupsMapState>{
    if (!action){
      return Promise.resolve(currentState);
    }
    switch (currentState) {
      case GroundGroupsMapState.DEFAULT:
        if (action.action === GroundGroupsMapActionName.SET_ADDING_TAGS_MODE) {
          return this.processSetAddingTagsMode(action.data);
        }
        else if (action.action === GroundGroupsMapActionName.SET_HIGHLIGHT_MODE) {
          return this.processSetHighlightMode(action.data);
        }
        else { console.log('not allowed', action); }
        break;
      case GroundGroupsMapState.GROUP_HIGHLIGHT:
        if (action.action === GroundGroupsMapActionName.RESET) {
          return this.processReset();
        }
        else if (action.action === GroundGroupsMapActionName.SET_ADDING_TAGS_MODE) {
          return this.processSetAddingTagsMode(action.data);
        }
        else { console.log('not allowed', action); }
        break;
      case GroundGroupsMapState.ADDING_TAGS:
        if (action.action === GroundGroupsMapActionName.RESET) {
          return this.processReset();
        }
        else if (action.action === GroundGroupsMapActionName.SAVE_TAGS) {
          return this.processSaveTags();
        }
        else if (action.action === GroundGroupsMapActionName.REMOVE_GROUND_GROUP){
          return this.processRemoveGroundGroup();
        }
        else { console.log('not allowed', action); }
        break;
    }
    return Promise.resolve(currentState);
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(){
    const p1 = this.groundGroupsService.getGroundGroups(true).then(res => {
      this.groups = res;
      return res;
    });
    const p2 = this.layoutService.getMap().then(res => {
      this.map = res;
      return res;
    });
    const p3 = this.tenantsService.get().then(res =>{
      this.tenantLevelsEnabled = res.levelsEnabled;
      return res;
    });
    return Promise.all([p1, p2, p3]);
  }

  async groupHighLighted(event){
    let action;
    const {hover, id} = event;
    switch (this.state) {
      case GroundGroupsMapState.DEFAULT:
        const group = this.getGroupById(id);
        const positions = group.positions.map(p => p.rfId);
        action = {
          action: GroundGroupsMapActionName.SET_HIGHLIGHT_MODE,
          data: {
            groundGroupId: id,
            positions
          }
        };
        break;
      case GroundGroupsMapState.GROUP_HIGHLIGHT:
        if (this.currentGroundGroupId !== id || hover === false){
          action = {
            action: GroundGroupsMapActionName.RESET
          };
        }
        break;
    }
    this.state = await this.processAction(this.state, action);
  }

  async groupClicked(groundGroupId){
    let action;
    switch (this.state) {
      case GroundGroupsMapState.DEFAULT:
      case GroundGroupsMapState.GROUP_HIGHLIGHT:
        const group = this.getGroupById(groundGroupId);
        const positions = group.positions.map(p => p.rfId);
        action = {
          action: GroundGroupsMapActionName.SET_ADDING_TAGS_MODE,
          data: {
            groundGroupId,
            positions
          }
        };
        break;
      case GroundGroupsMapState.ADDING_TAGS:
        if (groundGroupId === this.currentGroundGroupId){
          action = {
            action: GroundGroupsMapActionName.SAVE_TAGS,
          };
        }
        break;
      default:
        action = null;
    }
    this.state = await this.processAction(this.state, action);
  }

  removeSector(){
    const group = this.getGroupById(this.currentGroundGroupId);
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: { label: 'Deseja deletar o grupo:' + group.name }
    });
    return dialogRef.afterClosed().pipe(first()).subscribe(async (res) => {
      if (res === 'SUBMITTED'){
        let action;
        switch (this.state) {
          case GroundGroupsMapState.ADDING_TAGS:
            action = {
              action: GroundGroupsMapActionName.REMOVE_GROUND_GROUP,
              data: {
                groundGroupId:this.currentGroundGroupId,
              }
            };
            break;
          default:
            action = null;
        }
        this.state = await this.processAction(this.state, action);
      }
    });
  }

  positionSelected(position){
    if (this.selectedPositionIds.has(position.rfId)){
      this.selectedPositionIds.delete(position.rfId);
    } else{
      this.selectedPositionIds.add(position.rfId);
    }
    this.selectedPositionIds = new Set<string>(this.selectedPositionIds);
  }

  someGroundPositionHasDifferentType(){
    const group = this.getGroupById(this.currentGroundGroupId);
    for (const id of this.selectedPositionIds){
      const p = this.getPositionByRfId(id);
      if (p.type !== null && p.type !== group?.type) { return true; }
    }
    return false;
  }

  async updateGroundGroup(){
    const rfidTags: string[] = Array.from(this.selectedPositionIds) as string[];
    const payload = {
      groundPositionGroupId: this.currentGroundGroupId,
      rfidTags,
      overwrite: true
    };
    await this.actionService.setTagsToGroundGroup(payload);
  }

  async removeGroundGroup(){
    const payload = {
      groundPositionGroupId: this.currentGroundGroupId
    };
    await this.actionService.removeGroundGroup(payload);
  }

  getGroupById(id){
    for (const g of this.groups){
      if (g.id === id) { return g; }
    }
    return null;
  }

  getPositionByRfId(rfId){
    for (const p of this.map){
      if (p.rfId === rfId) { return p; }
    }
    return null;
  }

  openConfirmRemoveModal() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: { label: 'Existem Tags com tipos diferentes da do grupo! Sobrescrever?' }
    });
    return dialogRef.afterClosed().pipe(first()).toPromise();
  }

  openModal() {
    const dialogRef = this.dialog.open(AddGroupModalComponent, {
      panelClass: 'confirm-modal',
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res) {
        this.actionService.addGroundGroup(res).then(() => {
          this.loadData();
        });
      }
    });
  }

  async finish(){
    if(this.tenantLevelsEnabled){
      await this.actionService.setTenantState({state: TenantState.SETUP_LEVELS});
      await this.router.navigate(['/setup-levels']);
    } else{
      await this.actionService.setTenantState({state: TenantState.SETUP_REVIEW});
      await this.router.navigate(['/setup-finish']);
    }
  }
  async toSetup(){
    await this.router.navigate(['/setup']);
  }

}
