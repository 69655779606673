<div class="page-header">
  <app-prev-next-button (click)="toSetup()" direction="left" label="setup"></app-prev-next-button>
  <h1>Mapa de Tags</h1>
  <button mat-raised-button (click)="finish()">
  Ir para próximo passo
</button>
</div>

<div class="page-content">
  <div class="right-side">
    <app-side-color-card color="primary-1">
      <app-ground-position-tag-map id="zz"
                             [map]="map"
                             content #mapComponent>
      </app-ground-position-tag-map>
    </app-side-color-card>
  </div>
