import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-picking-group-in-separation-item',
  templateUrl: './picking-group-in-separation-item.component.html',
  styleUrls: ['./picking-group-in-separation-item.component.scss']
})
export class PickingGroupInSeparationItemComponent implements OnInit {

  @Input() item;
  @HostBinding('class.isOdd') @Input() isOdd;
  date;
  @Output() openNfeConfirmModalEvent = new EventEmitter();
  @Output() openFakeNfeModalEvent = new EventEmitter();
  @Output() itemClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.date = moment(this.item.issuedAt).format('DD/MM/YYYY - HH:mm');
  }

  isDevOrHom(): boolean {
    return environment.name === 'dev' || environment.name === 'hom';
  }

  openConfirmModal(item) {
    this.openNfeConfirmModalEvent.emit(item);
  }

  openFakeNfeModal(item) {
    this.openFakeNfeModalEvent.emit(item);
  }

  emulateNfe(item) {
    this.openFakeNfeModalEvent.emit(item);
  }

  translateStatus(status) {
    switch (status) {
      case 'READY_TO_INVOICE':
        return 'Pronto para emitir NFe';
      case 'PREPARING_FOR_DISPATCH':
        return 'Preparando para expedição';
      default:
        return status || '---';
    }
  }

  itemClickHandler(): void {
    this.itemClick.emit(this.item);
  }
}
