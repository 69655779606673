import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PickingService {

  constructor(private http: HttpClient) { }

  getDocuments() {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/picking').toPromise<any>();
  }

  getDetails(orderNumber) {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/picking/${orderNumber}`).toPromise<any>();
  }

  postFakeNfe(body) {
    return this.http.post(environment.baseUrl + '/api/v1/admin-desktop/mock-invoice', body).toPromise<any>();
  }
  fakeNfeIn(no:string){
    return this.http.post(environment.baseUrl + '/api/v1/admin-desktop/mock-out-order',{orderNo:no}).toPromise<any>();

  }
}
