import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IReport } from '../../../models/Report';
import * as moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import { ReportDetailsModalComponent } from '../../modals/report-details-modal/report-details-modal.component';
import { ReportDataSourceService } from '../../core/data-sources/report-data-source.service';
import { ActionService, InventoryReviewAction, InventoryTypesEnum } from '../../core/services/action.service';
import { ActionsEnum } from '../../core/data-sources/action-data-source.service';
import { ErrorService } from '../../core/services/error.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  reports: IReport[];
  actualHour = moment().format('HH:mm');

  isLoading = false;

  constructor(private router: Router,
              private dialog: MatDialog,
              private reportDataSource: ReportDataSourceService,
              private actionService: ActionService,
              private errorService: ErrorService) { }

  ngOnInit(): void {
    this.getReports();
  }

  async getReports(): Promise<void> {
    try {
      this.isLoading = true;
      this.reports = await this.reportDataSource.getInventories();
      this.reports = this.reports.filter(report => report.data.type !== InventoryTypesEnum.INVENTORY_BY_TAG_RFID);
      this.reports.sort((a, b) => {
        return (b.createdAt - a.createdAt);
      });
      this.isLoading = false;
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
      this.isLoading = false;
    }
  }

  itemClicked(report: IReport) {
    const dialogRef = this.dialog.open(ReportDetailsModalComponent, {
      panelClass: 'details-modal',
      data: report,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((res: {state: 'CANCELED' | 'REJECTED' | 'APPROVED', inventoryId: string}) => {
      if (!res) {
        return;
      }

      const data: InventoryReviewAction = {
        type: ActionsEnum.APPROVE_INVENTORY,
        data: {
          inventoryId: res.inventoryId
        }
      };
      switch (res.state) {
        case 'CANCELED':
          return;
        case 'APPROVED':
          data.type = ActionsEnum.APPROVE_INVENTORY;
          this.actionService.inventoryReview(data).then(() => {
            this.update();
          });
          break;
        case 'REJECTED':
          data.type = ActionsEnum.REJECT_INVENTORY;
          this.actionService.inventoryReview(data).then(() => {
            this.update();
          });
          break;
      }
    });
  }

  toStock() {
    this.router.navigate(['stock']);
  }

  update() {
    this.getReports();
    this.actualHour = moment().format('HH:mm');
  }
}
