import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

interface GroundGroupLine {
  id: string;
  name: string;
  type: string;
  slots: number;
  selected: boolean;
}


@Component({
  selector: 'app-ground-groups-list',
  templateUrl: './ground-groups-list.component.html',
  styleUrls: ['./ground-groups-list.component.scss']
})
export class GroundGroupsListComponent implements OnInit, OnChanges {

  @Input() groups;
  @Input() state;
  @Input() selectedGroupId;

  @Output() onGroupClick = new EventEmitter();
  @Output() onGroupHighLight = new EventEmitter();

  lines: GroundGroupLine[] = [];

  constructor() { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.groups){
      this.groupToLines(this.groups);
    }
  }
  groupToLines(groundGroups){
    const lines = groundGroups.map(g =>{
      return {
        id:g.id,
        name:g.name,
        type: g.type,
        slots: g.positions?.length || 0,
        selected: g.id === this.selectedGroupId
      };
    });
    this.lines = lines.sort((a,b) => {
      return (a.type + a.name).localeCompare(b.type+b.name);
    });
  }

  itemClick(item){
    this.onGroupClick.emit(item.id);
  }
  itemHighLight(item, hover){
    this.onGroupHighLight.emit({
      id: item.id,
      hover
    });
  }
}
