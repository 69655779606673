<div class="up-card" [ngClass]="{'no-margin': !displayList}">
  <div class="title" [ngClass]="{'red-text': isDivergent}">
    <app-background-icon backgroundColor="alert" icon="times" *ngIf="isDivergent"></app-background-icon>
    {{ title + ' (' + quantity + ')' | uppercase }}
  </div>
  <div class="controls">
    <p *ngIf="displayChangeSectorForm && handleSelectedCount() === 1">Redirecionar {{ handleSelectedCount() }} pedido</p>
    <p *ngIf="displayChangeSectorForm && handleSelectedCount() > 1">Redirecionar {{ handleSelectedCount() }} pedidos</p>
    <mat-form-field *ngIf="displayChangeSectorForm">
      <mat-label>Selecione o destino</mat-label>
      <mat-select (selectionChange)="onSelectChange($event.value)" [value]="selectedSector">
        <mat-option [value]="'STORAGE'" *ngIf="title !== 'armazenagem'">Armazenagem</mat-option>
        <mat-option [value]="'QUALITY'" *ngIf="title !== 'controle de qualidade'">Controle de qualidade</mat-option>
        <mat-option [value]="'STOCK_DIVERGENT'" *ngIf="title !== 'estoque divergente'">Estoque divergente</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button
            class="secondary-2"
            *ngIf="displayChangeSectorForm"
            (click)="submitChangeSector()" [disabled]="!selectedSector">OK</button>
    <div class="min-max-button" (click)="toggleList()">
      <i class="uil uil-minus" *ngIf="displayList"></i>
      <img src="assets/icons/window-maximize.svg" alt="" *ngIf="!displayList">
    </div>
  </div>
</div>

<div class="content">
  <div class="list" *ngIf="!isEmpty && displayList">
    <app-screening-group-header [selected]="isAllSelected" (selectAllEvent)="onSelectAll($event)"></app-screening-group-header>
    <div  *ngIf="statePage" class="loader">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <ng-container *ngIf="!statePage">
      <app-screening-group-item *ngFor="let pallet of displayedDocuments; let odd = odd"
                                            [pallet]="pallet"
                                            [isOdd]="odd"
                                            (selectCheckboxEvent)="onCheckboxItemChange($event)"
                                            [selected]="highlightedProducts[pallet.invoiceId.toString() + '/' + pallet.sku]"
                                            (goToOther)="handleItemClick(pallet, 0)"
                                            (goToComment)="handleItemClick(pallet, 3)">
      </app-screening-group-item>
    </ng-container>

  </div>

  <div class="empty" *ngIf="isEmpty && displayList && !statePage">
    <ng-content select="[empty]"></ng-content>
  </div>

  <div class="more" *ngIf="!displayAll && displayShowMoreToggle && displayList && !statePage" (click)="toggleAllDocuments()">
    <i class="uil uil-angle-down"></i> todos os produtos
  </div>
  <div class="less" *ngIf="displayAll && displayShowMoreToggle && displayList && !statePage" (click)="toggleAllDocuments()">
    <i class="uil uil-angle-up"></i> visualizar menos
  </div>
</div>

