import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, throwError} from 'rxjs';
import {LoginService} from '../services/login.service';
import {Session, SessionService} from '../services/session.service';
import {catchError, switchMap, take, filter} from 'rxjs/operators';
import {AuthDataSourceService} from '../data-sources/auth-data-source.service';
import {AuthService} from '../services/auth.service';
import jwtDecode from 'jwt-decode';
import {Router} from '@angular/router';
import { ErrorsEnum } from '../services/error.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private loginService: LoginService,
              private authDataSourceService: AuthDataSourceService,
              private authService: AuthService,
              private sessionService: SessionService,
              private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const isLoggedIn: boolean = this.authService.isLoggedIn();
    return next.handle(req).pipe(catchError(error => {
      console.log(error);
      if (error instanceof HttpErrorResponse
        && isLoggedIn && error.status === 403
        && error.error.type !== ErrorsEnum.PASSWORD_VALIDATION_FAILED
        && error.error.type !== ErrorsEnum.PRODUCTS_FORBIDDEN_ACCESS) {
        this.goToLogin();
      }
      if (error instanceof HttpErrorResponse && isLoggedIn && error.status === 401) {
        return this.handle401Error(req, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private goToLogin(){
    this.sessionService.clearSessionStorage();
    this.authService.isLoggedIn$.next(false);
    this.router.navigate(['login']);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const currentSession: Session = this.sessionService.getSession();
      return this.authDataSourceService.refreshToken(currentSession.refreshToken).pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          const newSession: Session = {...currentSession};
          const {exp}: any = jwtDecode(response.accessToken);

          newSession.accessToken = response.accessToken;
          newSession.refreshToken = response.refreshToken;
          newSession.exp = exp;
          this.sessionService.saveSession(newSession);

          const freshRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${response.accessToken}`
            }
          });

          this.refreshTokenSubject.next(response.accessToken);
          return next.handle(freshRequest);
        }),
        catchError((error) => {
          this.isRefreshing = false;
          if (error instanceof HttpErrorResponse){
            const status: number = error.status;
            switch (status) {
              case 403:
              case 401:
              case 404:
                this.goToLogin();
                break;
              default:
                return throwError(error);
            }
          }else{
            return throwError(error);
          }
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          const freshRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt}`
            }
          });
          return next.handle(freshRequest);
        }));
    }
  }
}
