<div class="up-modal">
  <div class="left">
    <div class="column">
      <app-info-oc-or-nfe label="Nº OC" [value]="orderNumbers ? orderNumbers : order?.number"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Razão Social" [value]="order?.vendor?.name"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Endereço" [value]="order?.address?.street"></app-info-oc-or-nfe>
    </div>
    <div class="column two">
      <app-info-oc-or-nfe label="Data/Hora de Emissão" [value]="timestampToDate(order?.issuedAt)"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="CPF/CNPJ Fornecedor" [value]="order?.vendor.doc"></app-info-oc-or-nfe>
      <div *ngIf="data.relatedInvoices.length > 0">
        <small>Notas fiscais relacionadas</small>
        <div class="related-invoices">
          <div class="invoice-number"
               *ngFor="let invoice of data.relatedInvoices; let last = last"
               (click)="itemClickHandler(invoice)">
            {{ last ? invoice.id : invoice.id + ', ' }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="right">
    <app-provider-contact [provider]="{name: order?.vendor?.spokesman ? order.vendor.spokesman : '-',
                                       phone: order?.vendor?.phone ? order.vendor.phone : '-',
                                       email: order?.vendor?.email ? order.vendor.email : '-'}">
    </app-provider-contact>
  </div>
</div>

<div class="down-modal">
  <div class="header">
    <div class="product-code column">
      Código de produto
    </div>
    <div class="provider-code column">
      Código do fornecedor
    </div>
    <div class="description column">
      Descrição
    </div>
<!--    <div class="packaging column">-->
<!--      Embalagem-->
<!--    </div>-->
    <div class="unit-of-measurement column">
      UM
    </div>
    <div class="quantity column">
      QTD
    </div>
  </div>

  <div class="item" *ngFor="let product of orderProducts">
    <div class="product-code column">
      {{ product.sku }}
    </div>
    <div class="provider-code column">
      {{ product.vendorCode }}
    </div>
    <div class="description column">
      {{ product.name }}
    </div>
<!--    <div class="packaging column">-->
<!--      Pallet-->
<!--    </div>-->
    <div class="unit-of-measurement column">
      {{ product.unit }}
    </div>
    <div class="quantity column">
      {{ product.quantity }}
    </div>
  </div>
</div>
