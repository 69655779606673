import { PaginationService } from 'src/app/core/services/pagination.service';
import { Injectable } from '@angular/core';
import { ProductManagementFiltersParams } from '../../../models/Product';
import { ProductManagementDataSourceService } from '../data-sources/product-management-data-source.service';


@Injectable({
  providedIn: 'root'
})
export class ProductManagementService {

  constructor(
    private dataSource: ProductManagementDataSourceService,
    public paginationService: PaginationService) { }

  async init(filter: ProductManagementFiltersParams = null, sortParams?) {
    return this.getPage(1, filter, sortParams);
  }

  async setPageSize(pageSize: number) {
    this.paginationService.pageSize = pageSize;
    this.paginationService.pageCount = Math.ceil(this.paginationService.totalItems / this.paginationService.pageSize);
    this.paginationService.$pageSize.next(this.paginationService.pageSize);
    await this.getPage(1);
  }

  async reload(filter: ProductManagementFiltersParams = null, sortParams?) {
    return await this.getPage(this.paginationService.currentPage, filter, sortParams);
  }

  async getNextPage(filter: ProductManagementFiltersParams = null) {
    if (this.paginationService.currentPage < this.paginationService.pageCount) {
      return await this.getPage(this.paginationService.currentPage + 1, filter);
    } else {
      throw Error('???');
    }
  }

  async getPreviousPage(filter: ProductManagementFiltersParams = null) {
    if (this.paginationService.currentPage > 1) {
      return await this.getPage(this.paginationService.currentPage - 1, filter);
    } else {
      throw Error('???');
    }
  }

  private async getPage(page: number, filter: ProductManagementFiltersParams = {}, sortParams?) {
    this.paginationService.currentPage = page;
    const params = {
      limit: this.paginationService.pageSize,
      offset: (this.paginationService.currentPage - 1) * this.paginationService.pageSize
    };
    Object.assign(params, filter);
    Object.assign(params, sortParams);
    const response = await this.dataSource.getProducts(params);
    this.paginationService.totalItems = response.count;
    this.paginationService.pageCount = Math.ceil(this.paginationService.totalItems / this.paginationService.pageSize);
    this.paginationService.$items.next(response.values);
    this.paginationService.$pageCount.next(this.paginationService.pageCount);
    this.paginationService.$totalItems.next(this.paginationService.totalItems);
    this.paginationService.$currentPage.next(this.paginationService.currentPage);
    this.paginationService.$atLastPage.next(this.paginationService.pageCount === 0 || this.paginationService.currentPage === this.paginationService.pageCount);
    this.paginationService.$atFirstPage.next(this.paginationService.currentPage === 1);
  }

}
