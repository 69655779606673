import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullLoaderService {

  public isOpen$ = new BehaviorSubject(false);

  constructor() { }

  open(){
    this.isOpen$.next(true);
  }
  
  close(){
    this.isOpen$.next(false);
  }
}
 