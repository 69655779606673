<app-table-header-column name="date" size="medium">
  <span content>
    Data de emissão
    <app-filter-button
      [column]="'createdAt'"
      [menu]="dateFilterMenu"
      [appliedFilters]="appliedFilters">
    </app-filter-button>
  </span>
</app-table-header-column>

<mat-menu #dateFilterMenu="matMenu">
  <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <h4>Filtrar por data de entrada</h4>
    <button (click)="sort('createdAt', 'DESC')" mat-menu-item>
      Ordem decrescente
      <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'DESC'"></i>
    </button>
    <button (click)="sort('createdAt', 'ASC')" mat-menu-item>
      Ordem crescente
      <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'ASC'"></i>
    </button>
    <form [formGroup]="dateFiltersForm">
      <mat-form-field appearance="outline">
        <mat-label>Data inicial</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="start" [max]="maxDate" disabled>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Data final</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="end" [max]="maxDate" disabled>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </form>
  </div>
</mat-menu>

<app-table-header-column name="admin" size="medium">
  <span content>
    Responsável
    <app-filter-button
      [column]="'adminName'"
      [menu]="adminNameMenu"
      [appliedFilters]="appliedFilters">
    </app-filter-button>
  </span>
</app-table-header-column>

<mat-menu #adminNameMenu="matMenu">
  <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <h4>Filtrar por responsável</h4>
    <button (click)="sort('adminName', 'DESC')" mat-menu-item>
      Ordem decrescente
      <i class="uil uil-check" *ngIf="showCheckIcon['adminName'] && sortDirection == 'DESC'"></i>
    </button>
    <button (click)="sort('adminName', 'ASC')" mat-menu-item>
      Ordem crescente
      <i class="uil uil-check" *ngIf="showCheckIcon['adminName'] && sortDirection == 'ASC'"></i>
    </button>
      <mat-form-field appearance="outline">
        <input matInput
               placeholder="Responsável"
               [formControl]="adminNameFormControl"
               autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
  </div>
</mat-menu>

<app-table-header-column name="type" size="medium">
  <span content>
    Tipo de inventário
    <app-filter-button
      [column]="'type'"
      [menu]="inventoryTypeMenu"
      [appliedFilters]="appliedFilters">
    </app-filter-button>
  </span>
</app-table-header-column>

<mat-menu #inventoryTypeMenu="matMenu">
  <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <h4>Filtrar por tipo de inventário</h4>
    <button (click)="sort('type', 'DESC')" mat-menu-item>
      Ordem decrescente
      <i class="uil uil-check" *ngIf="showCheckIcon['type'] && sortDirection == 'DESC'"></i>
    </button>
    <button (click)="sort('type', 'ASC')" mat-menu-item>
      Ordem crescente
      <i class="uil uil-check" *ngIf="showCheckIcon['type'] && sortDirection == 'ASC'"></i>
    </button>
    <mat-form-field class="dropdown" appearance="outline">
      <mat-label>Tipo de inventário</mat-label>
      <mat-select [(value)]="inventoryTypeValue">
        <mat-option *ngFor="let option of inventoryTypeOptions" [value]="option.value">{{ option.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <section class="actions">
      <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
      <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
    </section>
  </div>
</mat-menu>

<app-table-header-column name="total" size="medium">
  <span content>Total de itens</span>
</app-table-header-column>

<app-table-header-column name="qty-found" size="medium">
  <span content>Qtd. encontrada</span>
</app-table-header-column>

<app-table-header-column name="qty-missing" size="medium">
  <span content>Qtd. perdida</span>
</app-table-header-column>

<app-table-header-column name="state" size="small">
  <span content>
    Estado
    <app-filter-button
      [column]="'state'"
      [menu]="stateFilterMenu"
      [appliedFilters]="appliedFilters">
    </app-filter-button>
  </span>
</app-table-header-column>

<mat-menu #stateFilterMenu="matMenu">
  <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <h4>Filtrar por estado</h4>
    <button (click)="sort('state', 'DESC')" mat-menu-item>
      Ordem decrescente
      <i class="uil uil-check" *ngIf="showCheckIcon['state'] && sortDirection == 'DESC'"></i>
    </button>
    <button (click)="sort('state', 'ASC')" mat-menu-item>
      Ordem crescente
      <i class="uil uil-check" *ngIf="showCheckIcon['state'] && sortDirection == 'ASC'"></i>
    </button>
    <mat-form-field class="dropdown" appearance="outline">
      <mat-label>Estado</mat-label>
      <mat-select [(value)]="stateValue">
        <mat-option *ngFor="let option of stateOptions" [value]="option.value">{{ option.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <section class="actions">
      <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
      <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
    </section>
  </div>
</mat-menu>
