import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { appendParams } from 'src/app/helpers/append-query-params';

@Injectable({
  providedIn: 'root'
})
export class GroundPositionService {

  constructor(private http: HttpClient) { }

  getDetails(rfId: string) {
    const endpoint = `${environment.baseUrl}/api/v1/admin-desktop/ground-position/${rfId}`;
    return this.http.get(endpoint).toPromise<any>();
  }

  getGroundPositions(x?: number, y?: number) {
    const queryParams = {
      x: x,
      y: y
    };
    const params = appendParams(queryParams);
    const endpoint = `${environment.baseUrl}/api/v1/admin-desktop/ground-positions`;
    return this.http.get(endpoint, {params}).toPromise<any>();
  }
}
