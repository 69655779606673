<div class="lower-content">
  <div class="title" *ngIf="title">{{ title }}</div>

  <table mat-table [dataSource]="dataMovement" class="table">
    <ng-container matColumnDef="type">
      <th mat-header-cell class="header" *matHeaderCellDef>Tipo de movimentação</th>
      <td mat-cell *matCellDef="let element">{{ translateMovementType(element.type) }}</td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell class="header" *matHeaderCellDef>Usuário</th>
      <td mat-cell *matCellDef="let element">{{ showUsername(element.user) }}</td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th mat-header-cell class="header" *matHeaderCellDef>Posição</th>
      <td mat-cell *matCellDef="let element">{{ formatPosition(element.x, element.y) }}</td>
    </ng-container>

    <ng-container matColumnDef="movedAt">
      <th mat-header-cell class="header" *matHeaderCellDef>Data da movimentação</th>
      <td mat-cell *matCellDef="let element">{{ timestampToDate(element.movedAt) }}</td>
    </ng-container>

    <tr mat-header-row class="header-container" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="item-container"
        *matRowDef="let row; columns: displayedColumns; let odd = odd"
        [ngClass]="{'strong-gray': odd}"></tr>
  </table>

  <div *ngIf="!dataMovement.length" class="no-item">
    Não existem movimentações para este pallet
  </div>
</div>
