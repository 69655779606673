import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-out-order-plan-modal',
  templateUrl: './out-order-plan-modal.component.html',
  styleUrls: ['./out-order-plan-modal.component.scss']
})
export class OutOrderPlanModalComponent implements OnInit {

  executionPlan;

  constructor(public dialogRef: MatDialogRef<OutOrderPlanModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.executionPlan = data;
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close(false);
  }

  handleSubmitButtonEvent() {
    this.dialogRef.close({ shouldSubmit: true, executionPlan: this.executionPlan });
  }
  
}
