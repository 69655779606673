import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskRequestSidenavService } from '../../../core/services/task-request-sidenav.service';
import { SidenavFooterAction } from '../../../shared/sidenav-footer/sidenav-footer.component';
import { SideNavResponse } from '../../stock/side-nav/SideNav';
import { ActionService, RemoveProductFromPickingPositionAction } from '../../../core/services/action.service';
import { ActionsEnum } from '../../../core/data-sources/action-data-source.service';
import { toExcelFormat } from '../../../helpers/format-position';
import { SidenavDestinationComponent } from '../../../shared/sidenav-destination/sidenav-destination.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-relocate-from-picking-position-sidenav',
  templateUrl: './relocate-from-picking-position-sidenav.component.html',
  styleUrls: ['./relocate-from-picking-position-sidenav.component.scss']
})
export class RelocateFromPickingPositionSidenavComponent implements OnInit, OnChanges {

  @Input() drawer;
  @Input() data;

  @ViewChild('destination', {static: true}) destination: SidenavDestinationComponent;

  selectedUsers = [];
  selectOperatorError = false;

  productOptions = [];
  pickingPositionOptions = [];

  dataDestination;

  relocateForm: FormGroup;

  productInputSubscription: Subscription;

  toExcelFormat = toExcelFormat;

  constructor(private formBuilder: FormBuilder,
              private sidenavService: TaskRequestSidenavService,
              private actionService: ActionService) {
    this.relocateForm = this.formBuilder.group({
      product: ['', Validators.required],
      position: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reset();

    if (changes.data.currentValue && changes.data.currentValue.groundPosition){
      const {productName} = changes.data.currentValue;
      const {x, y} = changes.data.currentValue.groundPosition;
      this.relocateForm.controls.product.setValue(productName);
      this.relocateForm.controls.position.setValue(this.toExcelFormat(x, y));
    }

    if (changes.data.currentValue && changes.data.currentValue.products?.length) {
      this.productOptions = changes.data.currentValue.products;

      // populate position dropdown when user selects a product
      this.productInputSubscription = this.relocateForm.get('product').valueChanges.subscribe(product => {
        this.relocateForm.get('position').reset();
        this.pickingPositionOptions = [];
        if (typeof product === 'object') {
          this.pickingPositionOptions = product.pickingPositions;
        }
      });
    }
  }

  destinationChanged($event){
    this.dataDestination = $event;
  }

  selectUsers(users) {
    this.selectedUsers = users;
    this.selectOperatorError = this.selectedUsers.length === 0;
  }

  async sendTask() {
    const { data: destination } = this.dataDestination;
    const { x, y } = this.data.groundPosition
      ? this.data.groundPosition
      : this.relocateForm.get('position').value.groundPosition;
    const params: RemoveProductFromPickingPositionAction = {
      type: ActionsEnum.REMOVE_PRODUCT_FROM_PICKING_POSITION,
      data: {
        userIds: this.selectedUsers,
        pickingPosition: {x, y},
        destiny: {
          x: destination.address.x,
          y: destination.address.y
        }
      }
    };
    await this.actionService.removeProductFromPickingPosition(params);
  }

  async footerClicked(event){
    if (event === SidenavFooterAction.SUBMMITED){
      this.relocateForm.markAllAsTouched();
      this.destination.form.markAllAsTouched();

      if (
        (this.dataDestination && this.dataDestination.valid) &&
        this.relocateForm.valid &&
        this.selectedUsers.length > 0
      ) {
        await this.sendTask();
        this.close(SideNavResponse.SUBMMITED);
      } else {
        this.selectOperatorError = this.selectedUsers.length === 0;
      }

    } else {
      this.close(SideNavResponse.CLOSED);
    }
  }

  displayedProductString(product) {
    if (product) {
      return (product.name || product.description) + ' - ' + product.sku;
    } else {
      return null;
    }
  }

  displayPositionString(position) {
    if (position) {
      return toExcelFormat(position.groundPosition?.x, position.groundPosition?.y);
    }
    return null;
  }

  reset() {
    if (this.productInputSubscription) {
      this.productInputSubscription.unsubscribe();
    }
    this.destination.form.reset();
    this.relocateForm.reset();
    this.selectedUsers = [];
  }

  close(event: SideNavResponse = SideNavResponse.CLOSED) {
    this.reset();
    this.drawer.close();
    this.sidenavService.closeSidenav(event);
  }

}
