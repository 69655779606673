import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-screening-group-item',
  templateUrl: './screening-group-item.component.html',
  styleUrls: ['./screening-group-item.component.scss']
})
export class ScreeningGroupItemComponent implements OnInit {

  @Input() pallet;
  @Input() @HostBinding('class.primary-bg') selected = true;
  @Input() @HostBinding('class.isOdd') isOdd: boolean;

  @Output() goToComment = new EventEmitter();
  @Output() goToOther = new EventEmitter();

  @Output() selectCheckboxEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleDeliveryDate(buyOrders, deliveryDates) {
    const dates = [];
    if(buyOrders){
      for (const oc of buyOrders) {
        if (deliveryDates[oc]) {
          return moment(deliveryDates[oc]).format('DD/MM/YYYY - HH:mm');
        }
      }
    }
  }

  handleComment(event){
    this.goToComment.emit();
  }
  handleGotoOther(event){
    this.goToOther.emit();
  }

  onCheckboxChange(pallet) {
    this.selected = !this.selected;
    const id = pallet.invoiceId.toString() + '/' + pallet.sku;
    this.selectCheckboxEvent.emit(id);
  }
}
