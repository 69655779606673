import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {toExcelFormat} from '../../helpers/format-position';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export interface PasswordConfirmationModalData {
  tagRfid: string
}

@Component({
  selector: 'app-password-confirmation-modal',
  templateUrl: './password-confirmation-modal.component.html',
  styleUrls: ['./password-confirmation-modal.component.scss']
})
export class PasswordConfirmationModalComponent implements OnInit {

  form: FormGroup;
  tagRfid: string;
  constructor(public dialogRef: MatDialogRef<PasswordConfirmationModalComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: PasswordConfirmationModalData) {
    this.tagRfid = data.tagRfid;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: [null, Validators.required]
    });
  }

  formatPosition(x, y) {
    return toExcelFormat(x, y);
  }

  close() {
      this.dialogRef.close();
  }

  submmit() {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        const password = this.form.controls.password.value;
        this.dialogRef.close({password});
      }
  }

}
