import { Injectable } from '@angular/core';
import {TenantInterface} from '../../../models/Tenant';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HeatMap} from '../../../models/HeatMap';

@Injectable({
  providedIn: 'root'
})
export class ForkliftDataSourceService {

  constructor(private httpClient: HttpClient) { }

  async getByType(type: string, asFile?: boolean): Promise<HeatMap[]> {
    const headers = {
      'Content-Type': 'application/json'
    };
    let url = environment.baseUrl + '/api/v1/admin-desktop/heat-maps?type=' + type;
    if(asFile){
      url += `&asFile=${asFile}`;
    }
    return this.httpClient.get(url, { headers }).toPromise<any>();
  }
  async importByType(type: string, values: []): Promise<any> {
    const headers = {
      'Content-Type': 'application/json'
    };
    const url = environment.baseUrl + '/api/v1/admin-desktop/heat-maps?type=' + type;
    return this.httpClient.put(url, {values}, { headers }).toPromise<any>();
  }
  async removeByType(type: string): Promise<HeatMap[]> {
    const headers = {
      'Content-Type': 'application/json'
    };
    const url = environment.baseUrl + '/api/v1/admin-desktop/heat-maps?type=' + type;
    return this.httpClient.delete(url, { headers }).toPromise<any>();
  }
}
