import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-dispatch-group',
  templateUrl: './dispatch-group.component.html',
  styleUrls: ['./dispatch-group.component.scss']
})
export class DispatchGroupComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() quantity: number;
  @Input() windowSize: number;
  @Input() type: string;
  @Input() documents;
  @Input() isLoading;
  @Input() itemTemplate: TemplateRef<any>;

  displayedDocuments;

  displayAll: boolean;
  displayShowMoreToggle: boolean;
  isEmpty: boolean;

  constructor() {
    this.quantity = 0;
    this.windowSize = 3;
    this.displayShowMoreToggle = true;
    this.displayAll = false;
    this.isEmpty = true;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  toggle(event){
    this.displayAll = !this.displayAll;
    this.update();
  }

  update() {
    if (!this.displayAll){
      this.displayedDocuments = this.documents?.slice(0, this.windowSize);
    } else{
      this.displayedDocuments = [...this.documents];
    }
    this.quantity = this.documents?.length;

    if (this.documents === null) {
      this.quantity = 0;
      this.isEmpty = true;
    } else {
      this.isEmpty = this.quantity === 0;
    }
    this.displayShowMoreToggle = this.quantity > this.windowSize;
  }
}
