<div class="notifier" (click)="openDetailsModal()">
  <div class="title">
    <i  *ngIf="data.message.type === 'FAILED'" class="uil uil-exclamation-triangle"></i>
    {{ data.message.title }}
  </div>
  <div class="info">
    <p>{{ data.message.message }}</p>
    <p>{{ timestampToDate(data.message.timestamp.seconds) }}</p>
  </div>
</div>
