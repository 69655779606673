
<div class="background-label">
  <div class="top-label">
    <p>Selecione o período</p>
    <div class="background-hover" (click)="close()">
      <i class="uil uil-times" style="font-size: 16px; color: #ffff;"></i>
    </div>
  </div>

  <mat-calendar (selectedChange)="_onSelectedChange($event)"
              [selected]="selectedDateRange" >
</mat-calendar>
</div>

<p class="feedback">O período selecionado é limitado a consultas de no máximo 3 meses.</p>

