  <div class="left-side">
    <div class="title-row">
      <h3>{{title}}</h3>
      <button class="open-form-button" mat-raised-button (click)="openModal()">
        adicionar nível
      </button>
    </div>

    <div content class="table">
      <div class="item"  *ngIf="levels?.length === 0">
        Não existem níveis cadastrados neste setor.
      </div>

      <div class="header" *ngIf="levels?.length !== 0">
        <div class="column">ordem</div>
        <div class="column">nome</div>
        <div class="column">começo</div>
        <div class="column">referência</div>
        <div class="column">fim</div>
        <div class="column edit-sector"></div>
      </div>

      <div class="item"
           *ngFor="let level of levels; let odd = odd"
           [ngClass]="{'background-opacity': odd}">
        <div class="column">
          {{ level.order}}
        </div>
        <div class="column">
          {{ level.name}}
        </div>
        <div class="column">
          {{ level.start}} cm
        </div>
        <div class="column">
          {{ level.reference}} cm
        </div>
        <div class="column">
          {{ level.end}} cm
        </div>

        <div class="column edit-sector">
          <button mat-icon-button (click)="openConfirmRemoveModal(level)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="right-side">
    <app-level-preview
      [levels]="levels"
    ></app-level-preview>
  </div>
