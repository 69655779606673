import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-level-to-group-modal',
  templateUrl: './add-level-to-group-modal.component.html',
  styleUrls: ['./add-level-to-group-modal.component.scss']
})
export class AddLevelToGroupModalComponent implements OnInit {


  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<AddLevelToGroupModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      order: new FormControl('', Validators.required),
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
      reference: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
  }

  get name(){
    return this.form.get('name');
  }
  get order(){
    return this.form.get('order');
  }
  get end(){
    return this.form.get('end');
  }
  get start(){
    return this.form.get('start');
  }
  get reference(){
    return this.form.get('reference');
  }

  close(isSubmitted?) {
    if (isSubmitted) {
      this.form.get('name').markAsTouched();
      this.form.get('order').markAsTouched();
      this.form.get('start').markAsTouched();
      this.form.get('end').markAsTouched();
      this.form.get('reference').markAsTouched();

      if(!this.form.invalid){
        const values = this.form.getRawValue();
        this.dialogRef.close(values);
      }
    } else {
      this.dialogRef.close();
    }
  }

}
