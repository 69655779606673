<h3 [ngClass]="color">
  <i class="uil uil-exclamation-triangle"></i>
  Divergências
</h3>

<div class="table-border">
  <div class="table">
    <div class="header">
      <div class="product-code column">Código do produto</div>
      <div class="vendor-code column">Código do fornecedor</div>
      <div class="description column">Descrição</div>
      <div class="unit column">UM</div>
      <div class="quantity column">Qtd recebida</div>
      <div class="diff column">Diferença</div>
    </div>
    <div class="item" *ngFor="let product of allPhysicalProductsDivergent">
      <div class="product-code column">{{ product.sku }}</div>
      <div class="vendor-code column">{{ product.vendorCode }}</div>
      <div class="description column">{{ product.name }}</div>
      <div class="unit column">{{ product.unit }}</div>
      <div class="quantity column">{{ product.quantity }}</div>
      <div class="diff column">
        <div class="background-{{color}}">
          {{ product.difference }}
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="resolve">-->
<!--  <h4>RESOLUÇÕES</h4>-->
<!--  <mat-checkbox (change)="onCheckboxChange($event.checked)" [checked]="itemDetails?.invoice?.correctionIssued"><p>Correção requisitada</p></mat-checkbox>-->
<!--</div>-->

<div class="bottom">
  <div class="buttons">
    <button mat-stroked-button class="gray-2" (click)="cancel()">CANCELAR</button>
    <button mat-raised-button class="primary-2" (click)="submit()">OK</button>
  </div>
</div>
