<div class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Solicitação de tarefa" (closeEvent)="close()"></app-sidenav-header>

    <div class="pallet">
      <div class="top-line"></div>

      <div class="pallet-body">
        <div class="info-group">
          <div class="documents">
            <div class="info-header">
              <small>Pedido</small>
              <small>NFe</small>
            </div>
            <div class="info" *ngFor="let good of goods">
              <div>
                {{good.orderNumber}}
              </div>
              <div>
                {{good.invoiceNumber}}
              </div>
<!--              <app-info-oc-or-nfe label="" [value]="good.orderNumber"></app-info-oc-or-nfe>-->
<!--              <app-info-oc-or-nfe label="" [value]="good.invoiceNumber"></app-info-oc-or-nfe>-->
            </div>
          </div>
          <p><img src="../../../../assets/icons/rfid-blue.svg">{{ palletType === 'PALLET' ? 'Pallet' : 'Bin' }}</p>
        </div>
        <div class="destination">
          <p>DESTINO</p>
          <mat-form-field>
            <mat-select [(value)]="selectedDestination">
              <mat-option *ngFor="let destination of destinationOptions" [value]="destination.value">{{ destination.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="table">
          <div class="header">
            <div class="product">Produto</div>
            <div class="quantity">Quantidade</div>
          </div>
          <div class="item" *ngFor="let good of goods">
            <div class="product">{{ good.name }}</div>
            <div class="quantity">{{ good.quantity }}</div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="bottom-block"></div>
        <div class="bottom-line"></div>
        <div class="bottom-block"></div>
      </div>
    </div>

<!--    <mat-checkbox (change)="onCriticalChange($event.checked)"-->
<!--                  [checked]="isCritical">Tarefa crítica</mat-checkbox>-->

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>

    <app-operators-table device="FORKLIFT" [usersHighlighted]="usersHighlighted"
                         (usersHighlightedEvent)="setUsers($event)"
                         [openedStart]="drawer.openedStart"></app-operators-table>
  </div>

  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()">OK</button>
  </div>
</div>
