<div class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Solicitação de tarefa" (closeEvent)="close()"></app-sidenav-header>

    <form [formGroup]="inventoryForm" class="new-inventory-task-config">
      <div class="card-header">Configurar nova tarefa de inventário</div>
      <div class="card-body">
        <div class="product-input">
          <p>Digite o que deseja inventariar:</p>
          <mat-form-field appearance="outline">
            <mat-label>Tag RFID</mat-label>
            <input matInput autocomplete="off" formControlName="rfid" [attr.disabled]="disable">
          </mat-form-field>
        </div>
      </div>
    </form>

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>

    <app-operators-table device="HANDSCANNER"
                         [usersHighlighted]="usersHighlighted"
                         (usersHighlightedEvent)="setUsers($event)"
                         [openedStart]="drawer.openedStart"
                         [isForklift]="false"></app-operators-table>
  </div>

  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()">OK</button>
  </div>
</div>
