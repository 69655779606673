import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {TenantService} from '../services/tenant.service';

export interface TenantConfig {
  levelsEnabled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TenantConfigResolverResolver implements Resolve<TenantConfig> {

  constructor(private tenantService: TenantService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TenantConfig> {
    const isTenantLoaded = this.tenantService.isTenantLoaded();
    if(isTenantLoaded){
      return of({levelsEnabled: this.tenantService.levelsEnabled()});
    } else{
      return from(
        this.tenantService.get().then( tenant => {
          return {levelsEnabled: tenant.levelsEnabled};
        })
      );
    }

  }
}
