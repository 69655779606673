<div class="oc column">
  Nº OC
</div>
<div class="in-timestamp column">
  Data/Hora de Entrada
</div>
<div class="document column">
  CNPJ
</div>
<div class="name column">
  Razão Social
</div>
<div class="status column">
  Status
</div>
<div class="messages column">
  Comentários
</div>
