import {Component, HostBinding, Input, OnInit, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';

export enum TaskType {
  RECEIVING = 'RECEIVING',
  RELOCATE_POSITION = 'RELOCATE_POSITION',
  RELOCATE_DIVERGENT = 'RELOCATE_DIVERGENT',
  RELOCATE_EXPEDITION = 'RELOCATE_EXPEDITION',
  RELOCATE_FREE = 'RELOCATE_FREE',
  RELOCATE_STORAGE = 'RELOCATE_STORAGE',
  INVENTORY_BY_PRODUCT = 'INVENTORY_BY_PRODUCT',
  INVENTORY_BY_TAG_RFID = 'INVENTORY_BY_TAG_RFID',
  DISPATCHING = 'DISPATCHING',
  LOAD_DISPATCH = 'LOAD_DISPATCH'
}

export enum TaskStatus {
  QUEUE = 'QUEUE',
  IN_PROCESS = 'IN_PROCESS',
  PENDING = 'PENDING',
  ON_HOLD = 'ON_HOLD',
  ERROR = 'ERROR'
}

@Component({
  selector: 'app-tasks-table-item',
  templateUrl: './tasks-table-item.component.html',
  styleUrls: ['./tasks-table-item.component.scss']
})
export class TasksTableItemComponent implements OnInit {

  @Input() task;
  @Input() @HostBinding('class.isOdd') isOdd: boolean;
  @HostBinding('class.danger-background') isPending = false;
  @Output() deleteClick = new EventEmitter<any>();
  tooltipMessage;

  constructor() { }

  ngOnInit(): void {
    if (this.task.status === TaskStatus.PENDING) {
      this.isPending = true;
    }
    this.tooltipMessage = this.task.type === TaskType.LOAD_DISPATCH ?
      'Esta tarefa não pode ser cancelada diretamente, cancele o despache relacionado.' :
      !this.task.canDelete ? 'Não é possível deletar essa tarefa' : null;
  }

  handleDate(date) {
    if (date) {
      return moment(date).format('DD/MM/YYYY - HH:mm');
    } else {
      return '---';
    }
  }

  translateType(type) {
    switch (type) {
      case TaskType.RECEIVING:
        return 'Recebimento';
      case TaskType.RELOCATE_POSITION:
        return 'Realocação';
      case TaskType.RELOCATE_STORAGE:
        return 'Realocação para armazenamento';
      case TaskType.RELOCATE_DIVERGENT:
        return 'Realocação de divergente';
      case TaskType.RELOCATE_EXPEDITION:
        return 'Realocação para expedição';
      case TaskType.RELOCATE_FREE:
        return 'Realocação Livre';
      case TaskType.INVENTORY_BY_PRODUCT:
        return 'Inventário por produto';
      case TaskType.INVENTORY_BY_TAG_RFID:
        return 'Inventário por tag RFID';
      case TaskType.DISPATCHING:
        return 'Despachando';
      case TaskType.LOAD_DISPATCH:
        return 'Carregando caminhão';
      default:
        return type || '---';
    }
  }

  translateStatus(status) {
    switch (status) {
      case TaskStatus.IN_PROCESS:
        return 'Em andamento';
      case TaskStatus.PENDING:
        return 'Pendente';
      case TaskStatus.QUEUE:
        return 'Na fila';
      case TaskStatus.ON_HOLD:
        return 'Em espera';
        case TaskStatus.ERROR:
        return 'Erro';
      default:
        return status || '---';
    }
  }

  handleUsers(users) {
    let sentence = '';
    for (const u of users) {
      if (sentence === '') {
        sentence = u.name;
      } else {
        sentence = sentence + ', ' + u.name;
      }
    }
    return sentence;
  }

  deleteTask() {
    this.deleteClick.emit(this.task.ids);
  }
}
