import {Injectable} from '@angular/core';
import {collection, getFirestore, onSnapshot, query} from 'firebase/firestore';
import {FirebaseService} from './firebase.service';
import {UserService} from './user.service';
import {NotifierService} from 'angular-notifier';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  lastSeen: number;

  constructor(private firebase: FirebaseService,
              private userService: UserService,
              private notifierService: NotifierService) {
    this.loadLastSeen();
  }

  // new
  showFeedback(template, data: {type: string, title: string, message: string}, id?: string): void {
    this.notifierService.show({
      id,
      type: data.type,
      message: data.message,
      template
    });
  }

  // "old"
  showNotification(template, data) {
    this.notifierService.show({
      type: data.type,
      message: data,
      template
    });
  }

  hideNotification(id) {
    this.notifierService.hide(id);
  }

  getDatabase() {
    const db = getFirestore(this.firebase.getApp());
    return db;
  }

  // async getDevCollection() {
  //   const userSnapshot = await getDocs(collection(this.getDatabase(), 'dev'));
  //   return userSnapshot.docs.map(d => d.data());
  // }

  async onDocChange(template) {
    const user = await this.userService.getLoggedUser();
    const userId = user.id;
    const q = query(collection(this.getDatabase(), environment.name));

    return onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === 'modified' && change.doc.id === userId) {
          const notificationData = change.doc.data().NOTIFICATION;
          const seconds = notificationData.timestamp.seconds;

          if (this.lastSeen < seconds || !this.lastSeen) {
            this.showNotification(template, notificationData);
            this.updateLastSeen(seconds);
          }
        }
        // if (change.type === 'added') {
        //   console.log('Adicionado: ', change.doc.data());
        // }
        // if (change.type === 'removed') {
        //   console.log('Removido: ', change.doc.data());
        // }
      });
    });
  }

  updateLastSeen(seconds: number) {
    this.lastSeen = seconds;
    localStorage.setItem('LAST_SEEN_NOTIFICATION_TIMESTAMP', this.lastSeen.toString());
  }

  loadLastSeen() {
    this.lastSeen = parseInt(localStorage.getItem('LAST_SEEN_NOTIFICATION_TIMESTAMP'), 10) || moment().unix();
  }
}
