import { Injectable } from '@angular/core';
import {FirebaseService} from './firebase.service';
import {getStorage, ref, getDownloadURL, FirebaseStorage, StorageReference} from 'firebase/storage';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogosService {

  constructor(private firebaseService: FirebaseService) { }

  async getStorage() {
    const app = this.firebaseService.getApp();
    return  getStorage(app, environment.firebaseConfig.storageBucket);
  }

  getLogo(tenantId?) {
    const storage: FirebaseStorage = getStorage();
    const imageRef: StorageReference = ref(storage, tenantId ? `${environment.name}/logos/${tenantId}.png` : 'dev/logos/QUANTUM.png');
    return getDownloadURL(imageRef);
  }
}
