import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {StockDataSourceService} from '../../core/data-sources/stock-data-source.service';
import {TaskRequestSidenavService} from '../../core/services/task-request-sidenav.service';
import {MatDialog} from '@angular/material/dialog';
import {StockDetailsModalComponent} from '../../modals/stock-details-modal/stock-details-modal.component';
import {TenantConfig} from '../../core/resolvers/tenant-config-resolver.resolver';
import {StockFilterParams, StockService, StockSortParams} from '../../core/services/stock.service';
import { Subscription } from 'rxjs';
import { ErrorService } from '../../core/services/error.service';
import handlePaginationString from '../../helpers/pagination';

export enum StockProductType {
  FLAMMABLE = 'FLAMMABLE'
}

export interface StockNfeFilter {
  id: string;
  value: string;
}


@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {

  filterToggle = false;
  filterParams: StockFilterParams;
  actualHour;
  tenantConfig: TenantConfig;
  selectedCratesIds = new Set();
  pendingTaskHasError: boolean;
  isLoading = false;
  isModalLoading = false;
  items;
  itemsSubscription: Subscription;
  resItems;
  sortParams: StockSortParams;

  getPaginationString = handlePaginationString;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private stockDataSourceService: StockDataSourceService,
              public stockService: StockService,
              private sidenavService: TaskRequestSidenavService,
              public dialog: MatDialog,
              private errorService: ErrorService
              ) {
  }

  ngOnInit(): void {
    this.actualHour = moment().format('HH:mm');
    this.isLoading = true;
    this.stockService.init(this.filterParams).catch(err => console.error);
    this.itemsSubscription = this.stockService.items$.subscribe((items) => {
      this.items = items;
      this.isLoading = false;
    });
    this.tenantConfig = this.route.snapshot.data.tenantConfig;
  }

  openFilters() {
    this.filterToggle = !this.filterToggle;
  }

  async openSidenav(type) {
    const data = {
      type,
      data: {
        isPicking: false
      }
    };
    this.sidenavService.toggle(data).then(async (res: any) => {
      if (res.isCritical) {
        await this.update();
      } else if (!res.isCritical && res.userIds) {
        await this.update();
      }
    });
  }

  async itemColumnClicked($event){
    const {crate, column} = $event;
    if (column === 'check'){
      if (!this.selectedCratesIds.has(crate.id)){
        this.selectedCratesIds.add(crate.id);
      } else{
        this.selectedCratesIds.delete(crate.id);
      }
    } else{
      this.openDetailsModal(crate, column);
    }
  }

  async openDetailsModal(crate, column){
    try {
      this.isModalLoading = true;
      const page = column === 'messages' ? 3 : 0;
      const itemDetails = await this.stockDataSourceService.getStockDetails(crate.tagRfid).then((r: any) => {
        r.invoice = r.crate.invoices[0];
        return r;
      });
      const dialogRef = this.dialog.open(StockDetailsModalComponent, {
        panelClass: 'details-modal',
        data: {item: crate, itemDetails, page},
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res === 'SUBMITTED') {
          this.update();
        }
      });
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.isModalLoading = false;
    }
  }

  async getNextPage(){
    this.selectedCratesIds.clear();
    await this.stockService.getNextPage(this.filterParams, this.sortParams);
  }
  async getPreviousPage(){
    this.selectedCratesIds.clear();
    await this.stockService.getPreviousPage(this.filterParams, this.sortParams);
  }

  async onFiltersApply(params: { sortParams: StockSortParams, filterParams: StockFilterParams }) {
    this.filterParams = params?.filterParams;
    this.sortParams = this.setSortParams(params?.sortParams);
    await this.update();
  }

  async update() {
    try {
      this.isLoading = true;
      await this.stockService.reload(this.filterParams, this.sortParams);
      this.isLoading = false;
      this.actualHour = moment().format('HH:mm');
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
      this.isLoading = false;
    }
  }

  async getCSVStorage(){
    try {
      const csv = await this.stockService.getCSVStorage(this.filterParams);

      const url = window.URL.createObjectURL(csv);

      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${moment().format("yyy-MM-DD-hh-mm-ss")}-quantum-storage.csv`;
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    }
  }

  async toDashboard() {
    await this.router.navigate(['inspection']);
    this.itemsSubscription.unsubscribe();
  }

  async toSeparation() {
    await this.router.navigate(['picking']);
    this.itemsSubscription.unsubscribe();
  }

  visiblePendingTaskError() {
    this.pendingTaskHasError = true;
  }

  setSortParams(sort: StockSortParams) {
    if (!sort || !sort.sortBy) {
      return;
    }
    this.selectedCratesIds.clear();
    return sort;
  }
}
