<app-table-header-column name="date" size="medium">
  <span content>Data de emissão</span>
</app-table-header-column>

<app-table-header-column name="admin" size="medium">
  <span content>Responsável</span>
</app-table-header-column>

<app-table-header-column name="type" size="medium">
  <span content>Tipo de inventário</span>
</app-table-header-column>

<app-table-header-column name="total" size="medium">
  <span content>Total de itens</span>
</app-table-header-column>

<app-table-header-column name="qty-found" size="medium">
  <span content>Qtd. encontrada</span>
</app-table-header-column>

<app-table-header-column name="qty-missing" size="medium">
  <span content>Qtd. perdida</span>
</app-table-header-column>

<app-table-header-column name="state" size="small">
  <span content>Estado</span>
</app-table-header-column>
