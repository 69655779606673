<div class="page-header">
  <app-prev-next-button (click)="toSetup()" direction="left" label="setup"></app-prev-next-button>
  <h1>Setup das cofigurações iniciais</h1>
</div>

<div class="page-content">
  <app-side-color-card color="in">
    <div class="content" content>
      <div class="line">
        <div class="label">nome do armazém</div>
        <div class="contrl"> {{tenantName}}</div>
      </div>
      <div class="line">
        <div class="label">id do armazém (tenantId)</div>
        <div class="contrl"> {{tenantId}}</div>
      </div>
    </div>
  </app-side-color-card>
  <app-side-color-card color="in">
    <div class="content" content>
      <div class="line">
        <div class="label">usar níveis</div>
        <div class="contrl">
          <mat-slide-toggle
            class="example-margin"
            (change)="levelsChanged($event)"
            [color]="'primary-1'"
            [checked]="levelsEnabled"
            [disabled]="levelsSwitchDisabled">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="line">
        <div class="label">orientação</div>
        <div class="contrl">
          <input matInput
                 type="number"
                 class="gray"
                 placeholder="ex:90º"
                 [formControl]="orientation">
        </div>
      </div>
    </div>
  </app-side-color-card>

  <div class="buttons">
    <button mat-raised-button (click)="finish()">
      Ir para próximo passo
    </button>
  </div>
</div>
