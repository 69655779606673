import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TaskRequestSidenavService} from '../../../core/services/task-request-sidenav.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {StockDataSourceService} from '../../../core/data-sources/stock-data-source.service';
import {ActionService} from '../../../core/services/action.service';
import { toExcelFormat } from 'src/app/helpers/format-position';
import centimetersToMeters from 'src/app/helpers/centimeters-to-meters';
import { GroundGroupPosition, GroundPositionGroupDataSourceService } from 'src/app/core/data-sources/ground-position-group-data-source.service';
import { StockInventoryService } from '../stock-inventory.service';

interface SidenavData {
  isPicking?: boolean;
  position?: GroundGroupPosition;
}

export interface ProductsInterface {
  description: string;
  name: string;
  sku: string;
  type: string;
  unit: string;
  vendorCode: string;
}

export interface GroundPositionsInterface {
  column: string;
  groundId: string;
  groupName: string;
  line: number;
  rfid: string;
  state: string;
  type: string;
  x: number;
  y: number;
}

interface MultipleGroupOption {
  name: string,
  position: [{
    value: { x: number, y: number },
    viewValue: string
  }]
}

interface SingleGroupOption {
  viewValue: string,
  value: { x: number, y: number }
}

@Component({
  selector: 'app-stock-inventory-sidenav',
  templateUrl: './stock-inventory-sidenav.component.html',
  styleUrls: ['./stock-inventory-sidenav.component.scss']
})
export class StockInventorySidenavComponent implements OnInit, OnDestroy, OnChanges {
  @Input() drawer;
  @Input() data: SidenavData;

  openSubscription: Subscription;
  toExcelFormat = toExcelFormat;
  centimetersToMeters = centimetersToMeters;
  isCritical = false;
  usersHighlighted = [];
  isProductToggle = false;
  isSectorToggle = false;
  isPalletToggle = false;
  isMissingPalletsToggle = false;
  inventoryForm: FormGroup;
  showEntireSectorCheckbox$: Subscription;
  sectorValue;
  positionValue;
  palletInputSubscription$: Subscription;
  productInputSubscription: Subscription;
  resetInputSubscription: Subscription;
  products: ProductsInterface[];
  isProductChosen = false;
  isPalletChosen = false;
  groundPosition: GroundPositionsInterface;
  sectorOptions = [];
  positionOptions = [];
  selectedSectors = [];
  palletOptions = [];
  selectOperatorError = false;
  sectorsWithoutProducts = [];
  palletWithoutProduct;
  showPositionSelect = false;
  multipleGroupOptions: MultipleGroupOption[] = [];
  singleGroupOptions: SingleGroupOption[] = [];
  showMultipleOptionsSelect = false;
  showSingleOptionSelect = false;
  selectedPositions = [];
  emptyGroundPositionError = false;
  groundPositionWithoutPallet = [];
  selectedPallets = [];
  selectedPalletsTagsRfid: string[] = [];
  missingPallets = [];
  missingPalletsSubscription$: Subscription;
  missingPalletSearchSubject = new Subject();
  scrollSubject = new Subject();
  filterParams = {};
  searchValue = '';

  constructor(
    private sidenavService: TaskRequestSidenavService,
    private stockService: StockDataSourceService,
    private actionService: ActionService,
    private formBuilder: FormBuilder,
    private groundPositionService: GroundPositionGroupDataSourceService,
    private stockFilterService: StockInventoryService
  ) {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['data']?.currentValue) this.setPrefilledInventory();
    }

  ngOnInit(): void {
    this.stockFilterService.init();
    this.openSubscription =  this.drawer.openedStart.subscribe(async () => {
      this.groundPosition = await this.stockService.getGroundPosition(true);
      this.getSectors(this.groundPosition);
    });
    this.resetInputSubscription = this.inventoryForm.controls.product.valueChanges.subscribe(() => {
      if (this.isProductChosen) {
        this.isProductChosen = false;
        this.inventoryForm.reset();
      }
    });
    this.productInputSubscription = this.inventoryForm.controls.product.valueChanges.pipe(debounceTime(250))
      .subscribe(async (newValue) => {
        if (newValue) this.products = await this.stockService.getSearchedProducts(newValue);
        if (newValue?.sku) {
          this.isProductChosen = true;
        }
      }
    );

    this.resetInputSubscription = this.inventoryForm.controls.pallet.valueChanges.subscribe(() => {
      if (this.isPalletChosen) {
        this.isPalletChosen = false;
        this.inventoryForm.reset();
      }
    });

    this.palletInputSubscription$ = this.inventoryForm.controls.pallet.valueChanges
      .pipe(debounceTime(250))
      .subscribe(async (newValue) => {
        if (newValue) this.palletOptions = await this.stockService.getInventoryPalletOptions(newValue);
        if (newValue && newValue?.tagRfid) {
          this.isPalletChosen = true;
        }
      }
    );

    this.showEntireSectorCheckbox$ = this.inventoryForm.controls.sector.valueChanges.subscribe(change => {
      if (change?.length) {
        this.inventoryForm.controls.entireSector.enable();
      } else {
        this.inventoryForm.controls.entireSector.setValue(true);
        this.inventoryForm.controls.entireSector.disable();
      }
    });

    this.missingPalletsSubscription$ = this.stockFilterService.missingPallets$.subscribe((items) => {
      if (!this.missingPallets.length) {
        this.missingPallets.push(...items);
      } else {
        this.missingPallets = this.missingPallets.concat(...items);
        this.missingPallets = [... new Set(this.missingPallets)];
      }
      this.setSelectedPallets();
    });

    this.missingPalletSearchSubject.pipe(debounceTime(500)).subscribe((searchValue: string) => {
      this.missingPallets = [];
      this.filterParams = searchValue ? { tagRfid: searchValue } : {};
      this.stockFilterService.reload(this.filterParams, true);
    });

    this.scrollSubject.pipe(debounceTime(500)).subscribe(() => {
      if (this.stockFilterService.offset < this.stockFilterService.count) {
        this.stockFilterService.getNextPage(this.filterParams);
      }
    });
  }

  ngOnDestroy() {
    this.productInputSubscription.unsubscribe();
    this.resetInputSubscription.unsubscribe();
    this.openSubscription.unsubscribe();
    this.showEntireSectorCheckbox$.unsubscribe();
    this.missingPalletsSubscription$.unsubscribe();
    this.missingPalletSearchSubject.unsubscribe();
  }

  buildForm() {
    this.inventoryForm = this.formBuilder.group({
      product: [''],
      sector: [''],
      pallet: [''],
      position: [''],
      entireSector: [{value: true, disabled: true}],
      missingPallets: ['']
    });
  }

  setUsers(users) {
    this.usersHighlighted = users;
  }

  onCriticalChange(isChecked) {
    this.isCritical = isChecked;
  }

  toggleOption(type) {
    switch (type) {
      case "PRODUCT":
        this.isProductToggle = true;
        this.isSectorToggle = false;
        this.isPalletToggle = false;
        this.showPositionSelect = false;
        this.isMissingPalletsToggle = false;
        break;
      case "SECTOR":
        this.isSectorToggle = true;
        this.isProductToggle = false;
        this.isPalletToggle = false;
        this.inventoryForm.controls.entireSector.setValue(true);
        this.isMissingPalletsToggle = false;
        break;
      case "PALLET":
        this.isProductToggle = false;
        this.isSectorToggle = false;
        this.isPalletToggle = true;
        this.showPositionSelect = false;
        this.isMissingPalletsToggle = false;
        break;
      case 'MISSING_PALLET':
        this.isMissingPalletsToggle = true;
        this.isSectorToggle = false;
        this.isPalletToggle = false;
        this.isProductToggle = false;
        this.showPositionSelect = false;
        break;
    }
  }

  displayedString(product) {
    if (product) {
      return product.name + ' - ' + product.sku;
    } else {
      return null;
    }
  }

  setDisplayedPalletName(pallet) {
    if (pallet) {
      return `Tag RFID: ${pallet.tagRfid} - Posição: ${toExcelFormat(pallet.x, pallet.y)} - Altura: ${ pallet.z >= 0 ? centimetersToMeters(pallet.z) + ' metros' : 'Conflitante'}`
    } else {
      return null;
    }
  }

  setDisplayedMissingPalletName() {
    return ''; // keep autocomplete list without value after pallet is selected
  }

  onChangeSector(event) {
    // TODO change to debounce time
    setTimeout(() => {
      this.selectedSectors = event.value;
      this.showMultipleOptionsSelect = this.selectedSectors.length > 1;
      this.showSingleOptionSelect = this.selectedSectors.length === 1;
      if (event.value.length) {
        this.getPositionOptions();
      } else {
        this.showPositionSelect = false;
      }
    }, 500);
  }

  onCheckboxChange(event) {
    this.showPositionSelect = !event;
  }

  onChangePosition(event) {
    this.selectedPositions = event.value;
  }

  getSectors(gp) {
    const options = [];
    for (const item of gp) {
      if (!options.filter(e => e.name === item.groupName).length && item.groupName !== null) {
        options.push({ name: item.groupName, groupId: item.groupId });
      }
    }
    this.sectorOptions = [...options];
    this.sectorOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  getPositionOptions() {
    this.multipleGroupOptions = [];
    this.singleGroupOptions = [];
    this.groundPositionService.getDetailsById(this.selectedSectors).then((res) => {
      if (this.showMultipleOptionsSelect) {
        const groupedOptions = {};
        res.forEach(group => {
          group.positions.forEach(pos => {
            if (!groupedOptions[group.name]) {
              groupedOptions[group.name] = [];
            }
            groupedOptions[group.name].push({
              value: { x: pos.x, y: pos.y },
              viewValue: pos.alias ? pos.alias : `--- (coordenada: ${toExcelFormat(pos.x, pos.y)})`
            });
            groupedOptions[group.name].sort((a: SingleGroupOption, b: SingleGroupOption) => this.sortPositions(a, b));
          });
        });

        for (const name in groupedOptions) {
          this.multipleGroupOptions.push({
            name: name,
            position: groupedOptions[name]
          });
        }
      } else if (this.showSingleOptionSelect) {
        res.forEach(group => {
          group.positions.forEach(pos => {
            this.singleGroupOptions.push({
              viewValue: pos.alias ? pos.alias : `--- (coordenada: ${toExcelFormat(pos.x, pos.y)})`,
              value: { x: pos.x, y: pos.y }
            });
          });
        });
        this.singleGroupOptions.sort((a: SingleGroupOption, b: SingleGroupOption) => this.sortPositions(a, b));
      }
    });
  }

  async inventoryByGroundPosition() {
    if (!this.showPositionSelect) {
      const data = {
        type: 'INVENTORY_BY_GROUND_POSITION_GROUP',
        userIds: this.usersHighlighted,
        taskData: {
          groundPositionGroupId: this.selectedSectors
        }
      };
      await this.actionService.inventoryByGroundPositionGroup(data);
    } else {
      const data = {
        type: 'INVENTORY_BY_GROUND_POSITION',
        userIds: this.usersHighlighted,
        taskData: {
          groundPosition: this.selectedPositions
        }
      };
      await this.actionService.inventoryByGroundPosition(data);
    }
  }

  async inventoryByProduct() {
    const data = {
      type: 'INVENTORY_BY_PRODUCT',
      userIds: this.usersHighlighted,
      taskData: {
        sku: [this.inventoryForm.controls.product.value.sku]
      }
    };
    await this.actionService.inventoryByProduct(data);
  }

  async inventoryByTagRfid() {
    const data = {
      type: "PRODUCT_INVENTORY_BY_CRATE_TAG_RFID",
      userIds: this.usersHighlighted,
      taskData: {
        tagRfid: this.inventoryForm.controls.pallet.value.tagRfid
      }
    }
    await this.actionService.inventoryBySingleTagRfid(data);
  }

  async missingPalletsInventory() {
    const data = {
      type: "INVENTORY_BY_TAG_RFID",
      userIds: this.usersHighlighted,
      taskData: {
        deepSearch: true,
        tagRfid: this.selectedPalletsTagsRfid
      }
    };
    await this.actionService.inventoryByTagRfid(data);
  }

  setOperatorLengthError() {
    this.selectOperatorError = !this.usersHighlighted.length;
  }

  handleGroundPositionWithoutCrates(groupIds) {
    let sectors = [];
    for (const id of groupIds) {
      sectors = this.sectorOptions.filter(e => e.groupId === id);
      if (!this.sectorsWithoutProducts.filter(e => e.groupId === id).length && id !== null) {
        this.sectorsWithoutProducts.push(sectors[0]);
      }
    }
  }

  async submit() {
    try {
      this.setOperatorLengthError();
      if ((this.inventoryForm.controls.product.valid || this.inventoryForm.controls.sector.valid || this.inventoryForm.controls.pallet.valid) && this.usersHighlighted.length) {
        if (this.isSectorToggle) { await this.inventoryByGroundPosition(); }
        if (this.isProductToggle) { await this.inventoryByProduct(); }
        if (this.isPalletToggle) await this.inventoryByTagRfid();
        if (this.isMissingPalletsToggle) await this.missingPalletsInventory();
        this.drawer.close();
        this.sidenavService.closeSidenav(true);
        this.isProductToggle = false;
        this.isSectorToggle = false;
        this.isPalletToggle = false;
        this.isMissingPalletsToggle = false;
        this.isCritical = false;
        this.usersHighlighted = [];
        this.sectorsWithoutProducts = [];
        this.palletWithoutProduct = '';
        this.selectedSectors = [];
        this.sectorValue = [];
        this.groundPositionWithoutPallet = [];
        this.selectedPositions = [];
        this.showPositionSelect = false;
        this.emptyGroundPositionError = false;
        this.selectedPallets = [];
        this.selectedPalletsTagsRfid = [];
        this.inventoryForm.reset();
        this.missingPallets.forEach(pallet => {
          pallet.selected = false;
        });
      }
    } catch (e) {
      if (e.error.type === 'GROUP_POSITION_GROUP_WITHOUT_CRATES') {
        this.handleGroundPositionWithoutCrates(e.error.data);
      }
      if (e.error.type === 'NO_PALLET_WITH_PRODUCT_FOUND') {
        this.palletWithoutProduct = e.error.data;
      }
      if (e.error.type === 'GROUND_POSITION_WITHOUT_PALLET') {
        this.emptyGroundPositionError = e.error.data.length;
        this.groundPositionWithoutPallet = e.error.data;
        this.selectedPositions = [];
      }
    }
  }

  close() {
    try {
      this.drawer.close();
      this.sidenavService.closeSidenav(false);
    } finally {
      this.isCritical = false;
      this.isProductToggle = false;
      this.isSectorToggle = false;
      this.sectorValue = null;
      this.usersHighlighted = [];
      this.sectorsWithoutProducts = [];
      this.palletWithoutProduct = '';
      this.emptyGroundPositionError = false;
      this.groundPositionWithoutPallet = [];
      this.selectedPositions = [];
      this.sectorValue = [];
      this.selectedSectors = [];
      this.showPositionSelect = false;
      this.isPalletToggle = false;
      this.isMissingPalletsToggle = false;
      this.selectedPallets = [];
      this.selectedPalletsTagsRfid = [];
      this.inventoryForm.reset();
      this.missingPallets.forEach(pallet => {
        pallet.selected = false;
      });
      this.data = {};
    }
  }

  sortPositions(a, b) {
    const hasCoordenadaA = a.viewValue.includes("coordenada");
    const hasCoordenadaB = b.viewValue.includes("coordenada");
    if (hasCoordenadaA && !hasCoordenadaB) return 1;
    if (!hasCoordenadaA && hasCoordenadaB) return -1;
    return a.viewValue.localeCompare(b.viewValue);
  }

  onOptionClicked(event: Event, pallet) {
    event.stopPropagation();
    this.toggleSelection(pallet);
  }

  toggleSelection(pallet) {
    pallet.selected = !pallet.selected;
    if (pallet.selected) {
      this.selectedPallets.push(pallet);
    } else {
      const i = this.selectedPallets.findIndex(value => value.id === pallet.id);
      this.selectedPallets.splice(i, 1);
    }
    this.selectedPalletsTagsRfid = this.selectedPallets.map(pallet => pallet.tagRfid);
    this.selectedPallets = [... new Set(this.selectedPallets)];
    this.inventoryForm.controls.missingPallets.setValue(this.selectedPallets);
  }

  onInput(e) {
    this.searchValue = e.target.value;
    this.missingPalletSearchSubject.next(e.target.value);
  }

  onAutocompleteOpened() {
    const panel = document.querySelector('.mat-autocomplete-panel');
    if (panel) {
      panel.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  onAutocompleteFocus() {
    if (this.searchValue.length) {
      this.missingPalletSearchSubject.next();
    }
  }

  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    if (target.scrollTop > 0 && target.scrollTop + target.clientHeight >= target.scrollHeight) {
      this.scrollSubject.next();
    }
  }
  compareWithFn(obj1: GroundGroupPosition, obj2: GroundGroupPosition) {
    return obj1?.x === obj2?.x && obj1?.y === obj2?.y;
  }

  setPrefilledInventory() {
    if (this.data?.position) {
      this.isSectorToggle = true;
      this.showPositionSelect = true;
      this.showSingleOptionSelect = true;
      this.sectorValue = [this.data.position.group.id];
      this.inventoryForm.controls.sector.setValue(this.sectorValue);
      this.selectedPositions.push({
        x: this.data.position.x,
        y: this.data.position.y
      });
      this.inventoryForm.controls.position.setValue(this.selectedPositions);
      this.inventoryForm.controls.entireSector.setValue(false);
      this.inventoryForm.controls.entireSector.disable();
      this.singleGroupOptions.push({
        viewValue: this.data.position.alias ? this.data.position.alias : `--- (coordenada: ${toExcelFormat(this.data.position.x, this.data.position.y)})`,
        value: { x: this.data.position.x, y: this.data.position.y }
      });
    }
  }

  setSelectedPallets() {
    this.missingPallets.forEach(missing => {
      this.selectedPallets.forEach(pallet => {
        if (pallet.selected && pallet.id === missing.id) {
          missing.selected = true;
        }
      });
    });
  }

  removeSelectedPallet(palletToRemove) {
    this.selectedPallets = this.selectedPallets.filter(pallet => pallet.id !== palletToRemove.id);
    this.missingPallets.forEach(missingPallet => {
      if (missingPallet.id === palletToRemove.id) {
        missingPallet.selected = false;
      }
    });
  }

}
