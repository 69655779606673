import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

export interface PendingTask {
  type: PendingTaskTypeEnum;
  data: {
    originalPosition?: {
      x: number;
      y: number;
    };
    suggestedPosition?: {
      x: number;
      y: number;
    }
    tagRfid?: string;
    position: any;
    products?: [{
      sku: string;
      name: string;
    }];
    productName?: string;
    sku?: string;
    suggestedCrates?: SuggestedCrate[];
  };
}

interface SuggestedCrate {
  id: string;
  state: string;
  tagRfid: string;
  x: number;
  y: number;
  z: number;
  type: string;
  position: string;
  tenantId: string;
  createdAt: number;
  updatedAt: number;
  skuGroups: SkuGroup[];
}

interface SkuGroup {
  crateId: string;
  sku: string;
  packSize: number;
  quantity: number;
  createdAt: number;
  updatedAt: number;
}

export enum PendingTaskTypeEnum {
  MISPLACED_PALLETS = 'MISPLACED_PALLETS',
  MISPLACED_EXPEDITION = 'MISPLACED_EXPEDITION',
  CRITICAL_TASK_REJECTED = 'CRITICAL_TASK_REJECTED',
  MISSING_PALLETS = 'MISSING_PALLETS',
  MISPLACED_LOAD_DISPATCH = 'MISPLACED_LOAD_DISPATCH',
  EMPTY_PICKING_POSITION = 'EMPTY_PICKING_POSITION'
}

@Injectable({
  providedIn: 'root'
})
export class PendingTaskService {

  constructor(private http: HttpClient) { }

  getPendingTasks() {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/pending-tasks').toPromise<any>();
  }

  getSuggestedPosition(sku) {
    const data = {
      sku
    };
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/storage/suggested-position', { params: data }).toPromise<any>();
  }
}
