import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-alias-modal',
  templateUrl: './edit-alias-modal.component.html',
  styleUrls: ['./edit-alias-modal.component.scss']
})
export class EditAliasModalComponent implements OnInit {

  aliasForm = new FormControl(this.data.alias, [Validators.required, Validators.maxLength(5)]);
  constructor(
    public dialogRef: MatDialogRef<EditAliasModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  save() {
    this.data.alias = this.aliasForm.value.toUpperCase();
    this.dialogRef.close(this.data);
  }

}
