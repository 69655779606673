<app-table-column name="date" size="small" (click)="itemClicked('date')">
  <span content>{{formatDate(crate.createdAt)}}</span>
</app-table-column>

<app-table-column name="date" size="small" (click)="itemClicked('date')">
  <span content>{{formatDate(crate.updatedAt || crate.createdAt)}}</span>
</app-table-column>

<app-table-column name="sector" size="small" (click)="itemClicked('sector')">
   <span content>{{crate.section || '---'}}</span>
</app-table-column>

<app-table-column name="address" size="small" (click)="itemClicked('address')">
  <span content>  {{ toExcelFormat(crate.x, crate.y) }}  </span>
</app-table-column>

<app-table-column name="alias" size="small" (click)="itemClicked('alias')">
  <span content> {{ crate.alias || '---' }} </span>
</app-table-column>

<app-table-column name="level" size="smallest" (click)="itemClicked('level')">
  <span content>{{crate.levelName || '---'}}</span>
</app-table-column>

<app-table-column name="height" size="smallest" (click)="itemClicked('height')">
  <span content>{{ crate.z >= 0 ? centimetersToMeters(crate.z) + ' metros' : 'Conflitante' }}</span>
</app-table-column>

<app-table-column name="product-code" size="small" (click)="itemClicked('product-code')">
  <span content>
    <span *ngFor="let product of crate.skuGroups">{{ product.sku }}</span>
  </span>
</app-table-column>

<app-table-column name="description" size="medium" (click)="itemClicked('description')">
  <span content>
    <span *ngFor="let product of crate.skuGroups">{{ product.name }}</span>
  </span>
</app-table-column>

<app-table-column name="quantity" size="smallest" (click)="itemClicked('quantity')">
  <span content>
    <span *ngFor="let product of crate.skuGroups">{{ product.quantity }}</span>
  </span>
</app-table-column>

<app-table-column name="state" size="small" (click)="itemClicked('state')">
  <span content>{{ translateState(crate.state) }}</span>
</app-table-column>
