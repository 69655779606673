import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {toExcelFormat} from '../../../helpers/format-position';
import {debounceTime} from 'rxjs/operators';
import {FormBuilder, Validators} from '@angular/forms';
import {StockDataSourceService} from '../../../core/data-sources/stock-data-source.service';
import {TaskRequestSidenavService} from '../../../core/services/task-request-sidenav.service';
import {ActionService} from '../../../core/services/action.service';

@Component({
  selector: 'app-stock-pending-expedition-sidenav',
  templateUrl: './stock-pending-expedition-sidenav.component.html',
  styleUrls: ['./stock-pending-expedition-sidenav.component.scss']
})
export class StockPendingExpeditionSidenavComponent implements OnInit, OnDestroy, OnChanges {

  @Input() drawer;
  @Input() task;

  usersHighlighted = [];

  groundPosition;

  sectorOptions = [];
  addressOptions = [];
  suggestedPosition;

  productInputSubscription: Subscription;
  resetInputSubscription: Subscription;
  products;
  isProductChosen = false;

  relocateForm;

  selectOperatorError = false;
  isSectorSelected: boolean = false;

  constructor(private stockService: StockDataSourceService,
              private formBuilder: FormBuilder,
              private sidenavService: TaskRequestSidenavService,
              private actionService: ActionService) {
    this.buildForm();
  }



  ngOnInit(): void {
    this.getGroundPosition().then(() => {
      this.getSectors(this.groundPosition);
      this.getAddresses(this.groundPosition, this.sectorOptions);
    });
    this.productInputSubscription = this.relocateForm.controls.product.valueChanges.pipe(debounceTime(250))
      .subscribe(async (newValue) => {
        this.products = await this.stockService.getSearchedProducts(newValue);
        if (newValue?.sku) {
          this.isProductChosen = true;
        }
      });
    this.resetInputSubscription = this.relocateForm.controls.product.valueChanges.subscribe(() => {
      if (this.isProductChosen) {
        this.isProductChosen = false;
        this.relocateForm.controls.product.reset();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.task.currentValue) {
      this.relocateForm.controls.rfid.setValue(changes.task.currentValue.data.tagRfid);
      this.relocateForm.controls.product.setValue(changes.task.currentValue.data.products[0]);
    }
  }

  ngOnDestroy() {
    this.productInputSubscription.unsubscribe();
    this.resetInputSubscription.unsubscribe();
  }

  buildForm() {
    this.relocateForm = this.formBuilder.group({
      product: ['', Validators.required],
      rfid: ['', Validators.required],
      sector: ['', Validators.required],
      address: ['', Validators.required]
    });
  }

  async getGroundPosition() {
    this.groundPosition = await this.stockService.getGroundPosition(true);
  }

  getSectors(gp) {
    const options = [];
    for (const item of gp) {
      if (!options.includes(item.groupName) && item.groupName !== null && item.type === "EXPEDITION") {
        options.push(item.groupName);
      }
    }
    this.sectorOptions = [...options];
    this.sectorOptions.sort((a, b) => {
      if (a > b) { return 1; }
      if (a < b) { return -1; }
      return 0;
    });
  }

  getAddresses(gp, selectedSector) {
    this.suggestedPosition = {
      coordinates: { x: this.task.data.suggestedPosition.x, y: this.task.data.suggestedPosition.y },
      formatted: toExcelFormat(this.task.data.suggestedPosition.x, this.task.data.suggestedPosition.y)
    };
    const options = [];
    for (const item of gp) {
      if (!options.includes(item) && item.groupName === selectedSector) {
        options.push({ coordinates: { x: item.x, y: item.y }, formatted: toExcelFormat(item.x, item.y)});
      }
    }
    this.addressOptions = [...options];
    this.addressOptions.sort((a, b) => {
      if (a > b) { return 1; }
      if (a < b) { return -1; }
      return 0;
    });
  }

  setUsers(users) {
    this.usersHighlighted = users;
  }

  displayedString(product) {
    if (product) {
      return product.name + ' - ' + product.sku;
    } else {
      return null;
    }
  }

  async sendTask() {
    const params = {
      type: 'RELOCATE_EXPEDITION',
      userIds: this.usersHighlighted,
      tagRfid: this.relocateForm.controls.rfid.value,
      taskData: {
        outOrderNumber: '',
        destiny: {
          x: this.relocateForm.controls.address.value.x,
          y: this.relocateForm.controls.address.value.y
        }
      }
    };
    await this.actionService.relocateExpedition(params);
  }

  submit() {
    this.relocateForm.markAllAsTouched();
    if (this.relocateForm.valid && this.usersHighlighted.length) {
      this.sendTask();
      this.selectOperatorError = false;
      this.drawer.close();
      this.sidenavService.closeSidenav(true);
      this.resetForm();
    } else {
      this.selectOperatorError = true;
    }
  }

  close() {
    this.drawer.close();
    this.sidenavService.closeSidenav(false);
    this.resetForm();
  }

  resetForm() {
    this.relocateForm.reset();
    this.usersHighlighted = [];
    this.selectOperatorError = false;
  }

  handleSectorChange(option, event){
    if(event.isUserInput){
      this.getAddresses(this.groundPosition, option);
      this.isSectorSelected = true;
    }
  }
}
