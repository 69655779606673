import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-in-group-nfe-header',
  templateUrl: './in-group-nfe-header.component.html',
  styleUrls: ['./in-group-nfe-header.component.scss']
})
export class InGroupNfeHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
