import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InService {

  constructor(private http: HttpClient) { }

  getDocuments(startDate, endDate): Observable<any> {
    const dateParams = {
      startDate,
      endDate
    };
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/in-documents', { params: dateParams });
  }

  getInDetailsOrders(orderNumber): Observable<any> {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/in-documents/buy-order/${orderNumber}`);
  }

  getInDetailsInvoices(id): Observable<any> {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/in-documents/invoice/${id}`);
  }
  async getXml(key): Promise<any> {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/invoices/${key}/xml`).toPromise<any>()
      .then((res)=>{
        const xmltext = res;
        const filename = "file.xml";
        const pom = document.createElement('a');
        const bb = new Blob([xmltext], {type: 'text/plain'});

        pom.setAttribute('href', window.URL.createObjectURL(bb));
        pom.setAttribute('download', filename);

        pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
        pom.draggable = true;
        pom.classList.add('dragout');

        pom.click();
      });
  }
}
