import { Component, Input, OnInit } from '@angular/core';

interface CommonList {
  name: string
  value: number | string
}
@Component({
  selector: 'app-common-list',
  templateUrl: './common-list.component.html',
  styleUrls: ['./common-list.component.scss']
})
export class CommonListComponent implements OnInit {
  @Input() list: CommonList[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
