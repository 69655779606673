import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShowRoomService {

  constructor(private http: HttpClient) { }

  getInOrders() {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/show-room/in-orders').toPromise<any>();
  }
  getConsumedInvoices() {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/show-room/consumed-invoices').toPromise<any>();
  }
  getAllInvoices() {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/show-room/invoices').toPromise<any>();
  }
}
