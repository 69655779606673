<app-reports-table-modal-header [displayedColumns]="displayedColumns"></app-reports-table-modal-header>
<app-reports-table-modal-item
  *ngFor="let item of items; let odd = odd"
  [isOdd]="odd"
  [item]="item"
  [items]="items"
  [report]="report">
</app-reports-table-modal-item>

<div class="empty" *ngIf="!items.length">
  NÃO EXISTEM PRODUTOS.
</div>
