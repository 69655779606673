import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {CommentDataSourceService} from '../../core/data-sources/comment-data-source.service';
import {InService} from '../../core/services/in.service';

@Component({
  selector: 'app-in-details-nfe-modal',
  templateUrl: './in-details-nfe-modal.component.html',
  styleUrls: ['./in-details-nfe-modal.component.scss']
})
export class InDetailsNfeModalComponent implements OnInit {

  @Output() itemClick = new EventEmitter();

  highlightedProducts = [];

  translatedStatus: string;

  selected = new FormControl(0);

  titleModal: string;

  status;
  isCommentChange = false;
  isDestinationChange = false;

  products: {
    name: string;
    sku: string;
  }[];

  constructor(public dialogRef: MatDialogRef<InDetailsNfeModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private commentDataSource: CommentDataSourceService,
              private inService: InService) { }

  ngOnInit(): void {
    this.parseProduct();
    if (this.data.type === 'SCREENING') {
      this.highlightedProducts.push(this.data.item.sku);
    }
    this.status = this.data.itemDetails.invoice.status;
    this.translatedStatus = this.translateStatus();
    this.selected.setValue(this.data.page);
    this.titleModal = `NFe / Série N° ${this.data.itemDetails.invoice.id}`;
  }

  close(event?) {
    if (this.isCommentChange || this.isDestinationChange || event === 'SUBMITTED') {
      this.dialogRef.close('SUBMITTED');
    } else {
      this.dialogRef.close();
    }
  }

  translateStatus() {
    if (this.status === 'OK') {
      return 'Sucesso';
    } else if (this.status === 'DIVERGENT') {
      return 'Divergente';
    } else if (this.status === 'AWAITING_INVOICE') {
      return 'Aguardando NFe';
    } else if (this.status === 'AWAITING_RETIFICATION') {
      return 'Aguardando Correção';
    } else if (this.status === 'AWAITING_COMPARISON') {
      return 'Aguardando Comparação';
    } else if (this.status === 'RECEIVEMENT_BLOCKED') {
      return 'Recebimento Bloqueado';
    } else {
      return '';
    }
  }

  openBuyOrderModal(order) {
    if (this.data.closeLastModalOpened === true) {
      this.itemClick.next(order);
    } else {
      this.itemClick.next(order);
    }
  }


  setDestination() {
    this.isDestinationChange = true;
  }

  async deleteComment(id) {
    try {
      if (this.data.type === 'SCREENING') {
        await this.commentDataSource.deleteInvoiceComment(this.data.item.invoiceId, id);
      } else {
        await this.commentDataSource.deleteInvoiceComment(this.data.item.id, id);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.updateDetails();
      this.isCommentChange = true;
    }
  }

  async submitComment(event) {
    try {
      if (this.data.type === 'SCREENING') {
        await this.commentDataSource.postInvoiceComment(this.data.item.invoiceId, event.comment, 'SCREENING');
      } else {
        await this.commentDataSource.postInvoiceComment(this.data.item.id, event.comment, 'ENTRY');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.updateDetails();
      this.isCommentChange = true;
    }
  }

  updateDetails() {
    if (this.data.type === 'SCREENING') {
      this.inService.getInDetailsInvoices(this.data.item.invoiceId).subscribe(itemDetails => {
        this.data.itemDetails = itemDetails;
      });
    } else {
      this.inService.getInDetailsInvoices(this.data.item.id).subscribe(itemDetails => {
        this.data.itemDetails = itemDetails;
      });
    }
  }

  parseProduct() {
    this.products = this.data.itemDetails.invoice.products.map(p => {
      return {
        name: p.description ?? '-',
        sku: p.sku
      };
    });
  }
}
