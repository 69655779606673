import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-group-modal',
  templateUrl: './add-group-modal.component.html',
  styleUrls: ['./add-group-modal.component.scss']
})
export class AddGroupModalComponent implements OnInit {


  form: FormGroup;

  sectorOptions = [
    { value: 'DOCK', name: 'Doca' },
    { value: 'STREET', name: 'Rua' },
    { value: 'QUALITY_CONTROL', name: 'Controle de qualidade' },
    { value: 'STOCK', name: 'Estoque' },
    { value: 'EXPEDITION', name: 'Expedição' },
    { value: 'DIVERGENT_STOCK', name: 'Estoque divergente' },
    { value: 'DAMAGES', name: 'Danos' },
    { value: 'PICKING', name: 'Separação' },
    { value: 'PROVISION', name: 'Provisão' },
    { value: 'IN', name: 'Entrada' },
    { value: 'EXIT', name: 'Saída'}
  ];

  constructor(public dialogRef: MatDialogRef<AddGroupModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required)

    });
  }

  ngOnInit(): void {
  }

  get name(){
    return this.form.get('name');
  }
  get type(){
    return this.form.get('type');
  }


  close(isSubmitted?) {
    if (isSubmitted) {
      this.form.get('name').markAsTouched();
      this.form.get('type').markAsTouched();

      if(!this.form.invalid){
        const values = this.form.getRawValue();
        this.dialogRef.close(values);
      }
    } else {
      this.dialogRef.close();
    }
  }
}
