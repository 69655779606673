import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import formatDate from '../../helpers/format-date';
import { AddOrEditUserModalComponent } from '../add-or-edit-user-modal/add-or-edit-user-modal.component';
import { UserService } from '../../core/services/user.service';
import { IUser, UserRoles } from '../../../models/User';

@Component({
  selector: 'app-user-details-modal',
  templateUrl: './user-details-modal.component.html',
  styleUrls: ['./user-details-modal.component.scss']
})
export class UserDetailsModalComponent implements OnInit {

  disableEdit = false;
  loggedUser: IUser;

  formatDate = formatDate;

  constructor(
    public dialogRef: MatDialogRef<UserDetailsModalComponent>,
    private dialog: MatDialog,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: { user: IUser, disableEditAdmins: boolean }
  ) { }

  ngOnInit(): void {
    this.loggedUser = this.userService.getLoggedUser();
    if (this.data.disableEditAdmins) {
      this.disableEdit = (
        (this.loggedUser.type !== UserRoles.MASTER && this.loggedUser.type !== UserRoles.ADMIN)
        || this.data.user.type === UserRoles.ADMIN
        || this.data.user.type === UserRoles.MASTER
      );
    }
  }

  openEditUser(): void {
    const dialogRef = this.dialog.open(AddOrEditUserModalComponent, {
      panelClass: 'details-modal',
      autoFocus: false,
      data: { user: this.data.user }
    });
    dialogRef.afterClosed().subscribe(user => {
      if (user) {
        this.dialogRef.close(user);
      }
    });
  }

  disabledTooltipText(): string {
    if (this.data.disableEditAdmins) {
      if (this.loggedUser.type !== UserRoles.MASTER && this.loggedUser.type !== UserRoles.ADMIN) {
        return 'Você não possui cargo para editar os usuários.';
      }
      if (this.data.user.type === UserRoles.ADMIN) {
        return 'Para editar administradores, vá à tela de cadastro de administradores no setup do armazém.';
      }
      if (this.data.user.type === UserRoles.MASTER) {
        return 'Não é possível editar usuários do tipo MASTER.';
      }
    }
    return '';
  }

  close(): void {
    this.dialogRef.close();
  }

}
