import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {GroundGroupsService} from '../../../../core/services/ground-groups.service';
import {ActionService} from '../../../../core/services/action.service';

@Component({
  selector: 'app-ground-group-form',
  templateUrl: './ground-group-form.component.html',
  styleUrls: ['./ground-group-form.component.scss']
})
export class GroundGroupFormComponent implements OnInit {
  createSectorFormGroup: FormGroup;

  @Output()
  onCreate = new EventEmitter();

  @ViewChild(FormGroupDirective, { static: true })
  private formElement: FormGroupDirective;

  sectorOptions = [
    { value: 'DOCK', name: 'Doca' },
    { value: 'STREET', name: 'Rua' },
    { value: 'QUALITY_CONTROL', name: 'Controle de qualidade' },
    { value: 'STOCK', name: 'Estoque' },
    { value: 'EXPEDITION', name: 'Expedição' },
    { value: 'DIVERGENT_STOCK', name: 'Estoque divergente' },
    { value: 'DAMAGES', name: 'Danos' },
    { value: 'PICKING', name: 'Separação' },
    { value: 'PROVISION', name: 'Provisão' },
    { value: 'IN', name: 'Entrada' }
  ];
  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private groundGroupsService: GroundGroupsService,
              private actionService: ActionService) {
    this.createForm();
  }

  createForm(){
    this.createSectorFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    console.log(this.formElement)
  }
  async createSector() {
    try {
      this.createSectorFormGroup.markAllAsTouched();
      if (this.createSectorFormGroup.valid) {
        const params = {
          name: this.createSectorFormGroup.controls.name.value,
          type: this.createSectorFormGroup.controls.type.value
        };
        await this.actionService.addGroundGroup(params);
        this.onCreate.emit(true);
      }
    }
    catch (err) {
      this.onCreate.emit(false);
    }
    finally {
      this.reset();
    }
  }



  reset() {
    this.formElement.resetForm();
    this.createSectorFormGroup.reset();
    this.createForm();
  }

}
