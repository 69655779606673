import { Injectable } from '@angular/core';
import {UserDataSourceService} from '../data-sources/user-data-source.service';
import { IUser } from '../../../models/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private loggedUser: IUser;

  constructor(private userDatasource: UserDataSourceService) { }

  async saveLoggedUser(){
    this.loggedUser = await this.userDatasource.getLoggedUser();
  }

  clearLoggedUser(){
    this.loggedUser = null;
  }

  getLoggedUser() {
    return this.loggedUser;
  }

}
