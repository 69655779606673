<div class="card" [ngClass]="{'warning-background': pendingTaskOrigin?.includes('picking')}">
  <p>TAREFA PENDENTE</p>

  <ng-container [ngSwitch]="pendingTask.type">
    <ng-container *ngSwitchCase="'MISPLACED_PALLETS'">
      <p class="description">Produto(s)
        <span *ngFor="let product of pendingTask.data.products; let last = last">
          {{ last ? product.name : product.name + ', ' }}
        </span> fora do endereço especificado
      </p>
      <p>Produto encontrado em: <span>{{ pendingTask.data.position }}</span></p>

      <div class="send-task-buttons">
        <button mat-button (click)="openConfirmModal()">
          ACEITAR POSIÇÃO
        </button>

        <button mat-button (click)="openSidenav('STOCK_RELOCATE_SINGLE_PALLET')">
          MUDAR LOCALIZAÇÃO
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'WRONG_POSITION_PALLETS'">
      <p class="description">Produto(s)
        <span *ngFor="let product of pendingTask.data.products; let last = last">
          {{ last ? product.name : product.name + ', ' }}
        </span> fora do endereço especificado
      </p>
      <p>Produto encontrado em: <span>{{ pendingTask.data.position }}</span></p>

      <div class="send-task-buttons">
        <button mat-button (click)="openConfirmModal()">
          ACEITAR POSIÇÃO
        </button>

        <button mat-button (click)="openSidenav('STOCK_RELOCATE_SINGLE_PALLET')">
          MUDAR LOCALIZAÇÃO
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'EXTRA_CRATES_AT_PICKING'">
      <p class="description">Produto(s)
        <span *ngFor="let product of pendingTask.data.products; let last = last">
          {{ last ? product.name : product.name + ', ' }}
        </span> fora do endereço especificado
      </p>
      <p>Produto encontrado em: <span>{{ pendingTask.data.position }}</span></p>

      <div class="send-task-buttons">
        <button mat-button (click)="openSidenav('STOCK_RELOCATE_SINGLE_PALLET')">
          MUDAR LOCALIZAÇÃO
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'MISPLACED_EXPEDITION'">
      <p class="description">Produto(s)
        <span *ngFor="let product of pendingTask.data.products; let last = last">
            {{ last ? product.name : product.name + ', ' }}
        </span>
        fora do endereço especificado para expedição
      </p>
      <p>Produto encontrado em: <span>{{ pendingTask.data.position }}</span></p>

      <div class="send-task-buttons">
        <button mat-button
                (click)="openSidenav('RELOCATE_PENDING_EXPEDITION')">
          MUDAR LOCALIZAÇÃO
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'MISSING_PALLETS'">
      <p class="description">
        Pallet marcado como <span>perdido</span>. Última posição:
        {{ formatPosition(pendingTask.data.originalPosition.x, pendingTask.data.originalPosition.y) }}
      </p>
      <p>Solicite nova tarefa de inventário</p>

      <div class="send-task-buttons">
        <button mat-button
                (click)="openSidenav('STOCK_INVENTORY_BY_TAG_RFID')">
          SOLICITAR TAREFA
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'MISPLACED_LOAD_DISPATCH'">
      <p class="description">Produto(s)
        <span *ngFor="let product of pendingTask.data.products; let last = last">
          {{ last ? product.name : product.name + ', ' }}
        </span> fora do endereço especificado
      </p>
      <p>Produto encontrado em: <span>{{ pendingTask.data.position }}</span></p>

      <div class="send-task-buttons">
        <button mat-button
                (click)="openSidenav('RELOCATE_LOAD_DISPATCH')">
          SOLICITAR TAREFA
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'EMPTY_PICKING_POSITION'">
      <p class="description">A posição
        <span>
          {{formatPosition(pendingTask.data.position.x, pendingTask.data.position.y)}}
        </span>
        do tipo <b>PICKING</b> do produto <b>{{ pendingTask.data.productName }}</b> está vazia
      </p>
      <p>Solicite uma tarefa para reabastecer</p>

      <div class="send-task-buttons">
        <button mat-button
                (click)="openSidenav('EMPTY_PICKING_POSITION')">
          SOLICITAR TAREFA
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'CRITICAL_TASK_REJECTED'">
      <p class="description">
        Pallet marcado como <span>perdido</span>. Última posição:
        {{ formatPosition(pendingTask.data.originalPosition.x, pendingTask.data.originalPosition.y) }}
      </p>
      <p>Solicite nova tarefa de inventário</p>

      <div class="send-task-buttons">
        <button disabled
                mat-button
                (click)="openSidenav('STOCK_INVENTORY_BY_TAG_RFID')">
          SOLICITAR TAREFA
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'NEGATIVE_Z_INDEX_CRATES_AT_PICKING'">
      <p class="description">
        O pallet de tag <span>{{pendingTask.data.tagRfid}}</span> está com a altura indefinida, é necessário a atualização do valor da sua altura.
      </p>
      <p>Pallet encontrado em: <span>{{ pendingTask.data.position }}</span></p>

      <div class="send-task-buttons">
        <button mat-button (click)="openSidenav('NEGATIVE_Z_INDEX_CRATES_AT_PICKING')">
          MUDAR LOCALIZAÇÃO
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'UNDEFINED_PRODUCT'">
      <p>O cadastro do produto com sku: <span>{{ pendingTask.data.sku }}</span> não foi encontrado.</p>

      <div class="send-task-buttons">
        <button mat-button (click)="openAddProductModal(pendingTask)">
          CADASTRAR PRODUTO
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
