<div class="holder">
  <div *ngFor="let item of items; let odd = odd"
       class="item"
       [ngStyle]="{'height':item.height}"
       [ngClass]="{'odd':odd, 'level':item.level}">

    <div class="start" *ngIf="item.level">
      {{item.start}} cm
    </div>

    <div class="end"  *ngIf="item.level">
        {{item.end}} cm
      </div>

    <div class="label" *ngIf="item.level">
      {{item.order}} - {{item.name}}
    </div>
  </div>
</div>
