<app-side-color-card>
  <div class="table" content>
    <div class="table-top">
      <h2>PRODUTOS CADASTRADOS ({{ this.products?.length || '-' }})</h2>

      <div class="change-page-buttons">
        <button class="previous-page"
                (click)="getPreviousPage()"
                [disabled]="productManagementService.atFirstPage$ | async">
          <i class="uil uil-arrow-left"></i>
        </button>
        <p>{{ getPaginationString(productManagementService.currentPage$ | async, productManagementService.pageCount$ | async) }}</p>
        <button class="next-page" (click)="getNextPage()"
                [disabled]="productManagementService.atLastPage$ | async">
          <i class="uil uil-arrow-right"></i>
        </button>
      </div>
    </div>
    <app-table-header>
      <app-table-header-column name="createdAt">
        <span content>Data de criação</span>
      </app-table-header-column>
      <app-table-header-column name="name">
        <span content>Nome</span>
      </app-table-header-column>
      <app-table-header-column name="description">
        <span content>Descrição</span>
      </app-table-header-column>
      <app-table-header-column name="sku">
        <span content>SKU</span>
      </app-table-header-column>
      <app-table-header-column name="weight" size="small">
        <span content>Peso</span>
      </app-table-header-column>
      <app-table-header-column name="width" size="small">
        <span content>Comprimento</span>
      </app-table-header-column>
      <app-table-header-column name="height" size="small">
        <span content>Altura</span>
      </app-table-header-column>
      <app-table-header-column name="depth" size="small">
        <span content>Profundidade</span>
      </app-table-header-column>
      <app-table-header-column name="delete-btn" size="button">
        <span content></span>
      </app-table-header-column>
    </app-table-header>

    <app-table-items *ngIf="!isLoading">
      <app-table-item *ngFor="let product of products; let odd = odd" [isOdd]="odd" (click)="onItemClick(product)">
        <app-table-column name="createdAt">
          <span content>{{ formatDate(product.createdAt) }}</span>
        </app-table-column>
        <app-table-column name="name">
          <span content>{{ product.name }}</span>
        </app-table-column>
        <app-table-column name="description">
          <span content>{{ product.description || '-'}}</span>
        </app-table-column>
        <app-table-column name="sku">
          <span content>{{ product.sku }}</span>
        </app-table-column>
        <app-table-column name="weight" size="small">
          <span content>{{ product.weight ? product.weight + ' Kg' : '-'}}</span>
        </app-table-column>
        <app-table-column name="width" size="small">
          <span content>{{ product.width ? product.width + ' m' : '-'}}</span>
        </app-table-column>
        <app-table-column name="height" size="small">
          <span content>{{ product.height ? product.height + ' m' : '-'}}</span>
        </app-table-column>
        <app-table-column name="depth" size="small">
          <span content>{{ product.depth ? product.depth + ' m' : '-'}}</span>
        </app-table-column>
        <app-table-column name="delete-btn" size="button">
          <div content>
            <button (click)="openDeleteProductModal(product); $event.stopPropagation()">
              <i class="uil uil-trash"></i>
            </button>
          </div>
        </app-table-column>
      </app-table-item>
      <div class="empty-table" *ngIf="!products?.length">
        NÃO EXISTEM PRODUTOS NESTE MOMENTO.
      </div>
    </app-table-items>
    <div *ngIf="isLoading" class="loader">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
  </div>
</app-side-color-card>
