<div class="prev-next">
  <div class="align">
    <app-prev-next-button (click)="toHomePage()" [direction]="'left'" [icon]="'estate'"></app-prev-next-button>
  </div>
  <h1>Documentos de entrada / Triagem</h1>
  <div class="align">
    <app-prev-next-button (click)="toScreening()" [direction]="'right'" [label]="'triagem'"></app-prev-next-button>
  </div>
</div>

<div class="ctrl-bar">

  <div class="filters">
    <div class="search-input">
      <input [formControl]="searchTermFormControl" placeholder="Pesquisar OC, NFe, CNPJ, Razão social..." autocomplete="off">
      <i class="uil uil-search"></i>
    </div>

    <mat-select #select [value]="selectedFilter?.id" [matTooltip]="'Período de emissão'">
      <mat-option *ngFor="let filter of filters" [value]="filter.id">{{filter.name}}</mat-option>
      <mat-option [value]="'CUSTOM'" [disabled]="customFilterSelectedLabel">{{customFilterSelectedLabel ? customFilterSelectedLabel : "Customizar período"}}</mat-option>
      <mat-option [value]="'CUSTOM'" *ngIf="customFilterSelectedLabel">{{!customFilterSelectedLabel ? customFilterSelectedLabel : "Customizar período"}}</mat-option>
    </mat-select>

    <app-in-group-status-filter-ctrl (statusSelected)="statusSelected($event)"></app-in-group-status-filter-ctrl>
  </div>

  <div class="order">
    <button mat-raised-button (click)="openFakeInOrderModal()">
      Ordem de Demonstracao
    </button>
  </div>

  <div class="update-button">
    <p>Atualizado às {{actualHour}}</p>
    <button mat-raised-button (click)="update()">
      <i class="uil uil-sync"></i>
      <span>Atualizar</span>
    </button>
  </div>


</div>

<div class="content">

  <app-side-color-card color="in">
    <app-in-group
      content
      [title]="'ordens de compra'"
      [type]="'ORDER'"
      [windowSize]="5"
      [documents]="filteredOrders"
      [itemTemplate]="buyOrdersTemplate"
      [statePage]="isLoading">

      <ng-template #buyOrdersTemplate let-item="item" let-odd="odd">
        <app-in-group-buy-order-item
          [isOdd]="odd"
          [order]="item"
          (goToComment)="openOrderDetailsModal(item, $event, 99, false)"
          (goToOther)="openOrderDetailsModal(item, $event, 0, false)"
        ></app-in-group-buy-order-item>
      </ng-template>

      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-in-group-buy-order-header header></app-in-group-buy-order-header>
    </app-in-group>
  </app-side-color-card>


  <app-side-color-card color="in">
    <app-in-group
      content
      [title]="'notas fiscais recebidas (xml)'"
      [type]="'NFE'"
      [windowSize]="5"
      [documents]="filteredNFes"
      [itemTemplate]="nfeReceivedTemplate"
      [statePage]="isLoading">

      <ng-template #nfeReceivedTemplate let-item="item" let-odd="odd">
        <app-in-group-nfe-item
          [isOdd]="odd"
          [nfe]="item"
          (goToComments)="openOrderDetailsModal(item, $event, 99, true)"
          (goToOther)="openOrderDetailsModal(item, $event, 0, true)"
        ></app-in-group-nfe-item>
      </ng-template>

      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-in-group-nfe-header header></app-in-group-nfe-header>
    </app-in-group>
  </app-side-color-card>
</div>

<app-full-page-loader *ngIf="isModalLoading"></app-full-page-loader>
