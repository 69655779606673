import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-out-order-execution-plan',
  templateUrl: './out-order-execution-plan.component.html',
  styleUrls: ['./out-order-execution-plan.component.scss']
})
export class OutOrderExecutionPlanComponent implements OnInit, OnChanges {

  @Input() executionPlan;
  @Input() showBottomButtons = false;
  @Output() cancelButtonEmitter = new EventEmitter<boolean>();
  @Output() submitButtonEmitter = new EventEmitter<boolean>();
  pallets = [];
  steps = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.executionPlan){
      const {currentValue} = changes.executionPlan;
      this.pallets = currentValue.selectedPallets;
      this.steps = currentValue.pickingSteps;
    }
  }

  cancel() {
    this.cancelButtonEmitter.emit(true);
  }

  submit() {
    this.submitButtonEmitter.emit(true);
  }

}
