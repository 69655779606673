<div class="ov column" (click)="openDetailsModal()">
  {{ item.number || '---' }}
</div>
<div class="timestamp column" (click)="openDetailsModal()">
  {{ date || '---' }}
</div>
<div class="document column" (click)="openDetailsModal()">
  {{ item.clientDoc || '---' }}
</div>
<div class="name column" (click)="openDetailsModal()">
  <div class="client-name" [matTooltip]="item.clientName">{{ item.clientName || '---' }}</div>
</div>
<div class="products column" (click)="openDetailsModal()">
  <div class="product" *ngFor="let p of item.products" [matTooltip]="p.name">{{ p.name || '---' }}</div>
</div>
<div class="quantity column" (click)="openDetailsModal()">
  <div *ngFor="let p of item.products">{{ p.quantity.required || '---' }}</div>
</div>
<div class="status column" (click)="openDetailsModal()">
  {{ translateStatus(item.status) }}
</div>
<div class="fake-invoice column" *ngIf="isDevOrHom()">
  <button mat-raised-button class="primary-2"
          (click)="emulateNfe(item)">
    FAKE NFE
  </button>
</div>