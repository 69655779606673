import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private http: HttpClient) { }

  getTasks() {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/all-tasks').toPromise();
  }
}
