import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StockDataSourceService } from '../../core/data-sources/stock-data-source.service';
import { FullLoaderService } from 'src/app/core/services/full-loader.service';
import { SnackBarService } from '../../core/services/snack-bar.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {


  map;
  stockCount;
  isMapLoaded: boolean;
  notificationAlreadyOpen = false;
  notificationData: {
    type: string,
    title: string,
    message: string
  };
  openTip = false;

  @ViewChild('notification', { static: true }) notificationTmpl;
  @ViewChild('mapComponent', { static: true }) mapComponent: any;

  @HostListener('wheel', ['$event']) preventZoomOnWHeel(event: WheelEvent) {
    if (event.ctrlKey || event.metaKey) {
      this.openFeedbackOnce();
      event.preventDefault();
    }
  }
  @HostListener('window:keydown', ['$event']) preventZoomOnCtrlPlus(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-' || event.key === '=')) {
      this.openFeedbackOnce();
      event.preventDefault();
    }
  }

  constructor(private router: Router,
              private stockService: StockDataSourceService,
              public fullLoaderService: FullLoaderService,
              private snackbarService: SnackBarService) {
    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        if (routerEvent.urlAfterRedirects !== '/map') {
          location.reload();
        }
      }
    });
  }


  public navigate(excelCoord: string) {
    this.mapComponent.goTo(100, 1000);
  }

  ngOnInit(): void {
    this.fullLoaderService.open();
    this.getMaps().then(() => {})
      .catch((err) => console.log(err));
    this.stockService.reloadMap$.subscribe(value => {
      if (value) {
        setTimeout(() => {
          location.reload();
        }, 5000);
      }
    });
  }

  async getMaps() {
    this.map = await this.stockService.getGroundPosition(false);
    this.stockService.reloadMap$.next(false);
    return this.map;
  }

  toDashboard() {
    this.router.navigate(['in']);
  }

  mapLoaded() {
    this.isMapLoaded = true;
    this.closeLoader();
  }

  closeLoader() {
    if (this.map && this.isMapLoaded) {
      this.fullLoaderService.close();
    }
  }

  openFeedbackOnce(): void {
    if (this.notificationAlreadyOpen) {
      return;
    }
    this.notificationAlreadyOpen = true;
    this.openTip = true;
    this.notificationData = {
      type: 'warning',
      title: 'ATENÇÃO',
      message: 'Para dar zoom, utilize os botões no canto inferior direito no mapa.'
    };
    this.snackbarService.showFeedback(this.notificationTmpl, this.notificationData, '1');
    setTimeout(() => {
      this.snackbarService.hideNotification('1');
      this.notificationAlreadyOpen = false;
    }, 5000);
  }
}
