import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterViewChecked
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import {UserService} from '../../../core/services/user.service';

@Component({
  selector: 'app-in-details-comments',
  templateUrl: './in-details-comments.component.html',
  styleUrls: ['./in-details-comments.component.scss']
})
export class InDetailsCommentsComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {

  commentForm: FormGroup;
  comments: [{
    id: string,
    owner: string,
    value: string,
    user: {firstName: string, lastName: string},
    isOwner: boolean,
    toOldToDelete: boolean,
    createdAt: Date
  }];
  loggedUserId: string;

  private scrollContainer: any;

  @Output() submitEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Input() itemDetails;

  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;

  constructor(private formBuilder: FormBuilder, private userService: UserService) { }

  ngOnInit(): void {
    this.buildLoginForm();
    this.loggedUserId = this.userService.getLoggedUser().id;
  }

  ngOnChanges(changes): void {
    this.itemDetails.comments = this.itemDetails.comments.filter((comment)=> !(comment.deletedAt != 0))
    const comments = this.itemDetails.comments || [];
    this.comments = comments.map(a => ({
      ...a,
      isOwner: a.owner === this.loggedUserId,
      toOldToDelete: moment().diff(a.createdAt, 'hours') >= 48
    }));
  }

  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  buildLoginForm() {
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });
  }

  formatDate(date) {
    date = moment(date).format('DD/MM/YYYY - HH:mm');
    date = date.replace(':', 'h');
    return date;
  }

  delete(id) {
    this.deleteEvent.emit(id);
  }

  submit() {
    if (this.commentForm.valid) {
      const formValue = this.commentForm.getRawValue();
      this.submitEvent.emit(formValue);
      this.commentForm.controls.comment.setValue('');
    }
  }

}
