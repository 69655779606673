<!--<app-warehouse-map-view></app-warehouse-map-view>-->
<div class="map">
  <app-warehouse-map-task-position-view
  [events]="selectedEvents"
  ></app-warehouse-map-task-position-view>
</div>
<div class="list">
  <input id="file" type='file' (change)="fileChanged($event)">

  <mat-select #select [value]="selectedTag" (valueChange)="changeTag($event)">
    <mat-option value="ALL">ALL</mat-option>
    <mat-option *ngFor="let t of tags" [value]="t">{{t}}</mat-option>
  </mat-select>

  <mat-select #select [value]="selectedEventType" (valueChange)="changeEvent($event)">
    <mat-option value="ALL">ALL</mat-option>
    <mat-option *ngFor="let e of eventTypes" [value]="e">{{e}}</mat-option>
  </mat-select>
  <mat-slider
    thumbLabel
    [displayWith]="formatLabel"
    (valueChange)="range($event)"
    tickInterval="1"
    step="1"
    min="0"
    [max]="currentEvents.length"
    aria-label="units"></mat-slider>

  <div class="event-list">
    <div class="item" *ngFor="let e of selectedEvents">
      <div class="event">{{e.event}}</div>
      <div class="event">{{e.timestamp}}</div>
      <div class="event">{{e.tagRfid}}</div>
      <div class="event">x:{{e.x}} y:{{e.y}}</div>
    </div>
  </div>
</div>
