<app-table-column name="date" size="medium">
  <span content>{{ formatDate(report.createdAt) }}</span>
</app-table-column>

<app-table-column name="admin" size="medium">
  <span content>{{ report.admin?.firstName + ' ' + report.admin?.lastName  || '---' }}</span>
</app-table-column>

<app-table-column name="type" size="medium">
  <span content>{{ translateType(reportData.type) || '---' }}</span>
</app-table-column>

<app-table-column name="total" size="medium">
  <span content>{{ totalItems }}</span>
</app-table-column>

<app-table-column name="qty-found" size="medium">
  <span content>{{ qtyFound }}</span>
</app-table-column>

<app-table-column name="qty-missing" size="medium">
  <span content>{{ qtyMissing }}</span>
</app-table-column>

<app-table-column name="state" size="small">
  <app-background-icon
    [icon]="report.state === 'REJECTED' ? 'times' : report.state === 'APPROVED' ? 'check' : 'spinner-alt'"
    [backgroundColor]="report.state === 'REJECTED' ? 'alert' : report.state === 'APPROVED' ? 'success' : 'warning'"
    size="normal"
    content>
  </app-background-icon>
</app-table-column>
