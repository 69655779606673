import {Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-warehouse-map',
  templateUrl: './warehouse-map.component.html',
  styleUrls: ['./warehouse-map.component.scss']
})
export class WarehouseMapComponent implements OnInit {

  constructor(private elementRef: ElementRef, private ngZone: NgZone,
              private router: Router) {}

  ngOnInit(): void {
  }

  toDashboard() {
    this.router.navigate(['in']);
  }

  toEditGroundGroups() {
    this.router.navigate(['ground-groups']);
  }
}
