import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {
  @Input() column = '';
  @Input() menu;
  @Input() appliedFilters = {};

  constructor() { }

  ngOnInit(): void {
  }

}
