<app-reports-table-modal-header [displayedColumns]="displayedColumns"></app-reports-table-modal-header>
<app-pallet-reports-table-item
  *ngFor="let item of items; let odd = odd"
  [isOdd]="odd"
  [isMissing]="item.inconsistency?.state === 'MISSING'"
  [item]="item">
</app-pallet-reports-table-item>

<div class="empty" *ngIf="!items.length">
  NÃO EXISTEM PALLETS.
</div>
