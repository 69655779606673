import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { StockDataSourceService } from 'src/app/core/data-sources/stock-data-source.service';
import { StockFilterParams } from 'src/app/core/services/stock.service';


@Injectable({
  providedIn: 'root'
})
export class StockInventoryService {
  private $missingPallets: Subject<any[]> = new Subject<any[]>();
  public pageSize = 20;
  public pageCount;
  public currentPage;
  public offset;
  public count;

  constructor(private dataSource: StockDataSourceService) { }

  async init(filter: StockFilterParams = null) {
    return this.getPage(1, filter);
  }

  async reload(filter: StockFilterParams = null, resetOffset = false) {
    return await this.getPage(this.currentPage, filter, resetOffset);
  }

  async getNextPage(filter: StockFilterParams = null) {
    if (this.currentPage < this.pageCount) {
      return await this.getPage(this.currentPage + 1, filter);
    }
  }

  private async getPage(page: number, filter: StockFilterParams = {}, resetOffset = false) {
    this.currentPage = resetOffset ? 1 : page;
    this.offset = resetOffset ? 0 : (this.currentPage - 1) * this.pageSize;

    const stockParams = {
      limit: this.pageSize,
      offset: this.offset,
      state: "MISSING",
      orderBy: "updatedAt",
      sortBy: "DESC"
    };
    Object.assign(stockParams, filter);
    const response = await this.dataSource.getStockCratesV2(stockParams);
    this.count = response.count;
    this.$missingPallets.next(response.crates);
    this.pageCount = Math.ceil(this.count / this.pageSize);
  }

  get missingPallets$(): Observable<any> {
    return this.$missingPallets;
  }

}
