import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TaskRequestSidenavService } from '../../core/services/task-request-sidenav.service';
import {
  IPickingPosition,
  ProductPickingDataSourceService
} from '../../core/data-sources/product-picking-data-source.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { toExcelFormat } from '../../helpers/format-position';
import { ErrorService, ErrorsEnum } from '../../core/services/error.service';

@Component({
  selector: 'app-product-picking-details-modal',
  templateUrl: './product-picking-details-modal.component.html',
  styleUrls: ['./product-picking-details-modal.component.scss']
})
export class ProductPickingDetailsModalComponent implements OnInit {

  products: {
    name: string;
    sku: string;
    productImageUrl?: string;
  }[];
  selectedTab: number;

  constructor(public dialogRef: MatDialogRef<ProductPickingDetailsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private sidenavService: TaskRequestSidenavService,
              private productPickingService: ProductPickingDataSourceService,
              private dialog: MatDialog,
              private errorService: ErrorService) {
    this.selectedTab = data.tabIndex;
  }

  ngOnInit(): void {
    this.parseProducts();
  }

  parseProducts() {
    this.products = [{
      name: this.data.item.name,
      sku: this.data.item.sku
    }];
  }

  openRelocateSidenav(item) {
    const { x, y } = item.pickingPosition.groundPosition;
    const data = {
      type: 'RELOCATE_FROM_PRODUCT_PICKING',
      groundPosition: {
        x,
        y
      },
      productName: item.productName || 'nome não disponível'
    };
    this.close();
    this.sidenavService.toggle(data).then();
  }

  openConfirmClearPositionModal(pickingPosition: IPickingPosition) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: {
        label: `Você tem certeza que deseja resetar o produto da posição ${toExcelFormat(pickingPosition.groundPosition.x, pickingPosition.groundPosition.y)}?`
      }
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.resetPickingPosition(pickingPosition);
      }
    });
  }

  async resetPickingPosition(pickingPosition: IPickingPosition): Promise<void> {
    try {
      const body = {
        sku: null
      };
      await this.productPickingService.patchPickingPositionById(body, pickingPosition.id);
      this.dialogRef.close(true);
    } catch (e) {
      switch (e.error.type) {
        case ErrorsEnum.TASK_EXISTING:
          this.errorService.openErrorSnackBarWithCustomMessage(`Não é possível limpar posição pois existe uma tarefa para esta posição de picking`);
          break;
        default:
          this.errorService.openErrorSnackBar(e);
      }
    }
  }

  submitComment(comment) {
    console.log('submit: ', comment);
  }

  deleteComment(comment) {
    console.log('delete: ', comment);
  }

  close(event?) {
    this.dialogRef.close();
  }
}
