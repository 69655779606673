<div class="ov column" (click)="openDetailsModal()">
  {{ item.number || '---' }}
</div>
<div class="timestamp column" (click)="openDetailsModal()">
  {{ date || '---' }}
</div>
<div class="document column" (click)="openDetailsModal()">
  {{ item.clientDoc || '---' }}
</div>
<div class="name column" (click)="openDetailsModal()">
  <div class="client-name">{{ item.clientName || '---' }}</div>
</div>
<div class="products column" (click)="openDetailsModal()">
  <div class="product" *ngFor="let p of item.products">{{ p.name || '---' }}</div>
</div>
<div class="quantity column" (click)="openDetailsModal()">
  <div *ngFor="let p of item.products">{{ p.quantity || '---' }}</div>
</div>
<div class="pallets column" (click)="openDetailsModal()">
  {{ item.cratesQty }}
</div>
<div class="status column" (click)="openDetailsModal()">
  {{ translateStatus(item.status) }}
</div>
<!--<div class="send-task-button column">-->
<!--  <button mat-raised-button class="primary-1" (click)="openSidenav('PICKING_SELECT_DESTINATION', item)" [disabled]="item.hasDestiny">Destino</button>-->
<!--</div>-->
<!--<div class="send-task-button column">-->
<!--  <button mat-raised-button class="primary-1" (click)="openSidenav('PICKING_RELOCATE_EXPEDITION', item)" [disabled]="!item.hasDestiny && item.executionPlan?.selectedPallets?.length > 0">Pallets</button>-->
<!--</div>-->
<!--<div class="send-task-button column">-->
<!--  <button mat-raised-button class="primary-1" (click)="openSidenav('PICKING_PRODUCTS', item)" [disabled]="!item.hasDestiny && item.executionPlan?.pickingSteps?.length > 0">Produtos</button>-->
<!--</div>-->
<div class="send-task-button column">
  <button mat-raised-button class="primary-1" (click)="sendClickedHandler($event)">enviar</button>
  <button mat-raised-button class="secondary-2" (click)="removeClickedHandler($event)">remover</button>

</div>
<!--<div class="messages column">-->
<!--  <i *ngIf="item.commentsQty === 0" class="uil uil-comment"></i>-->
<!--  <i *ngIf="item.commentsQty !== 0" class="uil uil-comment-lines"></i>-->
<!--  <span>{{ item.commentsQty }}</span>-->
<!--</div>-->
