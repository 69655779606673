import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionService} from '../../../core/services/action.service';

@Component({
  selector: 'app-comparison-nfe-and-oc',
  templateUrl: './comparison-nfe-and-oc.component.html',
  styleUrls: ['./comparison-nfe-and-oc.component.scss']
})

export class ComparisonNfeAndOcComponent implements OnInit {

  @Input() data;

  @Output() closeEvent = new EventEmitter();

  isToggleChecked: boolean;
  isCheckboxChecked: boolean;

  invoiceProducts = [];
  orderProducts = [];

  consolidatedInvoice = [];
  consolidatedOrder = [];

  missingIds = [];
  // productsNotOrderedIds = [];

  constructor(private actionService: ActionService) { }

  ngOnInit(): void {
    this.isToggleChecked = this.data.comparison.receiveEnable === true;
    this.isCheckboxChecked = this.data.comparison.correctionIssued === true;

    this.handleProblemsArray(this.data.comparison.problems);
    this.handleMissingProduct(this.missingIds);
    this.consolidation();
    // this.handleProductNotOrdered(this.productsNotOrderedIds);
  }

  handleProblemsArray(problems) {
    for (const order of this.data.relatedOrders) {
      for (const p of order.products) {
        const product = {};
        Object.assign(product, p);
        this.orderProducts.push(product);
      }
    }

    const invoiceProductsBySku = {};
    for (const p of this.data.invoice.products) {
      invoiceProductsBySku[p.sku] = { ...p, problems: [] };
    }

    for (const c of problems) {
      const tokens = c.split('-');
      const id = tokens[tokens.length - 1];
      let problem = c;
      if (tokens.length === 2 || tokens.length === 3) {   // Always for one or two "-" on the problem enum.
        problem = problem.replace(`-${id}`, '');
      }
      if (problem === 'SET-PRODUCT_MISSING') {
        this.missingIds.push(id);
      }
      if (id && this.data.invoice.products.filter(p => p.sku === id).length !== 0) {
        invoiceProductsBySku[id].problems.push(problem);
      }
    }
    this.invoiceProducts = Object.values(invoiceProductsBySku);

    this.orderBySku(this.invoiceProducts);
    this.orderBySku(this.orderProducts);
  }

  handleMissingProduct(id) {
    const invoiceProductsWithMissing = [...this.invoiceProducts];
    for (const i of id) {
      const productMissingIndex = this.orderProducts.findIndex(item => item.sku === i);
      if (productMissingIndex > -1) {
        invoiceProductsWithMissing.splice(productMissingIndex, 0, { missing: true });
      }
    }
    this.invoiceProducts = [...invoiceProductsWithMissing];
  }

  // PRODUCT NOT ORDER HANDLE
  // handleProductNotOrdered(sku) {
  //   const productsNotOrdered = [...this.orderProducts];
  //   for (const id of sku) {
  //     const index = this.orderProducts.findIndex(item => item.sku === id);
  //     if (index > -1) {
  //       productsNotOrdered.splice(index, 1, { notOrdered: true });
  //     }
  //   }
  //   this.orderProducts = [...productsNotOrdered];
  // }

  consolidation() {
    for (const relatedInvoice of this.data.relatedInvoices) {
      for (const p of relatedInvoice.products) {
        const consolidatedOrderProducts = [...this.orderProducts.filter(item => item.sku === p.sku)];

        for (const cop of consolidatedOrderProducts) {
          cop.quantity -= p.quantity;
          this.consolidatedOrder.push({...cop, quantity: p.quantity});
        }

        if (this.consolidatedOrder.length > this.consolidatedInvoice.length) {
          this.consolidatedInvoice.push(p);
        }
      }
    }

    this.sumSameProduct(this.orderProducts);
    this.sumSameProduct(this.consolidatedInvoice);
    this.sumSameProduct(this.consolidatedOrder);
  }

  sumSameProduct(productsArray) {
    for (let i = 0; productsArray.length > i; i++) {
      for (let k = i + 1; productsArray.length > k; k++) {
        if (productsArray[i].sku === productsArray[k].sku) {
          productsArray[i].quantity = productsArray[i].quantity + productsArray[k].quantity;
          productsArray.splice(k, 1);
        }
      }
    }
  }

  orderBySku(productsArray) {
    productsArray.sort((a, b) => {
      if (a.sku === b.sku) {
        return 0;
      } else if (a.sku < b.sku) {
        return a.sku - b.sku;
      } else if (a.sku > b.sku) {
        return a.sku - b.sku;
      }
    });
  }

  async setResolution() {
    try {
      const params = {
        id: this.data.invoice.id,
        receiveEnable: this.isToggleChecked,
        correctionIssued: this.isCheckboxChecked
      };
      await this.actionService.setResolution(params);
    } catch (e) {
      // TODO handle error
    }
  }

  onSlideToggleChange(event) {
    this.isToggleChecked = event.checked;
    if (event.checked === false) {
      this.isCheckboxChecked = false;
    }
  }

  onCheckboxChange(event) {
    this.isCheckboxChecked = event.checked;
  }

  cancel() {
    this.closeEvent.emit('CANCELED');
  }

  async submit() {
    await this.setResolution();
    this.closeEvent.emit('SUBMITTED');
  }
}
