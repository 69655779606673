<div class="page-header">
  <app-prev-next-button (click)="toStock()" direction="left" label="estoque"></app-prev-next-button>
  <h1>Relatórios</h1>
</div>

<div class="buttons">
  <div class="update-button">
    <p>Atualizado às {{ actualHour }}</p>
    <button mat-raised-button (click)="update()">
      <i class="uil uil-sync"></i>
      <span>Atualizar</span>
    </button>
  </div>
</div>

<app-side-color-card color="primary-1">
  <div content class="card-content">
    <div class="card-header">
      <h2>Relatórios ({{ (reportService.totalItems$ | async) || '-' }})</h2>
      <app-paginator
        [currentPage]="reportService.currentPage$ | async"
        [pageCount]="reportService.pageCount$ | async"
        [atFirstPage]="reportService.atFirstPage$ | async"
        [atLastPage]="reportService.atLastPage$ | async"
        (previousPage)="onPreviousPage($event)"
        (nextPage)="onNextPage($event)">
      </app-paginator>
    </div>

    <div class="table">
      <app-reports-table-header
        [appliedFilters]="appliedFilters"
        (filterValues)="onFilterValuesChange($event)">
      </app-reports-table-header>

      <div *ngIf="isLoading" class="loader">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>

      <ng-container *ngIf="!isLoading">
        <app-reports-table-item
          *ngFor="let report of reports; let odd = odd"
          [report]="report"
          (click)="itemClicked(report)"
          [ngClass]="{'isOdd': odd}">
        </app-reports-table-item>

        <div class="empty" *ngIf="!reports?.length">
          NÃO EXISTEM RELATÓRIOS NESTE MOMENTO.
        </div>
      </ng-container>
    </div>
  </div>
</app-side-color-card>
