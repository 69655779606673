<!--<app-warehouse-map-view></app-warehouse-map-view>-->

<div class="page-header">
  <app-prev-next-button (click)="toDashboard()" [direction]="'left'" icon="estate"></app-prev-next-button>
  <h1>Tags</h1>
</div>

<div class="edit-sectors-button">
  <button mat-raised-button (click)="toEditGroundGroups()">
    <span>gerenciar setores</span>
  </button>
</div>

<app-warehouse-map-table></app-warehouse-map-table>
