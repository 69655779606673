import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { PickingService } from '../../core/services/picking.service';
import { first } from 'rxjs/operators';
import { ActionService, RunExecutionPlanAction, SetExecutionPlanAction } from '../../core/services/action.service';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { TaskRequestSidenavService } from '../../core/services/task-request-sidenav.service';
import { ManualPickingModalComponent } from '../../modals/manual-picking-modal/manual-picking-modal.component';
import { OutOrderDataSourceService } from '../../core/data-sources/out-order-data-source.service';
import { ErrorsEnum, ErrorService } from '../../core/services/error.service';
import { ActionsEnum } from '../../core/data-sources/action-data-source.service';
import { OutOrderPlanModalComponent } from '../../modals/out-order-plan-modal/out-order-plan-modal.component';
import { PackingModalService } from '../../core/services/packing-modal.service';

export interface PickingDocuments {
  outOrders: SellOrders[];
  outOrdersWithExecutionPlan: OutOrdersWithPlan[];
  outOrdersInSeparation: InSeparation[];
  outOrdersInExpedition: OrdersInExpedition[];
}

export interface SellOrders {
  number: string;
  issuedAt: number;
  clientName: string;
  clientDoc: string;
  products: PickingProducts[];
  status: PickingStatusEnum;
  commentsQty: number;
}

export interface OutOrdersWithPlan {
  number: string;
  issuedAt: number;
  clientName: string;
  clientDoc: string;
  products: PickingProducts[];
  status: string;
  cratesQty: number;
  commentsQty: number;
  hasDestiny: boolean;
}

export interface InSeparation {
  number: string;
  issuedAt: number;
  clientName: string;
  clientDoc: string;
  products: PickingProductsForSeparationAndExpedition[];
  status: string;
  tasks: string;
  commentsQty: number;
  invoiceRequested: boolean;
}

interface PickingSteps {
  x: number;
  y: number;
  sku: String;
  quantity: number;
}

interface SelectedPallets {
  sku: string;
  palletId: string;
  quantity: number;
}

export interface ExecutionPlan {
  pickingSteps: PickingSteps[];
  selectedPallets: SelectedPallets[];
}

export interface OrdersInExpedition {
  id: string;
  number: string;
  issuedAt: number;
  clientName: string;
  clientDoc: string;
  products: PickingProductsForSeparationAndExpedition[];
  status: string;
  commentsQty: number;
  cratesQty: number;
  invoiceRequested: boolean;
  executionPlan: ExecutionPlan;
  executionPlanVersion: number;
}

export interface PickingProducts {
  sku: string;
  name: string;
  quantity: number;
}

export interface PickingProductsForSeparationAndExpedition {
  sku: string;
  name: string;
  quantity: {
    required: number;
    collected: number;
  };
}

export enum PickingStatusEnum {
  UNAVAILABLE_PRODUCTS = 'UNAVAILABLE_PRODUCTS',
  INVALID = 'INVALID',
  OK = 'OK',
  NOT_ENOUGH_ON_STOCK = 'NOT_ENOUGH_ON_STOCK',
  UNKNOWN = 'UNKNOWN'
}

@Component({
  selector: 'app-picking',
  templateUrl: './picking.component.html',
  styleUrls: ['./picking.component.scss']
})

export class PickingComponent implements OnInit {

  allDocuments: PickingDocuments;
  isLoading: boolean;
  isModalLoading = false;

  actualHour;

  pallets = [];
  products = [];

  currentPlanningOutOrderId = null;
  disableProcessButton = false;
  readyToInvoiceItems: InSeparation[] = [];
  pendingTaskHasError: boolean;

  constructor(public dialog: MatDialog,
    private router: Router,
    private pickingService: PickingService,
    private actionService: ActionService,
    private sidenavService: TaskRequestSidenavService,
    private outOrderDataSource: OutOrderDataSourceService,
    private packingModalService: PackingModalService,
    private errorService: ErrorService) {
    this.getDocs();
  }

  ngOnInit(): void {
    this.update();
  }

  async runExecutionPlan(item) {
    this.currentPlanningOutOrderId = item.id;
    const action: RunExecutionPlanAction = {
      type: ActionsEnum.RUN_EXECUTION_PLAN,
      data: {
        outOrderId: item.id
      }
    };
    try {
      const plan = await this.actionService.runExecutionPlan(action, { disableAutoErrorHandler: true });
      this.disableProcessButton = true;
      const dialogRef = this.dialog.open(OutOrderPlanModalComponent, {
        panelClass: 'details-modal',
        autoFocus: false,
        data: plan
      });
      dialogRef.afterClosed().subscribe((res: { shouldSubmit: boolean, executionPlan: ExecutionPlan }) => {
        if (res.shouldSubmit) {
          this.setExecutionPlan(item, res.executionPlan);
        }
      });
    } catch (e) {
      const { type } = e.error;
      switch (type) {
        case ErrorsEnum.GROUND_POSITION_INVALID_STATE_TRANSITION:
          this.errorService.openErrorSnackBarWithCustomMessage('Não é possível planejar uma ordem enquanto existe alguma ordem em separação.');
          break;
        case ErrorsEnum.NOT_ENOUGH_PRODUCTS_AT_PICKING:
          this.errorService.openErrorSnackBarWithCustomMessage('Não existem produtos necessários para esta ordem disponíveis na separação');
          break;
        case ErrorsEnum.NO_PALLETS_WITH_SKU_AT_PICKING:
          this.errorService.openErrorSnackBarWithCustomMessage(`Não existem pallets do(s) produto(s) ${e.error.data.skus.join(', ')} disponíveis na separação`);
          break;
        default:
          this.errorService.openErrorSnackBar(e);
      }
    } finally {
      this.disableProcessButton = false;
    }
  }

  async setExecutionPlan(item, plan: ExecutionPlan) {
    const action: SetExecutionPlanAction = {
      type: ActionsEnum.SET_EXECUTION_PLAN,
      data: {
        outOrderId: item.id,
        executionPlan: plan
      }
    };
    try {
      await this.actionService.setExecutionPlan(action, { disableAutoErrorHandler: true });
      this.update();
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.currentPlanningOutOrderId = null;
    }
  }

  async getDocs() {
    this.isLoading = true;
    try {
      this.allDocuments = await this.pickingService.getDocuments();
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  order() {
    this.allDocuments.outOrders.sort((a, b) => {
      return a.issuedAt - b.issuedAt;
    });

    this.allDocuments.outOrdersWithExecutionPlan.sort((a, b) => {
      return a.issuedAt - b.issuedAt;
    });

    this.allDocuments.outOrdersInSeparation.sort((a, b) => {
      return a.issuedAt - b.issuedAt;
    });

    this.allDocuments.outOrdersInExpedition.sort((a, b) => {
      return a.issuedAt - b.issuedAt;
    });
  }

  async openReceivedOutOrderDetailsModal(item) {
    await this.packingModalService.openOutOrderDetails(item.number);
  }

  async openPlannedOutOrderDetailsModal(item) {
    await this.packingModalService.openOutOrderDetails(item.number);
  }

  async openExpeditionOutOrderDetailsModal(item) {
    await this.packingModalService.openOutOrderDetails(item.number);
  }

  async openInSeparationOutOrderDetailsModal(item) {
    await this.packingModalService.openOutOrderDetails(item.number);
  }

  openPackingTaskSideModal(item) {
    const data = {
      outOrderId: item.id,
      pallets: item.executionPlan.selectedPallets,
      steps: item.executionPlan.pickingSteps
    };
    return this.sidenavService.toggle({ type: 'START_PACKING', data }).then(async (res: any) => {
      if (res) {
        this.update();
      }
    });
  }

  async sendPackingTask(item) {
    await this.openPackingTaskSideModal(item);
  }
  async removeExecutionOrderPlan(item) {
    await this.actionService.unsetExecutionPlan({ type: ActionsEnum.UNSET_EXECUTION_PLAN, data: { outOrderId: item.id } });
    this.update();
  }


  async openSidenav(data) {
    const { item } = data;
    await this.openPackingTaskSideModal(item);
  }

  openConfirmNfeModal(item) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: { label: 'Você deseja confirmar que a NFe foi emitida no KPL?' }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res === 'SUBMITTED') {
        const actionData = {
          outOrderNumber: item.number,
          invoiceRequested: true
        };
        this.actionService.setInvoiceRequested(actionData).then(() => {
          this.update();
        });
      }
    });
  }

  openFakeNfeModal(item) {
    this.pickingService.fakeNfeIn(item.number).then(() => {
      this.update();
    });
    // const dialogRef = this.dialog.open(FakeNfeModalComponent, {
    //   panelClass: 'fake-nfe-modal',
    //   autoFocus: false
    // });
    // dialogRef.afterClosed().pipe(first()).subscribe(res => {
    //   const body = {
    //     orderNo: item.number,
    //     key: res.key,
    //     serial: res.serial,
    //     number: res.number
    //   };
    //   this.pickingService.postFakeNfe(body).then(() => {
    //     this.update();
    //   });
    // });
  }

  openManualPickingModal() {
    const dialogRef = this.dialog.open(ManualPickingModalComponent, {
      panelClass: 'manual-picking-modal',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res) {
        this.update();
      }
    });
  }

  async postReserveExpedition(details) {
    const cratesIds = [];
    for (const c of details.crates) {
      cratesIds.push(c.id);
    }
    const params = {
      outOrderNumber: details.order.number,
      cratesIds
    };
    await this.actionService.reserveExpedition(params);
  }

  async toStock() {
    await this.router.navigate(['stock']);
  }

  async toDispatch() {
    await this.router.navigate(['dispatch']);
  }

  update() {
    this.actualHour = moment().format('HH:mm');
    this.getDocs().then(() => {
      this.order();
    });
  }

  visiblePendingTaskError() {
    this.pendingTaskHasError = true;
  }
}
