<div class="page-header">
  <app-prev-next-button (click)="toScreening()" direction="left" label="triagem"></app-prev-next-button>
  <h1>Conferência física</h1>
  <app-prev-next-button (click)="toStocking()" direction="right" label="estoque"></app-prev-next-button>
</div>

<div class="ctrl-bar">

  <div class="filters">
    <div class="search-input">
      <input [formControl]="searchTermFormControl"
             placeholder="Pesquisar OC, NFe, Razão social, Produto..."
             autocomplete="off">
      <i class="uil uil-search"></i>
    </div>

    <mat-form-field>
      <mat-label>Filtrar por destino</mat-label>
      <mat-select #select [value]="selectedFilter">
        <mat-option [value]="'ALL'">Todos ({{ allGoods?.length >= 10 ? allGoods?.length : '0' + allGoods?.length }})</mat-option>
        <mat-option [value]="'STORAGE'">Armazenagem ({{ getNumberOfRepetitions('STORAGE') }})</mat-option>
        <mat-option [value]="'QUALITY'">Controle de qualidade ({{ getNumberOfRepetitions('QUALITY') }})</mat-option>
        <mat-option [value]="'DIVERGENT'">Estoque divergente ({{ getNumberOfRepetitions('DIVERGENT') }})</mat-option>
      </mat-select>
    </mat-form-field>

    <app-inspection-status-filter (statusSelected)="statusSelected($event)"></app-inspection-status-filter>
  </div>

  <div class="update-button">
    <p>Atualizado às {{actualHour}}</p>
    <button mat-raised-button (click)="update()">
      <i class="uil uil-sync"></i>
      <span>Atualizar</span>
    </button>
  </div>
</div>

<app-side-color-card color="in">
  <div content class="table">

    <div class="empty" *ngIf="filteredGoods?.length === 0">
      NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
    </div>

    <div class="header" *ngIf="filteredGoods?.length !== 0">
      <app-table-header-column name="check" (click)="selectAllGoods()" styleType="old">
        <span content>
          <mat-checkbox [checked]="allGoodsIsChecked()" (click)="$event.preventDefault()"></mat-checkbox>
        </span>
      </app-table-header-column>

      <app-table-header-column name="order" styleType="old">
        <span content>Pedido</span>
      </app-table-header-column>

      <app-table-header-column name="nfe" styleType="old">
        <span content>N° NFe</span>
      </app-table-header-column>

      <app-table-header-column name="name" styleType="old">
        <span content>Razão social</span>
      </app-table-header-column>

      <app-table-header-column name="product" styleType="old">
        <span content>Produto</span>
      </app-table-header-column>

      <app-table-header-column name="quantity" styleType="old">
        <span content>Qtd</span>
      </app-table-header-column>

      <app-table-header-column name="destination" styleType="old">
        <span content>Destino</span>
      </app-table-header-column>

      <app-table-header-column name="status" styleType="old">
        <span content>Status</span>
      </app-table-header-column>

      <app-table-header-column name="messages" styleType="old">
        <span content>Comentários</span>
      </app-table-header-column>

      <app-table-header-column name="rfid" styleType="old">
        <span content>
          <img src="assets/icons/rfid.svg">
          Tag RFID
        </span>
      </app-table-header-column>
    </div>

    <ng-container *ngIf="state !== InspectionPageState.LOADING">
      <div class="item"
           *ngFor="let good of filteredGoods; let odd = odd"
           [ngClass]="{'background-opacity': odd}">

        <app-table-column name="check" (click)="selectGood(good)" styleType="old">
          <div content>
            <mat-checkbox
              *ngIf="good.palletState === PalletState.RECEIVED"
              [checked]="selectedGoodsTagRfids.has(good.tagRfid)"
              (click)="$event.preventDefault()">
            </mat-checkbox>
          </div>
        </app-table-column>

        <app-table-column name="order"
                          *ngIf="good.inType === 'WITH_INVOICE'"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          size="medium"
                          styleType="old">
          <span content>
            {{
              good.buyOrders.length <= 1
              ? good.buyOrders[0]
              : good.buyOrders[good.buyOrders.length - 1] + ' (' + good.buyOrders.length + ')'
            }}
          </span>
        </app-table-column>

        <app-table-column name="order"
                          *ngIf="good.inType === 'WITHOUT_INVOICE'"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          size="medium"
                          styleType="old">
          <span content>---</span>
        </app-table-column>

        <app-table-column name="nfe"
                          *ngIf="good.inType === 'WITH_INVOICE'"
                          [ngClass]="{'alert-color': good.invoiceStatus === 'RECEIVED_DIVERGENT', 'in-color': good.invoiceStatus === 'RECEIVED_AWAITING_RETIFICATION'}"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          size="large"
                          styleType="old">
          <span content
                [ngClass]="{'alert-color': good.invoiceStatus === 'RECEIVED_DIVERGENT', 'in-color': good.invoiceStatus === 'RECEIVED_AWAITING_RETIFICATION'}">
            {{ good.invoicesId.length <= 1 ? good.invoicesId[0] : good.invoicesId[good.invoicesId.length - 1] + ' (' + good.invoicesId.length + ')' }}
            <div class="background alert-color" *ngIf="good.invoiceStatus === 'RECEIVED_DIVERGENT'">
              <i class="uil uil-times"></i>
            </div>
            <img *ngIf="good.invoiceStatus === 'RECEIVED_AWAITING_RETIFICATION'" src="assets/icons/spinner-edit.svg">
          </span>
        </app-table-column>

        <app-table-column name="nfe"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          *ngIf="good.inType === 'WITHOUT_INVOICE'"
                          styleType="old">
          <span content>---</span>
        </app-table-column>

        <app-table-column name="name"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <span content>{{ good.vendor ? good.vendor : 'n/a'}}</span>
        </app-table-column>

        <app-table-column name="product"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <div content class="product-name-wrapper">
            <span *ngFor="let product of good.products">{{ product.name }}</span>
          </div>
        </app-table-column>

        <app-table-column name="quantity"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <div content class="quantity-wrapper">
            <span *ngFor="let product of good.products">{{ product.quantity }}</span>
          </div>
        </app-table-column>

        <app-table-column name="destination"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <span content
                class="border secondary-color"
                [ngClass]="{'alert': good.destination === 'STOCK_DIVERGENT', 'hide-element': good.palletStatus === 'MISSING'}">
            {{ good.destination === 'STORAGE' ? 'Armazenagem' : good.destination === 'QUALITY' ? 'Controle de qualidade' :
            good.destination === 'STOCK_DIVERGENT' ? 'Estoque divergente' : '---' }}
          </span>
        </app-table-column>

        <app-table-column name="status" (click)="openProductDetailsModal(good, $event, 0)" styleType="old">
          <app-background-icon [icon]="good.palletStatus === 'OK' ? 'check' : good.palletStatus === 'SURPLUS' ? 'plus' : 'minus'"
                               [backgroundColor]="good.palletStatus === 'OK' ? 'success' : good.palletStatus === 'SURPLUS' ? 'in' : 'alert'"
                               content>
          </app-background-icon>
        </app-table-column>

        <app-table-column name="messages" (click)="openProductDetailsModal(good, $event, 4)" styleType="old">
          <span content>
            <i *ngIf="!good.commentsQty" class="uil uil-comment"></i>
            <i *ngIf="good.commentsQty" class="uil uil-comment-lines"></i>
            {{ good.commentsQty }}
          </span>
        </app-table-column>

        <app-table-column name="rfid"
                          (click)="good.palletStatus === 'MISSING' ? openProductDetailsModal(good, $event, 0) : null"
                          styleType="old">
          <div content>
            <span [ngClass]="{'bin-padding': good.palletType === 'BIN', 'hide-element': good.palletStatus === 'MISSING'}">
              {{ checkPalletType(good.palletType) }}
            </span>
            <button mat-raised-button class="primary-2"
                    (click)="openTaskRequest(good)"
                    [ngClass]="{'white': good.palletState === 'AWAITING_STORAGE', 'hide-element': good.palletStatus === 'MISSING'}">

              <img *ngIf="good.palletState === 'AWAITING_STORAGE'" src="assets/icons/forklift-1.svg">
              <img *ngIf="good.palletState === 'RECEIVED'" src="assets/icons/forklift-2.svg">
              {{ getButtonText(good) }}
            </button>
          </div>
        </app-table-column>
      </div>
    </ng-container>

    <div class="loader" *ngIf="state === InspectionPageState.LOADING">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
  </div>
</app-side-color-card>

<app-full-page-loader *ngIf="isLoadingModal"></app-full-page-loader>
