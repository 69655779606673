import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskRequestSidenavService {

  toggleTaskRequest = new Subject();

  promise;
  resolve;

  constructor() { }

  toggle(data) {
    this.toggleTaskRequest.next(data);
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
    });
  }

  closeSidenav(data) {
    this.resolve(data);
  }
}
