import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { IUser } from '../../../models/User';

export interface UserModel {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  type: string;
  tenantId: string;
}

export interface UserByDeviceModel {
  id: string;
  firstName: string;
  lastName: string;
  currentDevice: string;
  taskCount: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserDataSourceService {

  constructor(private httpClient: HttpClient) { }

  async getLoggedUser(): Promise<IUser> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get(environment.baseUrl + '/api/v1/admin-desktop/users/me', { headers }).toPromise<any>();
  }

  async getUsersByDevice(device, isUserOnline?): Promise<UserByDeviceModel> {
    const data = {
      device,
    };

    if(isUserOnline){
      data["online"]= true;
    }

    return this.httpClient.get(environment.baseUrl + '/api/v1/admin-desktop/users/on-device', { params: data }).toPromise<any>();
  }

  async getUserTasks() {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get(environment.baseUrl + '/api/v1/admin-desktop/tasks', { headers }).toPromise<any>();
  }

  async getAll(): Promise<{count: number, users: IUser[]}>{
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get(environment.baseUrl + '/api/v1/admin-desktop/users', { headers }).toPromise<any>();
  }
}

