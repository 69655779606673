<h3>Emitir Fake NFe</h3>

<p class="alert">A nota inserida através desse fluxo não tem nenhum vinculo com o <span>ERP</span> ou a <span>SEFAZ</span>.</p>

<form [formGroup]="fakeNfeForm">
  <mat-form-field appearance="outline">
    <mat-label>Chave</mat-label>
    <input matInput formControlName="key" autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Número</mat-label>
    <input matInput formControlName="number" autocomplete="off" type="number">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Série</mat-label>
    <input matInput formControlName="serial" autocomplete="off">
  </mat-form-field>
</form>

<div class="buttons">
  <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
  <button mat-raised-button class="primary-1" (click)="close('SUBMITTED')">confirmar</button>
</div>
