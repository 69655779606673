<app-side-color-card color="primary-1">
  <div class="holder" content>
    <h3 class="title">NÚMEROS GERAIS</h3>

    <div class="widget">
      <div class="canvas-clipper">
        <app-doughnut-chart
          [datasets]="datasets" 
          [labels]="chartLabels"
          [chartSize]="window.innerWidth <= 1919 ? 150 : 200">
        </app-doughnut-chart>
      </div>

      <div class="text">
        <h5>Ocupação do Armazém</h5>
        <p>{{total}} células totais</p>
        <span>{{now}}</span>
      </div>
    </div>

    <app-common-list [list]="occupationList"></app-common-list>
  </div>



</app-side-color-card>
