<div class="page-header">
  <app-prev-next-button (click)="toStock()" direction="left" label="estoque"></app-prev-next-button>
  <h1>Separação</h1>
  <app-prev-next-button (click)="toDispatch()" direction="right" label="expedição"></app-prev-next-button>
</div>

<div class="ctrl-bar">
  <app-pending-task-widget
    (updated)="update()"
    *ngIf="!pendingTaskHasError"
    (errorPendingTask)="visiblePendingTaskError()"
    [pageTitle]="'Separação'">
  </app-pending-task-widget>
  <app-pending-task-error-widget *ngIf="pendingTaskHasError"></app-pending-task-error-widget>

  <div class="buttons">
    <div class="order">
      <button mat-raised-button (click)="openManualPickingModal()">
        Separação sem ordem
      </button>
    </div>

    <div class="update-button">
      <p>Atualizado às {{ actualHour }}</p>
      <button mat-raised-button (click)="update()">
        <i class="uil uil-sync"></i>
        <span>Atualizar</span>
      </button>
    </div>
  </div>
</div>



<div class="content">

  <app-side-color-card color="picking">
    <app-picking-group
      content
      [title]="'ordens de saída recebidas'"
      [windowSize]="5"
      [documents]="allDocuments?.outOrders"
      [isLoading]="isLoading"
      [itemTemplate]="pickingReceivedOutOrderItemTemplate"
    >
      <ng-template #pickingReceivedOutOrderItemTemplate let-item="item" let-odd="odd">
        <app-picking-received-out-order-item
          [item]="item"
          [isOdd]="odd"
          [disabled]="disableProcessButton"
          (processClick)="runExecutionPlan(item)"
          (itemClick)="openReceivedOutOrderDetailsModal(item)"
        ></app-picking-received-out-order-item>
      </ng-template>

      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-picking-group-sell-order-header header></app-picking-group-sell-order-header>

    </app-picking-group>
  </app-side-color-card>

  <app-side-color-card color="picking">
    <app-picking-group
      content
      [title]="'ordens de saída com plano de execução'"
      [windowSize]="5"
      [documents]="allDocuments?.outOrdersWithExecutionPlan"
      [isLoading]="isLoading"
      [itemTemplate]="packingPlannedOutOrderItemTemplate"
    >
      <ng-template #packingPlannedOutOrderItemTemplate let-item="item" let-odd="odd">
        <app-picking-group-sell-order-with-pallet-item
          [item]="item"
          [isOdd]="odd"
          (openDrawer)="openSidenav($event)"
          (sendClicked)="sendPackingTask(item)"
          (removeClick)="removeExecutionOrderPlan(item)"
          (itemClick)="openPlannedOutOrderDetailsModal(item)"
        >
        </app-picking-group-sell-order-with-pallet-item>
      </ng-template>
      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-picking-group-sell-order-with-pallet-header header></app-picking-group-sell-order-with-pallet-header>
    </app-picking-group>
  </app-side-color-card>

  <app-side-color-card color="picking">
    <app-picking-group
      content
      [title]="'ordens em separação'"
      [windowSize]="5"
      [documents]="allDocuments?.outOrdersInSeparation"
      [isLoading]="isLoading"
      [itemTemplate]="packingOutOrderWithTasks"
      >

      <ng-template #packingOutOrderWithTasks let-item="item" let-odd="odd">
                <app-picking-group-in-separation-item
                                                      [item]="item"
                                                      [isOdd]="odd"
                                                      (openNfeConfirmModalEvent)="openConfirmNfeModal($event)"
                                                      (openFakeNfeModalEvent)="openFakeNfeModal($event)"
                                                      (itemClick)="openInSeparationOutOrderDetailsModal(item)">
                </app-picking-group-in-separation-item>
      </ng-template>

      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-picking-group-in-separation-header header></app-picking-group-in-separation-header>
    </app-picking-group>
  </app-side-color-card>

  <app-side-color-card color="picking">
    <app-picking-group
      content
      [title]="'ordens separadas em expedição'"
      [windowSize]="5"
      [documents]="allDocuments?.outOrdersInExpedition"
      [isLoading]="isLoading"
      [itemTemplate]="packingOutOrderInExpedition"
      (openConfirmModalEvent)="openConfirmNfeModal($event)"
      (openFakeNfeModalEvent)="openFakeNfeModal($event)">

      <ng-template #packingOutOrderInExpedition let-item="item" let-odd="odd">
        <app-picking-group-in-execution-item
                                              [item]="item"
                                              [isOdd]="odd"
                                              (openNfeConfirmModalEvent)="openConfirmNfeModal($event)"
                                              (openFakeNfeModalEvent)="openFakeNfeModal($event)"
                                              (goToDetails)="openExpeditionOutOrderDetailsModal(item)">
        </app-picking-group-in-execution-item>
      </ng-template>

      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-picking-group-in-execution-header header></app-picking-group-in-execution-header>
    </app-picking-group>
  </app-side-color-card>
</div>

<app-full-page-loader *ngIf="isModalLoading"></app-full-page-loader>
