<div class="body">
  <div class="left-content">
    <button mat-button (click)="toggleSidebar()">
      <i class="uil uil-subject" style="font-size: 35px; color: #FFFFFF"></i>
    </button>
    <img alt="" [src]="imageURL" (click)="toDashboard()">
  </div>
  <div class="right-content">
<!--    <img *ngIf="user" src="" alt="">-->
    <div class="vertical-line"></div>
    <div class="background">
      <i class="uil uil-user"></i>
    </div>
    <div class="user">
      <p>{{ user?.firstName + ' ' + user?.lastName }}</p>
      <small>{{ user?.type | titlecase }}</small>
    </div>
    <button mat-button (click)="logout()">
      <i class="uil uil-signout" style="font-size: 30px; color: #FFFFFF"></i>
    </button>
  </div>
</div>
<app-sidebar-menu *ngIf="sidebarOpened" (toggleSideBarEvent)="toggleSidebar()" [user]="user"></app-sidebar-menu>
