<div class="entry columns-group" [ngClass]="{'divergent': nfe.status === 'DIVERGENT' || nfe.status === 'RECEIVEMENT_BLOCKED'}" (click)="handleGotoOther($event)">
  <div class="oc">
    <p>{{ nfe.orders.length <= 1 ? nfe.orders[0]?.number || '----' : nfe.orders[nfe.orders.length - 1]?.number + ' (' + nfe.orders.length + ')' }}</p>
  </div>
  <div class="in-timestamp">
    {{ orderDate || '----' }}
  </div>
</div>
<div class="nfe-entry columns-group" [ngClass]="{'divergent': nfe.status === 'DIVERGENT' || nfe.status === 'RECEIVEMENT_BLOCKED'}" (click)="handleGotoOther($event)">
  <div class="nfe">
    {{ nfe.number }}-{{ nfe.serial }}
  </div>
  <div class="nfe-timestamp">
    {{ nfeDate }}
  </div>
</div>
<div class="document column" (click)="handleGotoOther($event)">
  {{ nfe.vendorDoc }}
</div>
<div class="name column" (click)="handleGotoOther($event)">
  {{ nfe.vendorName }}
</div>
<div class="status column" (click)="handleGotoOther($event)">
  <div  *ngIf="nfe.status === 'OK'" class="background success-color">
    <i class="uil uil-check"></i>
  </div>
  <div *ngIf="nfe.status === 'DIVERGENT'" class="background alert-color">
    <i class="uil uil-times"></i>
  </div>
  <div *ngIf="nfe.status === 'RECEIVEMENT_BLOCKED'" class="background alert-color">
    <i class="uil uil-padlock"></i>
  </div>
  <img *ngIf="nfe.status === 'AWAITING_COMPARISON'" src="assets/icons/spinner-check.svg">
  <img *ngIf="nfe.status === 'AWAITING_RETIFICATION'" src="assets/icons/spinner-edit.svg">
</div>
<div class="messages column" (click)="handleComment($event)">
  <i *ngIf="!nfe.commentsQty" class="uil uil-comment"></i>
  <i *ngIf="nfe.commentsQty" class="uil uil-comment-lines"></i>
  <span>{{ nfe.commentsQty }}</span>
</div>

