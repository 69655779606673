<app-header-modal [color]="'secondary-2'" title="Dados do Relatório">
  <div class="status-header" status>
    <app-status-header-modal
      [icon]="report.state === 'APPROVED' ? 'check' : report.state === 'REJECTED' ? 'times' : 'spinner-alt'"
      [status]="translateState(report.state)"
      [color]="report.state === 'APPROVED' ? 'success' : report.state === 'REJECTED' ? 'alert' : 'warning'">
    </app-status-header-modal>
    <i class="uil uil-times" (click)="close('CANCELED')"></i>
  </div>
</app-header-modal>

<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Relatório">
    <div class="infos">
      <app-info-oc-or-nfe label="Data de emissão" [value]="formatDate(report.createdAt) || '---'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Responsável" [value]="report.admin?.firstName + ' ' + report.admin?.lastName || '---'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Tipo de inventário" [value]="translateType(report.data.type) || '---'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe *ngIf="reportData.type === inventoryTypesEnum.INVENTORY_BY_PRODUCT"
        label="Nome do produto" [value]="productName || '---'">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe *ngIf="reportData.type === inventoryTypesEnum.INVENTORY_BY_GROUND_POSITION_GROUP"
        label="Setor" [value]="formattedGroundPositionName || '---'">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe *ngIf="reportData.type === inventoryTypesEnum.INVENTORY_BY_PRODUCT"
        label="SKU" [value]="reportData?.target?.skus?.[0] || '---'">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Total de itens" [value]="totalItems"></app-info-oc-or-nfe>
    </div>

    <ng-container>
      <div class="table pallet-inventory" *ngIf="reportData?.crate">
        <app-pallet-details-modal [reportData]="reportData"></app-pallet-details-modal>
        <app-pallet-reports-table [items]="items"></app-pallet-reports-table>
      </div>

      <div class="table others-inventory" *ngIf="!reportData?.crate">
        <app-reports-table-modal [items]="items"></app-reports-table-modal>
      </div>

      <div class="buttons">
        <button mat-stroked-button class="gray-2" (click)="close('CANCELED')">cancelar</button>
        <button mat-raised-button
                class="alert"
                (click)="close('REJECTED')"
                [disabled]="report.state !== 'AWAITING_ADMIN_APPROVAL'">rejeitar</button>
        <button mat-raised-button
                class="primary-1"
                (click)="close('APPROVED')"
                [disabled]="report.state !== 'AWAITING_ADMIN_APPROVAL'">confirmar</button>
      </div>
    </ng-container>
  </mat-tab>
</mat-tab-group>
