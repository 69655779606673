    <app-table-header-column name="date" size="small">
      <span content>
        Entrada
        <button mat-icon-button [matMenuTriggerFor]="createdAtFilterMenu" class="filter-icon"
          [matTooltip]="'Filtros'"
          [matBadge]="appliedFilters['createdAt']"
          [matBadgeHidden]="!appliedFilters"
          matBadgeColor="accent">
          <img src="/assets/icons/filter-icon.svg">
        </button>
      </span>
    </app-table-header-column>

    <mat-menu #createdAtFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por data de entrada</h4>
        <button (click)="sort('createdAt', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('createdAt', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'asc'"></i>
        </button>
        <form [formGroup]="dateFiltersForm">
          <mat-form-field appearance="outline">
            <mat-label>Data inicial</mat-label>
            <input matInput [matDatepicker]="startDatePicker" formControlName="start" [max]="maxDate" disabled>
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Data final</mat-label>
            <input matInput [matDatepicker]="endDatePicker" formControlName="end" [max]="maxDate" disabled>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <section class="actions">
            <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
            <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
          </section>
        </form>
      </div>
    </mat-menu>

    <app-table-header-column name="date" size="small">
      <span content>
        Últ movimentação
        <button mat-icon-button [matMenuTriggerFor]="lastMovedAtFilterMenu" class="filter-icon"
          [matTooltip]="'Filtros'"
          [matBadge]="appliedFilters['lastMovedAt']"
          [matBadgeHidden]="!appliedFilters"
          matBadgeColor="accent">
          <img src="/assets/icons/filter-icon.svg">
        </button>
      </span>
    </app-table-header-column>

    <mat-menu #lastMovedAtFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Ordenar</h4>
        <button (click)="sort('lastMovedAt', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('lastMovedAt', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'asc'"></i>
        </button>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
          <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="sector" size="small">
      <span content>
        Setor
        <button mat-icon-button [matMenuTriggerFor]="sectionFilterMenu" class="filter-icon"
          [matTooltip]="'Filtros'"
          [matBadge]="appliedFilters['section']"
          [matBadgeHidden]="!appliedFilters"
          matBadgeColor="accent">
          <img src="/assets/icons/filter-icon.svg">
        </button>
      </span>
    </app-table-header-column>

    <mat-menu #sectionFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por setor</h4>
        <button (click)="sort('section', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['section'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('section', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['section'] && sortDirection == 'asc'"></i>
        </button>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Setor"
                   [formControl]="sectionFormControl"
                   autocomplete="off">
          </mat-form-field>
          <section class="actions">
            <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
            <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
          </section>
      </div>
    </mat-menu>

    <app-table-header-column name="address" size="small">
      <span content>
        Coordenada
        <button mat-icon-button [matMenuTriggerFor]="addressFilterMenu" class="filter-icon"
          [matTooltip]="'Filtros'"
          [matBadge]="appliedFilters['address']"
          [matBadgeHidden]="!appliedFilters"
          matBadgeColor="accent">
          <img src="/assets/icons/filter-icon.svg">
        </button>
      </span>
    </app-table-header-column>

    <mat-menu #addressFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por coordenada</h4>
        <button (click)="sort('position', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['position'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('position', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['position'] && sortDirection == 'asc'"></i>
        </button>
        <mat-form-field appearance="outline">
          <input matInput
                 placeholder="Coordenada"
                 [formControl]="addressFormControl"
                 autocomplete="off">
          <mat-error>Coordenada inválida</mat-error>
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
          <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="alias" size="small">
      <span content>
        Posição
        <button mat-icon-button [matMenuTriggerFor]="aliasFilterMenu" class="filter-icon"
          [matTooltip]="'Filtros'"
          [matBadge]="appliedFilters['alias']"
          [matBadgeHidden]="!appliedFilters"
          matBadgeColor="accent">
          <img src="/assets/icons/filter-icon.svg">
        </button>
      </span>
    </app-table-header-column>

    <mat-menu #aliasFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por posição</h4>
        <button (click)="sort('alias', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['alias'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('alias', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['alias'] && sortDirection == 'asc'"></i>
        </button>
        <mat-form-field appearance="outline">
          <input matInput
                 placeholder="Posição"
                 [formControl]="aliasFormControl"
                 autocomplete="off">
          <mat-error>Posição inválida</mat-error>
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
          <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="level" size="smallest">
      <span content>
        Nível
        <button mat-icon-button [matMenuTriggerFor]="levelNameFilterMenu" class="filter-icon"
          [matTooltip]="'Filtros'"
          [matBadge]="appliedFilters['levelName']"
          [matBadgeHidden]="!appliedFilters"
          matBadgeColor="accent">
          <img src="/assets/icons/filter-icon.svg">
        </button>
      </span>
    </app-table-header-column>

    <mat-menu #levelNameFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por nível</h4>
        <button (click)="sort('levelName', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['levelName'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('levelName', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['levelName'] && sortDirection == 'asc'"></i>
        </button>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Nível"
                   [formControl]="levelNameFormControl"
                   autocomplete="off">
          </mat-form-field>
          <section class="actions">
            <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
            <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
          </section>
      </div>
    </mat-menu>

    <app-table-header-column name="height" size="smallest">
      <span content>
        Altura
        <button mat-icon-button [matMenuTriggerFor]="heightFilterMenu" class="filter-icon"
          [matTooltip]="'Filtros'"
          [matBadge]="appliedFilters['z']"
          [matBadgeHidden]="!appliedFilters"
          matBadgeColor="accent">
          <img src="/assets/icons/filter-icon.svg">
        </button>
      </span>
    </app-table-header-column>

    <mat-menu #heightFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Ordenar</h4>
        <button (click)="sort('z', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['z'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('z', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['z'] && sortDirection == 'asc'"></i>
        </button>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
          <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="product-code" size="small">
      <span content>
        Código de produto
          <button mat-icon-button [matMenuTriggerFor]="productSkuFilterMenu" class="filter-icon"
            [matTooltip]="'Filtros'"
            [matBadge]="appliedFilters['sku']"
            [matBadgeHidden]="!appliedFilters"
            matBadgeColor="accent">
            <img src="/assets/icons/filter-icon.svg">
          </button>
      </span>

    </app-table-header-column>

    <mat-menu #productSkuFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por código de produto</h4>
        <button (click)="sort('sku', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('sku', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'asc'"></i>
        </button>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Código do produto"
                   [formControl]="productSkuFormControl"
                   autocomplete="off">
          </mat-form-field>
          <section class="actions">
            <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
            <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
          </section>
      </div>
    </mat-menu>

    <app-table-header-column name="description" size="medium">
      <span content>Descrição</span>
    </app-table-header-column>

    <app-table-header-column name="quantity" size="smallest">
      <span content>
        Qtd
        <button mat-icon-button [matMenuTriggerFor]="quantityFilterMenu" class="filter-icon"
          [matTooltip]="'Filtros'"
          [matBadge]="appliedFilters['quantity']"
          [matBadgeHidden]="!appliedFilters"
          matBadgeColor="accent">
          <img src="/assets/icons/filter-icon.svg">
        </button>
      </span>
    </app-table-header-column>

    <mat-menu #quantityFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por quantidade</h4>
        <button (click)="sort('quantity', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('quantity', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'asc'"></i>
        </button>
        <section class="quantity">
          <p>QUANTIDADE</p>
          <div class="counter">
            <div class="operator-button" (click)="changeQuantity('MINUS')">
              <i class="uil uil-minus"></i>
            </div>
            <span>{{ quantityCount }}</span>
            <div class="operator-button" (click)="changeQuantity('PLUS')">
              <i class="uil uil-plus"></i>
            </div>
          </div>
        </section>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
          <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="state" size="small">
      <span content>
        Estado
        <button mat-icon-button [matMenuTriggerFor]="stateFilterMenu" class="filter-icon"
          [matTooltip]="'Filtros'"
          [matBadge]="appliedFilters['state']"
          [matBadgeHidden]="!appliedFilters"
          matBadgeColor="accent">
          <img src="/assets/icons/filter-icon.svg">
        </button>
      </span>
    </app-table-header-column>

    <mat-menu #stateFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por estado</h4>
        <button (click)="sort('state', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['state'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('state', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['state'] && sortDirection == 'asc'"></i>
        </button>
        <mat-form-field class="dropdown" appearance="outline">
          <mat-label>Estado</mat-label>
          <mat-select [(value)]="stateValue">
            <mat-option *ngFor="let option of stateOptions" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
          <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
        </section>
      </div>
    </mat-menu>
