import {Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-debug-page',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent implements OnInit {

  file: any;

  originalEvents = [];
  events = [];

  currentEvents = [];
  selectedEvents = [];

  eventTypes = [];
  selectedEventType = 'ALL';

  tags = [];
  selectedTag = 'ALL';

  selectedRange = 0;

  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  range($event){
    this.selectedRange = $event;
    this.filter();
  }

  filter(){
    let filtered = [...this.events];
    if (this.selectedTag !== 'ALL'){
      filtered = filtered.filter(e => e.tagRfid === this.selectedTag);
    }
    if (this.selectedEventType !== 'ALL'){
      filtered = filtered.filter(e => e.event === this.selectedEventType);
    }
    this.currentEvents = [...filtered];

    filtered = filtered.slice(0, this.selectedRange);
    this.selectedEvents = [...filtered];
  }

  changeEvent($event){
    this.selectedEventType = $event;
    this.filter();
  }
  changeTag($event){
    this.selectedTag = $event;
    this.filter();
  }

  ngOnInit(): void {
  }

  fileChanged(e) {
    this.file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const buffer = fileReader.result.toString();
      const lines = buffer.split('\n');
      for (const l of lines){
        const event = this.parseLedger(l);
        if (event){
          this.originalEvents.push(event);
        }
      }

      this.originalEvents = this.originalEvents.sort((a, b) => a.timestamp - b.timestamp);
      let i = 0;
      while(i<this.originalEvents.length){
        const current = this.originalEvents[i];
        const start = current.timestamp;
        let end = current.timestamp;
        let j = i+1;
        let next = this.originalEvents[j];
        while(j < this.originalEvents.length && next.x === current.x && next.y === current.y && next.event === current.event){
            end = next.timestamp;
            j = j+1;
            next = this.originalEvents[j];
        }
        i = j;
        this.events.push({
          x:current.x,
          y:current.y,
          start, end, event: current.event
        });
      }

      this.selectedEvents = [...this.events];
      this.currentEvents = [...this.events];
      this.tags = this.selectedEvents.map(t => t.tagRfid).filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      this.eventTypes = this.selectedEvents.map(t => t.event).filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      this.selectedRange = this.selectedEvents.length;
    };
    fileReader.readAsText(this.file);
  }

  parseLedger(l){
    if (l.includes('LEDGER:::')){
      const str = l.split(('LEDGER:::'))[1];
      // const tenant = str.trim();
      const items = str.split(' ');
      console.log(items);
      const event = items[1];
      const timestamp = items[2];
      const date = items[3].match(/,\s*([^,]+)$/)[1];
      let raw = items[3].replace('",' + date, '');
      console.log(raw);
      raw = raw.replaceAll('""', '"');
      const data = JSON.parse(raw);

      return {
        event, timestamp, date, ...data
      };
    }
  }
}
