import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActionService, TenantState } from '../../../core/services/action.service';
import { MatDialog } from '@angular/material/dialog';
import { IUser, UserRoles } from '../../../../models/User';
import { UserDataSourceService, UserModel } from '../../../core/data-sources/user-data-source.service';
import { ErrorService } from '../../../core/services/error.service';
import { AddOrEditUserModalComponent } from '../../../modals/add-or-edit-user-modal/add-or-edit-user-modal.component';
import { UserDetailsModalComponent } from '../../../modals/user-details-modal/user-details-modal.component';
import { first } from 'rxjs/operators';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { ConfirmModalComponent } from '../../../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-setup-admins',
  templateUrl: './setup-admins.component.html',
  styleUrls: ['./setup-admins.component.scss']
})
export class SetupAdminsComponent implements OnInit {

  admins: IUser[] = [];

  isLoading = false;
  fullPageIsLoading = false;

  @ViewChild('notification', { static: true }) notificationTmpl;
  notificationData: {type: string, title: string, message: string};

  constructor(private router: Router,
              private actionService: ActionService,
              private dialog: MatDialog,
              private userDataSource: UserDataSourceService,
              private errorService: ErrorService,
              private snackbar: SnackBarService) { }

  ngOnInit(): void {
    this.getAdmins();
  }

  async getAdmins(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.userDataSource.getAll();
      this.admins = response.users.filter(user => user.type === UserRoles.ADMIN);
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.isLoading = false;
    }
  }

  openCreateUserModal(): void {
    const dialogRef = this.dialog.open(AddOrEditUserModalComponent, {
      data: { definedRole: UserRoles.ADMIN },
      panelClass: 'details-modal',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async (user) => {
      if (user) {
        await this.getAdmins();
      }
    });
  }

  openDetailsModal(user: UserModel): void {
    const dialogRef = this.dialog.open(UserDetailsModalComponent, {
      panelClass: 'details-modal',
      autoFocus: false,
      data: { user, disableEditAdmins: false }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(async (res) => {
      if (res) {
        try {
          this.fullPageIsLoading = true;
          this.notificationData = {
            type: 'success',
            title: 'USUÁRIO EDITADO COM SUCESSO!',
            message: `O usuário <span>${user.username}</span> foi editado`
          };
          await this.getAdmins();
          this.snackbar.showFeedback(
            this.notificationTmpl,
            this.notificationData,
            '1'
          );
          // dismiss notification after 5 sec
          setTimeout(() => {
            this.snackbar.hideNotification('1');
          }, 5000);
        } catch (e) {
          this.errorService.openErrorSnackBar(e);
        } finally {
          this.fullPageIsLoading = false;
        }
      }
    });
  }

  deleteAdmin(event: MouseEvent, admin: UserModel): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: { label: `Tem certeza que deseja excluir o usuário ${admin.firstName}?` }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(async (response) => {
      if (response) {
        try {
          this.fullPageIsLoading = true;
          const actionData = {
            id: admin.id
          };
          await this.actionService.deleteUser(actionData);
          await this.getAdmins();
        } catch (e) {
          this.errorService.getErrorMessage(e);
        } finally {
          this.fullPageIsLoading = false;
        }
      }
    });
  }

  async toSetup(): Promise<void> {
    await this.router.navigate(['/setup']);
  }

  async finish(): Promise<void> {
    await this.actionService.setTenantState({state: TenantState.SETUP_TAGS});
    await this.router.navigate(['/setup-tags']);
  }
}
