<app-header-modal [color]="'secondary-2'" [title]="'Produto ' + product.name">
  <div class="status-header" status>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>

<div class="container">
  <div class="upper-content">
    <div class="first-column">
      <app-info-oc-or-nfe label="Nome" [value]="product.name"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="SKU" [value]="product.sku"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Descrição" [value]="product.description || '-'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Peso" [value]="product.weight ? product.weight + ' Kg' : '-' "></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Comprimento" [value]="product.width ? product.width + ' m' : '-' "></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Altura" [value]="product.height ? product.height + ' m' : '-' "></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Profundidade" [value]="product.depth ? product.depth + ' m' : '-' "></app-info-oc-or-nfe>
    </div>

    <div class="second-column">
      <app-info-oc-or-nfe label="Criado em" [value]="moment(product.createdAt).format('DD/MM/YYYY HH:mm')"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Atualizado em" [value]="moment(product.updatedAt).format('DD/MM/YYYY HH:mm')"></app-info-oc-or-nfe>
      <img *ngIf="product.imageUrl" [src]="product.imageUrl">
    </div>

    <div class="third-column">
        <button mat-raised-button class="primary-1 edit-product" (click)="openEditProductModal()">Editar Produto</button>
    </div>
  </div>
</div>
