import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {drawColumns, drawGroundPosition, drawRows, getHeight, getWidth} from './ground-cell';
import {MapViewComponent} from '../../../shared/map-view/map-view.component';
import {LayoutService} from '../../../core/data-sources/layout.service';
import {MatDialog} from '@angular/material/dialog';
import {ICrateMap} from '../../../../models/Crate';
import { MapPositionDetailsModalComponent } from 'src/app/modals/map-position-details-modal/map-position-details-modal.component';
import { TaskRequestSidenavService } from 'src/app/core/services/task-request-sidenav.service';

export interface GroundPosition {
  rfId: string;
  x: number;
  y: number;
  line: string;
  column: string;
  type: string;
  groupName: string;
  hasPallet: boolean;
  state: string;
  alias?: string
}

@Component({
  selector: 'app-warehouse-map-task-position-view',
  templateUrl: './warehouse-map-task-position-view.component.html',
  styleUrls: ['./warehouse-map-task-position-view.component.scss']
})
export class WarehouseMapTaskPositionViewComponent extends MapViewComponent implements OnInit, OnChanges {

  public layout;
  public productsBy = new Map();

  @Input()
  public devicePixelRatio = window.devicePixelRatio || 1;

  @Input()
  public events;

  @Input() map;

  public mouseX;
  public mouseY;
  public showTooltip;
  public tooltipValues = [];
  public isMapEmpty = false;
  public timeout;
  @Output() mapLoaded = new EventEmitter();

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @Output() toggleTipEvent = new EventEmitter<boolean>(false);
  @Input() openTip = false;
  toggleTip = false;

  @HostListener('mousemove', ['$event']) onMouseMove(event) {
    const canvas = (this.elementRef.nativeElement as HTMLElement).querySelector('canvas');
    const canvasTop = canvas.getBoundingClientRect().top;
    const canvasLeft = canvas.getBoundingClientRect().left;
    this.mouseX = event.clientX - canvasLeft; // x position within the element.
    this.mouseY = event.clientY - canvasTop;
  }

  constructor(
    protected elementRef: ElementRef,
    protected ngZone: NgZone,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private sidenavService: TaskRequestSidenavService
  ) {
    super(elementRef, ngZone);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.map?.currentValue) {
      if (!changes.map.currentValue?.length) {
        this.isMapEmpty = true;
        this.mapLoaded.emit();
        return;
      }

      const gradePositions = this.getMaxCoordinates(changes.map.currentValue);
      const maxWidth = getWidth(gradePositions.positionsX.length);
      const maxHeight = getHeight(gradePositions.positionsY.length);
      this.initMap(maxWidth, maxHeight);
      this.drawMap(changes.map.currentValue);
      setTimeout(() => {
        this.mapLoaded.emit();
      }, 300); // 300ms é o tempo médio que termina a renderização do mapa na tela depois do load acabar.
      // TODO: tentar achar um jeito melhor de previnir que o canva pisque preto depois do load acabar.
    }
    if (changes.openTip?.currentValue) {
      this.toggleTip = changes.openTip.currentValue;
    }
    // this.drawMap(this.layout);
  }

  ngOnInit(): void {
    this.layoutService.getMapCrates().then(res => {
      this.layoutService.cratesCount$.next(res.length);
      this.processProducts(res);
    });
  }

  public processProducts(crates: ICrateMap[]) {
    for (const c of crates) {
      // this index can be a linear 2 dimension matrix index...
      const idx = c.x + '_' + c.y;
      if (!this.productsBy.has(idx)) {
        this.productsBy.set(idx, []);
      }
      const position = {
        sku: c.skuGroups[0]?.sku,
        name: c.skuGroups[0]?.name,
        z: c?.z,
        levelName : c?.levelName
      }
      this.productsBy.get(idx).push(position);
    }
  }

  public drawMap(groundPositions: GroundPosition[]) {
    if (groundPositions) {
      this.drawGrades(groundPositions);
      this.drawDefaultMap(groundPositions);
      this.scrollBox.update();
    }
  }

  drawGrades(groundPositions: GroundPosition[]) {
    const gradePositions = this.getMaxCoordinates(groundPositions);
    const columns = drawColumns(gradePositions.positionsX);
    for (const c of columns) {
      c.interactive = true;
      c.cursor = 'pointer';
      c.on('click', () => {
        this.openLevelsModal(null, 'column', c.text);
      });
      // @ts-ignore
      this.viewPort.addChild(c);
    }
    const rows = drawRows(gradePositions.positionsY);
    for (const r of rows) {
      r.interactive = true;
      r.cursor = 'pointer';
      r.on('click', () => {
        this.openLevelsModal(null, 'row', r.text);
      });
      // @ts-ignore
      this.viewPort.addChild(r);
    }
  }

  getToolTipValues(x, y){
    const idx = x + '_' + y;
    const skus = this.productsBy.get(idx);
    if (skus != undefined){
      this.tooltipValues = skus;
    } else{
      this.tooltipValues = [{error: 'Não existem produtos aqui.'}];
    }
  }

  openLevelsModal(rfId: string, type: 'row' | 'column' | 'position', position?: string, alias?: string) {
    this.dialog.open(MapPositionDetailsModalComponent, {
      panelClass: 'details-modal',
      data: { rfId, type, position, alias }
    }).afterClosed().subscribe(res => {
      if (res?.openRelocateSidenav) {
        const crate = res.crate;
        const productName = crate.skuGroups[0]?.name ?? 'nome não disponível';
        const data = {
          type: 'STOCK_RELOCATE_SINGLE_PALLET',
          rfId: crate.tagRfid,
          product: productName
        };
        this.sidenavService.toggle(data);
      } else if (res?.openInventorySidenav) {
        const data = {
          type: "STOCK_INVENTORY",
          data: {
            isPicking: false,
            position: res.position
          }
        };
        this.sidenavService.toggle(data);
      }
    });
  }

  drawDefaultMap(groundPositions: GroundPosition[]) {
    for (const groundPosition of groundPositions) {
      const elements = drawGroundPosition(groundPosition);
      const area = elements[0];
      area.interactive = true;
      area.cursor = 'pointer';

      area.on('pointerover', () => {
        if (groundPosition.hasPallet){
          this.getToolTipValues(groundPosition.x, groundPosition.y);
        } else{
          // no pallet...
          this.tooltipValues = [{error: 'Não há pallets aqui.'}];
        }
        if (this.tooltipValues && this.tooltipValues.length > 0){
          this.showTooltip = true;
          if (this.timeout){
            clearTimeout(this.timeout);
          }
        }
      });
      area.on('click', () => {
        this.openLevelsModal(groundPosition.rfId, 'position', null, groundPosition.alias);
      });
      area.on('pointerout', () => {
        this.timeout = setTimeout(() => {
          this.showTooltip = false;
          this.tooltipValues = [];
          this.timeout = null;
        }, 50);
      });



      for (const e of elements) {
        // @ts-ignore
        this.viewPort.addChild(e);
      }
    }
  }

  onToggleTip() {
    this.toggleTip = !this.toggleTip;
    this.toggleTipEvent.emit(this.toggleTip);
    this.openTip = false;
  }

  zoom(type: 'IN' | 'OUT') {
    if (this.viewPort.scaled > 2 && type === 'IN') {
      return;
    }
    this.viewPort.zoom(type === 'IN' ? -250 : 250);
    this.scrollBox.update();
  }

  getMaxCoordinates(groundPositions) {
    const positionsX = [];
    const positionsY = [];
    let x = 0;
    let y = 0;
    for (const gp of groundPositions) {
      if (gp.x > x) { x = gp.x; }
      if (gp.y > y) { y = gp.y; }
    }
    let countX = 0;
    while (countX <= x) {
      positionsX.push(countX);
      countX++;
    }
    let countY = 0;
    while (countY <= y) {
      positionsY.push(countY);
      countY++;
    }

    return {positionsX, positionsY};
  }
}
