<div class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Solicitação de tarefa" (closeEvent)="close()"></app-sidenav-header>

    <form [formGroup]="relocateForm">
      <div class="card-header">Configurar nova tarefa de realocação</div>
      <div class="card-body">
        <div class="product-input">
          <p>Digite o que deseja realocar:</p>
          <mat-form-field>
            <input matInput
                   placeholder="SKU ou a descrição do produto"
                   formControlName="productSku"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayedString">
              <mat-option *ngFor="let product of productOptions" [value]="product">{{ product.name + ' - ' + product.sku }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="destination">
          <p>Selecione o destino do produto:</p>
          <div class="dropdowns">
            <mat-form-field class="sector">
              <mat-label>Setor</mat-label>
              <input type="text"
                     placeholder="Setor"
                     aria-label="Number"
                     matInput
                     formControlName="groundGroup"
                     [matAutocomplete]="groundGroupAuto">
              <mat-autocomplete
                autoActiveFirstOption #groundGroupAuto="matAutocomplete" [displayWith]="displayedGGString">
                <mat-option [value]="groundGroup"
                            *ngFor="let groundGroup of groundGroups"
                            (onSelectionChange)="onChangeSector($event)">{{ groundGroup.name }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="address">
              <mat-label>Endereço</mat-label>
              <input type="text"
                     placeholder="Endereço"
                     matInput
                     formControlName="position"
                     [matAutocomplete]="addressAuto">
              <mat-autocomplete
                autoActiveFirstOption #addressAuto="matAutocomplete" [displayWith]="displayPositionString">
                <mat-option
                  [value]="groundPosition"
                  *ngFor="let groundPosition of filteredGroundPosition"
                  [disabled]="groundPosition.disabled"
                  [matTooltip]="groundPositionOptionTooltip(groundPosition.disabled)">
                  {{ groundPosition.formatted }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <mat-form-field class="pallets">
          <mat-label>Selecione o pallet desejado</mat-label>
          <mat-select formControlName="pallet">
            <mat-option *ngFor="let pallet of palletsOptions"
                        [value]="pallet">
              {{ 'Posição: ' + handlePosition(pallet.x, pallet.y) + ', quantidade: ' + handleQuantity(pallet.skuGroups)}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="min-max">
          <p>Digite o mínimo e o máximo do produto: <span class="uil uil-question-circle" [matTooltip]="minMaxTooltip"></span></p>
          <div class="inputs">
            <mat-form-field class="min">
              <mat-label>Mínimo de produtos</mat-label>
              <input matInput formControlName="min" placeholder="Mínimo de produtos" type="number" [min]="0">
            </mat-form-field>
            <mat-form-field class="max">
              <mat-label>Máximo de produtos</mat-label>
              <input matInput formControlName="max" placeholder="Máximo de produtos" type="number" [min]="relocateForm.controls.min.value || 0">
            </mat-form-field>
          </div>
        </div>

<!--        <mat-tab-group>-->
<!--          <mat-tab label="Pallets específicos">-->
<!--            <mat-form-field class="pallets">-->
<!--              <mat-label>Selecione os pallets desejados</mat-label>-->
<!--              <mat-select multiple formControlName="pallets">-->
<!--                <mat-option *ngFor="let pallet of palletsOptions"-->
<!--                            [value]="pallet">-->
<!--                  {{ 'Posição: ' + handlePosition(pallet.x, pallet.y) + ', quantidade: ' + handleQuantity(pallet.skuGroups)}}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </mat-tab>-->
<!--          <mat-tab label="Quantidades">-->
<!--            <input placeholder="Digite a quantidade desejada" formControlName="quantity">-->
<!--          </mat-tab>-->
<!--        </mat-tab-group>-->
      </div>
    </form>

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>
    <p class="error" *ngIf="noPickingSectorError">Não há setores do tipo SEPARAÇÃO cadastrados no sistema. Vá à tela de setup e cadastre!</p>
    <p class="error" *ngIf="noPalletsError">Não há pallets disponíveis com este produto.</p>

    <app-operators-table device="FORKLIFT"
                         [usersHighlighted]="usersHighlighted"
                         (usersHighlightedEvent)="setUsers($event)"
                         [openedStart]="drawer.openedStart"
                         [isUserOnline]="true"></app-operators-table>
  </div>

  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()">OK</button>
  </div>
</div>
