import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserDataSourceService, UserModel } from '../../core/data-sources/user-data-source.service';
import { UserRoles } from '../../../models/User';
import { ErrorsEnum, ErrorService } from '../../core/services/error.service';
import { ActionService } from '../../core/services/action.service';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-add-or-edit-user-modal',
  templateUrl: './add-or-edit-user-modal.component.html',
  styleUrls: ['./add-or-edit-user-modal.component.scss']
})
export class AddOrEditUserModalComponent implements OnInit {

  form: FormGroup;
  roleOptions: { value: UserRoles, label: string }[];

  modalType: 'add' | 'edit';
  btnLabel: string;
  title: string;
  passwordErrorText = 'Mínimo de 6 caractéres';
  hide = true;

  isLoading: boolean;

  constructor(public dialogRef: MatDialogRef<AddOrEditUserModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { definedRole: UserRoles, user: UserModel, disabledRole: UserRoles },
              private formBuilder: FormBuilder,
              private userDataSource: UserDataSourceService,
              private userService: UserService,
              private actionService: ActionService,
              private errorService: ErrorService) {
    this.isLoading = false;
    this.roleOptions = [
      { value: UserRoles.ADMIN, label: 'Administrador' },
      { value: UserRoles.MANAGER, label: 'Gestor' },
      { value: UserRoles.OPERATOR, label: 'Operador' }
    ];
  }

  ngOnInit(): void {
    this.buildForm();
    this.modalType = 'add';
    this.btnLabel = 'Criar novo usuário';
    this.title = 'Cadastrar novo usuário';
    if (this.data.user) {
      this.modalType = 'edit';
      this.btnLabel = 'Editar usuário';
      this.title = 'Editar usuário';
      this.form.controls.username.disable();
      this.form.controls.password.disable();
      if (this.data.user.type === UserRoles.MASTER) {
        this.form.controls.type.disable();
      }
      this.setFormValues();
    }

    if (this.data?.definedRole) {
      this.form.controls.type.setValue(this.data.definedRole);
      this.form.controls.type.disable();
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      type: [null, Validators.required]
    });
  }

  setFormValues(): void {
    const formControls = this.form.controls;
    formControls.firstName.setValue(this.data.user.firstName);
    formControls.lastName.setValue(this.data.user.lastName);
    formControls.username.setValue(this.data.user.username);
    // formControls.password.setValue(this.data.user.password); // password is not possible to change right now
    formControls.email.setValue(this.data.user.email);
    formControls.type.setValue(this.data.user.type);
  }

  async submit(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      try {
        this.isLoading = true;
        const formControls = this.form.controls;
        const user: any = {
          firstName: formControls.firstName.value,
          lastName: formControls.lastName.value,
          username: formControls.username.value,
          email: formControls.email.value,
          password: formControls.password.value,
          type: formControls.type.value
        };
        if (this.modalType === 'add') {
          await this.actionService.createUser(user);
          this.dialogRef.close(user.username);
        } else {
          this.removeNullKeys(user);
          user.id = this.data.user.id;
          delete user.password;
          delete user.username;
          await this.actionService.editUser(user);
          this.dialogRef.close(user.firstName);
        }
      } catch (e) {
        this.setSnackBarErrors(e);
        this.setFormErrors(e.error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null || params[key] === '') {
        delete params[key];
      }
    });
  }

  setSnackBarErrors(e): void {
    switch (e.error.type) {
      case ErrorsEnum.USER_ERROR_EDIT_SELF_TYPE:
        this.errorService.openErrorSnackBarWithCustomMessage('O usuário não pode editar seu próprio cargo.');
        break;
      case ErrorsEnum.USER_ERROR_INVALID_PERMISSION:
        this.errorService.openErrorSnackBarWithCustomMessage('O usuário não possui permissões necessárias para criar ou editar este usuário.');
        break;
      default:
        this.errorService.openErrorSnackBar(e);
        break;
    }
  }

  setFormErrors(error) {
    switch (error.type) {
      case ErrorsEnum.USER_ERROR_INVALID_PASSWORD_PATTERN:
        this.form.controls.password.setErrors({invalid: true});
        if (error.data.expected === '/^\\d{6,8}$/') {
          this.passwordErrorText = 'A senha deve conter de 6 a 8 números';
        }
        break;
      case ErrorsEnum.USER_ERROR_USER_ALREADY_EXISTS:
        if (error.data.username) {
          this.form.controls.username.setErrors({alreadyExist: true});
        }
        if (error.data.email) {
          this.form.controls.email.setErrors({alreadyExist: true});
        }
        break;
      case ErrorsEnum.VALIDATION_ERROR:
        if (error?.data?.filter(e => e.type === 'email')?.length) {
          this.form.controls.email.setErrors({invalid: true});
        }
        break;
    }
  }

  close(): void {
    this.form.reset();
    this.dialogRef.close();
  }
}
