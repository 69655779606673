import {InspectionComponentStatusOptions} from '../pages/inspection/inspection.component';
import {InComponentStatusOptions} from '../pages/in/in.component';

export function filterOrders(orders, searchTerm) {
  return orders.filter(o => {
    const productsSearchValue = o.products.map(p => p.name + '#' + p.sku).join('+');
    const orderSearchValue = [o.number, o.vendorDoc, o.vendorName, productsSearchValue].map(item => item?.toUpperCase()).join('+');
    return searchInput(orderSearchValue, searchTerm);
  });
}

export function filterNfe(nfe, searchTerm) {
  return nfe.filter(i => {
    const productsSearchValue = i.products.map(p => p.name + '#' + p.sku).join('+');
    const nfeOrdersSearchValue = i.orders.map(o => o.number.toUpperCase()).join('+');
    const nfeSearchValue = [i.id, i.vendorName, i.vendorDoc, nfeOrdersSearchValue, productsSearchValue]
      .map(item => item?.toUpperCase()).join('+');
    return searchInput(nfeSearchValue, searchTerm);
  });
}

export function filterGoods(goods, searchTerm) {
  return goods.filter(g => {
    switch (searchTerm.toUpperCase()){
      case 'WITHOUT_VENDOR':
      return !g.hasOwnProperty('vendor');
      case 'WITHOUT_INVOICE':
        return g.inType === 'WITHOUT_INVOICE';
      default:
         const productsSearchValue = g.products?.map(p => p.name).join('+') || '';
         const orderNumbersSearchValue = g.buyOrders?.map(o => o.toUpperCase()).join('+') || '';
         const nfeNumberSearchValue = g.invoicesId?.map(i => i.toUpperCase()).join('+') || '';
         const searchValue = [g.vendor, orderNumbersSearchValue, nfeNumberSearchValue, productsSearchValue].map(item => item?.toUpperCase()).join('+');
         return searchInput(searchValue, searchTerm);
       }
     });
}

export function filterScreeningPallets(pallets, searchTerm) {
  return pallets.filter(p => {
    const buyOrdersSearchValue = p.buyOrders.map(o => o.toUpperCase()).join('+');
    const searchValue = [p.productName, p.invoiceId, p.vendorName, buyOrdersSearchValue].map(item => item?.toUpperCase()).join('+');
    return searchInput(searchValue, searchTerm);
  });
}

export function filterUsers(users, searchTerm) {
  return users.filter(u => {
    const searchValue = [u.firstName, u.lastName, u.username].map(item => item?.toUpperCase()).join('+');
    return searchInput(searchValue, searchTerm);
  });
}

// export function filterStockProducts(crates, searchTerm) {
//   return crates.filter(c => {
//     const productsSearchValue = c.skuGroups.map(p => p.name + '#' + p.sku).join('+');
//     const orderNumbersSearchValue = c.buyOrders.map(o => o.toUpperCase()).join('+');
//     const searchValue = [c.section, productsSearchValue, orderNumbersSearchValue].map(item => item?.toUpperCase()).join('+');
//     return searchInput(searchValue, searchTerm);
//   });
// }

function searchInput(searchValue, searchTerm) {
  return JSON.stringify(searchValue).toUpperCase().includes(searchTerm.toUpperCase());
}



export function filterInDocuments(allDocuments, selectedStatus, selectedFilter) {
  let documents = [];
  if (selectedStatus !== InComponentStatusOptions.NONE){
    documents = allDocuments.filter(o => o.status === selectedStatus);
  } else {
    documents = [...allDocuments];
  }
  return documents.filter(selectedFilter.filter);
}

export function filterInspectionDocuments(allGoods, selectedStatus, selectedFilter) {
  let documents = [...allGoods];
  if (selectedStatus !== InspectionComponentStatusOptions.NONE) {
    documents = documents.filter(o => o.palletStatus === selectedStatus);
  }

  if (selectedFilter && selectedFilter !== 'ALL') {
    documents = documents.filter(o => o.destination === selectedFilter);
  }

  return documents;
}

export function filterScreeningDocuments(allDocuments, selectedFilter) {
  const documents = [...allDocuments];
  documents.forEach(p => {
    if (p.deliveryDate[p.buyOrders[0]] === null) {
      p.deliveryDate[p.buyOrders[0]] = undefined;
    }
  });

  return documents.filter(selectedFilter.filter);
}
