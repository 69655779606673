<app-header-modal [color]="(status === 'DIVERGENT' || status === 'RECEIVEMENT_BOCKED') ? 'alert' : 'secondary-2'"
                  [title]="titleModal">
  <div class="right" status>
    <app-status-header-modal [icon]="status === 'AWAITING_RETIFICATION' ? 'spinner-edit' : 'spinner-alt'"
                             [color]="'in'"
                             [status]="status === 'AWAITING_RETIFICATION' ? 'Aguardando Correção' : 'Aguardando NFe'"></app-status-header-modal>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>

<mat-tab-group mat-align-tabs="center" [selectedIndex]="selected.value"
(selectedIndexChange)="selected.setValue($event)">
  <mat-tab label="Ordem de compra">
    <app-in-details-buy-order [data]="data.itemDetails" (itemClick)="openNfeModal($event)"></app-in-details-buy-order>
  </mat-tab>
  <mat-tab label="Comentários">
    <app-in-details-comments (submitEvent)="submitComment($event)"
                             [itemDetails]="data.itemDetails"
                             (deleteEvent)="deleteComment($event)"></app-in-details-comments>
  </mat-tab>
</mat-tab-group>
