import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DispatchService {

  constructor(private http: HttpClient) { }

  getDocuments() {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/dispatch').toPromise<any>();
  }

  fakeNfeIn(no:string){
    return this.http.patch(environment.baseUrl + '/api/v1/admin-desktop/out-order',{orderNo:no}).toPromise<any>();

  }

  getDispatchOrderDetails(orderNumber: string) {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/dispatch/${orderNumber}`).toPromise<any>();
  }

}
