<mat-form-field>
  <mat-label>Filtrar por status</mat-label>
  <mat-select>
    <mat-option *ngIf="selectedStatus !== 'NONE'" (click)="selectStatus('NONE')">Todos</mat-option>
    <mat-option (click)="selectOk()" [value]="'Conferido'">
      <div class="option">
        <div class="background success">
          <i class="uil uil-check"></i>
        </div>
        Conferido
      </div>
    </mat-option>
    <mat-option (click)="selectSurplus()" [value]="'Excedente'">
      <div class="option">
        <div class="background in">
          <i class="uil uil-plus"></i>
        </div>
        Excedente
      </div>
    </mat-option>
    <mat-option (click)="selectMissing()" [value]="'Faltante'">
      <div class="option">
        <div class="background alert">
          <i class="uil uil-minus"></i>
        </div>
        Faltante
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>

