import { HttpParams } from "@angular/common/http";

export function appendParams(queryParams: Object) {
  let params = new HttpParams();
  Object.keys(queryParams).forEach(key => {
    if (queryParams[key] !== null && queryParams[key] !== undefined) {
      params = params.append(key, queryParams[key]);
    }
  });
  return params;
}