import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {ActionService} from '../../../core/services/action.service';
import {TaskRequestSidenavService} from '../../../core/services/task-request-sidenav.service';

@Component({
  selector: 'app-stock-inventory-by-tag-rfid-sidenav',
  templateUrl: './stock-inventory-by-tag-rfid-sidenav.component.html',
  styleUrls: ['./stock-inventory-by-tag-rfid-sidenav.component.scss']
})
export class StockInventoryByTagRfidSidenavComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() drawer;
  @Input() task;

  inventoryForm: FormGroup;
  openSubscription: Subscription;

  usersHighlighted = [];

  selectOperatorError = false;

  disable: boolean;

  constructor(private formBuilder: FormBuilder,
              private actionService: ActionService,
              private sidenavService: TaskRequestSidenavService) {
    this.disable = false;
  }

  ngOnInit(): void {
    console.log(this.task);
    this.buildForm();
  }

  ngAfterViewInit() {
    this.openSubscription = this.drawer.openedStart.subscribe(() => {
      if (this.task) {
        this.disable = true;
      }
      this.selectOperatorError = false;
      this.setFormValues();
    });
  }

  ngOnDestroy() {
    this.openSubscription.unsubscribe();
  }

  buildForm() {
    this.inventoryForm = this.formBuilder.group({
      rfid: ['', Validators.required]
    });
  }

  resetForm() {
    this.inventoryForm.reset();
    this.usersHighlighted = [];
  }

  setFormValues() {
    this.inventoryForm.controls.rfid.setValue(this.task.rfId);
  }

  setUsers(users) {
    this.usersHighlighted = users;
  }

  async sendTask() {
    const params = {
      type: 'INVENTORY_BY_TAG_RFID',
      userIds: this.usersHighlighted,
      taskData: {
        tagRfid: [this.inventoryForm.controls.rfid.value]
      }
    };
    await this.actionService.inventoryByTagRfid(params);
  }

  submit() {
    this.inventoryForm.markAllAsTouched();
    if (this.inventoryForm.valid && this.usersHighlighted.length) {
      this.sendTask().then(() => {
        this.drawer.close();
        this.sidenavService.closeSidenav(true);
        this.resetForm();
        this.usersHighlighted = [];
        this.selectOperatorError = false;
      });
    } else {
      this.selectOperatorError = true;
    }
  }

  close() {
    this.drawer.close();
    this.sidenavService.closeSidenav(false);
    this.resetForm();
  }

}
