<div class="page-header">
  <app-prev-next-button (click)="toSetup()" direction="left" label="setup"></app-prev-next-button>
  <h1>Setup de Setores</h1>
  <button mat-raised-button class="primary" (click)="finish()">
    Ir para próximo passo
  </button>
</div>

<div class="page-content">
  <div class="left-side">
    <button mat-raised-button class="add-group" (click)="openModal()" *ngIf="state !== 'ADDING_TAGS'">
      adicionar setor
    </button>
    <button mat-raised-button class="remove-group" (click)="removeSector()" *ngIf="state === 'ADDING_TAGS'">
      remover setor
    </button>
    <button mat-raised-button class="save-group" (click)="groupClicked(currentGroundGroupId)" *ngIf="state === 'ADDING_TAGS'">
      salvar
    </button>
<!--    <app-ground-group-edit-->
<!--      (onDelete)="removeClicked($event)"-->
<!--    [groundGroupName]="currentGroundGroupName"-->
<!--    *ngIf="state === 'ADDING_TAGS'"-->
<!--    >-->
<!--    </app-ground-group-edit>-->
<!--    <app-ground-group-form-->
<!--      (onCreate)="groupAdded($event)"-->
<!--      *ngIf="state !== 'ADDING_TAGS'"-->
<!--    >-->
<!--    </app-ground-group-form>-->
    <app-ground-groups-list
      [groups]="groups"
      [state]="state"
      [selectedGroupId]="currentGroundGroupId"
      (onGroupClick)="groupClicked($event)"
      (onGroupHighLight)="groupHighLighted($event)"
    >
    </app-ground-groups-list>
  </div>
  <div class="right-side">
  <app-side-color-card color="primary-1">
    <app-ground-groups-map id="zz"
                           (positionClicked)="positionSelected($event)"
                           [state]="state"
                           [selectedPositionIds]="selectedPositionIds"
                           [selectedGroupId]="currentGroundGroupId"
                           [map]="map"
                           content #mapComponent>
    </app-ground-groups-map>
  </app-side-color-card>
</div>
