export function setOccupationChartDataset(data: any[]) {
  let occupiedGroundPositions = 0;
  let freeGroundPositions = 0;

  for (const d of data) {
    if (d.name.includes('ocupadas')) {
      occupiedGroundPositions = d.value;
    }

    if (d.name.includes('livres')) {
      freeGroundPositions = d.value;
    }
  }

  return [occupiedGroundPositions, freeGroundPositions];
}