import { Injectable } from '@angular/core';
import {AuthDataSourceService} from '../data-sources/auth-data-source.service';
import {Session, SessionService} from './session.service';
import jwtDecode from 'jwt-decode';
import {AuthService} from './auth.service';
import {UserService} from './user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private isLoggedIn = false;

  constructor(private authDataSourceService: AuthDataSourceService,
              private authService: AuthService,
              private userService: UserService,
              private sessionService: SessionService) { }

  async login(user, pass, device, tenantId) {
    return this.authDataSourceService.getToken(user, pass, device, tenantId).then(res => {
      const {exp}: any = jwtDecode(res.accessToken);
      const session: Session = {...res, exp};
      this.sessionService.saveSession(session);
      this.userService.saveLoggedUser();
      this.isLoggedIn = true;
      this.authService.isLoggedIn$.next(this.isLoggedIn);
    }).catch(err => {
      this.isLoggedIn = false;
      this.userService.clearLoggedUser();
      this.authService.isLoggedIn$.next(this.isLoggedIn);
      return err;
    });
  }

  async logout() {
    this.sessionService.clearSessionStorage();
    this.userService.clearLoggedUser();
    this.authDataSourceService.signOut();
    this.isLoggedIn = false;
    this.authService.isLoggedIn$.next(this.isLoggedIn);
  }
}
