<section class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Tarefa" (closeEvent)="close()"></app-sidenav-header>

    <form class="new-task-config" [formGroup]="relocateForm">
      <div class="card-header">Configurar nova tarefa de realocação</div>
      <div class="card-body">
        <div class="product-input">
          <p>Digite o que deseja realocar:</p>
          <mat-form-field>
            <input matInput
                   class="gray test"
                   placeholder="SKU ou a descrição do produto"
                   formControlName="product"
                   [matAutocomplete]="auto"
                   [readonly]="true">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayedString">
              <mat-option *ngFor="let product of products" [value]="product">{{ product.name + ' - ' + product.sku }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>Tag RFID</mat-label>
          <input matInput autocomplete="off" formControlName="rfid" [readonly]="true">
        </mat-form-field>

        <div class="destination">
          <p>Selecione o destino do produto</p>
          <div class="dropdowns">
            <mat-form-field class="sector">
              <mat-label>Setor</mat-label>
              <mat-select formControlName="sector">
                <mat-option (onSelectionChange)="handleSectorChange(sector, $event)"
                            [value]="sector"
                            *ngFor="let sector of sectorOptions">{{ sector }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="address">
              <mat-label>Endereço</mat-label>
              <mat-select formControlName="address" [value]="suggestedPosition?.coordinates" [disabled]="!isSectorSelected">
                <mat-option [value]="suggestedPosition?.coordinates">Seção recomendada: {{ suggestedPosition?.formatted }}</mat-option>
                <mat-option [value]="address.coordinates" *ngFor="let address of addressOptions">{{ address.formatted }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>

    <app-operators-table device="FORKLIFT"
                         [usersHighlighted]="usersHighlighted"
                         (usersHighlightedEvent)="setUsers($event)"
                         [openedStart]="drawer.openedStart"></app-operators-table>
  </div>

  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()">OK</button>
  </div>
</section>

