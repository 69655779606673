import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, first} from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';
import {DatePickerComponent} from '../../modals/date-picker/date-picker.component';
import {MatDialog} from '@angular/material/dialog';
import {filterScreeningDocuments, filterScreeningPallets} from '../../helpers/filters';
import {ScreeningService} from '../../core/services/screening.service';
import {InspectionService} from '../../core/services/inspection.service';
import {InService} from '../../core/services/in.service';
import {InDetailsNfeModalComponent} from '../../modals/in-details-nfe-modal/in-details-nfe-modal.component';
import {ActionService} from '../../core/services/action.service';

interface ScreeningComponentFilters {
  name: string;
  id: string;
  filter: (item) => boolean;
}

@Component({
  selector: 'app-screening',
  templateUrl: './screening.component.html',
  styleUrls: ['./screening.component.scss']
})
export class ScreeningComponent implements OnInit, OnDestroy, AfterViewInit {

  filters: ScreeningComponentFilters[] = [
    {
      name: 'Hoje',
      id: 'TODAY',
      filter: (item) => {
        const firstTimeToday = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
        return moment(item.deliveryDate[item.buyOrders[0]]).isSameOrBefore(firstTimeToday);
      }
    },
    {
      name: 'Próximos 3 dias',
      id: '3_DAYS',
      filter: (item) => {
        const timeIn3days = moment().add(3, 'days').endOf('day');
        return moment(item.deliveryDate[item.buyOrders[0]]).isSameOrBefore(timeIn3days);
      }
    },
    {
      name: 'Próximos 5 dias',
      id: '5_DAYS',
      filter: (item) => {
        const timeIn5days = moment().add(5, 'days').endOf('day');
        return moment(item.deliveryDate[item.buyOrders[0]]).isSameOrBefore(timeIn5days);
      }
    },
    {
      name: 'Próximos 7 dias',
      id: '7_DAYS',
      filter: (item) => {
        const timeIn7days = moment().add(7, 'days').endOf('day');
        return moment(item.deliveryDate[item.buyOrders[0]]).isSameOrBefore(timeIn7days);
      }
    },
    {
      name: 'Próximos 30 dias',
      id: '30_DAYS',
      filter: (item) => {
        const timeIn30days = moment().add(30, 'days').endOf('day');
        return moment(item.deliveryDate[item.buyOrders[0]]).isSameOrBefore(timeIn30days);
      }
    }
  ];
  selectedFilter;

  searchTermFormControl: FormControl;
  searchTermSubscription: Subscription;
  searchTerm: string;

  @ViewChild('select') select: MatSelect;
  selectSubscription: Subscription;

  customFilterSelectedLabel: string;
  customFilterResponse;

  actualHour;
  today;

  allDocuments;
  filteredStorage;
  filteredQuality;
  filteredDivergent;

  isStorageHighlighted = false;
  isQualityHighlighted = false;
  isDivergentHighlighted = false;
  isLoading: boolean = true;
  isModalLoading = false;

  constructor(private router: Router,
              public dialog: MatDialog,
              private screeningService: ScreeningService,
              private inService: InService,
              private actionService: ActionService) {
    this.searchTermFormControl = new FormControl();
    this.selectedFilter = this.filters[2];

    this.today = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
    const timeIn30Days = moment().add(30, 'days').startOf('day');
    this.getDocuments(this.today, timeIn30Days);
  }

  ngOnInit(): void {
    this.update();
    this.searchTermSubscription = this.searchTermFormControl.valueChanges.pipe(debounceTime(500)).subscribe(newValue => {
      this.searchTerm = newValue;
      this.filter();
    });
  }

  ngOnDestroy() {
    this.searchTermSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.selectSubscription = this.select.optionSelectionChanges.subscribe(event => {
      if (event.isUserInput){
        const value = event.source.value;

        this.customFilterSelectedLabel = null;
        if (value === 'CUSTOM' || value === 'CURRENT_CUSTOM'){
          this.openCustomDateFilterModal();
        } else {
          this.selectedFilter = this.filters.find((f) => f.id === value);
          this.filter();
          // do something
        }
      }
    });
  }

  async getDocuments(start, end) {
    try {
      this.isLoading = true;
      this.allDocuments = await this.screeningService.getAllProducts(start, end);

      this.filteredStorage = this.allDocuments.storage;
      this.filteredQuality = this.allDocuments.quality;
      this.filteredDivergent = this.allDocuments.divergent;
      this.filter();
    } catch (e) {
      console.log(e);
    } finally{
      this.isLoading = false;
    }
  }

  getCustomDocs(response) {
    const start = moment(response.start).set({hour: 0, minute: 0, second: 0, millisecond: 0});
    const end = moment(response.end).set({hour: 23, minute: 59, second: 59, millisecond: 0});

    const inThreeMonths = moment(response.end).add(3, 'months').startOf('day');
    if (moment(response.start).isBetween(inThreeMonths, end)) {
      this.getDocuments(start, end);
    } else {
      this.getDocuments(inThreeMonths, end);
      // TODO: user feedback toast.
    }
  }

  filter() {
    if (this.allDocuments) {
      let storage = [...this.allDocuments.storage];
      let quality = [...this.allDocuments.quality];
      let divergent = [...this.allDocuments.divergent];

      if (this.searchTerm) {
        storage = filterScreeningPallets(storage, this.searchTerm);
        quality = filterScreeningPallets(quality, this.searchTerm);
        divergent = filterScreeningPallets(divergent, this.searchTerm);
      }

      this.filteredStorage = filterScreeningDocuments(storage, this.selectedFilter);
      this.filteredQuality = filterScreeningDocuments(quality, this.selectedFilter);
      this.filteredDivergent = filterScreeningDocuments(divergent, this.selectedFilter);
    }
  }

  openCustomDateFilterModal() {
    const dialogRef = this.dialog.open(DatePickerComponent, {
    });
    dialogRef.afterClosed().pipe(first()).subscribe( res => {
      this.customFilterResponse = res;
      this.update();

      if (res?.start && res?.end) {
        const start = moment(res.start).set({hour: 0, minute: 0, second: 0, millisecond: 0});
        const end = moment(res.end).set({hour: 23, minute: 59, second: 59, millisecond: 0});
        this.getCustomDocs(res);

        this.customFilterSelectedLabel = `${this.padDate(res.start._i.date.toString())}/${this.padDate((res.start._i.month + 1).toString())}
        /${res.start._i.year.toString().substr(2)} - ${this.padDate(res.end._i.date.toString())}/${this.padDate((res.end._i.month + 1)
          .toString())}/${res.end._i.year.toString().substr(2)}`;
        this.selectedFilter = {
          id: 'CUSTOM',
          name: 'Customizado',
          filter: (item) => {
            return moment(item.deliveryDate[item.buyOrders[0]]).isBetween(start, end);
          }
        };
        this.filter();
      }
    });
  }

  padDate(date) {
    if (date < 10) {
      return '0' + date;
    } else {
      return date;
    }
  }

  openModal = async (item, page) => {
    this.isModalLoading = true;
    this.inService.getInDetailsInvoices(item.invoiceId).subscribe( itemDetails => {
      const dialogRef = this.dialog.open(InDetailsNfeModalComponent, {
        panelClass: 'details-modal',
        data: {
          item,
          itemDetails,
          page,
          type: 'SCREENING',
          autoFocus: false
        }
      });
      dialogRef.afterClosed().pipe(first()).subscribe(res => {
        if (res === 'SUBMITTED') {
          this.update();
        }
      });
      this.isModalLoading = false;
    });
  }

  async onSubmitChangeSector(data) {
    await this.actionService.bulkSetDestination(data);
    this.update();
  }

  update() {
    this.actualHour = moment().format('HH:mm');
    if (this.selectedFilter.id === 'CUSTOM') {
      this.getCustomDocs(this.customFilterResponse);
    } else {
      const timeIn30Days = moment().add(30, 'days').startOf('day');
      this.getDocuments(this.today, timeIn30Days);
    }
  }

  toggleHighlight(event, type) {
    if (type === 'STORAGE') {
      this.isStorageHighlighted = event;
    }
    if (type === 'QUALITY') {
      this.isQualityHighlighted = event;
    }
    if (type === 'DIVERGENT') {
      this.isDivergentHighlighted = event;
    }
  }

  async toIn(){
    await this.router.navigate(['in']);
  }

  async toInspection(){
    await this.router.navigate(['inspection']);
  }

}
