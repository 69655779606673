import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IMovementHistory} from '../../../models/History';

@Injectable({
  providedIn: 'root'
})
export class MovementHistoryService {

  constructor(private http: HttpClient) { }

  getMovementHistory(crateId: string): Promise<IMovementHistory[]> {
    return this.http.get<IMovementHistory[]>(environment.baseUrl + `/api/v1/admin-desktop/crates/${crateId}/movement-history`).toPromise();
  }
}
