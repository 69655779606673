import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.scss']
})
export class DashboardItemComponent implements OnInit {

  @Input() number;
  @Input() label;
  @Input() color;
  @Input() isLoading;

  constructor() { }

  ngOnInit(): void {
  }

}
