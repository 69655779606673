<ng-template #defaultTemplate let-item="item" >
  {{item.id}}
</ng-template>

<div class="summary">
  <div class="value">
    {{quantity}}
  </div>
  <div class="title">
    {{title | uppercase}}
  </div>
</div>
<div class="content">
  <div class="list" *ngIf="!isEmpty && !statePage">
    <ng-content select="[header]"></ng-content>
    <ng-container *ngFor="let document of displayedDocuments; let odd = odd"
                  [ngTemplateOutlet]="itemTemplate ? itemTemplate : defaultTemplate"
                  [ngTemplateOutletContext]="{item: document, odd: odd}"
    ></ng-container>
  </div>

  <div class="loader" *ngIf="statePage">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>

  <div class="empty" *ngIf="isEmpty && !statePage">
    <ng-content select="[empty]"></ng-content>
  </div>

  <div class="more" *ngIf="!displayAll && displayShowMoreToggle && !statePage" (click)="toggle($event)">
    <i class="uil uil-angle-down"></i> ver mais
  </div>
  <div class="less" *ngIf="displayAll && displayShowMoreToggle && !statePage" (click)="toggle($event)">
    <i class="uil uil-angle-up"></i> ver menos
  </div>
</div>
