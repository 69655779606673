import {Component, Inject, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CommentDataSourceService} from '../../core/data-sources/comment-data-source.service';
import {InService} from '../../core/services/in.service';

@Component({
  selector: 'app-in-details',
  templateUrl: './in-details-buy-order-modal.component.html',
  styleUrls: ['./in-details-buy-order-modal.component.scss']
})
export class InDetailsBuyOrderModalComponent implements OnInit, OnDestroy {

  selected = new FormControl(0);

  @Output() itemClick = new EventEmitter();

  titleModal: string;
  isCommentChange = false;
  status: string;

  constructor(public dialogRef: MatDialogRef<InDetailsBuyOrderModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private commentDataSource: CommentDataSourceService,
              private inService: InService) {
    this.status = this.data.itemDetails.order.status;
  }

  ngOnInit(): void {
    this.selected.setValue(this.data.page);

    this.titleModal = `Pedido N° ${this.data.item.number}`;
  }

  ngOnDestroy() {
    this.close();
  }

  close() {
    if (this.isCommentChange) {
      this.dialogRef.close('SUBMITTED');
    } else {
      this.dialogRef.close();
    }
  }

  openNfeModal(data) {
    this.itemClick.next(data);
  }

  async deleteComment(id) {
    try {
      await this.commentDataSource.deleteBuyOrderComment(this.data.item.number, id);
    } catch (e) {
      console.log(e);
    } finally {
      this.inService.getInDetailsOrders(this.data.item.number).subscribe(itemDetails => {
        this.data.itemDetails = itemDetails;
      });
      this.isCommentChange = true;
    }
  }

  async submitComment(event) {
    try {
      await this.commentDataSource.postBuyOrderComment(this.data.item.number, event.comment, 'ENTRY');
    } catch (e) {
      console.log(e);
    } finally {
      this.inService.getInDetailsOrders(this.data.item.number).subscribe(itemDetails => {
        this.data.itemDetails = itemDetails;
      });
      this.isCommentChange = true;
    }
  }
}
