<section>
  <div class="body">
    <app-sidenav-header title="Tarefa de realocação" (closeEvent)="close()"></app-sidenav-header>

    <app-sidenav-card title="Configurar nova tarefa de realocação">
      <div content>
        <form class="new-task-config"
              style="gap: 1rem"
              [formGroup]="relocateForm"
              *ngIf="!!data.groundPosition && !!data.productName">
          <mat-form-field appearance="outline">
            <mat-label>Produto</mat-label>
            <input matInput autocomplete="off" formControlName="product" [readonly]="true">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Posição atual</mat-label>
            <input matInput autocomplete="off" formControlName="position" [readonly]="true">
          </mat-form-field>
        </form>

        <form class="new-task-config legacy-form"
              [formGroup]="relocateForm"
              *ngIf="!data.groundPosition && !data.productName">
          <div class="product-input">
            <p>Digite o que deseja remover:</p>
            <mat-form-field>
              <mat-label>SKU ou a descrição do produto</mat-label>
              <input matInput
                     placeholder="SKU ou a descrição do produto"
                     formControlName="product"
                     [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayedProductString">
                <mat-option *ngFor="let product of productOptions"
                            [value]="product">
                  {{ (product.name || product.description) + ' - ' + product.sku }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="product-input" style="margin-top: 0.9rem;">
            <p>Selecione um endereço do produto:</p>
            <mat-form-field class="address">
              <mat-label>Endereço</mat-label>
              <input type="text"
                     placeholder="Endereço"
                     matInput
                     formControlName="position"
                     [matAutocomplete]="addressAuto">
              <mat-autocomplete
                autoActiveFirstOption #addressAuto="matAutocomplete" [displayWith]="displayPositionString">
                <mat-option
                  [value]="pp"
                  *ngFor="let pp of pickingPositionOptions">
                  {{ toExcelFormat(pp.groundPosition.x, pp.groundPosition.y) }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </form>

        <app-sidenav-destination #destination
                                 (changed)="destinationChanged($event)"
        ></app-sidenav-destination>

      </div>

    </app-sidenav-card>

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>

    <app-operators-table device="FORKLIFT"
                         [usersHighlighted]="selectedUsers"
                         (usersHighlightedEvent)="selectUsers($event)"
                         [openedStart]="drawer.openedStart"
                         [isUserOnline]="true"></app-operators-table>
  </div>
  <app-sidenav-footer
    (clicked)="footerClicked($event)"
  ></app-sidenav-footer>
</section>
