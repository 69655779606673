import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductManagementDataSourceService {

  constructor(private http: HttpClient) { }

  getProducts(filterParams?): Promise<any> {
    return this.http.get(environment.baseUrl + '/api/v1/admin-product/products', { params: filterParams }).toPromise();
  }

  getProduct(sku: string) {
    return this.http.get(environment.baseUrl + `/api/v1/admin-product/products/${sku}`).toPromise();
  }
}
