import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ActionService, TenantState} from '../../../core/services/action.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TenantService} from '../../../core/services/tenant.service';
import { ErrorService } from '../../../core/services/error.service';

@Component({
  selector: 'app-setup-finish',
  templateUrl: './setup-finish.component.html',
  styleUrls: ['./setup-finish.component.scss']
})
export class SetupFinishComponent implements OnInit {

  erps;
  selectedErp: string;
  name: string;
  id: string;
  useInternalProducts = false;
  enableEditionProductByHandScanner = false;
  internalProductsTooltip = 'Ao marcar a opção, as informações dos produtos não são resgatadas do ERP e sim em uma tela especifica de produtos no quantum. Apenas produtos serão gerenciados pelo quantum, Ordens de venda continuam sendo resgatadas do ERP.';

  dummyDetails: FormGroup;
  protheusDetails: FormGroup;
  defaultConfig = {
    TZ: 'America/Sao_Paulo',
  }

  constructor(
    private formBuilder: FormBuilder,
    private actionService: ActionService,
    private tenantsService: TenantService,
    private router: Router,
    private errorService: ErrorService) { }

  ngOnInit(): void {
    this.dummyDetails = this.formBuilder.group({
      url: ['', Validators.required],
    });
    this.protheusDetails = this.formBuilder.group({
      url: ['', Validators.required],
    });

    this.tenantsService.get(true).then(tenant => {
      this.selectedErp = tenant.erp;
      this.name = tenant.name;
      this.id = tenant.id;
      this.useInternalProducts = tenant.moduleProducts;
      this.enableEditionProductByHandScanner = tenant.enableHandScannerCrateEdition;
      if(tenant.erp === 'DUMMY'){
        this.dummyDetails.controls.url.patchValue(tenant.erpConfig.url);
      }
      else if(tenant.erp === 'PROTHEUS'){
        this.protheusDetails.controls.url.patchValue(tenant.erpConfig.url);
      }
    });
    this.tenantsService.getErps().then((erps: any) => {
      this.erps = erps;
    });

  }
  async changeErp($event){
    this.selectedErp = $event;
    await this.actionService.setTenantErp({erp: $event});
  }

  async finish(){
    try{
      let config = {};
      if(this.selectedErp === 'DUMMY') {
        if (this.dummyDetails.valid) {
          this.dummyDetails.markAllAsTouched();
          config = this.dummyDetails.getRawValue();
          config = Object.assign(config, this.defaultConfig);
        } else{
          throw new Error('aaa');
        }
      }
      else if(this.selectedErp === 'PROTHEUS') {
        if (this.protheusDetails.valid) {
          this.protheusDetails.markAllAsTouched();
          config = this.protheusDetails.getRawValue();
          config = Object.assign(config, this.defaultConfig);
        } else{
          throw new Error('aaa');
        }
      }

      await this.actionService.setTenantErpConfig({erp: this.selectedErp, config});
      await this.actionService.setTenantState({state: TenantState.ONLINE});
      await this.actionService.setTenantModules({ products: this.useInternalProducts });
      await this.actionService.setTenantConfig({ enableHandScannerCrateEdition: this.enableEditionProductByHandScanner });
      await this.router.navigate(['/setup']);
    } catch (error) {
      this.errorService.openErrorSnackBar(error);
    }

  }

  async toSetup(){
    await this.router.navigate(['/setup']);
  }

}
