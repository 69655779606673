import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  public $pageSize: Subject<number> = new Subject<number>();
  public $totalItems: Subject<number> = new Subject<number>();
  public $pageCount: Subject<number> = new Subject<number>();
  public $currentPage: Subject<number> = new Subject<number>();
  public $atLastPage: Subject<boolean> = new Subject<boolean>();
  public $atFirstPage: Subject<boolean> = new Subject<boolean>();
  public $items: Subject<any[]> = new Subject<any[]>();
  public pageSize = 30;
  public pageCount: number;
  public currentPage: number;
  public totalItems: number;

  constructor() { }

  get currentPage$(): Observable<number> {
    return this.$currentPage;
  }
  
  get atFirstPage$(): Observable<boolean> {
    return this.$atFirstPage;
  }

  get atLastPage$(): Observable<boolean> {
    return this.$atLastPage;
  }

  get totalItems$(): Observable<number> {
    return this.$totalItems;
  }

  get pageCount$(): Observable<number> {
    return this.$pageCount;
  }

  get items$(): Observable<any[]> {
    return this.$items;
  }
}
