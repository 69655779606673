<app-header-modal [color]="'secondary-2'" title="Plano de execução da Ordem de Saída">
  <div class="status-header" status>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>


<div class="content">
  <app-out-order-execution-plan
    [executionPlan]="executionPlan"
    [showBottomButtons]="true"
    (cancelButtonEmitter)="close()"
    (submitButtonEmitter)="handleSubmitButtonEvent()"
    >
  </app-out-order-execution-plan>
</div>