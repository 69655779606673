import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-import-file-content-button',
  templateUrl: './import-file-content-button.component.html',
  styleUrls: ['./import-file-content-button.component.scss']
})
export class ImportFileContentButtonComponent implements OnInit {

  file: any;
  name: string;
  @Output() data = new EventEmitter();
  constructor() { }

  reset(){
    this.file = null;
    this.name = null;
  }

  fileChanged(e) {
    this.file = e.target.files[0];
    this.name = this.file.name;

    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const result:any = fileReader.result;
      this.data.emit(result);
    };
    fileReader.readAsText(this.file);
  }

  ngOnInit(): void {
  }


}
