<div class="ov column">
  {{ item?.number || '---' }}
</div>
<div class="timestamp column">
  {{ date || '---' }}
</div>
<div class="document column">
  {{ item?.clientDoc || '---' }}
</div>
<div class="name column">
  <div class="client-name">{{ item?.clientName || '---' }}</div>
</div>
<div class="products column">
  <ng-container *ngIf="item?.products.length > 0">
    <div class="product" *ngFor="let p of item?.products">{{ p.name || '---' }}</div>
  </ng-container>
  <ng-container *ngIf="item?.products.length === 0">
    <div class="product">---</div>
  </ng-container>
</div>
<div class="quantity column">
  <div *ngFor="let p of item?.products">{{ p.quantity || '---' }}</div>
</div>
<div class="status column">
  {{ translateStatus(item?.status) }}
</div>
<div class="reserve-button">
  <button [matTooltip]="showTooltip()"
          [disabled]="disabled"
          mat-raised-button
          class="primary-1"
          (click)="processClickHandler($event)">PROCESSAR</button>
</div>
