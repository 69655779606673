import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {toExcelFormat} from '../../../helpers/format-position';

@Component({
  selector: 'app-select-pallet-dropdown',
  templateUrl: './select-pallet-dropdown.component.html',
  styleUrls: ['./select-pallet-dropdown.component.scss']
})
export class SelectPalletDropdownComponent implements OnInit {

  @Input() pallets = [];

  constructor() { }

  ngOnInit(): void {
  }

  handlePosition(x, y) {
    return toExcelFormat(x, y);
  }

  handleName(pallet){
    if (pallet.skuGroups.length > 0){
      const group = pallet.skuGroups[0];
      if(group.name){
        return `${group.name} (${group.sku})`;
      } else{
        return group.sku;
      }
    } else{
      return 'vazio';
    }
  }

  handleQuantity(skuGroups) {
    let quantity = 0;
    for (const p of skuGroups) {
      quantity = (p.quantity * p.packSize) + quantity;
    }
    return quantity;
  }

}
