<div class="duplicated-nfe-key-error" *ngIf="nfe?.duplicatedKeyInvoices?.length !== 0 && nfe.duplicatedKeyInvoices">
  <i class="uil uil-exclamation-octagon"></i>
  <p *ngIf="nfe?.duplicatedKeyInvoices?.length === 1">As notas fiscais
    <span>{{ nfe?.invoice?.id }}</span> e
    <span (click)="itemClickHandler(nfe.invoice)">>{{ nfe?.duplicatedKeyInvoices[0]?.id }}</span>
    possuem a mesma chave. Verifique no ERP e altere uma delas</p>
  <p *ngIf="nfe?.duplicatedKeyInvoices?.length > 1">As notas fiscais
    <span (click)="itemClickHandler(nfe?.invoice)">{{ nfe?.invoice?.id }}</span>
    <span *ngFor="let di of nfe?.duplicatedKeyInvoices; let last = last" (click)="itemClickHandler(di)">{{ last ? ' e ' + di.id : ', ' + di.id }}</span>
    possuem a mesma chave. Verifique no ERP e altere uma delas.</p>
</div>

<div class="up-modal">
  <div class="left">
    <div class="column">
      <app-info-oc-or-nfe label="Nº Nota Fiscal/Série" [value]="nfe.invoice.number" [serial]="nfe.invoice.serial"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Razão Social" [value]="nfe.invoice.vendor?.name"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Endereço" [value]="nfe.invoice.address?.street"></app-info-oc-or-nfe>
    </div>
    <div class="column two">
      <app-info-oc-or-nfe label="Data/Hora de Emissão" [value]="timestampToDate(nfe.invoice.issuedAt)"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="CPF/CNPJ Fornecedor" [value]="nfe.invoice.vendor?.doc"></app-info-oc-or-nfe>
      <div *ngIf="nfe.relatedInvoices.length > 0 && nfe.invoice.id !== nfe.relatedInvoices[0].id">
        <small>Notas fiscais relacionadas</small>
        <div class="related-invoices">
          <div class="invoice-number"
               *ngFor="let invoice of nfe.relatedInvoices; let last = last"
               (click)="itemClickHandler(invoice)">
            {{ last ? invoice.id : invoice.id + ', ' }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="right">
    <div class="download-button">
      <button mat-button (click)="downloadXml()" [ngClass]="{'error-button': errorXML}">
        <i class="uil uil-file-download"></i>
        <span *ngIf="!errorXML" class="text">BAIXAR NFE EM XML</span>
        <span *ngIf="errorXML" class="text">ERRO AO BAIXAR XML</span>
      </button>
    </div>
    <app-provider-contact [provider]="{name: nfe.invoice.vendor?.spokesman ? nfe.invoice.vendor.spokesman : '-',
                                       phone: nfe.invoice.vendor?.phone ? nfe.invoice.vendor.phone : '-',
                                       email: nfe.invoice.vendor?.email}">
    </app-provider-contact>
  </div>
</div>

<div class="down-modal">
  <div class="table">
    <div class="header">
      <div class="product-code column">
        Código de produto
      </div>
      <div class="provider-code column">
        Código do fornecedor
      </div>
      <div class="description column">
        Descrição
      </div>
      <div class="cfop column">
        CFOP
      </div>
      <div class="ncm column">
        NCM
      </div>
<!--      <div class="packaging column">-->
<!--        Embalagem-->
<!--      </div>-->
      <div class="unit-of-measurement column">
        UM
      </div>
      <div class="quantity column">
        QTD
      </div>
    </div>

    <div class="item"
         *ngFor="let product of nfe.invoice.products"
         [ngClass]="{'primary-bg': highlightedProducts?.includes(product.sku)}">
      <div class="product-code column">
        {{ product.sku }}
      </div>
      <div class="provider-code column">
        {{ product.vendorCode }}
      </div>
      <div class="description column">
        {{ product.name }}
      </div>
      <div class="cfop column">
        {{ product.cfop }}
      </div>
      <div class="ncm column">
        {{ product.ncm }}
      </div>
<!--      <div class="packaging column">-->
<!--        Pallet-->
<!--      </div>-->
      <div class="unit-of-measurement column">
        {{ product.unit }}
      </div>
      <div class="quantity column">
        {{ product.quantity }}
      </div>
    </div>
  </div>
  <div class="destination">
    <div class="text">
      <small>Setor de destino</small>
    </div>
    <mat-select *ngFor="let product of nfe.invoice.products"
                [(value)]="product.destiny"
                (selectionChange)="changeOption($event, product)"
                (openedChange)="saveState(product)"
                [disabled]="!!product.loading || nfe.invoice.status === 'AWAITING_COMPARISON' || nfe.invoice.status === 'RECEIVEMENT_BLOCKED'">
      <mat-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mat-option>
    </mat-select>
  </div>
</div>
