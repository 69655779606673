<div class="page-header">
  <app-prev-next-button (click)="navigateTo('dashboard')" direction="left" icon="estate"></app-prev-next-button>
  <h1>Separação de produtos</h1>
</div>

<div class="ctrl-bar">
  <div class="buttons">
    <button mat-raised-button class="secondary-2" (click)="openSidenav('PRODUCT_PICKING_RELOCATE')">
      Adicionar
    </button>

    <button mat-raised-button class="secondary-2" (click)="openSidenav('RELOCATE_FROM_PRODUCT_PICKING')">
     Remover
    </button>

    <div class="update-button">
      <p>Atualizado às {{actualHour}}</p>
      <button mat-raised-button (click)="update()">
        <i class="uil uil-sync"></i>
        <span>Atualizar</span>
      </button>
    </div>
  </div>
</div>

<app-side-color-card color="picking">
  <div class="card-content" content>
    <div class="card-header">
      <h2>PRODUTOS ({{ items?.length || '-' }})</h2>
    </div>
    <div class="table" *ngIf="!isLoading">
      <app-product-picking-table-header></app-product-picking-table-header>
      <app-product-picking-table-item *ngFor="let item of items; let odd = odd"
                                      [item]="item"
                                      [isOdd]="odd"
                                      (itemClickEvent)="openDetailsModal(item, $event)"></app-product-picking-table-item>
    </div>
    <div *ngIf="isLoading" class="loader">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <div class="empty" *ngIf="items?.length === 0 && !isLoading">
      NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
    </div>
  </div>
</app-side-color-card>

<app-full-page-loader *ngIf="isModalLoading"></app-full-page-loader>
