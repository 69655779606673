export enum UserLogType {
  OFF="OFF",
  ON="ON",
  HARDWARE="HARDWARE"
}

export enum UserRoles {
  MASTER = 'MASTER',
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR'
}

export interface IUser {
  createdAt: number;
  currentDevice: string | null;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  lastSeenAt: number;
  logConfig: string;
  online: boolean;
  tenantId: string;
  type: UserRoles;
  updatedAt: number;
  username: string;
}
