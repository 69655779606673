import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DatePickerComponent} from '../../modals/date-picker/date-picker.component';
import {MatSelect} from '@angular/material/select';
import {debounceTime, first} from 'rxjs/operators';
import {InService} from '../../core/services/in.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {InDetailsBuyOrderModalComponent} from '../../modals/in-details-buy-order-modal/in-details-buy-order-modal.component';
import {InDetailsNfeModalComponent} from '../../modals/in-details-nfe-modal/in-details-nfe-modal.component';
import {ActivatedRoute, Router} from '@angular/router';
import {filterInDocuments, filterNfe, filterOrders} from '../../helpers/filters';
import {FakeInOrderModalComponent} from '../../modals/fake-in-order-modal/fake-in-order-modal.component';
import { ErrorService } from '../../core/services/error.service';

export enum InComponentStatusOptions {
  OK = 'OK',
  AWAITING_INVOICE = 'AWAITING_INVOICE',
  AWAITING_RETIFICATION = 'AWAITING_RETIFICATION',
  AWAITING_COMPARISON = 'AWAITING_COMPARISON',
  DIVERGENT = 'DIVERGENT',
  RECEIVEMENT_BLOCKED = 'RECEIVEMENT_BLOCKED',
  NONE = 'NONE'
}

interface InComponentFilters {
  name: string;
  id: string;
  filter: (item) => boolean;
}

@Component({
  selector: 'app-in',
  templateUrl: './in.component.html',
  styleUrls: ['./in.component.scss']
})
export class InComponent implements OnInit, AfterViewInit, OnDestroy {

  allDocuments;
  allDocumentsCustom;
  allOrders;
  allNFes;

  filteredOrders;
  filteredNFes;

  searchTermFormControl: FormControl;
  searchTermSubscription: Subscription;
  searchTerm: string;

  selectedStatus: InComponentStatusOptions = InComponentStatusOptions.NONE;

  filters: InComponentFilters[] = [
    {
      name: 'Hoje',
      id: 'TODAY',
      filter: (item) => {
        const firstTimeToday = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
        return moment(item.issuedAt).isSameOrAfter(firstTimeToday);
      }
    },
    {
      name: 'Últimos 3 dias',
      id: '3_DAYS',
      filter: (item) => {
        const time3daysAgo = moment().subtract(3, 'days').startOf('day');
        return moment(item.issuedAt).isSameOrAfter(time3daysAgo);
      }
    },
    {
      name: 'Últimos 7 dias',
      id: '7_DAYS',
      filter: (item) => {
        const time7daysAgo = moment().subtract(7, 'days').startOf('day');
        return moment(item.issuedAt).isSameOrAfter(time7daysAgo);
      }
    },
    {
      name: 'Últimos 30 dias',
      id: '30_DAYS',
      filter: (item) => {
        const time30daysAgo = moment().subtract(30, 'days').startOf('day');
        return moment(item.issuedAt).isSameOrAfter(time30daysAgo);
      }
    }
  ];
  selectedFilter;

  customFilterSelectedLabel: string;
  customFilterResponse;

  @ViewChild('select') select: MatSelect;

  selectSubscription;

  actualHour;
  now;
  invoiceModalsOpened = new Set();
  orderModalsOpened = new Set();
  isLoading: boolean;
  isModalLoading = false;

  constructor(public dialog: MatDialog, public inService: InService, public ref: ChangeDetectorRef,
              private router: Router,
              private route: ActivatedRoute,
              private errorService: ErrorService) {
    this.selectedFilter = this.filters[1];

    this.searchTermFormControl = new FormControl();

    this.openModalFromRoute();

    this.now = moment();
  }

  ngOnInit(): void {
    this.update();
    this.searchTermSubscription = this.searchTermFormControl.valueChanges.pipe(debounceTime(500))
      .subscribe(newValue => {
        this.searchTerm = newValue;
        this.filter();
        this.order();
      });
  }

  ngOnDestroy(): void {
    this.selectSubscription?.unsubscribe();
    this.searchTermSubscription?.unsubscribe();
  }

  ngAfterViewInit(){
    this.selectSubscription = this.select.optionSelectionChanges.subscribe(event => {
      if (event.isUserInput){
        const value = event.source.value;

        this.customFilterSelectedLabel = null;
        if (value === 'CUSTOM' || value === 'CURRENT_CUSTOM'){
          this.openCustomDateFilterModal();
        } else{
          this.allDocumentsCustom = this.allDocuments;
          this.allOrders = this.allDocumentsCustom.orders;
          this.allNFes = this.allDocumentsCustom.invoices;
          this.selectedFilter = this.filters.find((f) => f.id === value);
          this.filter();
          this.order();
        }
      }
    });
  }

  getDocs(start: moment.Moment, end: moment.Moment) {
    this.isLoading = true;
    this.inService.getDocuments(start, end).subscribe((values) => {
      if (end === this.now) {
        this.allDocuments = values;
        this.allOrders = this.allDocuments.orders;
        this.allNFes = this.allDocuments.invoices;
      } else {
        this.allDocumentsCustom = values;
        this.allOrders = this.allDocumentsCustom.orders;
        this.allNFes = this.allDocumentsCustom.invoices;
      }

      this.filteredOrders = [...this.allOrders];
      this.filteredNFes = [...this.allNFes];

      this.filter();
      this.order();
      this.isLoading = false;
    }, err => {
      this.errorService.openErrorSnackBar(err);
      this.isLoading = false;
    });
  }

  openOrderDetailsModal = (item, event, page, closeLastModalOpened) => {
    this.openModal(item, event, page, closeLastModalOpened, 'order');
  }
  openNfeDetailsModal = (item, event, page, closeLastModalOpened?) => {
    this.openModal(item, event, page, closeLastModalOpened, 'invoice');
  }

  openModal = (item, event, page, modalToBeClosed, type) => {
    this.isModalLoading = true;
    let oppeningModal;
    const config = {
      order: {
        modalsOpened: this.orderModalsOpened,
        component: InDetailsBuyOrderModalComponent,
        get: 'getInDetailsOrders',
        id: item.number
      },
      invoice: {
        modalsOpened: this.invoiceModalsOpened,
        component: InDetailsNfeModalComponent,
        get: 'getInDetailsInvoices',
        id: item.id
      }
    };
    const {modalsOpened, component, get, id} = config[type];
    if (!modalsOpened.has(id)) {
      oppeningModal = true;
      const firstModal = !this.orderModalsOpened.size && !this.invoiceModalsOpened.size;
      modalsOpened.add(id);

      this.inService[get](id).pipe(first()).subscribe(itemDetails => {
        oppeningModal = false;

        const dialogRef: MatDialogRef<InDetailsBuyOrderModalComponent | InDetailsNfeModalComponent> =
          this.dialog.open(component, {
            panelClass: 'details-modal',
            data: {item, itemDetails, page, closeLastModalOpened: modalToBeClosed},
            autoFocus: false
          });

        if (modalToBeClosed) {
          modalToBeClosed.close();
        }

        dialogRef.afterClosed().pipe(first()).subscribe(res => {
          this.router.navigate(['.'], { relativeTo: this.route });
          modalsOpened.delete(id);
          if (res === 'SUBMITTED') {
            this.update();
          }
        });

        dialogRef.componentInstance.itemClick.subscribe((modal: { type: string, entity: any }) => {
          this.openModal(modal.entity, itemDetails, page, !firstModal && dialogRef, modal.type);
        });

        this.isModalLoading = false;
      });

    } else if (modalToBeClosed && !oppeningModal) {
      modalToBeClosed.close();
    }
  }

  statusSelected(status){
    this.selectedStatus = status;
    this.filter();
    this.order();
  }

  filter(){
    if (this.allOrders && this.allNFes) {
      let orders = [...this.allOrders];
      let nfe = [...this.allNFes];

      if (this.searchTerm){
        orders = filterOrders(orders, this.searchTerm);
        nfe = filterNfe(nfe, this.searchTerm);
      }
      this.filteredOrders = filterInDocuments(orders, this.selectedStatus, this.selectedFilter);
      this.filteredNFes = filterInDocuments(nfe, this.selectedStatus, this.selectedFilter);
    }
  }

  order(){
    this.filteredNFes = this.filteredNFes.sort((a, b) => {
      const weights = {
        UNKNOWN: 0,
        RECEIVEMENT_BLOCKED: 1,
        DIVERGENT: 2,
        AWAITING_RETIFICATION: 3,
        AWAITING_COMPARISON: 4,
        OK: 5
      };

      if (weights[a.status] === weights[b.status] && weights[a.status] !== undefined){
        return weights[a.status];
      }
      else if (weights[a.status] < weights[b.status] || (weights[a.status] && weights[b.status] === undefined)){
        return -1 * weights[a.status];
      }
      else if (weights[a.status] > weights[b.status] || (weights[a.status] === undefined && weights[b.status])){
        return 1 * weights[a.status];
      } else{
        return 999999999;
      }
    });

    this.filteredOrders = this.filteredOrders.sort((a, b) => {
      const weights = {
        AWAITING_RETIFICATION: 1,
        AWAITING_INVOICE: 2
      };

      return weights[a.status] - weights[b.status];
    });
  }

  openCustomDateFilterModal(){
    const dialogRef = this.dialog.open(DatePickerComponent, {
    });
    dialogRef.afterClosed().pipe(first()).subscribe( res => {
      this.customFilterResponse = res;

      const start = moment(res.start).set({hour: 0, minute: 0, second: 0, millisecond: 0});
      const end = moment(res.end).set({hour: 23, minute: 59, second: 59, millisecond: 0});
      this.getCustomDocs(res);

      this.customFilterSelectedLabel = `${this.padDate(res.start._i.date.toString())}/${this.padDate((res.start._i.month + 1).toString())}/${res.start._i.year.toString().substr(2)}
       - ${this.padDate(res.end._i.date.toString())}/${this.padDate((res.end._i.month + 1).toString())}/${res.end._i.year.toString().substr(2)}`;
      this.selectedFilter = {
        id: 'CUSTOM',
        name: 'Customizado',
        filter: (item) => {
          return moment(item.issuedAt).isBetween(start, end);
        }
      };
      this.filter();
      this.order();
    });
  }

  getCustomDocs(response) {
    const start = moment(response.start).set({hour: 0, minute: 0, second: 0, millisecond: 0});
    const end = moment(response.end).set({hour: 23, minute: 59, second: 59, millisecond: 0});
    const threeMonthsAgo = moment(response.end).subtract(3, 'months').startOf('day');
    if (moment(response.start).isBetween(threeMonthsAgo, end)) {
      this.getDocs(start, end);
    } else {
      this.getDocs(threeMonthsAgo, end);
      // TODO: user feedback toast.
    }
  }

  padDate(date) {
    if (date < 10) {
      return '0' + date;
    } else {
      return date;
    }
  }

  openModalFromRoute() {
    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.inService.getInDetailsInvoices(params.id).subscribe(itemDetails => {
          const dialogRef = this.dialog.open(InDetailsNfeModalComponent, {
            panelClass: 'details-modal',
            data: { itemDetails },
            autoFocus: false
          });
          dialogRef.afterClosed().pipe(first()).subscribe(res => {
            this.router.navigate(['.'], { relativeTo: this.route });
          });
        });
      }
    });
  }

  async toHomePage() {
    await this.router.navigate(['/dashboard']);
  }

  async toScreening() {
    await this.router.navigate(['/screening']);
  }

  update() {
    this.actualHour = moment().format('HH:mm');
    if (this.selectedFilter.id === 'CUSTOM') {
      this.getCustomDocs(this.customFilterResponse);
    } else {
      const time30daysAgo = moment().subtract(30, 'days').startOf('day');
      this.getDocs(time30daysAgo, this.now);
    }
  }

  openFakeInOrderModal(){
    const dialogRef = this.dialog.open(FakeInOrderModalComponent, {
      panelClass: 'fake-in-order-modal',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
        this.update();
    });
  }
}
