  <div class="oc column" (click)="handleGotoOther($event)">
    {{order.number}}
  </div>
  <div class="in-timestamp column" (click)="handleGotoOther($event)">
    {{ date }}
  </div>
  <div class="document column" (click)="handleGotoOther($event)">
    {{ order.vendorDoc }}
  </div>
  <div class="name column" (click)="handleGotoOther($event)">
    {{ order.vendorName }}
  </div>
  <div *ngIf="order.status === 'AWAITING_INVOICE'" class="status column" (click)="handleGotoOther($event)">
    <img src="/assets/icons/spinner-file.svg" alt="">
  </div>
  <div *ngIf="order.status === 'AWAITING_RETIFICATION'" class="status column" (click)="handleGotoOther($event)">
    <img src="/assets/icons/spinner-edit.svg" alt="">
  </div>
  <div *ngIf="order.status === 'UNKNOWN'" class="status column" (click)="handleGotoOther($event)">
    <img src="/assets/icons/spinner-unknown.svg" alt="">
  </div>
  <div class="messages column" (click)="handleComment($event)">
    <i *ngIf="order.commentsQty === 0" class="uil uil-comment"></i>
    <i *ngIf="order.commentsQty !== 0" class="uil uil-comment-lines"></i>
    <span>{{ order.commentsQty }}</span>
  </div>
