import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL, FirebaseStorage, StorageReference, uploadBytes } from 'firebase/storage';
import { environment } from '../../../environments/environment';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  public app;
  public storage: FirebaseStorage;

  constructor(private tenantService: TenantService) {
    this.app = initializeApp(environment.firebaseConfig);
    this.storage = getStorage(this.app);
  }

  getApp() {
    return this.app;
  }

  async getProductImage(sku: string) {
    const res = await this.tenantService.get();
    const tenantId = res.id.toLowerCase();
    const pathReference: StorageReference = ref(this.storage, `${environment.name}/${tenantId}/products/${sku}/picture.jpg`);
    return getDownloadURL(pathReference);
  }

  async uploadFile(sku: string, file, timestamp) {
    const res = await this.tenantService.get();
    const tenantId = res.id.toLowerCase();
    const pathReference: StorageReference = ref(this.storage, `${environment.name}/${tenantId}/products/${sku}/versions/${timestamp}.jpg`);
    return uploadBytes(pathReference, file);
  }
}
