import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-packing-out-order-details',
  templateUrl: './packing-out-order-details.component.html',
  styleUrls: ['./packing-out-order-details.component.scss']
})
export class PackingOutOrderDetailsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PackingOutOrderDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  timestampToDate(timestamp) {
    return moment(timestamp).format('DD/MM/YYYY - HH:mm');
  }

}
