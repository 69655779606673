import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from './core/services/auth.service';
import {UserService} from './core/services/user.service';
import {TaskRequestSidenavService} from './core/services/task-request-sidenav.service';
import {SnackBarService} from './core/services/snack-bar.service';
import {NavigationEnd, Router} from '@angular/router';
import {FullLoaderService} from './core/services/full-loader.service';
import { UserRoles } from '../models/User';
import { LoginService } from './core/services/login.service';
import { ErrorService } from './core/services/error.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pda001-quantum-fe';
  isLogged = false;

  user;
  isLoaderOpen = false;
  isOpen = false;
  drawerType;
  drawerData;
  currentPage;

  @ViewChild('taskRequestDrawer') taskRequestDrawer: any;
  @ViewChild('nfeNotification', { static: true }) nfeNotificationTmpl;

  constructor(private authService: AuthService, private userService: UserService,
              private sidenavService: TaskRequestSidenavService,
              private fullLoaderService: FullLoaderService,
              private snackbar: SnackBarService,
              private loginService: LoginService,
              private errorService: ErrorService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(routerEvent=>{
      if(routerEvent instanceof NavigationEnd){
        const sanitized = routerEvent.url.replace(/\//g,'');
        this.currentPage = sanitized;
      }
    });

    this.fullLoaderService.isOpen$.subscribe((isOpen)=>{
      this.isLoaderOpen = isOpen;
    });

    this.authService.isLoggedIn$.subscribe(
      async () => {
        this.isLogged = this.authService.isLoggedIn();
        if (this.isLogged){
          await this.userService.saveLoggedUser();
          this.user = this.userService.getLoggedUser();
          this.logoutRolesNotAllowed(this.user);
          await this.snackbar.onDocChange(this.nfeNotificationTmpl);
        }
      }
    );
    this.sidenavService.toggleTaskRequest.subscribe((res: any) => {
      const {type} = res;
      switch (type) {
        case 'STOCK_RELOCATE_SINGLE_PALLET':
        case 'MISPLACED_EXPEDITION':
        case 'STOCK_INVENTORY_BY_TAG_RFID':
        case 'PRODUCT_PICKING_RELOCATE':
        case 'RELOCATE_FROM_PRODUCT_PICKING':
        case 'RELOCATE_LOAD_DISPATCH':
        case 'EXTRA_CRATES_AT_PICKING':
        case 'EMPTY_PICKING_POSITION':
          this.drawerData = res;
          break;
        default:
          this.drawerData = res.data ? res.data : res;
          break;
      }

      this.isOpen = !this.isOpen;
      this.drawerType = type;
    });
  }

  logoutRolesNotAllowed(user) { // TODO: remove this when backend returns error for this roles on login
    if (user.type === UserRoles.OPERATOR || user.type === UserRoles.MANAGER) {
      this.loginService.logout();
      this.errorService.openErrorSnackBarWithCustomMessage('Você não possui cargo para acessar o sistema.');
      this.router.navigate(['/login']);
    }
  }

  closeDrawer() {
    this.sidenavService.closeSidenav(false);
  }
}
