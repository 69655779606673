<div class="ov column">
  {{ item.outOrderNumber || '---' }}
</div>
<div class="timestamp column">
  {{ date || '---' }}
</div>
<div class="document column">
  {{ item.client.doc || '---' }}
</div>
<div class="name column">
  <div class="client-name">{{ item.client.name || '---' }}</div>
</div>
<div class="products column">
  <div class="product" *ngFor="let p of item.products">{{ p.name || '---' }}</div>
</div>
<div class="quantity column">
  <div *ngFor="let p of item.products">{{ p.quantity || '---' }}</div>
</div>
<div class="crates column">
  <div>{{ item.cratesQty }}</div>
</div>
<div class="status column">
  {{ translateState(item.state) }}
</div>
<!--<div class="messages column">-->
<!--  <i *ngIf="item.commentsQty === 0" class="uil uil-comment"></i>-->
<!--  <i *ngIf="item.commentsQty !== 0" class="uil uil-comment-lines"></i>-->
<!--  <span>{{ item.commentsQty }}</span>-->
<!--</div>-->
