<div class="card">
    <img src="assets/icons/logo-quantum-1.svg">
    <p>ACESSE SUA CONTA</p>

    <form [formGroup]="loginForm">
      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Usuário</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="user" required>
      </mat-form-field>
      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Senha</mat-label>
        <input matInput #passwordInput="matInput" [type]="hide ? 'password' : 'text'" autocomplete="off"
               formControlName="password" [ngClass]="{'hide-password-spacing': hide}" required (keydown.enter)="keyPress($event)">
        <mat-error *ngIf="!loginForm?.controls?.password?.errors?.invalid || !loginForm?.controls?.user?.errors?.invalid">
          Digite seu usuário e senha para acessar a sua conta.
        </mat-error>
        <mat-error *ngIf="loginForm?.controls?.password?.errors?.invalid || loginForm?.controls?.user?.errors?.invalid">
          Usuário não encontrado ou senha incorreta.
        </mat-error>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-pressed]="hide">
          <mat-icon [color]="passwordInput.errorState ? 'warning' : ''">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <div class="bottom-card">
        <button *ngIf="isLoading" disabled mat-raised-button class="primary-3 full" (click)="login()">
        <mat-spinner class="spinner" [diameter]="30"></mat-spinner>
        </button>
        <button *ngIf="!isLoading" mat-raised-button class="primary-3 full" (click)="login()">
          Entrar
        </button>
        <small>Esqueceu sua senha?</small>
      </div>
    </form>

  </div>
