import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {InComponent} from './pages/in/in.component';
import {ExampleComponent} from './pages/example/example.component';
import {ScreeningComponent} from './pages/screening/screening.component';
import {InspectionComponent} from './pages/inspection/inspection.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {AuthGuard} from './core/guards/auth.guard';
import {ExternalPageGuard} from './core/guards/external-page.guard';
import {SidebarResolver} from './core/resolvers/sidebar/sidebar.resolver';
import {StockComponent} from './pages/stock/stock.component';
import {WarehouseMapComponent} from './pages/warehouse-map/warehouse-map.component';
import {TasksComponent} from './pages/tasks/tasks.component';
import {DebugComponent} from './pages/debug/debug.component';
import {DebugTasksComponent} from './pages/debug-tasks/debug-tasks.component';
import {PickingComponent} from './pages/picking/picking.component';
import {DispatchComponent} from './pages/dispatch/dispatch.component';
import {GroundGroupsComponent} from './pages/ground-groups/ground-groups.component';
import {MapComponent} from './pages/map/map.component';
import {UsersComponent} from './pages/users/users.component';
import {GroundGroupsDetailsComponent} from './pages/ground-groups-details/ground-groups-details.component';
import {SetupComponent} from './pages/setup/setup.component';
import {SetupTenantComponent} from './pages/setup/setup-tenant/setup-tenant.component';
import {SetupGroundComponent} from './pages/setup/setup-ground/setup-ground.component';
import {SetupTagsComponent} from './pages/setup/setup-tags/setup-tags.component';
import {SetupLevelsComponent} from './pages/setup/setup-levels/setup-levels.component';
import {SetupFinishComponent} from './pages/setup/setup-finish/setup-finish.component';
import {LedgerComponent} from './pages/ledger/ledger.component';
import {TenantConfigResolverResolver} from './core/resolvers/tenant-config-resolver.resolver';
import {PendingTasksComponent} from './pages/pending-tasks/pending-tasks.component';
import {ForkliftsComponent} from './pages/forklifts/forklifts.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ProductPickingComponent } from './pages/product-picking/product-picking.component';
import { SetupAdminsComponent } from './pages/setup/setup-admins/setup-admins.component';
import { ProductManagementComponent } from './pages/product-management/product-management.component';
import { UserTypeGuard } from './core/guards/user-type.guard';
import { UserRoles } from '../models/User';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [ExternalPageGuard]
  },
  {
    path: 'in',
    component: InComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'in' }
  },
  {
    path: 'screening',
    component: ScreeningComponent,
    resolve: { sidebar: SidebarResolver },
    canActivate: [AuthGuard]
  },
  {
    path: 'stock',
    component: StockComponent,
    resolve: { sidebar: SidebarResolver, tenantConfig: TenantConfigResolverResolver },
    data: { section: 'stock' },
    canActivate: [AuthGuard]
  },
  {
    path: 'pending-tasks',
    component: PendingTasksComponent,
    resolve: { sidebar: SidebarResolver, tenantConfig: TenantConfigResolverResolver },
    data: { section: 'stock' },
    canActivate: [AuthGuard]
  },
  {
    path: 'inspection',
    component: InspectionComponent,
    resolve: { sidebar: SidebarResolver },
    canActivate: [AuthGuard]
  },
  {
    path: 'picking',
    component: PickingComponent,
    resolve: { sidebar: SidebarResolver },
    canActivate: [AuthGuard]
  },
  {
    path: 'dispatch',
    component: DispatchComponent,
    resolve: { sidebar: SidebarResolver },
    canActivate: [AuthGuard]
  },
  {
    path: 'example',
    component: ExampleComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'dashboard' }
  },
  {
    path: 'warehouse-map',
    component: WarehouseMapComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'warehouse' }
  },
  {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'tasks'}
  },
  {
    path: 'map',
    component: MapComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'map' }
  },
  {
    path: 'ground-groups',
    component: GroundGroupsComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'ground-groups' }
  },
  {
    path: 'ground-group-levels/:id',
    component: GroundGroupsDetailsComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'ground-groups' }
  },
  {
    path: 'setup',
    component: SetupComponent,
    canActivate: [AuthGuard, UserTypeGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'setup', allowedType: UserRoles.MASTER }
  },
  {
    path: 'setup-tenant',
    component: SetupTenantComponent,
    canActivate: [AuthGuard, UserTypeGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'setup', allowedType: UserRoles.MASTER }
  },
  {
    path: 'setup-admins',
    component: SetupAdminsComponent,
    canActivate: [AuthGuard, UserTypeGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'setup', allowedType: UserRoles.MASTER }
  },
  {
    path: 'setup-ground',
    component: SetupGroundComponent,
    canActivate: [AuthGuard, UserTypeGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'setup', allowedType: UserRoles.MASTER }
  },
  {
    path: 'setup-tags',
    component: SetupTagsComponent,
    canActivate: [AuthGuard, UserTypeGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'setup', allowedType: UserRoles.MASTER }
  },
  {
    path: 'setup-levels',
    component: SetupLevelsComponent,
    canActivate: [AuthGuard, UserTypeGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'setup', allowedType: UserRoles.MASTER }
  },
  {
    path: 'setup-finish',
    component: SetupFinishComponent,
    canActivate: [AuthGuard, UserTypeGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'setup', allowedType: UserRoles.MASTER }
  },
  {
    path: 'register',
    component: UsersComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'register' }
  },
  {
    path: 'forklifts',
    component: ForkliftsComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver }
  },
  {
    path: 'ledger',
    component: LedgerComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver }
  },
  {
    path: 'debug',
    component: DebugComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver }
  },
  {
    path: 'debug-tasks',
    component: DebugTasksComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver }
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'reports' }
  },
  {
    path: 'product-picking',
    component: ProductPickingComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'product-picking' }
  },
  {
    path: 'product-management',
    component: ProductManagementComponent,
    canActivate: [AuthGuard],
    resolve: { sidebar: SidebarResolver },
    data: { section: 'product-management' }
  },
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
