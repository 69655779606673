<app-header-modal [color]="'secondary-2'" title="Detalhes da ordem">
  <i class="uil uil-times" (click)="close()" status></i>
</app-header-modal>

<div class="content">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="Detalhes de Ordem da Venda">
      <div class="up-modal">
        <div class="left">
          <div class="column">
            <app-info-oc-or-nfe label="Nº Nota Fiscal/Série" [value]="data?.number ? data.number : '---' "></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="Razão Social" [value]="data?.client?.name ? data.client.name : '---' " ></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="Endereço" [value]="data?.address?.street ? data.address.street : '---' "></app-info-oc-or-nfe>
          </div>
          <div class="column two">
            <app-info-oc-or-nfe label="Data/Hora de Emissão" [value]="timestampToDate(data?.issuedAt)"></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="CPF/CNPJ Fornecedor" [value]="data?.client?.doc ? data.client.doc : '---' "></app-info-oc-or-nfe>
          </div>
        </div>
      
        <div class="right">
          <app-provider-contact 
            [provider]="{
                          name: data.client?.name ? data.client.name : '---',
                          phone: data.client?.phone ? data.client.phone : '---',
                          email: data.client?.email ? data.client.email : '---'
                        }">
          </app-provider-contact>
        </div>
      </div>
      <div class="table">
        <app-table-header>
          <app-table-header-column name="name">
            <span content>Nome</span>
          </app-table-header-column>
          <app-table-header-column name="sku">
            <span content>SKU</span>
          </app-table-header-column>
          <app-table-header-column name="description">
            <span content>Descrição</span>
          </app-table-header-column>
          <app-table-header-column name="quantity">
            <span content>Quantidade</span>
          </app-table-header-column>
        </app-table-header>

        <app-table-items>
          <app-table-item *ngFor="let p of data?.products; let odd = odd" [isOdd]="odd">
            <app-table-column name="name">
              <span content>{{ p?.name || '-' }}</span>
            </app-table-column>
            <app-table-column name="sku">
              <span content>{{ p?.sku || '-' }}</span>
            </app-table-column>
            <app-table-column name="description">
              <span content>{{ p?.description || '-' }}</span>
            </app-table-column>
            <app-table-column name="quantity">
              <span content>{{ p?.quantity || '-' }}</span>
            </app-table-column>
          </app-table-item>
          <div class="empty-table" *ngIf="!data?.products?.length">
            NÃO EXISTEM PRODUTOS.
          </div>
        </app-table-items>
      </div>
    </mat-tab>

    <mat-tab label="Detalhes dos produtos">
      <app-product-image-tab *ngIf="data?.products?.length" [products]="data?.products"></app-product-image-tab>
      <div class="empty-products" *ngIf="!data?.products?.length">
        NÃO EXISTEM PRODUTOS.
      </div>
    </mat-tab>

    <mat-tab label="Plano" *ngIf="data?.executionPlan">
      <app-out-order-execution-plan [executionPlan]="data?.executionPlan"></app-out-order-execution-plan>
    </mat-tab>
  </mat-tab-group>
</div>
