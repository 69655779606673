<div class="table">
  <div class="header">
    <div class="column type">
      Tipo de tarefa
    </div>
    <div class="column tag-rfid">
      Tag RFID
    </div>
    <div class="column task-id">
      Task ID
    </div>
    <div class="column task-no">
      TaskNo
    </div>
    <div class="column state">
      Estado
    </div>
  </div>

  <div class="item" *ngFor="let task of allTasks">
    <div class="column type">
      {{ task.type }}
    </div>
    <div class="column tag-rfid">
      {{ task.tagRfid }}
    </div>
    <div class="column task-id">
      {{ task.id }}
    </div>
    <div class="column task-id">
      {{ task.taskNo }}
    </div>
    <div class="column state">
      {{ task.state }}
    </div>
  </div>

  <div class="empty" *ngIf="!allTasks?.length">
    NÃO EXISTEM TAREFAS NESTE MOMENTO.
  </div>
</div>
