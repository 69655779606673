import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-setup-step',
  templateUrl: './setup-step.component.html',
  styleUrls: ['./setup-step.component.scss']
})
export class SetupStepComponent implements OnInit {

  @Input() step;
  @Input() color;
  @Input() done;

  constructor() { }



  ngOnInit(): void {
  }

}
