import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {InComponentStatusOptions} from '../in.component';

@Component({
  selector: 'app-in-group-status-filter-ctrl',
  templateUrl: './in-group-status-filter-ctrl.component.html',
  styleUrls: ['./in-group-status-filter-ctrl.component.scss']
})
export class InGroupStatusFilterCtrlComponent implements OnInit {

  @Output() statusSelected = new EventEmitter();
  selectedStatus = InComponentStatusOptions.NONE;

  constructor() { }

  ngOnInit(): void {
  }

  selectSuccess(){
    this.selectStatus(InComponentStatusOptions.OK);
  }
  selectDivergent(){
    this.selectStatus(InComponentStatusOptions.DIVERGENT);
  }
  selectBlocked() {
    this.selectStatus(InComponentStatusOptions.RECEIVEMENT_BLOCKED);
  }
  selectWaiting(){
    this.selectStatus(InComponentStatusOptions.AWAITING_INVOICE);
  }
  selectWaitingComparison() {
    this.selectStatus(InComponentStatusOptions.AWAITING_COMPARISON);
  }
  selectWaitingCorrection() {
    this.selectStatus(InComponentStatusOptions.AWAITING_RETIFICATION);
  }

  selectStatus(status){
    if(this.selectedStatus === status && status !== InComponentStatusOptions.NONE){
      this.selectedStatus = InComponentStatusOptions.NONE;
    } else{
      this.selectedStatus = status;
    }
    this.statusSelected.emit(this.selectedStatus);
  }
}
