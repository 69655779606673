import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TenantInterface } from 'src/models/Tenant';
import { Level } from 'src/app/modals/stock-levels-model/stock-levels-modal.component';

export interface GroundGroupPosition {
  id: string;
  rfId: string;
  x: number;
  y: number;
  tenantId: string;
  type: string;
  createdAt: number;
  updatedAt: number;
  state: string;
  groundPositionGroupId: string;
  alias?: string;
  group: GroundGroup;
  coords?: string;
}
export interface GroundGroup {
  id: string;
  name: string;
  type: string;
  createdAt: number;
  updatedAt: number | null;
  tenantId: string;
  levelsEnabled: boolean;
  tenant?: TenantInterface;
  levels?: Level[];
  positions?: GroundGroupPosition[];
}

@Injectable({
  providedIn: 'root'
})
export class GroundPositionGroupDataSourceService {

  constructor(private http: HttpClient) { }

  getDetails(id: string) {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/ground/groups/${id}?eager=true`).toPromise<any>();
  }

  getDetailsById(groundGroupIds: string[]) {
    let params = new HttpParams();
    groundGroupIds.forEach(value => {
      params = params.append('id', value);
    });
    return this.http.get<GroundGroup[]>(environment.baseUrl + `/api/v1/admin-desktop/ground/groups?eager=true&${params.toString()}`).toPromise();
  }
}
