import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {UserDataSourceService} from '../../core/data-sources/user-data-source.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-operators-table',
  templateUrl: './operators-table.component.html',
  styleUrls: ['./operators-table.component.scss']
})
export class OperatorsTableComponent implements OnInit, OnDestroy {

  @Input() usersHighlighted;
  @Output() usersHighlightedEvent = new EventEmitter();
  @Input() openedStart;
  @Input() device;
  @Input() isForklift = true;
  @Input() isUserOnline: boolean;

  openSubscription: Subscription;

  usersForklift;

  isAllChecked = false;
  isEmpty = false;
  numberOfUsers: string;

  constructor(private userDataSource: UserDataSourceService) { }

  ngOnInit(): void {
    this.openSubscription = this.openedStart.subscribe(async () => {
      await this.getUsers();
      this.isAllChecked = false;
    });
  }

  ngOnDestroy() {
    this.openSubscription.unsubscribe();
  }

  handleNumbersOfUsers() {
    if (this.usersForklift?.length > 0 && this.usersForklift?.length < 10) {
      return '0' + this.usersForklift?.length;
    } else if (this.usersForklift?.length > 10) {
      return this.usersForklift?.length;
    } else {
      return 0;
    }
  }

  async getUsers() {
    try {
      this.usersForklift = await this.userDataSource.getUsersByDevice(this.device, this.isUserOnline);
      this.numberOfUsers = this.handleNumbersOfUsers();
      this.isEmpty = !this.usersForklift?.length;
    } catch {
      // TODO handle error
    }
  }

  allChecked(isChecked) {
    this.isAllChecked = isChecked;
    this.usersHighlighted = [];
    if (isChecked) {
      for (const u of this.usersForklift) {
        this.usersHighlighted.push(u.id);
      }
    } else {
      this.usersHighlighted = [];
    }
    this.usersHighlightedEvent.emit(this.usersHighlighted);
  }

  onCheckboxChange(event) {
    if (event.checked) {
      this.usersHighlighted.push(event.source.value);
    } else {
      this.usersHighlighted.splice(this.usersHighlighted.indexOf(event.source.value), 1);
    }
    this.usersHighlightedEvent.emit(this.usersHighlighted);
  }
}
