import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskRequestSidenavService } from '../../core/services/task-request-sidenav.service';
import { toExcelFormat } from '../../helpers/format-position';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { ActionService, ApprovePalletStockPositionAction } from '../../core/services/action.service';
import { ActionsEnum } from '../../core/data-sources/action-data-source.service';
import { PendingTask } from '../../core/data-sources/pending-task.service';
import { AddOrEditProductModalComponent } from 'src/app/modals/add-or-edit-product-modal/add-or-edit-product-modal.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-pending-task-card',
  templateUrl: './pending-task-card.component.html',
  styleUrls: ['./pending-task-card.component.scss']
})
export class PendingTaskCardComponent implements OnInit {
  @Input() pendingTaskOrigin = '';
  @Input() pendingTask: PendingTask;
  @Output() updateEvent = new EventEmitter();

  constructor(private sidenavService: TaskRequestSidenavService,
              private dialog: MatDialog,
              private actionService: ActionService) { }

  ngOnInit(): void {
  }

  openSidenav(type) {
    switch (type) {
      case 'RELOCATE_PENDING_EXPEDITION':
        this.sidenavService.toggle(this.pendingTask).then(res => {
          this.updateEvent.emit();
        });
        break;
      case 'RELOCATE_LOAD_DISPATCH':
        const taskData = {
          type,
          rfId: this.pendingTask.data.tagRfid,
          product: this.pendingTask.data.products[0]?.name
            ? this.pendingTask.data.products[0]?.name
            : this.pendingTask.data.products[0]?.sku,
          suggestedPosition: this.pendingTask.data.suggestedPosition
        };
        this.sidenavService.toggle(taskData).then(res => {
          this.updateEvent.emit();
        });
        break;
      case 'EMPTY_PICKING_POSITION':
        const task = {
          type,
          position: this.pendingTask.data.position,
          suggestedCrates: this.pendingTask.data.suggestedCrates,
          product: this.pendingTask.data.productName
        };
        this.sidenavService.toggle(task).then(res => {
          this.updateEvent.emit();
        });
        break;
      case 'NEGATIVE_Z_INDEX_CRATES_AT_PICKING':
        const product = this.pendingTask.data.products[0]?.name
          ? this.pendingTask.data.products[0]?.name
          : this.pendingTask.data.products[0]?.sku;
        this.sidenavService.toggle({
          taskType: 'NEGATIVE_Z_INDEX_CRATES_AT_PICKING',
          type: 'STOCK_RELOCATE_SINGLE_PALLET',
          position: this.pendingTask.data.position,
          rfId: this.pendingTask.data.tagRfid,
          product
        }).then(res => {
          this.updateEvent.emit();
        });
        break;
      default:
        const productName = this.pendingTask.data.products[0]?.name
          ? this.pendingTask.data.products[0]?.name
          : this.pendingTask.data.products[0]?.sku;
        const data = {
          type,
          rfId: this.pendingTask.data.tagRfid,
          product: productName
        };
        this.sidenavService.toggle(data).then(res => {
          this.updateEvent.emit();
        });
        break;
    }
  }

  openConfirmModal() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: {
        label: `Você tem certeza que deseja manter o pallet na coordenada ${this.pendingTask.data.position}?`
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const body: ApprovePalletStockPositionAction = {
          type: ActionsEnum.APPROVE_PALLET_STOCK_POSITION,
          data: {
            tagRfid: this.pendingTask.data.tagRfid
          }
        };
        this.actionService.acceptActualPosition(body).then(() => {
          this.updateEvent.emit();
        });
      }
    });
  }

  openAddProductModal(task) {
    const dialogRef = this.dialog.open(AddOrEditProductModalComponent, {
      panelClass: 'details-modal',
      autoFocus: false,
      data: { sku: task.data.sku, modalType: 'add' }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(async (response) => {
      if (response) {
        this.updateEvent.emit();
      }
    });
  }

  formatPosition(x, y) {
    return toExcelFormat(x, y);
  }

  // just for type RESTOCK_PICKING
  getPositionsString(): string {
    return '';
  }
}


