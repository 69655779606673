import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddOrEditProductModalComponent } from '../../modals/add-or-edit-product-modal/add-or-edit-product-modal.component';
import * as moment from 'moment/moment';
import { first } from 'rxjs/operators';
import { ProductManagementService } from '../../core/services/product-management.service';
import { Product, ProductManagementFiltersParams } from '../../../models/Product';
import { Subscription } from 'rxjs';
import { ErrorsEnum, ErrorService } from '../../core/services/error.service';
import { ActionService } from '../../core/services/action.service';
import { ActionsEnum } from '../../core/data-sources/action-data-source.service';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { ProductDetailsModalComponent } from '../../modals/product-details-modal/product-details-modal.component';
import { ProductManagementDataSourceService } from '../../core/data-sources/product-management-data-source.service';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.scss']
})
export class ProductManagementComponent implements OnInit, OnDestroy {

  actualHour;

  filterToggle = false;
  filtersApplied: ProductManagementFiltersParams;
  numberOfFiltersApply = 0;

  products: Product[] = [];

  isLoading = false;
  fullPageLoading = false;
  productsSubscription: Subscription;

  constructor(private router: Router,
              private dialog: MatDialog,
              private productManagementService: ProductManagementService,
              private productManagementDataSource: ProductManagementDataSourceService,
              private errorService: ErrorService,
              private actionService: ActionService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.productManagementService.init(this.filtersApplied).catch(err => {
      if (err.error.type === ErrorsEnum.PRODUCTS_FORBIDDEN_ACCESS) {
        this.router.navigate(['/dashboard']);
        this.errorService.openErrorSnackBarWithCustomMessage('Altere a opção de gerenciar produtos no setup para ter acesso a esta tela.');
      }
      console.error(err);
    });
    this.productsSubscription = this.productManagementService.items$.subscribe(items => {
      this.actualHour = moment().format('HH:mm');
      this.products = items;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.productsSubscription?.unsubscribe();
  }

  openAddProductModal(): void {
    const dialogRef = this.dialog.open(AddOrEditProductModalComponent, {
      panelClass: 'details-modal',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe(async (response) => {
      if (response) {
        await this.update();
      }
    });
  }

  async openDetailsModal(product): Promise<void> {
    try {
      this.fullPageLoading = true;
      const productDetails = await this.productManagementDataSource.getProduct(product.sku);
      const dialogRef = this.dialog.open(ProductDetailsModalComponent, {
        panelClass: 'details-modal',
        autoFocus: false,
        data: productDetails
      });
      dialogRef.afterClosed().pipe(first()).subscribe(async (response) => {
        if (response) {
          await this.update();
        }
      });
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.fullPageLoading = false;
    }

  }

  deleteProduct(product): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      autoFocus: false,
      panelClass: 'confirm-modal',
      data: { label: `Deseja deletar o produto ${product.name || product.sku}?` }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(async (res) => {
      if (res) {
        try {
          this.isLoading = true;
          await this.actionService.removeProduct({
            type: ActionsEnum.REMOVE_PRODUCT,
            data: {
              sku: product.sku
            }
          });
          await this.update();
        } catch (e) {
          this.errorService.openErrorSnackBar(e);
        } finally {
          this.isLoading = false;
        }
      }
    });
  }

  openFilters(): void {
    this.filterToggle = !this.filterToggle;
  }

  async filter(params: ProductManagementFiltersParams): Promise<void> {
    if (params) {
      this.filtersApplied = params;
      this.numberOfFiltersApply = Object.keys(params).length;
    } else {
      this.filtersApplied = null;
      this.numberOfFiltersApply = 0;
    }
    await this.update();
  }

  async update() {
    try {
      this.isLoading = true;
      await this.productManagementService.reload(this.filtersApplied);
      this.actualHour = moment().format('HH:mm');
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.isLoading = false;
    }
  }

  toDashboard(): void {
    this.router.navigate(['in']);
  }
}
