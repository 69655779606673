import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StockSortParams } from 'src/app/core/services/stock.service';
import { ProductManagementFiltersParams } from 'src/models/Product';

@Component({
  selector: 'app-product-management-table-header',
  templateUrl: './product-management-table-header.component.html',
  styleUrls: ['./product-management-table-header.component.scss']
})
export class ProductManagementTableHeaderComponent implements OnInit {
  @Output() filterValues = new EventEmitter();
  showCheckIcon = {};
  sortDirection: "ASC" | "DESC";
  sortParams: StockSortParams;
  appliedFilters: ProductManagementFiltersParams = {};
  form = new FormGroup({
    name: new FormControl(null),
    description: new FormControl(null),
    sku: new FormControl(null),
    minWeight: new FormControl(null),
    maxWeight: new FormControl(null),
    minHeight: new FormControl(null),
    maxHeight: new FormControl(null),
    minWidth: new FormControl(null),
    maxWidth: new FormControl(null),
    minDepth: new FormControl(null),
    maxDepth: new FormControl(null)
  });

  constructor() { }

  ngOnInit(): void {
  }

  sort(orderBy: string, sortBy: "ASC" | "DESC") {
    this.sortDirection = sortBy;
    this.showCheckIcon[orderBy] = true;
    this.sortParams = { orderBy, sortBy };
  }

  clearFilters() {
    this.appliedFilters = {};
    this.showCheckIcon = {};
    this.sortDirection = null;
    this.sortParams = null;
    this.form.reset();
    this.filterValues.emit();
  }

  applyFilters() {
    this.removeNullKeys(this.form.value);
    this.filterValues.emit({
      filterParams: this.form.value,
      sortParams: this.sortParams
    });
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      } else {
        this.setAppliedFilters(key);
      }
    });
  }

  setAppliedFilters(name: string) {
    switch (name) {
      case 'maxHeight':
      case 'minHeight':
        this.appliedFilters['height'] = true;
        break;
      case 'minWidth':
      case 'maxWidth':
        this.appliedFilters['width'] = true;
        break;
      case 'minDepth':
      case 'maxDepth':
        this.appliedFilters['depth'] = true;
        break;
      case 'minWeight':
      case 'maxWeight':
        this.appliedFilters['weight'] = true;
        break;
      default:
        this.appliedFilters[name] = true;
        break;
    }
  }

}
