import {Component, HostBinding, Input, OnInit, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-in-group-buy-order-item',
  templateUrl: './in-group-buy-order-item.component.html',
  styleUrls: ['./in-group-buy-order-item.component.scss']
})
export class InGroupBuyOrderItemComponent implements OnInit {

  @Input() order;
  @HostBinding('class.isOdd') @Input() isOdd;
  date;
  @Output() goToComment = new EventEmitter();
  @Output() goToOther = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
    this.date = moment(this.order.issuedAt).format('DD/MM/YYYY - HH:mm');
  }

  handleComment($event){
    this.goToComment.emit()
  }
  handleGotoOther($event){
    this.goToOther.emit()
  }
}
