import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-side-color-card',
  templateUrl: './side-color-card.component.html',
  styleUrls: ['./side-color-card.component.scss']
})
export class SideColorCardComponent implements OnInit {

  @Input() color;

  @Input() @HostBinding('class.selected') highlight: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
