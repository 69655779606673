<p>Selecione o destino do produto:</p>
<form [formGroup]="form">
  <mat-form-field class="group">
    <mat-label>Setor</mat-label>
    <input type="text"
           placeholder="Setor"
           aria-label="Number"
           matInput
           formControlName="groundGroup"
           [matAutocomplete]="groundGroupAuto">
    <mat-autocomplete
      (closed)="groundGroupAutoClosed($event)"
      autoActiveFirstOption #groundGroupAuto="matAutocomplete" [displayWith]="displayedGroundGroup">
      <mat-option [value]="groundGroup"
                  *ngFor="let groundGroup of filteredGroundGroups"
                  (onSelectionChange)="onChangeSector($event)">{{ groundGroup.name }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="address">
    <mat-label>Posição</mat-label>
    <input type="text"
           placeholder="Posição"
           matInput
           formControlName="position"
           [matAutocomplete]="addressAuto"
    >
    <mat-autocomplete
      autoActiveFirstOption
      #addressAuto="matAutocomplete"
      [displayWith]="displayedPosition"
      (closed)="groundPositionAutoClosed()">
      <mat-option *ngFor="let groundPosition of filteredGroundPosition" [value]="groundPosition">
        {{ groundPosition.isSuggested ? 'Seção recomendada: ' + groundPosition.label : groundPosition.label }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
