import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-fake-nfe-modal',
  templateUrl: './fake-nfe-modal.component.html',
  styleUrls: ['./fake-nfe-modal.component.scss']
})
export class FakeNfeModalComponent implements OnInit {

  fakeNfeForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<FakeNfeModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.fakeNfeForm = this.formBuilder.group({
      key: ['', Validators.required],
      number: [null, Validators.required],
      serial: ['', Validators.required]
    });
  }

  close(isSubmitted?) {
    if (isSubmitted) {
      this.fakeNfeForm.markAllAsTouched();
      if (this.fakeNfeForm.valid) {
        const data = {
          key: this.fakeNfeForm.controls.key.value,
          number: this.fakeNfeForm.controls.number.value,
          serial: this.fakeNfeForm.controls.serial.value
        };
        this.dialogRef.close(data);
      }
    } else {
      this.dialogRef.close();
    }
  }

}
