import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private currentSection = '';
  public currentSection$ = new BehaviorSubject('');

  constructor() { }

  updateSection(section: string) {
    this.currentSection = section;
    this.currentSection$.next(this.currentSection);
  }
}
