import * as PIXI from 'pixi.js';
import {GroundPosition} from './warehouse-map-task-position-view.component';
import {toExcelFormat} from '../../../helpers/format-position';

export enum GroundType {
  STOCK = 'STOCK',
  DOCK = 'DOCK',
  STREET = 'STREET',
  QUALITY_CONTROL = 'QUALITY_CONTROL',
  DIVERGENT_STOCK = 'DIVERGENT_STOCK',
  EXPEDITION = 'EXPEDITION',
  DAMAGES = 'DAMAGES',
  PICKING = 'PICKING',
  PROVISION = 'PROVISION',
  IN = 'IN',
  EXIT = 'EXIT'
}

const width = 50;
const height = 50;
const border = 10;
const titleStyleWhite = new PIXI.TextStyle({
  fontFamily: 'Roboto',
  fontSize: 12,
  fontWeight: 'bold',
  wordWrap: true,
  wordWrapWidth: 440,
  lineJoin: 'round',
});

const columnStyle = new PIXI.TextStyle({
  fontFamily: 'Roboto',
  fontSize: 14,
  fontWeight: 'bold',
  // fill: ['#454F63'], // gradient
  wordWrap: true,
  wordWrapWidth: 440,
  lineJoin: 'round',
});

const rowsStyle = new PIXI.TextStyle({
  fontFamily: 'Roboto',
  fontSize: 14,
  fontWeight: 'bold',
  fill: ['#454F63'], // gradient
  wordWrap: true,
  wordWrapWidth: 440,
  lineJoin: 'round',
});

export function getWidth(w) {
  return (w * width) + 50;
}
export function getHeight(h) {
  return (h * height) + 45;
}

export function drawGroundPosition(ground: GroundPosition) {
  const graphics = new PIXI.Graphics();
  graphics.cacheAsBitmap = true;
  let color;
  const initialBorder = border;
  if (!ground.hasPallet){
    color = setColor(ground.type);
    graphics.beginFill(0xFFFFFF)
      .lineStyle(2, color === 0xFFFFFF ? 0x000000 : color, 1)
      .drawRoundedRect(initialBorder + ground.x * width + 50, initialBorder + ground.y * height + 45, width - 5, height - 8, 5)
      .endFill();
  } else{
    color = setFilledColor(ground.type);
    graphics.beginFill(color)
      .lineStyle(2, color === 0xFFFFFF ? 0x000000 : color, 1)
      .drawRoundedRect(initialBorder + ground.x * width + 50, initialBorder + ground.y * height + 45, width - 5, height - 8, 5)
      .endFill();
  }
  if(ground.state === 'BLOCKED'){
    graphics.beginFill(color)
      .lineStyle(1, color === 0xFFFFFF ? 0x000000 : color, 1)
      .drawRoundedRect(initialBorder + ground.x * width + 50 + 5, initialBorder + (ground.y+1) * height + 15, width - 5 - 10, 1, 0)
      .endFill();
  }



  graphics.hitArea = new PIXI.RoundedRectangle(ground.x * width + 50, initialBorder + ground.y * height  + 45, width - 5, height - 8, 5);
  //graphics.buttonMode = false;
  //graphics.interactive = false;
  const titleStyle = Object.assign({}, titleStyleWhite);
  if (!ground.hasPallet) {
    titleStyle.fill = [color];
  } else{
    titleStyle.fill = ['#FFFFFF'];
  }
  const titleText = ground.alias ?? '- - -';
  const title = new PIXI.Text(titleText, titleStyle);
  const titleTextWidth = title.width;
  const rectangleWidth = width - 5;
  const horizontalPadding = (rectangleWidth - titleTextWidth) / 2;

  title.x = initialBorder + ground.x * width + 50 + horizontalPadding;
  title.y = initialBorder + ground.y * height + 45 + (height - 8) / 2 - title.height / 2;

  return [graphics, title];
}

function setFilledColor(groundType) {
  switch (groundType) {
    case GroundType.STREET:
      return 0x89909F;
    case GroundType.STOCK:
      return 0x92B531;
    case GroundType.DOCK:
      return 0x3D539E;
    case GroundType.EXPEDITION:
      return 0x0F8A8D;
    case GroundType.QUALITY_CONTROL:
      return 0x4E146C;
    case GroundType.DIVERGENT_STOCK:
      return 0xB02A09;
    case GroundType.DAMAGES:
      return 0x9B7D08;
    case GroundType.PICKING:
      return 0xE10767;
    case GroundType.PROVISION:
      return 0x29A66C;
    case GroundType.IN:
      return 0xEC8E00;
    case GroundType.EXIT:
      return 0x000000;
    default:
      return 0xFFFFFF; // default color
  }
}

function setColor(groundType) {
  switch (groundType) {
    case GroundType.STREET:
      return 0x9BA1AF;
    case GroundType.STOCK:
      return 0xA1B76D;
    case GroundType.DOCK:
      return 0x6E7CAF;
    case GroundType.EXPEDITION:
      return 0x65A3AB;
    case GroundType.QUALITY_CONTROL:
      return 0x826799;
    case GroundType.DIVERGENT_STOCK:
      return 0xB26455;
    case GroundType.DAMAGES:
      return 0xA69654;
    case GroundType.PICKING:
      return 0xCC6096;
    case GroundType.PROVISION:
      return 0x61AE90;
    case GroundType.IN:
      return 0xD6A050;
    case GroundType.EXIT:
      return 0x000000;
    default:
      return 0xFFFFFF; // default color
  }
}

export function drawColumns(positionsX) {
  const columns = [];
  const initialBorder = border;
  for (const x of positionsX) {
    const grade = setColumns(x);

    const columnsText = new PIXI.Text(grade, columnStyle);
    columnsText.resolution = 1.5;
    columnsText.y = initialBorder;
    columnsText.position.x = grade.length === 3 ? initialBorder + x * width + 55 : grade.length === 2 ? initialBorder + x * width + 60 : initialBorder + x * width + 68;
    columnsText.style.wordWrap = false;
    columns.push(columnsText);
  }

  return columns;
}

function setColumns(x) {
  let column = '';
  while (x >= 0) {
    const mod = x % 26;
    const modX = 65 + mod;
    column = String.fromCharCode(modX) + column;
    x = (x - mod - 1) / 26;
  }
  return column;
}

export function drawRows(positionsY) {
  const rows = [];

  for (const y of positionsY) {
    const grade = setRows(y);
    const initialBorder = border;

    const rowsText = new PIXI.Text(grade, rowsStyle);
    rowsText.resolution = 1.5;
    rowsText.position.x = initialBorder + 5;
    rowsText.position.y = initialBorder + (y * (height) + 50)+6;
    rowsText.style.wordWrap = true;
    rowsText.style.wordWrapWidth = 10;
    rows.push(rowsText);
  }
  return rows;
}

function setRows(y) {
  return (y + 1).toString();
}
