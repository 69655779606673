import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-table-header-column',
  templateUrl: './table-header-column.component.html',
  styleUrls: ['./table-header-column.component.scss']
})
export class TableHeaderColumnComponent implements OnInit {


  @Input() name;
  @Input() size = 'medium';
  @Input() styleType: 'old' | null;

  @HostBinding('className') className: string;

  constructor() { }

  ngOnInit(): void {
    this.className = [this.name, this.size, this.styleType].join(' ');
  }

}
