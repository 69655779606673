import { Injectable } from '@angular/core';
import {first} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {PickingService} from './picking.service';
import {PackingOutOrderDetailsComponent} from '../../modals/packing-out-order-details/packing-out-order-details.component';

@Injectable({
  providedIn: 'root'
})
export class PackingModalService {

  private openOutOrderDetailsLock = false;

  constructor(private dialog: MatDialog,
              private pickingService: PickingService) {
  }

  async openOutOrderDetails(outOrderNumber: string) {
    if(!this.openOutOrderDetailsLock){
      this.openOutOrderDetailsLock = true;
      const outOrder = await this.pickingService.getDetails(outOrderNumber);
      const dialogRef = this.dialog.open(PackingOutOrderDetailsComponent, {
        data: outOrder,
        panelClass: 'details-modal',
        autoFocus: false
      });
      dialogRef.afterOpened().pipe(first()).subscribe(() => {
        this.openOutOrderDetailsLock = false;
      }, error => {
        console.log(error)
      });
      return dialogRef;
    } else{
      return null;
    }
  }
}
