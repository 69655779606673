import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-background-icon',
  templateUrl: './background-icon.component.html',
  styleUrls: ['./background-icon.component.scss']
})
export class BackgroundIconComponent implements OnInit {

  @Input() icon;
  @Input() backgroundColor;
  @Input() size: 'normal' | 'small';

  constructor() { }

  ngOnInit(): void {
  }

}
