import { Component, OnInit } from '@angular/core';
import {ForkliftDataSourceService} from '../../core/data-sources/forklift-data-source.service';
import {TenantService} from '../../core/services/tenant.service';
import {TenantInterface} from '../../../models/Tenant';
import {HeatMap, HeatMapType} from '../../../models/HeatMap';

@Component({
  selector: 'app-forklifts',
  templateUrl: './forklifts.component.html',
  styleUrls: ['./forklifts.component.scss']
})
export class ForkliftsComponent implements OnInit {

  data: HeatMap[] = [];

  constructor(
    private tenantService: TenantService,
    private forkliftDataSourceService: ForkliftDataSourceService) {
    this.forkliftDataSourceService.getByType(HeatMapType.FORKLIFT_POSITION).then(heatMap => {
      this.data = heatMap;
    });
  }
  over(event){}

  async loadData(event){
    const values = JSON.parse(event);
    await this.forkliftDataSourceService.importByType(HeatMapType.FORKLIFT_POSITION, values);
  }

  async remove(){
    try{
      await this.forkliftDataSourceService.removeByType(HeatMapType.FORKLIFT_POSITION);
    } catch (error) {
      console.error(error);
    }
  }

  async download(){
    const res: any = await this.forkliftDataSourceService.getByType(HeatMapType.FORKLIFT_POSITION, true);
    const tenant: TenantInterface = await this.tenantService.get();
    const now = Date.now();
    const filename = `${tenant.id}-FORKLIFTS-${now}.json`;
    const pom = document.createElement('a');
    const bb = new Blob([JSON.stringify(res, null, 2)], {type: 'text/plain'});

    pom.setAttribute('href', window.URL.createObjectURL(bb));
    pom.setAttribute('download', filename);

    pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
    pom.draggable = true;
    pom.classList.add('dragout');

    pom.click();
  }

  ngOnInit(): void {
  }

}
