<app-side-color-card color="primary-1">
  <div class="holder" content>
    <h3 class="title">ADICIONAR NOVO SETOR</h3>
    <div class="form-section">
      <form [formGroup]="createSectorFormGroup">
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let sector of sectorOptions" [value]="sector.value">{{ sector.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="buttons">
          <button mat-raised-button class="primary-1" (click)="createSector()">criar</button>
          <button mat-stroked-button class="gray-1" (click)="reset()">cancelar</button>
        </div>
      </form>
    </div>
  </div>
</app-side-color-card>
