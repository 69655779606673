<app-page-header
  [back]="{label:'Estoque', link:'stock'}"
  title="Empilhadeiras"
></app-page-header>
<div class="content">
  <div class="buttons">
    <button mat-raised-button class="secondary-1" (click)="download()">download</button>

    <button mat-raised-button class="secondary-1" (click)="remove()">reset</button>


    <app-import-file-content-button
      (data)="loadData($event)"
    ></app-import-file-content-button>


  </div>
  <app-heat-map
    [data]="data"
    (over)="over($event)"
  ></app-heat-map>

</div>
