import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {toExcelFormat} from '../../helpers/format-position';

@Component({
  selector: 'app-picking-pallets-confirm-modal',
  templateUrl: './picking-pallets-confirm-modal.component.html',
  styleUrls: ['./picking-pallets-confirm-modal.component.scss']
})
export class PickingPalletsConfirmModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PickingPalletsConfirmModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  formatPosition(x, y) {
    return toExcelFormat(x, y);
  }

  close(submit?) {
    if (submit) {
      this.dialogRef.close(submit);
    } else {
      this.dialogRef.close();
    }
  }

}
