import * as PIXI from 'pixi.js';
import {toExcelFormat} from '../../../../helpers/format-position';
import {GroundPosition} from '../../../../shared/map-view/map-view.component';


const width = 50;
const height = 50;
const border = 10;
const titleStyleWhite = new PIXI.TextStyle({
  fontFamily: 'Roboto',
  fontSize: 12,
  fontWeight: 'bold',
  wordWrap: true,
  wordWrapWidth: 440,
  lineJoin: 'round',
});

const columnStyle = new PIXI.TextStyle({
  fontFamily: 'Roboto',
  fontSize: 14,
  fontWeight: 'bold',
  // fill: ['#454F63'], // gradient
  wordWrap: true,
  wordWrapWidth: 440,
  lineJoin: 'round',
});

const rowsStyle = new PIXI.TextStyle({
  fontFamily: 'Roboto',
  fontSize: 14,
  fontWeight: 'bold',
  fill: ['#454F63'], // gradient
  wordWrap: true,
  wordWrapWidth: 440,
  lineJoin: 'round',
});

export function getWidth(w) {
  return (w * width) + 50;
}
export function getHeight(h) {
  return (h * height) + 45;
}

export function drawGroundPosition(ground: GroundPosition) {
  const graphics = new PIXI.Graphics();
  let color;
  const initialBorder = border;
    color = 0x666666;
    graphics.beginFill(0xFFFFFF)
      .lineStyle(2, color === 0xFFFFFF ? 0x000000 : color, 1)
      .drawRoundedRect(initialBorder + ground.x * width + 50, initialBorder + ground.y * height + 45, width - 8, height - 8, 5)
      .endFill();

  graphics.hitArea = new PIXI.RoundedRectangle(ground.x * width + 50, initialBorder + ground.y * height  + 45, width -  8, height - 8, 5);
  const titleStyle = Object.assign({}, titleStyleWhite);
  titleStyle.fill = [color];

  const title = new PIXI.Text(toExcelFormat(ground.x, ground.y), titleStyle);

  if (toExcelFormat(ground.x, ground.y).length === 2) {
    title.x = initialBorder +  ground.x * width + 63;
  } else if (toExcelFormat(ground.x, ground.y).length === 3) {
    title.x = initialBorder + ground.x * width + 60;
  } else {
    title.x = initialBorder + ground.x * width + 55;
  }
  title.y = initialBorder + ground.y * height + 58;
  title.resolution = 2;

  return [graphics, title];
}
