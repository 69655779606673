import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {TenantInterface} from '../../../models/Tenant';

@Injectable({
  providedIn: 'root'
})
export class TenantDataSourceService {

  constructor(private httpClient: HttpClient) { }

  async get(): Promise<TenantInterface> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get(environment.baseUrl + '/api/v1/admin-desktop/tenant', { headers }).toPromise<any>();
  }

  async getErps(): Promise<any> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get(environment.baseUrl + '/api/v1/admin-desktop/tenant/erps', { headers }).toPromise<any>();
  }

  async getLevels(): Promise<any> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get(environment.baseUrl + '/api/v1/admin-desktop/tenant/levels', { headers }).toPromise<any>();
  }
}
