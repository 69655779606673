import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import {SessionService} from '../services/session.service';
import jwtDecode from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  domain;

  constructor(private sessionService: SessionService) {
    this.domain = /[^.]+/.exec(location.origin)[0].substr(8);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url !== environment.baseUrl + '/api/v1/auth/token' && req.url !== environment.baseUrl + `/api/v1/${this.domain}/tenantId`) {
      const tenant = this.getTenantId();
      const modifiedRequest = req.clone({headers: req.headers.append('tenant-id', tenant)});
      return next.handle(modifiedRequest);
    } else {
      return next.handle(req);
    }
  }

  getTenantId() {
    const session = this.sessionService.getSession();
    const decodedSession: any = jwtDecode(session.accessToken);
    return decodedSession.tenantId;
  }
}
