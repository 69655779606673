import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {CommentDataSourceService} from '../../core/data-sources/comment-data-source.service';
import {StockDataSourceService} from '../../core/data-sources/stock-data-source.service';
import {TaskRequestSidenavService} from '../../core/services/task-request-sidenav.service';
import {StockService} from '../../core/services/stock.service';

export interface StockDetailsInterface {
  item: {
    commentsQty: number;
    createdAt: number;
    id: string;
    position: string;
    section: string;
    palletStatus: string;
    skuGroups: {
      crateId: string,
      createdAt: number
      name: string,
      packSize: number,
      quantity: number,
      sku: string,
      updatedAt: number
    }[],
    state: string,
    tagRfid: string,
    tenantId: string,
    type: string,
    updatedAt: number,
    x: number,
    y: number,
    z: number
  };
  itemDetails: {
    comments: any[],
    crate: any,
    invoice: any,
    levels: any[],
    pathToEntry: any[],
    position: any,
  };
  page: number;
}





@Component({
  selector: 'app-stock-details-modal',
  templateUrl: './stock-details-modal.component.html',
  styleUrls: ['./stock-details-modal.component.scss']
})
export class StockDetailsModalComponent implements OnInit {

  selected = new FormControl(0);

  highlightedProducts = [];

  isCommentChange = false;
  isDestinyChange = false;

  products: {
    name: string;
    sku: string;
  }[];

  constructor(public dialogRef: MatDialogRef<StockDetailsModalComponent>,
              private sidenavService: TaskRequestSidenavService,
              @Inject(MAT_DIALOG_DATA) public data: StockDetailsInterface,
              private commentDataSource: CommentDataSourceService,
              private stockService: StockService,
              private stockDataSourceService: StockDataSourceService) { }

  ngOnInit(): void {
    this.parseProduct();
    this.selected.setValue(this.data.page);
    this.highlightedProducts = this.data.item.skuGroups.map(p => p.sku);
    this.data.itemDetails.comments = this.data.itemDetails.comments.filter((comment)=> !(comment.deletedAt != 0))
  }

  close(event?) {
    if (this.isCommentChange || this.isDestinyChange || event === 'SUBMITTED') {
      this.dialogRef.close('SUBMITTED');
    } else {
      this.dialogRef.close();
    }
  }

  async openSidenav() {
    this.dialogRef.close();
    const crate = this.data.itemDetails.crate;
    const productName = crate.skuGroups[0]?.name ?? 'nome não disponível';
    const data = {
      type: 'STOCK_RELOCATE_SINGLE_PALLET',
      rfId: crate.tagRfid,
      product: productName
    };
    await this.sidenavService.toggle(data).then( _ => {
      this.stockService.reload();
    });

  }

  async submitComment(event) {
    try {
      await this.commentDataSource.postStockComment(this.data.itemDetails.crate.skuGroups[0].crateId, event.comment, 'STORED');
    } catch (e) {
      console.log(e);
    } finally {
      await this.update();
      this.isCommentChange = true;
    }
  }

  async deleteComment(event) {
    try {
      await this.commentDataSource.deleteStockComment(this.data.itemDetails.crate.skuGroups[0].crateId, event);
    } catch (e) {
      console.log(e);
    } finally {
      await this.update();
      this.isCommentChange = true;
    }
  }

  async update() {
    this.data.itemDetails = await this.stockDataSourceService.getStockDetails(this.data.itemDetails.crate.tagRfid).then((r: any) => {
      r.invoice = r.crate.invoices[0];
      return r;
    });
  }

  parseProduct() {
    this.products = this.data.item.skuGroups.map(p => {
      return {
        name: p.name,
        sku: p.sku
      };
    });
  }
}
