<app-table-column name="sku" size="medium">
  <span content>{{ item.sku || '---' }}</span>
</app-table-column>
<app-table-column name="description" size="medium">
  <span content>{{ item.name || '---' }}</span>
</app-table-column>
<app-table-column name="expected-qty" size="small">
  <span content>{{ expectedQuantity || 0 }}</span>
</app-table-column>
<app-table-column name="found-qty" size="small">
  <span content>{{ foundQuantity || 0 }}</span>
</app-table-column>
<app-table-column name="balance" size="small">
  <span class="balance-background" [ngClass]="{'warning-background': isWarningBackground, 'alert-background': isAlertBackground}" content>
    {{ ( balance > 0 ? '+' + balance : balance ) || 0 }}
  </span>
</app-table-column>
<app-table-column name="status" size="medium">
  <span content>{{ palletState }}</span>
</app-table-column>