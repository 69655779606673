import {Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import { PickingStatusEnum } from '../picking.component';
import { ActionService, SetExecutionPlanAction } from '../../../core/services/action.service';
import { ActionsEnum } from '../../../core/data-sources/action-data-source.service';

@Component({
  selector: 'app-picking-received-out-order-item',
  templateUrl: './picking-received-out-order-item.component.html',
  styleUrls: ['./picking-received-out-order-item.component.scss']
})
export class PickingReceivedOutOrderItemComponent implements OnInit {

  @Input() item;
  @Input() disabled = false;

  @Input() showToolTip = false;
  @Input() toolTipMessage = '';

  @HostBinding('class.isOdd') @Input() isOdd;

  @Output() itemClick = new EventEmitter();
  @Output() processClick = new EventEmitter();

  date;



  constructor(private actionService: ActionService) { }

  ngOnInit(): void {
    this.date = moment(this.item?.issuedAt).format('DD/MM/YYYY - HH:mm');
  }

  @HostListener('click') onClick(){
    if(!this.disabled){
      this.itemClick.emit(this.item);
    }
  }

  translateStatus(status) {
    switch (status) {
      case PickingStatusEnum.UNAVAILABLE_PRODUCTS:
        return 'Produto indisponível';
      case PickingStatusEnum.INVALID:
        return 'Inválido';
      case PickingStatusEnum.NOT_ENOUGH_ON_STOCK:
        return 'Quantidade não suficiente em estoque';
      case PickingStatusEnum.OK:
        return 'Ordem recebida';
      case PickingStatusEnum.UNKNOWN:
        return '--';
      default:
        return status || '---';
    }
  }

  showTooltip() {
    if (this.showToolTip) {
      return this.toolTipMessage;
    }
    return null;
  }

  async processClickHandler($event) {
    this.processClick.emit(this.item);
    $event.stopPropagation();
  }
}
