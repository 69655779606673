import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InventoryTypesEnum } from 'src/app/core/services/action.service';
import { StockSortParams } from 'src/app/core/services/stock.service';
import { InventoryReportStateEnum } from 'src/models/Report';
import * as moment from 'moment';

@Component({
  selector: 'app-reports-table-header',
  templateUrl: './reports-table-header.component.html',
  styleUrls: ['./reports-table-header.component.scss']
})
export class ReportsTableHeaderComponent implements OnInit {
  @Input() appliedFilters = {};
  @Output() filterValues = new EventEmitter();
  showCheckIcon = {};
  sortDirection: "ASC" | "DESC";
  maxDate = new Date();
  sortParams: StockSortParams;
  stateValue = null;
  inventoryTypeValue = null;
  adminNameFormControl = new FormControl(null);
  dateFiltersForm = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null)
  });
  stateOptions: { label: string, value: InventoryReportStateEnum }[] = [
    { label: 'Aprovado', value: InventoryReportStateEnum.APPROVED },
    { label: 'Rejeitado', value: InventoryReportStateEnum.REJECTED },
    { label: 'Aguardando aprovação', value: InventoryReportStateEnum.AWAITING_ADMIN_APPROVAL},
  ];
  inventoryTypeOptions: { label: string, value: InventoryTypesEnum }[] = [
    { label: 'Inventário por setor', value: InventoryTypesEnum.INVENTORY_BY_GROUND_POSITION_GROUP },
    { label: 'Inventário por posição', value: InventoryTypesEnum.INVENTORY_BY_GROUND_POSITION },
    { label: 'Inventário por produto', value: InventoryTypesEnum.INVENTORY_BY_PRODUCT },
    { label: 'Inventário por pallet', value: InventoryTypesEnum.PRODUCT_INVENTORY_BY_CRATE_TAG_RFID },
    { label: 'Inventário por Tag RFID', value: InventoryTypesEnum.INVENTORY_BY_TAG_RFID },
  ];

  constructor() { }

  ngOnInit(): void {
  }

  applyFilters() {
    const startDate = this.dateFiltersForm.value.start ? moment(this.dateFiltersForm.value.start._d).valueOf() : null;
    const endDate = this.dateFiltersForm.value.end ? moment(this.dateFiltersForm.value.end._d).endOf('day').valueOf() : null;
    const params = {
      startDate: startDate,
      endDate: endDate,
      state: this.stateValue,
      type: this.inventoryTypeValue,
      adminName: this.adminNameFormControl.value,
    }
    this.removeNullKeys(params);
    this.filterValues.emit({
      filterParams: Object.keys(params).some(key => params[key]) ? params : null,
      sortParams: this.sortParams
    });
  }

  sort(orderBy: string, sortBy: "ASC" | "DESC") {
    this.sortDirection = sortBy;
    this.showCheckIcon[orderBy] = true;
    this.sortParams = { orderBy, sortBy };
  }

  clearFilters() {
    this.appliedFilters = {};
    this.showCheckIcon = {};
    this.stateValue = null;
    this.sortDirection = null;
    this.inventoryTypeValue = null;
    this.dateFiltersForm.reset();
    this.adminNameFormControl.reset();
    this.sortParams = null;
    this.filterValues.emit();
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      } else {
        this.setAppliedFilters(key);
      }
    });
  }

  setAppliedFilters(name) {
    switch (name) {
      case 'startDate':
      case 'endDate':
        this.appliedFilters['createdAt'] = true;
        break;
      default:
        this.appliedFilters[name] = true;
        break;
    }
  }

}
