<div class="page-header">
  <app-prev-next-button (click)="toDashboard()" direction="left" icon="estate"></app-prev-next-button>
  <h1>Mapa</h1>
</div>
<div *ngIf="this.fullLoaderService.isOpen$ | async">
  <app-full-page-loader></app-full-page-loader>
</div>
<div class="page-content" >
  <div class="left-side">
    <app-ground-types-aggregation [map]="map"></app-ground-types-aggregation>
    <app-warehouse-aggregation [map]="map"></app-warehouse-aggregation>
  </div>
  <div class="right-side">
    <app-side-color-card color="primary-1">
      <app-warehouse-map-task-position-view id="zz"
                                            content
                                            #mapComponent
                                            (mapLoaded)="mapLoaded()"
                                            (toggleTipEvent)="openTip = $event"
                                            [openTip]="openTip"
                                            [map]="map">
      </app-warehouse-map-task-position-view>
    </app-side-color-card>
  </div>
</div>

<ng-template #notification>
  <app-notification [data]="notificationData" icon="times-circle"></app-notification>
</ng-template>
