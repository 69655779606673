import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import centimetersToMeters from '../../../helpers/centimeters-to-meters';
import { translateState } from '../../../../models/Crate';
import formatDate from '../../../helpers/format-date';
import {toExcelFormat} from '../../../helpers/format-position';
import { findCrateLevel } from '../../../helpers/find-crate-level';

@Component({
  selector: 'app-stock-item-with-level',
  templateUrl: './stock-item-with-level.component.html',
  styleUrls: ['./stock-item-with-level.component.scss']
})
export class StockItemWithLevelComponent implements OnInit {

  @Input() crate;
  @Input() checked;
  @Output() clicked = new EventEmitter();
  formatDate = formatDate;
  translateState = translateState;
  toExcelFormat = toExcelFormat;
  centimetersToMeters = centimetersToMeters;
  findCrateLevel = findCrateLevel;

  constructor() { }

  ngOnInit(): void {
  }

  itemClicked(column: string){
    this.clicked.emit({column, crate: this.crate});
  }
}
