import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionService } from '../../../core/services/action.service';
import { TaskRequestSidenavService } from '../../../core/services/task-request-sidenav.service';
import { SidenavDestinationComponent } from '../../../shared/sidenav-destination/sidenav-destination.component';
import { SideNavResponse } from '../../stock/side-nav/SideNav';
import { SidenavFooterAction } from '../../../shared/sidenav-footer/sidenav-footer.component';

@Component({
  selector: 'app-relocate-load-dispatch-sidenav',
  templateUrl: './relocate-load-dispatch-sidenav.component.html',
  styleUrls: ['./relocate-load-dispatch-sidenav.component.scss']
})
export class RelocateLoadDispatchSidenavComponent implements OnChanges {

  @Input() drawer;
  @Input() task;

  selectedUsers = [];
  selectOperatorError = false;
  dataDestination;
  relocateForm: FormGroup;

  @ViewChild('destination', {static: true}) destination: SidenavDestinationComponent;

  constructor(private formBuilder: FormBuilder,
              private actionService: ActionService,
              private sidenavService: TaskRequestSidenavService) {
    this.relocateForm = this.formBuilder.group({
      rfid: ['', Validators.required],
      product: ['nome não definido'],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.task.currentValue){
      const {rfId, product} = changes.task.currentValue;
      this.relocateForm.controls.rfid.setValue(rfId);
      this.relocateForm.controls.product.setValue(product);
    }
  }

  destinationChanged($event){
    this.dataDestination = $event;
  }

  selectUsers(users) {
    this.selectedUsers = users;
    this.selectOperatorError = this.selectedUsers.length === 0;
  }

  reset() {
    this.relocateForm.reset();
    this.selectedUsers = [];
    this.selectOperatorError = false;
    this.destination.reset();
  }

  close(event: SideNavResponse = SideNavResponse.CLOSED) {
    this.reset();
    this.drawer.close();
    this.sidenavService.closeSidenav(event);
  }

  async footerClicked(event){
    if (event === SidenavFooterAction.SUBMMITED){
      this.relocateForm.markAllAsTouched();

      if(
        (this.dataDestination && this.dataDestination.valid) &&
        this.relocateForm.valid &&
        this.selectedUsers.length > 0
      ){
        await this.sendTask();
        this.close(SideNavResponse.SUBMMITED);
      } else{
        this.selectOperatorError = this.selectedUsers.length === 0;
      }

    } else{
      this.close(SideNavResponse.CLOSED);
    }
  }

  async sendTask() {
    const {data: destination} = this.dataDestination;
    const params = {
      type: 'LOAD_DISPATCH',
      userIds: this.selectedUsers,
      tagsRfid: [this.relocateForm.controls.rfid.value],
      taskData: {
        destiny: {
          x: destination.address.x,
          y: destination.address.y
        }
      }
    };
    await this.actionService.bulkSendTask(params);
  }
}
