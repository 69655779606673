import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {InspectionComponentStatusOptions} from '../inspection.component';

@Component({
  selector: 'app-inspection-status-filter',
  templateUrl: './inspection-status-filter.component.html',
  styleUrls: ['./inspection-status-filter.component.scss']
})
export class InspectionStatusFilterComponent implements OnInit {

  @Output() statusSelected = new EventEmitter();
  selectedStatus = InspectionComponentStatusOptions.NONE;

  constructor() { }

  ngOnInit(): void {
  }

  selectOk() {
    this.selectStatus(InspectionComponentStatusOptions.OK);
  }
  selectSurplus() {
    this.selectStatus(InspectionComponentStatusOptions.SURPLUS);
  }
  selectMissing() {
    this.selectStatus(InspectionComponentStatusOptions.MISSING);
  }

  selectStatus(status){
    if (this.selectedStatus === status && status !== InspectionComponentStatusOptions.NONE){
      this.selectedStatus = InspectionComponentStatusOptions.NONE;
    } else{
      this.selectedStatus = status;
    }
    this.statusSelected.emit(this.selectedStatus);
  }
}
