<app-header-modal [color]="'secondary-2'" title="Dados do Produto">
  <div class="status-header" status>
    <app-status-header-modal [icon]="'check'" [color]="'success'" [status]="'Conferido'"></app-status-header-modal>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>

<mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedTab"
               (selectedIndexChange)="selectedTab = $event">

  <mat-tab label="Produto">
    <app-product-image-tab [products]="products"></app-product-image-tab>
  </mat-tab>
  <mat-tab label="Posições">
    <app-pallets-details [product]="data.item"
                         (relocate)="openRelocateSidenav($event)"
                         (clearPositionEvent)="openConfirmClearPositionModal($event)">
    </app-pallets-details>
  </mat-tab>
<!--  <mat-tab label="Movimentações">-->
<!--    <app-movement-details [crateId]="data.itemDetails.crate.id"></app-movement-details>-->
<!--  </mat-tab>-->
<!--  <mat-tab label="Comentários">-->
<!--    <app-in-details-comments [itemDetails]="data"-->
<!--                             (submitEvent)="submitComment($event)"-->
<!--                             (deleteEvent)="deleteComment($event)"></app-in-details-comments>-->
<!--  </mat-tab>-->
</mat-tab-group>
