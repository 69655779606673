import { StockDataSourceService } from './../../core/data-sources/stock-data-source.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pallet } from '../stock-levels-model/stock-levels-modal.component';

interface MapPositionDetailsModalDataInterface {
  alias?: string,
  position?: string;
  selectedTabIndex?: number;
  scrollTo?: string;
  type?: 'column' | 'row';
  rfId?: string;
}

@Component({
  selector: 'app-map-position-details-modal',
  templateUrl: './map-position-details-modal.component.html',
  styleUrls: ['./map-position-details-modal.component.scss']
})
export class MapPositionDetailsModalComponent implements OnInit {
  title = '';
  isPallet = false;
  isPosition = true;
  palletDetails;
  pallet: Pallet;
  constructor(
    public dialogRef: MatDialogRef<MapPositionDetailsModalComponent>,
    private stockDataSourceService: StockDataSourceService,
    @Inject(MAT_DIALOG_DATA) public data: MapPositionDetailsModalDataInterface
  ) { }

  ngOnInit(): void {
    this.setTitle(this.data.alias, this.data.position);
  }

  async onChangeToPallet(pallet: Pallet) {
    this.pallet = pallet;
    const itemDetails = await this.stockDataSourceService.getStockDetails(pallet.tagRfid).then((r: any) => {
      r.invoice = r.crate.invoices[0];
      return r;
    });
    this.palletDetails = {
      item: pallet,
      itemDetails
    };
    this.isPallet = true;
    this.isPosition = false;
    this.setTitle(null, null);
  }

  goBackToMap() {
    this.isPosition = true;
    this.isPallet = false;
    this.data.selectedTabIndex = 1;
    this.data.scrollTo = this.pallet.tagRfid;
    this.setTitle(this.data.alias, this.data.position);
  }

  close() {
    this.dialogRef.close();
  }

  setTitle(alias?: string, name?: string) {
    if (this.isPallet) {
      this.title = 'Dados Do Pallet';
      return
    }
    switch (this.data.type) {
      case 'column':
        this.title = `Coluna ${name}`;
        break;
      case 'row':
        this.title = `Linha ${name}`;
        break;
      default:
        this.title = alias ?? '---';
        break;
    }
  }

  onRelocateClicked($event) {
    this.dialogRef.close({
      openRelocateSidenav: true,
      crate: $event.crate
    });
  }

}
