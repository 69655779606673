import { Injectable } from '@angular/core';
import {Session, SessionService} from './session.service';
import {BehaviorSubject} from 'rxjs';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLoggedIn$ = new BehaviorSubject(false);

    constructor(private sessionService: SessionService) { }

  isAtRenewalThreshold(){
    const session: Session = this.sessionService.getSession();
    const expires = session.exp;
    const delta: number = expires / 10;
    const now = +Date();
  }

  isLoggedIn() {
    const session: Session = this.sessionService.getSession();
    if (!session) { return false; }

    try{
      jwtDecode(session.accessToken);
      return true;
    } catch (e) {
      return false;
    }
  }

}
