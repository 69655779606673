import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TaskRequestSidenavService} from '../../../core/services/task-request-sidenav.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StockDataSourceService} from '../../../core/data-sources/stock-data-source.service';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ActionService} from '../../../core/services/action.service';
import {toExcelFormat} from '../../../helpers/format-position';
import { ProductsInterface} from '../stock-inventory-sidenav/stock-inventory-sidenav.component';
import { GroundGroupsService } from '../../../core/services/ground-groups.service';

@Component({
  selector: 'app-stock-relocate-sidenav',
  templateUrl: './stock-relocate-sidenav.component.html',
  styleUrls: ['./stock-relocate-sidenav.component.scss']
})
export class StockRelocateSidenavComponent implements OnInit, OnDestroy {

  @Input() drawer;
  @Input() data;
  @Output() changed = new EventEmitter();
  @ViewChild('groundGroupAuto') groundGroupAuto: any;

  isCritical = false;

  usersHighlighted = [];
  productInputSubscription: Subscription;
  resetInputSubscription: Subscription;
  products: ProductsInterface[];
  isProductChosen = false;
  hasPickingCrates = false;
  sectorOptions = [];
  addressOptions = [];
  palletsOptions = [];
  crateForm: FormGroup;

  selectOperatorError = false;
  noPalletsError = false;
  destinationData;
  selectedTab: number | null = 0;

  pendingTask;
  excelToXY = new Map();

  constructor(private sidenavService: TaskRequestSidenavService,
              private stockService: StockDataSourceService,
              private groundGroupsService: GroundGroupsService,
              private actionService: ActionService,
              private formBuilder: FormBuilder) {
  }

  async ngOnInit(): Promise<void> {
    this.buildCrateForm();
    this.resetInputSubscription = this.crateForm.controls.productSku.valueChanges.subscribe(() => {
      if (this.isProductChosen) {
        this.isProductChosen = false;
        this.crateForm.controls.productSku.reset();
      }
    });
    this.productInputSubscription = this.crateForm.controls.productSku.valueChanges.pipe(debounceTime(250))
      .subscribe(async (newValue) => {
        this.products = await this.stockService.getSearchedProducts(newValue);
        if (newValue?.sku) {
          this.isProductChosen = true;
          await this.getPallets(newValue.sku);
          this.handlePickingCrates();
        } else {
          this.hasPickingCrates = false;
          this.palletsOptions = [];
        }
      });
  }

  ngOnDestroy() {
    this.productInputSubscription.unsubscribe();
    this.resetInputSubscription.unsubscribe();
  }

  buildCrateForm() {
    this.crateForm = this.formBuilder.group({
      productSku: ['', Validators.required],
      pallets: [[], Validators.required],
      quantity: ['']
    });
  }

  setUsers(users) {
    this.usersHighlighted = users;
  }

  async getPallets(sku) {
    this.palletsOptions = await this.stockService.getPallets(sku);
    this.noPalletsError = this.palletsOptions.length === 0;
  }

  handlePickingCrates() {
    this.hasPickingCrates = this.palletsOptions.some(el => el.groundPosition.type === 'PICKING');
    this.hasPickingCrates ? this.selectedTab = 0 : this.selectedTab = null;
  }

  displayedString(product) {
    if (product) {
      return product.name + ' - ' + product.sku;
    } else {
      return null;
    }
  }

  onCriticalChange(isChecked) {
    this.isCritical = isChecked;
  }

  handlePosition(x, y) {
    return toExcelFormat(x, y);
  }

  handleQuantity(skuGroups) {
    let quantity = 0;
    for (const p of skuGroups) {
      quantity = (p.quantity * p.packSize) + quantity;
    }
    return quantity;
  }

  async bulkSendTask() {
    const tagsRfid = [];
    for (const p of this.crateForm.controls.pallets.value) {
      tagsRfid.push(p.tagRfid);
    }
    const { data: destination } = this.destinationData;
    const params = {
      type: 'RELOCATE_POSITION',
      userIds: this.usersHighlighted,
      tagsRfid,
      taskData: {
        destiny: {
          x: destination.address.x,
          y: destination.address.y
        }
      }
    };
    if (this.isCritical) {
      await this.actionService.bulkSendCriticalTask(params);
    } else {
      await this.actionService.bulkSendTask(params);
    }
  }

  resetForms() {
    this.crateForm.reset();
    this.usersHighlighted = [];
    this.palletsOptions = [];
    this.isCritical = false;
    this.noPalletsError = false;
    this.selectOperatorError = false;
  }

  setErrors() {
    this.selectOperatorError = !this.usersHighlighted.length;
    this.noPalletsError = !this.palletsOptions.length;
  }

  async submit() {
    this.crateForm.markAllAsTouched();
    this.setErrors();
    if (this.crateForm.valid && this.usersHighlighted.length && !this.noPalletsError) {
      this.selectOperatorError = false;
      this.noPalletsError = false;
      await this.bulkSendTask();
      this.sidenavService.closeSidenav(true);
      this.drawer.close();
      this.resetForms();
    }
  }

  async close(event?) {
    this.resetForms();
    this.sidenavService.closeSidenav(false);
    this.drawer.close();
  }

  destinationChanged(event) {
    this.destinationData = event;
  }
}
