import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-picking-table-header',
  templateUrl: './product-picking-table-header.component.html',
  styleUrls: ['./product-picking-table-header.component.scss']
})
export class ProductPickingTableHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
