<div class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Separação" (closeEvent)="close()"></app-sidenav-header>

    <app-sidenav-card title="Escolha o destino das tarefas">
      <div content>
        <app-sidenav-destination #destination [groundType]="'EXPEDITION'"
                                 (changed)="destinationChanged($event)"
        ></app-sidenav-destination>
      </div>

    </app-sidenav-card>

    <section>
    <app-sidenav-card title="Pallets fechados">
      <div content>
        <p>Pallets fechados que vão ser levados para a expedição diretamente do estoque</p>
      </div>
    </app-sidenav-card>
    <div class="pallets-list">
      <p>Lista de Pallets Fechados</p>
      <div class="header">
        <div class="rfid column">Tag RFID</div>
        <div class="position column">Posição</div>
        <div class="products column">Produtos</div>
        <div class="quantity column">Qtd</div>
      </div>

      <div class="item" *ngFor="let pallet of task?.pallets; let odd = odd" [ngClass]="{'isOdd': odd}">
        <div class="rfid column"> - </div>
        <div class="position column"> - </div>
        <div class="products column">
          <div class="pallet-product">{{ pallet.sku }}</div>
        </div>
        <div class="quantity column">{{ pallet.quantity }}</div>
      </div>
      <!--        <div class="rfid column">{{ pallet.tagRfid }}</div>-->
      <!--        <div class="position column">{{ formatPosition(pallet.x, pallet.y) }}</div>-->
      <!--        <div class="products column">-->
      <!--          <div class="pallet-product" *ngFor="let product of pallet.sku">{{ product.name }}</div>-->
      <!--        </div>-->
      <!--        <div class="quantity column" *ngFor="let product of details.order.products">{{ product.quantity }}</div>-->
      <!--      </div>-->
      <div class="empty" *ngIf="!task?.pallets?.length">NÃO EXISTEM PALLETS FECHADOS PARA ESTA ORDEM.</div>
    </div>

      <p class="error" *ngIf="selectRelocateOperatorError">Selecione um ou mais operadores para atribuir esta tarefa.</p>

      <app-operators-table device="FORKLIFT" *ngIf="task?.pallets?.length"
                         [usersHighlighted]="relocateUsersHighlighted"
                         (usersHighlightedEvent)="setRelocateUsers($event)"
                         [openedStart]="drawer.openedStart"></app-operators-table>

    </section>

    <section>
      <app-sidenav-card title="Picking">
        <div content>
          <p>Produtos serão pegos no picking e colocados em pallets para serem levados para a expedição.</p>
        </div>
      </app-sidenav-card>

      <div class="products-list">
        <p>Produtos que irão ser pegos no Picking</p>
        <div class="header">
          <div class="sku column">SKU</div>
          <div class="position column">Posição</div>
          <div class="quantity column">Qtd</div>
        </div>

        <div class="item" *ngFor="let step of task?.steps; let odd = odd" [ngClass]="{'isOdd': odd}">
          <div class="sku column">{{ step.sku }}</div>
          <div class="position column">{{ formatPosition(step.x, step.y) }}</div>
          <div class="quantity column">{{ step.quantity }}</div>
        </div>
        <div class="empty" *ngIf="!task?.steps.length">ESTA ORDEM NAO PRECISA DE PRODUTOS DO PICKING PARA SER CUMPRIDA.</div>
      </div>

      <p class="error" *ngIf="selectPickingOperatorError">Selecione um ou mais operadores para atribuir esta tarefa.</p>

      <app-operators-table device="GLOVE" *ngIf="task?.steps?.length"
                           [usersHighlighted]="pickingUsersHighlighted"
                           (usersHighlightedEvent)="setPickingUsers($event)"
                           [openedStart]="drawer.openedStart"></app-operators-table>
    </section>
  </div>
  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()">OK</button>
  </div>
</div>
