import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {GroundPosition, MapViewComponent} from '../../../../shared/map-view/map-view.component';
import {
  getHeight,
  getWidth
} from '../../setup-ground/ground-groups-map/ground-cell';
import {drawGroundPosition} from './ground-cell';

@Component({
  selector: 'app-ground-position-tag-map',
  templateUrl: './ground-position-tag-map.component.html',
  styleUrls: ['./ground-position-tag-map.component.scss']
})
export class GroundPositionTagMapComponent extends MapViewComponent implements OnInit, OnChanges{

  @Input()
  public map;

  public mouseX;
  public mouseY;

  public tooltipValue;
  public showTooltip;
  public timeout;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @HostListener('mousemove', ['$event']) onMouseMove(event) {
    const canvas = (this.elementRef.nativeElement as HTMLElement).querySelector('canvas');
    const canvasTop = canvas.getBoundingClientRect().top;
    const canvasLeft = canvas.getBoundingClientRect().left;
    this.mouseX = event.clientX - canvasLeft; // x position within the element.
    this.mouseY = event.clientY - canvasTop;
  }

  constructor(
    protected elementRef: ElementRef,
    protected ngZone: NgZone
  ) {
    super(elementRef, ngZone);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (!changes.map?.previousValue && changes.map?.currentValue){
      const gradePositions = this.getMaxCoordinates(changes.map.currentValue);
      const maxWidth = getWidth(gradePositions.positionsX.length);
      const maxHeight = getHeight(gradePositions.positionsY.length);
      this.initMap(maxWidth, maxHeight);
      this.drawMap(changes.map.currentValue);
    }
    else if (changes.map?.currentValue){
      this.drawMap(changes.map.currentValue);
    }
  }

  ngOnInit(): void {

  }

  public drawMap(groundPositions: GroundPosition[]) {
    if (groundPositions) {
      this.drawGrades(groundPositions);
      this.drawDefaultMap(groundPositions);
      this.scrollBox.update();
    }
  }





  drawDefaultMap(groundPositions: GroundPosition[]) {
    this.clearContent();
    for (const groundPosition of groundPositions) {
      let elements;
      let area;
      elements = drawGroundPosition(groundPosition);
      area = elements[0];
      area.interactive = true;
      area.on('pointerover', () => {
          if(groundPosition.rfId){
            this.tooltipValue = groundPosition.rfId;
            this.showTooltip = true;
            if (this.timeout){
              clearTimeout(this.timeout);
            }
        }
        });
        area.on('pointerout', () => {

          this.timeout = setTimeout(() => {
            this.showTooltip = false;
            this.tooltipValue = '';
            this.timeout = null;
          }, 50);
        });


      for (const e of elements) {
        // @ts-ignore
        this.viewPort.addChild(e);
      }
    }
  }

}
