<app-header-modal [color]="'secondary-2'" title="{{title}}">
  <div icon *ngIf="isPallet">
    <i class="uil uil-arrow-left" (click)="goBackToMap()"></i>
  </div>
  <div class="status-header" status *ngIf="isPallet">
    <app-status-header-modal [icon]="'check'" [color]="'success'" [status]="'Conferido'"></app-status-header-modal>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>
<app-stock-levels-modal *ngIf="isPosition"
  [data]="data"
  (changeToPallet)="onChangeToPallet($event)"
  (closeModal)="close()">
</app-stock-levels-modal>
<app-map-pallet-details *ngIf="isPallet"
  [data]="palletDetails"
  (relocateClicked)="onRelocateClicked($event)">
</app-map-pallet-details>

