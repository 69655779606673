import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

function getGroundTypeNameFromType(type: string){
  switch (type) {
    case 'STOCK':
      return 'estoque';
    case 'DOCK':
      return 'doca';
    case 'EXPEDITION':
      return 'doca';
    case 'QUALITY_CONTROL':
      return 'constrole de qualidade';
    case 'DIVERGENT_STOCK':
      return 'estoque divergente';
    case 'STREET':
      return 'rua';
    case 'DAMAGES':
      return 'doca';
    case 'PICKING':
      return 'picking';
    case 'PROVISION':
      return 'provisão';
    case 'IN':
      return 'entrada';
    default:
      return '-';
  }
}



@Component({
  selector: 'app-ground-types-aggregation',
  templateUrl: './ground-types-aggregation.component.html',
  styleUrls: ['./ground-types-aggregation.component.scss']
})
export class GroundTypesAggregationComponent implements OnInit, OnChanges{

  @Input() map;
  lines = [];
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.map.currentValue){
      this.mapToAggregation(changes.map.currentValue);
    }
  }

  mapToAggregation(maps){
    const groups = {};
    this.lines = [];
    for (const m of maps){
      if (m.groupId){
        if (!groups[m.groupId]){
          groups[m.groupId] = {
            id: m.groupId,
            name: m.groupName,
            slots: 0,
            occupied_slots: 0,
            type: m.type
          };
        }

        groups[m.groupId].slots = groups[m.groupId].slots + 1;
        if (m.hasPallet){
          groups[m.groupId].occupied_slots = groups[m.groupId].occupied_slots + 1;
        }
      } else{
        //if does not have a group, group as nameless by ground type
        if (!groups[m.type]){
          groups[m.type] = {
            id: m.type,
            name: 'Sem grupo (' + getGroundTypeNameFromType(m.type)+')',
            slots: 0,
            occupied_slots: 0,
            type: m.type
          };
        }
        groups[m.type].slots = groups[m.type].slots + 1;
        if (m.hasPallet){
          groups[m.type].occupied_slots = groups[m.type].occupied_slots + 1;
        }
      }

    }
    for (const g in groups){
      const occupation = groups[g].occupied_slots / groups[g].slots;
      this.lines.push({
          name: groups[g].name ?? '-',
          slots: groups[g].slots,
          type: groups[g].type,
          occupation,
      });
    }
    this.lines = this.lines.sort((a,b)=>(a.type+a.name).localeCompare(b.type+b.name));
  }



}
