<div class="page-header">
  <app-prev-next-button (click)="toGroundGroups()" [direction]="'left'" label="Setores"></app-prev-next-button>
  <h1>{{groupName || "Detalhes do Setor"}}</h1>
</div>

<button class="open-form-button" mat-raised-button (click)="openModal()">
  adicionar nível
</button>

<app-side-color-card color="primary-1">
  <div content class="table">
    <div class="item"  *ngIf="levels?.length === 0">
      Não existem níveis cadastrados neste setor.
    </div>

<div class="header" *ngIf="levels?.length !== 0">
  <div class="column">ordem</div>
  <div class="column">nome</div>
  <div class="column">começo</div>
  <div class="column">referência</div>
  <div class="column">fim</div>
  <div class="column edit-sector"></div>
</div>

<div class="item"
     *ngFor="let level of levels; let odd = odd"
     [ngClass]="{'background-opacity': odd}">
  <div class="column">
    {{ level.order}}
  </div>
  <div class="column">
    {{ level.name}}
  </div>
  <div class="column">
    {{ level.start}} cm
  </div>
  <div class="column">
    {{ level.reference}} cm
  </div>
  <div class="column">
    {{ level.end}} cm
  </div>

  <div class="column edit-sector">
    <button mat-raised-button class="alert" (click)="openConfirmRemoveModal(level)">
      remover
    </button>
  </div>
</div>
  </div>
</app-side-color-card>
