import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CrateStateEnum } from '../../../../models/Crate';
import { StockFilterParams } from '../../../core/services/stock.service';
import { getCoordinates } from '../../../helpers/format-position';

export interface StockFiltersSection {
  productCode: string;
  orderNumber: string;
  quantity: number;
  productType: string;
  vendor: string;
  storage: string;
  dateRange: {
    startDate: Date,
    endDate: Date
  };
}

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-stock-filters-section',
  templateUrl: './stock-filters-section.component.html',
  styleUrls: ['./stock-filters-section.component.scss'],
  providers: [{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}]
})
export class StockFiltersSectionComponent implements OnInit {

  @Output() valuesToFilter = new EventEmitter();
  @Input() valuesApplied;

  productNameSearchFormControl: FormControl;
  productSkuSearchFormControl: FormControl;
  sectionSearchFormControl: FormControl;
  levelNameSearchFormControl: FormControl;
  addressFormControl: FormControl;
  dateRangeSearchFormGroup: FormGroup;

  stateValue = null;
  storageValue;

  stateOptions: { label: string; value: CrateStateEnum }[] = [
    { label: 'Armazenado', value: CrateStateEnum.STORED },
    { label: 'Armazenado e divergente', value: CrateStateEnum.STORED_DIVERGENT },
    { label: 'Posição errada', value: CrateStateEnum.WRONG_POSITION },
    { label: 'Aguardando armazenamento', value: CrateStateEnum.AWAITING_STORAGE },
    { label: 'Aguardando separação', value: CrateStateEnum.AWAITING_PICKING },
    { label: 'Aguardando movimentação', value: CrateStateEnum.AWAITING_MOVING },
    { label: 'Movimentando', value: CrateStateEnum.MOVING },
    { label: 'Deslocado', value: CrateStateEnum.MISPLACED },
    { label: 'Deslocado e divergente', value: CrateStateEnum.MISPLACED_DIVERGENT },
    { label: 'Perdido', value: CrateStateEnum.MISSING },
    { label: 'Reservado', value: CrateStateEnum.RESERVED }
  ];

  quantityCount = 0;

  constructor() {
    this.productNameSearchFormControl = new FormControl();
    this.productSkuSearchFormControl = new FormControl();
    this.sectionSearchFormControl = new FormControl();
    this.levelNameSearchFormControl = new FormControl();
    this.addressFormControl = new FormControl(null, Validators.pattern(/^[A-Za-z]{1,3}\d{1,7}$/));
    this.dateRangeSearchFormGroup = new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.setAppliedValues();
  }

  setAppliedValues() {
    if (this.valuesApplied?.quantity) {
      this.quantityCount = this.valuesApplied.quantity;
    }
    if (this.valuesApplied?.sku) {
      this.productSkuSearchFormControl.setValue(this.valuesApplied.sku);
    }
    if (this.valuesApplied?.state) {
      this.stateValue = this.valuesApplied.state;
    }
  }

  changeQuantity(operator) {
    if (operator === 'PLUS') {
      return this.quantityCount++;
    } else if (operator === 'MINUS' && this.quantityCount > 0) {
      return this.quantityCount--;
    }
  }

  clear() {
    this.stateValue = null;
    this.storageValue = null;
    this.dateRangeSearchFormGroup.reset();
    this.productSkuSearchFormControl.reset();
    this.productNameSearchFormControl.reset();
    this.addressFormControl.reset();
    this.levelNameSearchFormControl.reset();
    this.sectionSearchFormControl.reset();
    this.quantityCount = 0;
    this.valuesToFilter.emit();
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      }
    });
  }

  apply() {
    let x = null;
    let y = null;
    if (this.addressFormControl.invalid) {
      return;
    } else if (this.addressFormControl.value) {
      x = getCoordinates(this.addressFormControl.value.toUpperCase()).x;
      y = getCoordinates(this.addressFormControl.value.toUpperCase()).y - 1;
    }

    const startDate = !this.dateRangeSearchFormGroup.value.startDate ?
      null : moment(this.dateRangeSearchFormGroup.value.startDate).valueOf();
    const endDate = !this.dateRangeSearchFormGroup.value.endDate ?
      null : moment(this.dateRangeSearchFormGroup.value.endDate).valueOf();

    const params: StockFilterParams = {
      sku: this.productSkuSearchFormControl.value,
      name: this.productNameSearchFormControl.value,
      section: this.sectionSearchFormControl.value,
      levelName: this.levelNameSearchFormControl.value,
      state: this.stateValue,
      x,
      y,
      quantity: this.quantityCount !== 0 ? this.quantityCount : null,
      startDate,
      endDate
    };
    this.removeNullKeys(params);
    this.valuesToFilter.emit(params);
  }
}
