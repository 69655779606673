import { Component, OnInit } from '@angular/core';
import { PendingTask, PendingTaskService } from '../../core/data-sources/pending-task.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pending-tasks',
  templateUrl: './pending-tasks.component.html',
  styleUrls: ['./pending-tasks.component.scss']
})
export class PendingTasksComponent implements OnInit {

  pendingTasks: PendingTask[] = [];
  isLoading: boolean;
  pendingTaskOrigin = {
    link: '',
    label: ''
  };

  constructor(private pendingTaskService: PendingTaskService, private activatedRoute: ActivatedRoute) {
    this.getPendingTask();
    this.pendingTaskOrigin.link = activatedRoute.snapshot.queryParams.origin;
    this.pendingTaskOrigin.label = activatedRoute.snapshot.queryParams.label;
  }

  ngOnInit(): void {
  }

  update() {
    this.getPendingTask();
  }

  async getPendingTask() {
    this.isLoading = true;
    try {
      const pendingTasks = await this.pendingTaskService.getPendingTasks();
      this.pendingTasks = pendingTasks;
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

}
