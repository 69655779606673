<div class="page-header">
  <app-prev-next-button (click)="toSetup()" direction="left" label="setup"></app-prev-next-button>
  <h1>Mapa de altura</h1>
</div>

<div class="page-content">



  <app-side-color-card color="picking">
    <app-setup-level-card
      [levels]="levels"
      title="NÍVEIS DO ARMAZÉM (geral)"
      (onRemove)="onRemoveLevelFromTenant($event)"
      (onCreate)="onCreateLevelForTenant($event)"
      content></app-setup-level-card>
  </app-side-color-card>

  <div class="section-header">
    <h2>Setores</h2>
  </div>

  <app-side-color-card color="picking" class="group" *ngFor="let group of groups">
    <div content class="group-content">
      <div class="group-line" [ngClass]="{'open':group.levelsEnabled}">
        <div class="label" *ngIf="!group.levelsEnabled">
          {{group.title}}
        </div>
        <div class="ctrl">
          <div>usar níveis próprios</div>
          <mat-slide-toggle
            class="example-margin"
            (change)="enableGroupLevel($event, group.id)"
            [checked]="group.levelsEnabled"
            [disabled]="false">
          </mat-slide-toggle>
        </div>
      </div>

      <app-setup-level-card *ngIf="group.levelsEnabled"
        [levels]="group.levels"
        [title]="group.title"
        [id]="group.id"
        (onRemove)="onRemoveLevelFromGroup($event)"
        (onCreate)="onCreateLevelForGroup($event)"
        ></app-setup-level-card>
    </div>
  </app-side-color-card>
  <div class="buttons">
    <button mat-raised-button (click)="finish()">
      Ir para próximo passo
    </button>
  </div>

</div>
