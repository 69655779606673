<app-header-modal [color]="'secondary-2'" title="Detalhes da ordem">
  <i class="uil uil-times" (click)="close()" status></i>
</app-header-modal>

<div class="content">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="Detalhes de Ordem de Venda">
      <div class="up-modal">
        <div class="left">
          <div class="column">
            <app-info-oc-or-nfe label="Nº Nota Fiscal/Série" [value]="data?.number ? data.number : '---' "></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="Razão Social" [value]="data?.client?.name ? data.client.name : '---' " ></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="Endereço" [value]="data?.address?.street ? data.address.street : '---' "></app-info-oc-or-nfe>
          </div>
          <div class="column two">
            <app-info-oc-or-nfe label="Data/Hora de Emissão" [value]="timestampToDate(data?.issuedAt)"></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="CPF/CNPJ Fornecedor" [value]="data?.client?.doc ? data.client.doc : '---' "></app-info-oc-or-nfe>
          </div>
        </div>

        <div class="right">
          <app-provider-contact [provider]="{
                          name: data.client?.name ? data.client.name : '---',
                          phone: data.client?.phone ? data.client.phone : '---',
                          email: data.client?.email ? data.client.email : '---'
                        }">
          </app-provider-contact>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Pallets">
      <mat-tab-group>
        <mat-tab *ngFor="let crate of data.crates" [label]="crate.tagRfid">
          <div class="wrapper">
            <div class="upper-content">
              <div class="first-column">
                <app-info-oc-or-nfe label="Tag RFID" [value]="crate.tagRfid"></app-info-oc-or-nfe>
                <app-info-oc-or-nfe label="Estado (crateState)" [value]="crate.state"></app-info-oc-or-nfe>
                <app-info-oc-or-nfe label="Posição" [value]="setExcelFormat(crate.x, crate.y)"></app-info-oc-or-nfe>
              </div>
              <div class="second-column">
                <app-info-oc-or-nfe label="X" [value]="crate.x"></app-info-oc-or-nfe>
                <app-info-oc-or-nfe label="Y" [value]="crate.y"></app-info-oc-or-nfe>
                <app-info-oc-or-nfe label="Z" [value]="crate.z"></app-info-oc-or-nfe>
                <app-info-oc-or-nfe label="Nível" [value]="level"></app-info-oc-or-nfe>
              </div>
            </div>
          </div>
          <div class="table">
            <app-table-header>
              <app-table-header-column name="name">
                <span content>Nome</span>
              </app-table-header-column>
              <app-table-header-column name="sku">
                <span content>SKU</span>
              </app-table-header-column>
              <app-table-header-column name="description">
                <span content>Descrição</span>
              </app-table-header-column>
              <app-table-header-column name="quantity">
                <span content>Quantidade</span>
              </app-table-header-column>
            </app-table-header>

            <app-table-items>
              <app-table-item *ngFor="let p of crate?.details?.crate.skuGroups; let odd = odd" [isOdd]="odd">
                <app-table-column name="name">
                  <span content>{{ p.name || '-' }}</span>
                </app-table-column>
                <app-table-column name="sku">
                  <span content>{{ p.sku || '-' }}</span>
                </app-table-column>
                <app-table-column name="description">
                  <span content>{{ p.description || '-' }}</span>
                </app-table-column>
                <app-table-column name="quantity">
                  <span content>{{ p.quantity || '-' }}</span>
                </app-table-column>
              </app-table-item>
              <div class="empty-table" *ngIf="!crate?.details?.crate?.skuGroups?.length">
                NÃO EXISTEM PRODUTOS.
              </div>
            </app-table-items>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>

    <mat-tab label="Detalhes dos produtos">
      <app-product-image-tab [products]="data?.products"></app-product-image-tab>
      <div class="empty-products" *ngIf="!data?.products?.length">
        NÃO EXISTEM PRODUTOS.
      </div>
    </mat-tab>

    <mat-tab label="Movimentações">
      <app-movement-details *ngFor="let c of data.crates"
        [crateId]="c.id"
        title="Tag RFID: {{c.tagRfid}} | SKU: {{c.sku ? c.sku : c.pickingProductsSku}}">
      </app-movement-details>
    </mat-tab>

  </mat-tab-group>
</div>
