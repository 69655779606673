<app-header-modal [color]="'secondary-2'" title="Dados de Usuário">
    <div class="status-header" status>
        <i class="uil uil-times" (click)="close()"></i>
    </div>
</app-header-modal>

<div class="container">
    <div class="upper-content">
        <div class="first-column">
            <app-info-oc-or-nfe label="Nome" [value]="data.user.firstName"></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="sobrenome" [value]="data.user.lastName"></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="Nome de Usuário" [value]="data.user.username"></app-info-oc-or-nfe>
        </div>
        <div class="second-column">
            <app-info-oc-or-nfe label="Email" [value]="data.user.email"></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="Tipo" [value]="data.user.type"></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="Data de emissão" [value]="formatDate(data.user.createdAt) || '---'"></app-info-oc-or-nfe>
            <app-info-oc-or-nfe label="Última vez online" [value]="formatDate(data.user.lastSeenAt) || '---'"></app-info-oc-or-nfe>
        </div>
        <div class="third-column">
            <button mat-raised-button
                    class="primary-1 edit-user"
                    (click)="openEditUser()"
                    [disabled]="disableEdit" [matTooltip]="disabledTooltipText()">Editar Usuário</button>
        </div>
    </div>
</div>

