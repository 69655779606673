import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ConfirmModalComponent} from '../../../../modals/confirm-modal/confirm-modal.component';
import {first} from 'rxjs/operators';
import {AddLevelToGroupModalComponent} from '../../../../modals/add-level-to-group-modal/add-level-to-group-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ActionService} from '../../../../core/services/action.service';

@Component({
  selector: 'app-setup-level-card',
  templateUrl: './setup-level-card.component.html',
  styleUrls: ['./setup-level-card.component.scss']
})
export class SetupLevelCardComponent implements OnInit, OnChanges {

  @Output()
  onRemove = new EventEmitter();

  @Output()
  onCreate = new EventEmitter();

  @Input()
  levels = [];

  @Input()
  id;

  @Input()
  title;

  constructor(
    private dialog: MatDialog,
    private actionService: ActionService
  ) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.levels.currentValue){
      this.levels = changes.levels.currentValue;
    }
    if(changes.id?.currentValue){
      this.id = changes.id.currentValue;
    }
  }

  openConfirmRemoveModal(item) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: { label: 'Você deseja remover este nível?' }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res === 'SUBMITTED') {
        this.onRemove.emit({levelId: item.id, id: this.id});
      }
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(AddLevelToGroupModalComponent, {
      panelClass: 'confirm-modal',
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res) {
        this.onCreate.emit({values:res, id:this.id});
      }
    });
  }
}
