import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IReport } from '../../../models/Report';

@Injectable({
  providedIn: 'root'
})
export class ReportDataSourceService {
  constructor(private http: HttpClient) { }

  getReports(): Promise<IReport[]> {
    return this.http.get<IReport[]>(environment.baseUrl + '/api/v1/admin-desktop/reports').toPromise();
  }

  getInventories(): Promise<IReport[]> {
    return this.http.get<IReport[]>(environment.baseUrl + '/api/v1/admin-desktop/inventories').toPromise();
  }
}
