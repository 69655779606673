import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {SnackBarService} from '../../core/services/snack-bar.service';
import * as moment from 'moment';
import {Params, Router} from '@angular/router';

@Component({
  selector: 'app-notification-nfe',
  templateUrl: './notification-nfe.component.html',
  styleUrls: ['./notification-nfe.component.scss']
})
export class NotificationNfeComponent implements OnInit {

  @Input() data;

  @HostBinding('class.failed-background') alert;
  @HostBinding('class.success-background') success;

  constructor(private snackbarService: SnackBarService,
              private router: Router) { }

  ngOnInit(): void {
    this.data.type === 'FAILED' ? this.alert = true : this.success = true;
  }

  async openDetailsModal() {
    console.log(this.data);
    const params: Params = {
      id: this.data.message.message.substring(5)
    };
    await this.router.navigate(['/in'], { queryParams: params });
    if (!this.data.message.url) {
      return;
    }
    await this.router.navigate([this.data.message.data.url]);
  }

  timestampToDate(timestamp) {
    timestamp = timestamp * 1000;
    return moment(timestamp).format('DD/MM/YYYY - HH:mm');
  }
}
