<section>
  <div class="body">
    <app-sidenav-header title="Solicitação de tarefa" (closeEvent)="close()"></app-sidenav-header>

    <p class="error" *ngIf="noAvailablePalletsError">
      Não existem pallets com esse produto no estoque. Faça o recebimento deste produto ou utilize a
      <a (click)="toProductPicking()">separação de produtos</a> para remover e adicionar outro produto para esta posição.
    </p>

    <app-sidenav-card title="Configurar nova tarefa de realocação">
      <div content>
        <form class="new-task-config" [formGroup]="relocateForm" >
          <p>Destino do pallet: <b>{{toExcelFormat(task.position.x, task.position.y)}}</b></p>
          <mat-form-field appearance="outline">
            <mat-label>Produto</mat-label>
            <input matInput autocomplete="off" formControlName="product" [readonly]="true">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Pallets</mat-label>
            <mat-select formControlName="pallet">
              <mat-option *ngFor="let crate of task.suggestedCrates" [matTooltip]="parseTooltip(crate)" [value]="crate.tagRfid">
                <span *ngFor="let product of crate.skuGroups; let last = last">
                  {{ getCratesInfoString(product, last) }}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </app-sidenav-card>

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>

    <app-operators-table device="FORKLIFT"
                         [usersHighlighted]="selectedUsers"
                         (usersHighlightedEvent)="selectUsers($event)"
                         [openedStart]="drawer.openedStart"
                         [isUserOnline]="true">
    </app-operators-table>
  </div>

  <app-sidenav-footer
    [disabled]="noAvailablePalletsError"
    (clicked)="footerClicked($event)"
  ></app-sidenav-footer>
</section>

