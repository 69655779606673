<h3 class="blue-color" *ngIf="consolidatedInvoice.length !== 0 && data.relatedInvoices.length > 0">
  Pedidos e notas anteriores
</h3>

<div class="comparison" *ngIf="consolidatedInvoice.length !== 0 && data.relatedInvoices.length > 0">
  <div class="info-oc">
    <div class="table">
      <div class="header">
        <div class="product-code column">Código de Produto</div>
        <div class="provider-code column">Código do Fornecedor</div>
        <div class="description column">Descrição</div>
        <div class="measurement column">UM</div>
        <div class="quantity column">Qtd</div>
      </div>
      <div class="item" *ngFor="let product of consolidatedOrder">
        <div class="product-code column">{{ product.sku }}</div>
        <div class="provider-code column">{{ product.vendorCode }}</div>
        <div class="description column">{{ product.name }}</div>
        <div class="measurement column">{{ product.unit }}</div>
        <div class="quantity column">{{ product.quantity }}</div>
      </div>
    </div>
  </div>

  <div class="info-nfe">
    <div class="table">
      <div class="header">
        <div class="product-code column">
          Código de Produto
        </div>
        <div class="provider-code column">
          Código do Fornecedor
        </div>
        <div class="description column">
          Descrição
        </div>
        <div class="measurement column">
          UM
        </div>
        <div class="quantity column">
          Qtd
        </div>
      </div>

      <div class="item" *ngFor="let product of consolidatedInvoice">
        <div class="product-code column">{{ product.sku }}</div>
        <div class="provider-code column">{{ product.vendorCode }}</div>
        <div class="description column">{{ product.name }}</div>
        <div class="measurement column">{{ product.unit }}</div>
        <div class="quantity column">{{ product.quantity }}</div>
      </div>

    </div>
  </div>
</div>

<h3>
  <i class="uil uil-exclamation-triangle"></i>
  Divergências OC x NFe
</h3>

<div class="comparison">
  <div class="info-oc">
    <div id="container-oc" class="info">
      <div *ngIf="data.relatedOrders.length > 0">
        <small>Nº OC</small>
        <div class="related-orders">
          <div class="order-number"
               *ngFor="let order of data.relatedOrders; let last = last">
            {{ last ? order.number : order.number + ', ' }}
          </div>
        </div>
      </div>
      <app-info-oc-or-nfe class="column name"
                          label="Razão Social"
                          [value]="data.relatedOrders.length ? data.relatedOrders[0].vendor.name : '---'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe class="column"
                          label="CPF/CNPJ Fornecedor"
                          [value]="data.relatedOrders.length ? data.relatedOrders[0].vendor.doc : '---'"></app-info-oc-or-nfe>
    </div>

    <div class="table">
      <div class="header">
        <div class="product-code column">Código de Produto</div>
        <div class="provider-code column">Código do Fornecedor</div>
        <div class="description column">Descrição</div>
        <div class="measurement column">UM</div>
        <div class="quantity column">Qtd</div>
      </div>
      <div class="item" *ngFor="let product of orderProducts" [ngClass]="{'alert-background': product.notOrdered}">
        <div class="product-code column">{{ product.sku }}</div>
        <div class="provider-code column">{{ product.vendorCode }}</div>
        <div class="description column"
             [ngClass]="{'alert': product.notOrdered}">
          {{ product.notOrdered ? 'NOT ORDERED' : product.name }}
        </div>
        <div class="measurement column">{{ product.unit }}</div>
        <div class="quantity column">{{ product.quantity }}</div>
      </div>
    </div>
  </div>

  <div class="info-nfe">
    <div id="container-nfe" class="info">
      <app-info-oc-or-nfe class="column" label="Nº Nota Fiscal/Série"
                          [value]="data.invoice.number"
                          [serial]="data.invoice.serial"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe class="column name" label="Razão Social"
                          [value]="data.invoice.vendor?.name"
                          [valueColor]="data.comparison.problems.includes('VENDOR_NAME_DIFF') ? 'alert' : ''"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe class="column" label="CPF/CNPJ Fornecedor"
                          [value]="data.invoice.vendor?.doc"
                          [valueColor]="data.comparison.problems.includes('VENDOR_DOC_DIFF') ? 'alert' : ''"></app-info-oc-or-nfe>
    </div>

    <div class="table">
      <div class="header">
        <div class="product-code column">Código de Produto</div>
        <div class="provider-code column">Código do Fornecedor</div>
        <div class="description column">Descrição</div>
        <div class="measurement column">UM</div>
        <div class="quantity column">Qtd</div>
      </div>

      <div class="item" *ngFor="let product of invoiceProducts" [ngClass]="{'alert-background': product.missing}">
        <div class="product-code column" [ngClass]="{'alert': product?.problems?.includes('PRODUCT_CODE_DIFF')}">
          {{ product.sku }}
        </div>
        <div class="provider-code column" [ngClass]="{'alert': product?.problems?.includes('PRODUCT_VENDORCODE_DIFF')}">
          {{ product.vendorCode }}
        </div>
        <div class="description column" [ngClass]="{'alert': product?.problems?.includes('PRODUCT_VENDORCODE_DIFF') ||
                                                      product.missing}">
          {{ product.missing ? 'MISSING' : product.name }}
        </div>
        <div class="measurement column" [ngClass]="{'alert': product?.problems?.includes('PRODUCT_UNIT_DIFF')}">
          {{ product.unit }}
        </div>
        <div class="quantity column" [ngClass]="{'alert': product?.problems?.includes('SET-PRODUCT_QUANTITY_EXCESS') ||
                                                product?.problems?.includes('SET-PRODUCT_QUANTITY_LACK')}">
          {{ product.quantity }}
        </div>
      </div>

    </div>
  </div>
</div>

<!--<div class="resolve">-->
<!--  <h4>RESOLUÇÕES</h4>-->
<!--  <mat-slide-toggle (change)="onSlideToggleChange($event)"-->
<!--                    [checked]="isToggleChecked"><p>Receber mercadoria</p></mat-slide-toggle>-->
<!--  <mat-checkbox (change)="onCheckboxChange($event)"-->
<!--                [checked]="isCheckboxChecked"-->
<!--                [disabled]="!isToggleChecked && !isCheckboxChecked"><p>Correção Requisitada</p></mat-checkbox>-->
<!--</div>-->

<div class="bottom">
  <p *ngIf="!isToggleChecked && !isCheckboxChecked"><strong>Atenção:</strong> Caso a opção “Receber mercadoria” não
    esteja marcada, todos os pedidos referentes à essa nota fiscal serão impedidos de serem descarregados no local e o
    documento ficará pausado no sistema.</p>
  <div class="buttons">
    <button mat-stroked-button class="gray-2" (click)="cancel()">CANCELAR</button>
    <button mat-raised-button class="primary-2" (click)="submit()">OK</button>
  </div>
</div>
