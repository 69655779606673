<div class="enabled-users">
  <h3>Usuários habilitados ({{ numberOfUsers }})</h3>
  <div class="table">
    <div class="empty" *ngIf="isEmpty">
      NÃO HÁ OPERADORES DISPONÍVEIS NESSE MOMENTO.
    </div>

    <div class="header" *ngIf="!isEmpty">
      <mat-checkbox (change)="allChecked($event.checked)" [checked]="isAllChecked"></mat-checkbox>
      <div class="operator column">Operador</div>
      <div class="user column">Nome</div>
      <div class="tasks column">Tarefas</div>
      <div class="forklift column" *ngIf="isForklift">Empilhadeira</div>
      <div class="device column" *ngIf="!isForklift">Dispositivo</div>
    </div>

    <div class="item-list" *ngIf="!isEmpty">
      <div class="item" *ngFor="let user of usersForklift" [ngClass]="{'primary-bg': usersHighlighted.includes(user.id)}">
        <mat-checkbox [value]="user.id" [checked]="isAllChecked || usersHighlighted.includes(user.id)" (change)="onCheckboxChange($event)"></mat-checkbox>
        <div class="operator column">{{ user.lastName }}</div>
        <div class="user column">{{ user.firstName }}</div>
        <div class="tasks column">{{ user.taskCount }}</div>
        <div class="forklift column" *ngIf="isForklift"> Emp. {{ user.currentDevice || '---' }}</div>
        <div class="device column" *ngIf="!isForklift">{{ user.currentDevice || '---' }}</div>
      </div>
    </div>
  </div>
</div>
