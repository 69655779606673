import { Injectable } from '@angular/core';
import {TenantDataSourceService} from '../data-sources/tenant-data-source.service';
import {TenantInterface} from '../../../models/Tenant';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private tenant: TenantInterface;
  public tenant$ = new BehaviorSubject<TenantInterface>(null);


  constructor(private dataSource: TenantDataSourceService) {

  }

  isTenantLoaded(){
    return !!this.tenant;
  }

  levelsEnabled(){
    if(this.tenant){
      return this.tenant.levelsEnabled;
    } else{
      throw new Error("tenant is null");
    }
  }

  async get(force:boolean = false): Promise<TenantInterface> {
    if(this.tenant && force === false){
      return Promise.resolve(this.tenant);
    } else{
      return this.dataSource.get().then(res => {
        this.tenant = res;
        this.tenant$.next(this.tenant);
        return res;
      });
    }
  }

  async getErps(): Promise<any> {
    return this.dataSource.getErps();
  }

  async getLevels(): Promise<any> {
    return this.dataSource.getLevels()
  }
}
