import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductManagementService } from '../../../core/services/product-management.service';
import { Product, ProductManagementFiltersParams } from '../../../../models/Product';
import formatDate from '../../../helpers/format-date';
import getPaginationString from '../../../helpers/pagination';
import { PaginationService } from 'src/app/core/services/pagination.service';

@Component({
  selector: 'app-product-management-table',
  templateUrl: './product-management-table.component.html',
  styleUrls: ['./product-management-table.component.scss']
})
export class ProductManagementTableComponent implements OnInit {
  @Input() appliedFilters: ProductManagementFiltersParams = {};
  @Input() products: Product[];
  @Input() isLoading: boolean;
  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() valuesToFilter = new EventEmitter();

  formatDate = formatDate;
  getPaginationString = getPaginationString;

  constructor(
    private productManagementService: ProductManagementService,
    public paginationService: PaginationService
  ) { }

  ngOnInit(): void {
  }

  onItemClick(product): void {
    this.itemClick.emit(product);
  }

  openDeleteProductModal(product): void {
    this.itemDelete.emit(product);
  }

  async getNextPage(event: boolean) {
    if (event) await this.productManagementService.getNextPage(this.appliedFilters);
  }

  async getPreviousPage(event: boolean) {
    if (event) await this.productManagementService.getPreviousPage(this.appliedFilters);
  }

  onFilterValues(value) {
    this.valuesToFilter.emit(value);
  }

}
