import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CrateStateEnum } from '../../../models/Crate';

export interface IItem {
  description: string;
  name: string;
  sku: string;
  quantity: number;
  pickingPositions: IPickingPosition[];
}

export interface IPickingPosition {
  createdAt: number;
  groundPosition: any;
  groundPositionId: string;
  id: string;
  maxQuantity: number;
  minQuantity: number;
  sku: string;
  tenantId: string;
  updatedAt: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProductPickingDataSourceService {

  constructor(private http: HttpClient) { }

  getItems(): Observable<IItem[]> {
    return this.http.get<IItem[]>(environment.baseUrl + '/api/v1/admin-desktop/picking-positions/products');
  }

  // TODO: set return interface
  // this api returns the data to populate the dropdown on add product sidenav
  getProducts(searchTerm: string): Promise<any> {
    const searchParams = {
      search: searchTerm
    };
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/products', { params: searchParams }).toPromise();
  }

  getAllPickingPositions(): Promise<any> {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/picking-positions').toPromise();
  }

  patchPickingPositionById(body, id: string): Promise<any> {
    return this.http.patch(environment.baseUrl + `/api/v1/admin-desktop/picking-positions/${id}`, body).toPromise();
  }
}
