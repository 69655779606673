<div class="header-modal">
  <p>Novo nível</p>
</div>

<form [formGroup]="form" (ngSubmit)="close('SUBMITTED')">

  <p class="error" *ngIf="!order.valid && order.touched">Obrigatório</p>
<mat-form-field appearance="outline">
  <mat-label>Ordem (posição do nível de baixo para cima)</mat-label>
  <input matInput formControlName="order" autocomplete="off" type="number">
</mat-form-field>

<p class="error" *ngIf="!name.valid && name.touched">Obrigatório</p>
<mat-form-field appearance="outline">
  <mat-label>Nome</mat-label>
  <input matInput formControlName="name" autocomplete="off">
</mat-form-field>


  <p class="error" *ngIf="!start.valid && start.touched">Obrigatório</p>
  <mat-form-field appearance="outline">
  <mat-label>Começo (parte inferior do nível em cm)</mat-label>
  <input matInput formControlName="start" autocomplete="off" type="number">
</mat-form-field>

  <p class="error" *ngIf="!reference.valid && reference.touched">Obrigatório</p>
  <mat-form-field appearance="outline">
  <mat-label>Referência (ponto que funciona como centro de gravidade do nível em cm)</mat-label>
  <input matInput formControlName="reference" autocomplete="off" type="number">
</mat-form-field>

  <p class="error" *ngIf="!end.valid && end.touched">Obrigatório</p>
  <mat-form-field appearance="outline">
  <mat-label>Fim (parte superior do nível em cm)</mat-label>
  <input matInput formControlName="end" autocomplete="off" type="number">
</mat-form-field>
</form>
<div class="buttons">
  <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
  <button mat-raised-button class="primary-1" (click)="close('SUBMITTED')">confirmar</button>
</div>
