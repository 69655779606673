<div class="ov column" (click)="itemClickHandler()">
  {{ item.number || '---' }}
</div>
<div class="timestamp column" (click)="itemClickHandler()">
  {{ date || '---' }}
</div>
<div class="document column" (click)="itemClickHandler()">
  {{ item.clientDoc || '---' }}
</div>
<div class="name column" (click)="itemClickHandler()">
  <div class="client-name" [matTooltip]="item.clientName">{{ item.clientName || '---' }}</div>
</div>
<div class="products column" (click)="itemClickHandler()">
  <div class="product" *ngFor="let p of item.products" [matTooltip]="p.name">{{ p.name || '---' }}</div>
</div>
<div class="quantity column" (click)="itemClickHandler()">
  <div *ngFor="let p of item.products">{{ p.quantity.required || '---' }}</div>
</div>
<div class="tasks column" (click)="itemClickHandler()">
  {{ item.tasks }}
</div>
<div class="status column" (click)="itemClickHandler()">
  {{ translateStatus(item.status) }}
</div>

<!--<div class="messages column">-->
<!--  <i *ngIf="item.commentsQty === 0" class="uil uil-comment"></i>-->
<!--  <i *ngIf="item.commentsQty !== 0" class="uil uil-comment-lines"></i>-->
<!--  <span>{{ item.commentsQty }}</span>-->
<!--</div>-->

