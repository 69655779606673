<app-header-modal [color]="(status === 'DIVERGENT' || status === 'RECEIVEMENT_BLOCKED' || status === 'AWAITING_RETIFICATION') ? 'alert' : 'secondary-2'"
                  [title]="titleModal">
  <div class="right" status>
    <app-status-header-modal [icon]="status === 'RECEIVEMENT_BLOCKED' ? 'padlock' : status === 'DIVERGENT' ? 'times' :
                              status === 'AWAITING_RETIFICATION' ? 'spinner-edit' :
                              status === 'AWAITING_COMPARISON' ? 'spinner-check' : 'check'"
                             [color]="(status === 'DIVERGENT' || status === 'RECEIVEMENT_BLOCKED') ? 'alert' :
                              (status === 'AWAITING_COMPARISON' || status === 'AWAITING_RETIFICATION') ? 'in' : 'success'"
                             [status]="translatedStatus"></app-status-header-modal>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>

<mat-tab-group mat-align-tabs="center" [selectedIndex]="selected.value"
               (selectedIndexChange)="selected.setValue($event)">
  <mat-tab *ngIf="status === 'DIVERGENT' || status === 'RECEIVEMENT_BLOCKED' || status === 'AWAITING_RETIFICATION'" label="Comparação">
    <app-comparison-nfe-and-oc [data]="data.itemDetails"
                               (closeEvent)="close($event)"></app-comparison-nfe-and-oc>
  </mat-tab>
  <mat-tab label="Nota Fiscal">
    <app-in-details-nfe [nfe]="data.itemDetails"
                        (itemClick)="openBuyOrderModal($event)"
                        (destinationChanged)="setDestination()"
                        [highlightedProducts]="highlightedProducts"></app-in-details-nfe>
  </mat-tab>
  <mat-tab label="Detalhes dos produtos">
    <app-product-image-tab [products]="products"></app-product-image-tab>
  </mat-tab>
  <mat-tab label="Ordem de compra" *ngIf="data?.itemDetails?.relatedOrders?.length">
    <app-in-details-buy-order [data]="data.itemDetails"></app-in-details-buy-order>
  </mat-tab>
  <mat-tab label="Comentários">
    <app-in-details-comments [itemDetails]="data.itemDetails"
                             (submitEvent)="submitComment($event)"
                             (deleteEvent)="deleteComment($event)"></app-in-details-comments>
  </mat-tab>
</mat-tab-group>
