<div class="page-header">
  <app-prev-next-button (click)="toPicking()" direction="left" label="separação"></app-prev-next-button>
  <h1>Expedição</h1>
</div>

<div class="ctrl-bar">
  <div class="update-button">
    <p>Atualizado às {{actualHour}}</p>
    <button mat-raised-button (click)="update()">
      <i class="uil uil-sync"></i>
      <span>Atualizar</span>
    </button>
  </div>
</div>

<div class="content">

  <app-side-color-card color="out">
    <app-dispatch-group
      content
      [title]="'nota expedida'"
      [type]="'INVOICE_ISSUED'"
      [windowSize]="5"
      [documents]="allDocuments?.issuedInvoices"
      [itemTemplate]="issuedInvoices"
      [isLoading]="isLoading">

      <ng-template #issuedInvoices let-item="item" let-odd="odd">
        <app-dispatch-group-item
          [item]="item"
          [isOdd]="odd"
          (goToOther)="openDetailsModal($event)"
        ></app-dispatch-group-item>
      </ng-template>

      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-dispatch-group-header header></app-dispatch-group-header>
    </app-dispatch-group>
  </app-side-color-card>


  <app-side-color-card color="out">
    <app-dispatch-group
      content
      [title]="'carregando'"
      [type]="'LOADING'"
      [windowSize]="5"
      [itemTemplate]="dispatchingOutOrders"
      [documents]="allDocuments?.dispatching"
      [isLoading]="isLoading">

      <ng-template #dispatchingOutOrders let-item="item" let-odd="odd">
        <app-dispatch-group-item
          [item]="item"
          [isOdd]="odd"
          (goToOther)="openDetailsModal($event)"
        ></app-dispatch-group-item>
      </ng-template>

      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-dispatch-group-header header></app-dispatch-group-header>
    </app-dispatch-group>
  </app-side-color-card>

  <app-side-color-card color="out">
    <app-dispatch-group
      content
      [title]="'finalizado'"
      [type]="'FINISHED'"
      [windowSize]="5"
      [itemTemplate]="finalizedOutOrders"
      [documents]="allDocuments?.finalized"
      [isLoading]="isLoading">

      <ng-template #finalizedOutOrders let-item="item" let-odd="odd">
        <app-dispatch-group-item
          [item]="item"
          [isOdd]="odd"
          (goToOther)="openDetailsModal($event)"
        ></app-dispatch-group-item>
      </ng-template>

      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-dispatch-group-header header></app-dispatch-group-header>
    </app-dispatch-group>
  </app-side-color-card>
</div>

<app-full-page-loader *ngIf="isModalLoading"></app-full-page-loader>
