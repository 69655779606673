import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {GroundGroupsService} from '../../core/services/ground-groups.service';

@Component({
  selector: 'app-edit-sector-modal',
  templateUrl: './edit-sector-modal.component.html',
  styleUrls: ['./edit-sector-modal.component.scss']
})
export class EditSectorModalComponent implements OnInit {

  newSectorFormControl: FormControl;

  groundGroups;
  sectorOptions;

  constructor(public dialogRef: MatDialogRef<EditSectorModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private groundGroupsService: GroundGroupsService) {
    this.newSectorFormControl = new FormControl();
    this.getGroundGroups().then(() => {
      this.newSectorFormControl.setValue(this.setDefinedGroundGroup()[0].id);
    });
  }

  ngOnInit(): void {
  }

  async getGroundGroups() {
    this.groundGroups = await this.groundGroupsService.getGroundGroups();
    this.sectorOptions = this.groundGroups.filter(gg => gg.type === this.data.type);
  }

  setDefinedGroundGroup() {
    const sectors = [...this.sectorOptions];
    return sectors.filter(s => s.id === this.data.groupId);
  }

  close(isSubmitted?) {
    if (isSubmitted) {
      const data = {
        type: this.data.type,
        groundPositionGroupId: this.newSectorFormControl.value
      };
      this.dialogRef.close(data);
    } else {
      this.dialogRef.close();
    }
  }

}
