export interface IMovementHistory {
  movedAt: number;
  updatedAt?: number;
  createdAt: number;
  id: string;
  crateId: string;
  userId: string;
  type: MovementType;
  x: number;
  y: number;
  z: number;
}

export enum MovementType {
  LOAD = 'LOAD',
  UNLOAD = 'UNLOAD'
}
