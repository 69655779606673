<div class="drawer">
  <div class="items">
    <div *ngFor="let option of options" class="item"
         (click)="navigateTo(option.route)"
         [class.selected]="option.sectionName === currentSectionName"
         [class.disabled]="option.sectionName === ''"
         [class.hidden]="option.sectionName === 'setup' && user.type !== 'MASTER'">
      <div class="item-text">
        <i class="uil uil-{{ option.icon }}"></i>
        <span>{{ option.label }}</span>
      </div>

      <i *ngIf="currentSectionName === 'in' && option.sectionName === currentSectionName && !toggle" class="uil uil-angle-down"></i>
      <i *ngIf="currentSectionName === 'in' && option.sectionName === currentSectionName && toggle" class="uil uil-angle-up"></i>
    </div>
  </div>

  <div class="logo">
    <img src="assets/icons/logo-quantum-2.svg">
  </div>
</div>

