import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

const defaultMessage = 'Algo deu errado! Tente novamente em instantes ou entre em contato com suporte.';
const ERROR_MESSAGE_DURATION = 20000;
export enum ErrorsEnum {
  OUT_ORDER_STATE_CONFLICT = 'OUT_ORDER-STATE_CONFLICT',
  GROUND_POSITION_INVALID_STATE_TRANSITION = 'GROUND_POSITION_INVALID_STATE_TRANSITION',
  NOT_ENOUGH_PRODUCTS_AT_PICKING = 'NOT_ENOUGH_PRODUCTS_AT_PICKING',
  NO_PALLETS_WITH_SKU_AT_PICKING = 'NO_PALLETS_WITH_SKU_AT_PICKING',
  TASK_EXISTING = 'TASK_EXISTING',
  PRODUCTS_FORBIDDEN_ACCESS = 'PRODUCTS_FORBIDDEN_ACCESS',
  PASSWORD_VALIDATION_FAILED = 'PASSWORD_VALIDATION_FAILED',
  USER_ERROR_EDIT_SELF_TYPE = 'USER_ERROR-EDIT_SELF_TYPE',
  USER_ERROR_INVALID_PERMISSION = 'USER_ERROR-INVALID_PERMISSION',
  USER_ERROR_INVALID_PASSWORD_PATTERN = 'USER_ERROR-INVALID_PASSWORD_PATTERN',
  USER_ERROR_USER_ALREADY_EXISTS = 'USER_ERROR-USER_ALREADY_EXISTS',
  VALIDATION_ERROR = 'VALIDATION_ERROR'
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private snackBar: MatSnackBar) { }

  openErrorSnackBar(error) {
    const beError = error.error;
    const {type} = beError;
    const message = this.getErrorMessage(type);
    this.snackBar.open(message, null, {
      duration: ERROR_MESSAGE_DURATION,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['red-snackbar'],
    });
  }
  openErrorSnackBarWithCustomMessage(message: string) {
    this.snackBar.open(message, null, {
      duration: ERROR_MESSAGE_DURATION,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['red-snackbar'],
    });
  }

  getErrorMessage(type: ErrorsEnum){
    switch (type){
      case ErrorsEnum.OUT_ORDER_STATE_CONFLICT:
        return 'Algo deu errado ao processar a ordem de venda. Tente novamente em instantes ou entre em contato com suporte.';
        default:
        return defaultMessage;
    }
  }
}
