import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductManagementService } from '../../../core/services/product-management.service';
import { Product, ProductManagementFiltersParams } from '../../../../models/Product';
import formatDate from '../../../helpers/format-date';
import getPaginationString from '../../../helpers/pagination';

@Component({
  selector: 'app-product-management-table',
  templateUrl: './product-management-table.component.html',
  styleUrls: ['./product-management-table.component.scss']
})
export class ProductManagementTableComponent implements OnInit {

  @Input() products: Product[];
  @Input() isLoading: boolean;
  @Input() filterParams: ProductManagementFiltersParams;
  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();

  formatDate = formatDate;
  getPaginationString = getPaginationString;

  constructor(public productManagementService: ProductManagementService) { }

  ngOnInit(): void {
  }

  onItemClick(product): void {
    this.itemClick.emit(product);
  }

  openDeleteProductModal(product): void {
    this.itemDelete.emit(product);
  }

  async getNextPage(){
    await this.productManagementService.getNextPage(this.filterParams);
  }
  async getPreviousPage(){
    await this.productManagementService.getPreviousPage(this.filterParams);
  }
}
