export function findCrateLevel(z, levels) {
  if (!levels.length) {
    return 0;
  }
  let level = 0;
  let name = '';
  let isInsideBounds = false;
  levels.map(item => {
    if (z >= item.start && z > item.end) {
      level += 1;
    }
    if (z >= item.start && z <= item.end) {
      name = item.name;
      isInsideBounds = true;
    }
  });
  return isInsideBounds ? `${name} [${level}]` : '---';
}
