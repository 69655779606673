
<h3>Criar separação </h3>

<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Número</mat-label>
    <input matInput formControlName="number" autocomplete="off" type="text">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Selecione os pallets desejados</mat-label>
    <mat-select multiple formControlName="pallets">
      <mat-option *ngFor="let pallet of stockedPalletsWithProducts" [value]="pallet" [disabled]="pallet.state !== 'STORED'">
        {{ pallet.state+' '+ handleName(pallet) + ', posição: ' + handlePosition(pallet.x, pallet.y) + ', quantidade: ' + handleQuantity(pallet.skuGroups)}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<div class="buttons">
  <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
  <button mat-raised-button class="primary-1" (click)="submit('SUBMITTED')">confirmar</button>
</div>
