import {Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';

export enum DispatchState {
  READY_TO_DISPATCH = 'READY_TO_DISPATCH',
  DISPATCHING = 'DISPATCHING',
  FINISHED = 'FINISHED'
}

@Component({
  selector: 'app-dispatch-group-item',
  templateUrl: './dispatch-group-item.component.html',
  styleUrls: ['./dispatch-group-item.component.scss']
})
export class DispatchGroupItemComponent implements OnInit {

  @Input() item;
  @HostBinding('class.isOdd') @Input() isOdd;
  @Output() goToComment = new EventEmitter();
  @Output() goToOther = new EventEmitter();
  @HostListener('click') openModal(){
    this.goToOther.emit(this.item);
  }

  date;

  constructor() { }

  ngOnInit(): void {
    this.date = moment(this.item.issuedAt).format('DD/MM/YYYY - HH:mm');
  }

  translateState(state) {
    switch (state) {
      case DispatchState.READY_TO_DISPATCH:
        return 'Aguardando caminhão';
      case DispatchState.DISPATCHING:
        return 'Carregando caminhão';
      case DispatchState.FINISHED:
        return 'Expedição finalizada';
      default:
        return state || '---';
    }
  }

}
