<div class="page-header">
  <app-prev-next-button (click)="toMap()" [direction]="'left'" label="Mapa"></app-prev-next-button>
  <h1>Gerenciar Setores</h1>
</div>

<button class="open-form-button" mat-raised-button (click)="toggleForm()" [ngClass]="{'blue-label': formToggle}">
  criar setor
</button>

<div class="form-section" *ngIf="formToggle">
  <form [formGroup]="createSectorFormGroup" #formElement>
    <mat-form-field appearance="outline">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Tipo</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let sector of sectorOptions" [value]="sector.value">{{ sector.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="buttons">
      <button mat-raised-button class="primary-1" (click)="createSector()">criar</button>
      <button mat-stroked-button class="gray-1" (click)="cancel()">cancelar</button>
    </div>
  </form>
</div>

<app-side-color-card color="primary-1">
  <div content class="table">
    <div class="header">
      <div class="name column">
        Nome
      </div>
      <div class="type column">
        Tipo
      </div>
      <div class="type column">
        Níveis Cadastrados
      </div>
      <div class="button column"></div>
    </div>

    <div class="item" *ngFor="let p of positions">
      <div class="name column">
        {{ p.name }}
      </div>
      <div class="type column">
        {{ translateType(p.type) }}
      </div>
      <div class="type column">
        {{ p.levels.length }}
      </div>
      <div class="button column">
        <button mat-raised-button class="primary-2" (click)="toLevels(p)">
          editar níveis
        </button>
      </div>

    </div>
  </div>
</app-side-color-card>
