import {Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {LoginService} from '../../core/services/login.service';
import {Router} from '@angular/router';
import {UserService} from '../../core/services/user.service';
import {LogosService} from '../../core/services/logos.service';
import {SessionService} from '../../core/services/session.service';
import jwtDecode from 'jwt-decode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() user;
  sidebarOpened = false;

  imageURL: string;

  @HostListener('document:click', ['$event']) public onClick(event: any): void
  {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.sidebarOpened = false;
      event.stopPropagation();
    }
  }

  constructor(private loginService: LoginService,
              private userService: UserService,
              private logoService: LogosService,
              private sessionService: SessionService,
              private router: Router,
              private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.getTenantId();
    this.getLogo();
  }

  getTenantId() {
    const session = this.sessionService.getSession();
    const decodedSession: any = jwtDecode(session.accessToken);
    return decodedSession.tenantId;
  }

  async getLogo() {
    const tenantId = this.getTenantId();
    this.imageURL = await this.logoService.getLogo(tenantId);
  }

  async logout() {
    await this.loginService.logout();
    await this.router.navigate(['/login']);
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  toDashboard() {
    this.router.navigate(['dashboard']);
  }
}
