<app-table-header-column name="task-id" size="small">
  <span content>ID</span>
</app-table-header-column>

<app-table-header-column name="created-date" size="small">
  <span content>Data/Hora de Criação</span>
</app-table-header-column>

<app-table-header-column name="responsible" size="small">
  <span content>Responsável</span>
</app-table-header-column>

<app-table-header-column name="users" size="small">
  <span content>Usuários elegíveis</span>
</app-table-header-column>

<app-table-header-column name="description" size="medium">
  <span content>Descrição</span>
</app-table-header-column>

<app-table-header-column name="start-date" size="small">
  <span content>Data/Hora de Início</span>
</app-table-header-column>

<app-table-header-column name="type" size="medium">
  <span content>Tipo de tarefa</span>
</app-table-header-column>

<app-table-header-column name="tag-rfid" size="medium">
  <span content>Tag RFID</span>
</app-table-header-column>

<app-table-header-column name="status" size="small">
  <span content>Status</span>
</app-table-header-column>

<app-table-header-column name="delete-button" size="button"></app-table-header-column>
