import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../core/services/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {HeaderDataSourceService} from '../../core/data-sources/header-data-source.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  hide = true;

  tenantId: string;

  public routerSubs: Subscription;
  private returnUrl: string;

  userInputSubs: Subscription;
  passwordInputSubs: Subscription;
  isLoading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private loginService: LoginService,
              private headerService: HeaderDataSourceService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.buildLoginForm();
    this.routerSubs = this.route.queryParams.subscribe(params => {
      if (params.returnUrl) {
        this.returnUrl = decodeURIComponent(params.returnUrl);
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubs.unsubscribe();
    if (this.userInputSubs && this.passwordInputSubs) { // TODO see if there is a better way to do this
      this.userInputSubs.unsubscribe();
      this.passwordInputSubs.unsubscribe();
    }
  }

  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async getTenant() {
    try {
      this.tenantId = await this.headerService.getTenant();
      if (!this.tenantId) {
        this.tenantId = environment.tenantId;
      }
    } catch (e) {
      this.tenantId = environment.tenantId;
    }
  }

  async login() {
    this.isLoading=true;
    try{
    await this.getTenant();
      if (this.loginForm.valid) {
        const userFormControl = this.loginForm.controls.user;
        const passFormControl = this.loginForm.controls.password;
        const response = await this.loginService.login(userFormControl.value, passFormControl.value, 'DESKTOP.' + userFormControl.value,
          this.tenantId);
        if (!response?.error) {
          if (this.returnUrl) {
            await this.router.navigateByUrl(this.returnUrl);
          } else {
            await this.router.navigate(['/dashboard']);
          }
        }
        if (response?.error?.code === 401 || response?.error?.code === 403 || response?.error?.code === 404) {
          passFormControl.setErrors({invalid: true});
          userFormControl.setErrors({invalid: true});
          this.userInputSubs = userFormControl.valueChanges.pipe(first()).subscribe(() => {
            passFormControl.setErrors(null);
            userFormControl.setErrors(null);
          });
          this.passwordInputSubs = passFormControl.valueChanges.pipe(first()).subscribe(() => {
            passFormControl.setErrors(null);
            userFormControl.setErrors(null);
          });
        }
      }
    } catch(err){
      console.log(err)
    await this.getTenant();
    } finally{ this.isLoading= false}
  }

  keyPress(event: KeyboardEvent){
    event.stopPropagation();
    event.preventDefault();
    this.login().then();
  }
}
