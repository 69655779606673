<section>
  <div class="body">
    <app-sidenav-header title="A Tarefa #0000" (closeEvent)="close()"></app-sidenav-header>

    <app-sidenav-card title="Configurar nova tarefa de realocação">
      <div content>
        <form class="new-task-config" [formGroup]="relocateForm" >
          <mat-form-field appearance="outline">
            <mat-label>Produto</mat-label>
            <input matInput autocomplete="off" formControlName="product" [readonly]="true">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Tag RFID</mat-label>
            <input matInput autocomplete="off" formControlName="rfid" [readonly]="true">
          </mat-form-field>
        </form>

        <app-sidenav-destination #destination
                                 [selectedPosition]="task.position ?? null"
                                 (changed)="destinationChanged($event)"
        ></app-sidenav-destination>

      </div>

    </app-sidenav-card>

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>

    <app-operators-table device="FORKLIFT"
                         [usersHighlighted]="selectedUsers"
                         (usersHighlightedEvent)="selectUsers($event)"
                         [openedStart]="drawer.openedStart"
                         [isUserOnline]="true"></app-operators-table>
  </div>
  <app-sidenav-footer
    (clicked)="footerClicked($event)"
  ></app-sidenav-footer>
</section>
