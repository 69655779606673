import {Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-picking-group-sell-order-with-pallet-item',
  templateUrl: './picking-group-sell-order-with-pallet-item.component.html',
  styleUrls: ['./picking-group-sell-order-with-pallet-item.component.scss']
})
export class PickingGroupSellOrderWithPalletItemComponent implements OnInit {

  @Input() item;
  @Input() disabled;

  @Input() isDestinationSelected: boolean;
  @HostBinding('class.isOdd') @Input() isOdd;
  date;
  @Output() openDrawer = new EventEmitter();
  @Output() goToDetails: EventEmitter<any> = new EventEmitter();
  @Output() sendClicked: EventEmitter<any> = new EventEmitter();

  @Output() itemClick = new EventEmitter();
  @Output() removeClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.date = moment(this.item.issuedAt).format('DD/MM/YYYY - HH:mm');
  }

  translateStatus(status): string {
    switch (status) {
      case 'READY_TO_PACKING':
        return 'Pronto para separar';
      default:
        return '---';
    }
  }

  @HostListener('click') onClick(){
    if(!this.disabled){
      this.itemClick.emit(this.item);
    }
  }

  openSidenav(type, item) {
    const data = {
      type,
      item
    };
    this.openDrawer.emit(data);
  }

  openDetailsModal(): void {
    this.goToDetails.emit(this.item);
  }

  sendClickedHandler($event){
    this.sendClicked.emit(this.item);
    $event.stopPropagation();
  }
  removeClickedHandler($event){
    this.removeClick.emit(this.item);
    $event.stopPropagation();

  }
}
