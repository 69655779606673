import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GroundGroupsService} from '../../../core/services/ground-groups.service';
import {GroundPositionType} from '../../warehouse-map/warehouse-map-table/warehouse-map-table.component';
import {MatDialog} from '@angular/material/dialog';
import {ActionService, TenantState} from '../../../core/services/action.service';
import {TenantService} from '../../../core/services/tenant.service';

@Component({
  selector: 'app-setup-levels',
  templateUrl: './setup-levels.component.html',
  styleUrls: ['./setup-levels.component.scss']
})
export class SetupLevelsComponent implements OnInit {

  levels = [];
  groups = [];
  constructor(
    private tenantsService: TenantService,
    private groundGroupsService: GroundGroupsService,
    private actionService: ActionService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.loadInfo();
  }

  loadInfo(){
    this.loadTenantLevels();
    this.loadGroundGroups();
  }

  async loadTenantLevels(){
    const levels = await this.tenantsService.getLevels();
    this.levels = levels;
  }
  async loadGroundGroups(){
    let groups = await this.groundGroupsService.getGroundGroups(true);
    groups = groups.map( g => {
      const group = {...g, title: this.translateType(g.type)+" - "+g.name}
      return group;
    });
    this.groups = groups.sort((a, b) =>{ return (a.type+a.name).localeCompare(b.type+b.name)});
  }

  async toSetup(){
    await this.router.navigate(['/setup']);
  }

  translateType(type) {
    switch (type) {
      case GroundPositionType.DOCK:
        return 'Doca';
      case GroundPositionType.STREET:
        return 'Rua';
      case GroundPositionType.QUALITY_CONTROL:
        return 'Controle de qualidade';
      case GroundPositionType.STOCK:
        return 'Estoque';
      case GroundPositionType.EXPEDITION:
        return 'Expedição';
      case GroundPositionType.DIVERGENT_STOCK:
        return 'Estoque Divergente';
      case GroundPositionType.DAMAGES:
        return 'Danos';
      case GroundPositionType.PICKING:
        return 'Separação';
      case GroundPositionType.PROVISION:
        return 'Provisão';
      case GroundPositionType.IN:
        return 'Entrada';
      default:
        return type || '---';
    }
  }

  async toLevels(p: any){
    await this.router.navigate(['ground-group-levels', p.id]);
  }

  enableGroupLevel($event, id){
    const {checked} = $event;
    this.actionService.enableGroundGroupLevels({groundPositionGroupId:id, enabled:checked}).then(() => {
      this.loadInfo();
    });
  }

  onRemoveLevelFromTenant($event){
    const {levelId} = $event;
    this.actionService.removeLevelFromTenant({id:levelId}).then(() => {
      this.loadInfo();
    });
  }
  onCreateLevelForTenant($event){
    const {values} = $event;
    this.actionService.addLevelToTenant(values).then(()=>{
      this.loadInfo();
    });
  }
  onCreateLevelForGroup($event){
    const {values, id} = $event;
    const res = {...values, groundPositionGroupId: id}
    this.actionService.addLevelToGroundGroup(res).then(() => {
      this.loadInfo();
    });
  }
  onRemoveLevelFromGroup($event){
    const {id, levelId} = $event;
    this.actionService.removeLevelToGroundGroup({id: levelId}).then(() => {
      this.loadInfo();
    });
  }

  async finish(){
    await this.actionService.setTenantState({state: TenantState.SETUP_REVIEW});
    await this.router.navigate(['/setup-finish']);
  }
}
