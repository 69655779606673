import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TaskRequestSidenavService } from '../../../core/services/task-request-sidenav.service';
import { SidenavDestinationComponent } from '../../../shared/sidenav-destination/sidenav-destination.component';
import {toExcelFormat} from '../../../helpers/format-position';
import {ActionService, StartPackingAction} from '../../../core/services/action.service';
import {ActionsEnum} from '../../../core/data-sources/action-data-source.service';

@Component({
  selector: 'app-packing-start-sidenav',
  templateUrl: './packing-start-sidenav.component.html',
  styleUrls: ['./packing-start-sidenav.component.scss']
})
export class PackingStartSidenavComponent implements OnInit {

  @Input() drawer;
  @Input() task;

  pickingUsersHighlighted = [];
  selectPickingOperatorError = false;
  relocateUsersHighlighted = [];
  selectRelocateOperatorError = false;
  details;
  destination;

  @ViewChild('destination', {static: true}) destinationForm: SidenavDestinationComponent;

  constructor(
    private actionService: ActionService,
    private sidenavService: TaskRequestSidenavService) { }

  ngOnInit(): void {
  }

  destinationChanged($event){
    this.destination = $event;
  }

  resetForm() {
    this.destinationForm.reset();
  }

  setPickingUsers(users) {
    this.pickingUsersHighlighted = users;
  }

  setRelocateUsers(users) {
    this.relocateUsersHighlighted = users;
  }

  formatPosition(x, y) {
    return toExcelFormat(x, y);
  }

  isFormValid(): boolean {
    this.destinationForm.form.markAllAsTouched();
    if (this.task.pallets.length) {
      this.selectRelocateOperatorError = !this.relocateUsersHighlighted.length;
    }
    if (this.task.steps.length) {
      this.selectPickingOperatorError = !this.pickingUsersHighlighted.length;
    }
    return (!this.selectRelocateOperatorError && !this.selectPickingOperatorError && this.destinationForm.form.valid);
  }

  async submit() {
    try {
      if (!this.isFormValid()) {
        return;
      }
      await this.startPacking();
      this.drawer.close();
      this.sidenavService.closeSidenav(true);
      this.resetForm();
    } catch (e) {
      console.log(e);
    }
  }

  async startPacking(){
    const pickingSteps = {
      userIds: this.pickingUsersHighlighted,
        steps: this.task.steps
    };
    const selectedPallets = {
      userIds: this.relocateUsersHighlighted,
      palletIds: this.task.pallets.map(p => p.palletId)
    };
    const action: StartPackingAction = {
      type: ActionsEnum.START_PACKING,
      data: {
        outOrderId: this.task.outOrderId,
        destiny: this.destination.data.address
      }
    };
    if (this.task.steps.length > 0){
      action.data.pickingSteps = pickingSteps;
    }
    if (this.task.pallets.length > 0){
      action.data.selectedPallets = selectedPallets;
    }
    await this.actionService.startPacking(action);
  }


  close() {
    this.resetForm();
    this.drawer.close();
    this.sidenavService.closeSidenav(false);
  }
}
