import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-picking-group',
  templateUrl: './picking-group.component.html',
  styleUrls: ['./picking-group.component.scss']
})
export class PickingGroupComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() quantity: number;
  @Input() windowSize: number;
  @Input() documents;
  @Input() isLoading;
  @Input() itemClick: (item) => void;
  @Input() disabledButton = false;

  @Output() openConfirmModalEvent = new EventEmitter();
  @Output() openFakeNfeModalEvent = new EventEmitter();
  @Output() openSidenavEvent = new EventEmitter();
  @Output() sendEvent = new EventEmitter();

  displayedDocuments;

  displayAll: boolean;
  displayShowMoreToggle: boolean;
  isEmpty: boolean;

  @Input()
  itemTemplate: TemplateRef<any>;

  constructor() {
    this.quantity = 0;
    this.windowSize = 3;
    this.displayShowMoreToggle = true;
    this.displayAll = false;
    this.isEmpty = true;
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  openConfirmModal(item) {
    this.openConfirmModalEvent.emit(item);
  }

  openFakeNfeModal(item) {
    this.openFakeNfeModalEvent.emit(item);
  }

  openSidenav(data) {
    this.openSidenavEvent.emit(data);
  }

  send(data){
    this.sendEvent.emit(data);
  }


  toggle(event){
    this.displayAll = !this.displayAll;
    this.update();
  }

  update() {
    if (!this.displayAll){
      this.displayedDocuments = this.documents?.slice(0, this.windowSize);
    } else{
      this.displayedDocuments = [...this.documents];
    }
    this.quantity = this.documents?.length;

    if (this.documents === null) {
      this.quantity = 0;
      this.isEmpty = true;
    } else {
      this.isEmpty = this.quantity === 0;
    }
    this.displayShowMoreToggle = this.quantity > this.windowSize;
  }
}
