
<h2>Pallets Fechados</h2>
<div class="table">
  <app-table-header *ngIf="pallets.length">
    <!--          <app-table-header-column name="name">-->
    <!--            <span content>Nome</span>-->
    <!--          </app-table-header-column>-->

    <app-table-header-column name="sku">
      <span content>SKU</span>
    </app-table-header-column>

    <!--          <app-table-header-column name="description">-->
    <!--            <span content>Descrição</span>-->
    <!--          </app-table-header-column>-->

    <app-table-header-column name="quantity">
      <span content>Quantidade</span>
    </app-table-header-column>
  </app-table-header>

  <app-table-items *ngIf="pallets.length">
    <app-table-item *ngFor="let p of pallets; let odd = odd" [isOdd]="odd">
      <app-table-column name="sku">
        <span content>{{ p.sku || '-' }}</span>
      </app-table-column>
      <!--            <app-table-column name="description">-->
      <!--              <span content>{{ s.description || '-' }}</span>-->
      <!--            </app-table-column>-->
      <app-table-column name="quantity">
        <span content>{{ p.quantity || '-' }}</span>
      </app-table-column>
    </app-table-item>
  </app-table-items>

  <app-table-items *ngIf="!pallets.length">

    <app-table-item>
      Não serão usados pallets fechados nesta ordem.
    </app-table-item>
  </app-table-items>
</div>

<h2>Picking</h2>
<div class="table">
  <app-table-header *ngIf="steps.length">
    <app-table-header-column name="name">
      <span content>Nome</span>
    </app-table-header-column>

    <app-table-header-column name="sku">
      <span content>SKU</span>
    </app-table-header-column>

    <app-table-header-column name="description">
      <span content>Descrição</span>
    </app-table-header-column>

    <app-table-header-column name="quantity">
      <span content>Quantidade</span>
    </app-table-header-column>
  </app-table-header>

  <app-table-items *ngIf="steps.length">
    <app-table-item *ngFor="let s of steps; let odd = odd" [isOdd]="odd">
      <app-table-column name="name">
        <span content>{{ s.name || '-' }}</span>
      </app-table-column>
      <app-table-column name="sku">
        <span content>{{ s.sku || '-' }}</span>
      </app-table-column>
      <app-table-column name="description">
        <span content>{{ s.description || '-' }}</span>
      </app-table-column>
      <app-table-column name="quantity">
        <span content>{{ s.quantity || '-' }}</span>
      </app-table-column>
    </app-table-item>
  </app-table-items>
  <app-table-items *ngIf="!steps.length">

    <app-table-item>
      Não serão usados produtos do picking nesta ordem.
    </app-table-item>
  </app-table-items>

</div>

<div class="bottom" *ngIf="showBottomButtons">
  <div class="buttons">
    <button mat-stroked-button class="gray-2" (click)="cancel()">CANCELAR</button>
    <button mat-raised-button class="primary-2" (click)="submit()">CONFIRMAR</button>
  </div>
</div>
