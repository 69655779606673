<div class="header-modal">
  <p>Você deseja remover o pallet?</p>
</div>

<div class="content">
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" autocomplete="off" type="password">
    </mat-form-field>
  </form>
</div>

<div class="buttons">
  <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
  <button mat-raised-button class="primary-1" (click)="submmit()">confirmar</button>
</div>
