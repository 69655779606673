<form [formGroup]="filtersForm">
  <mat-form-field appearance="outline">
    <mat-label>Filtrar por nome do produto</mat-label>
    <input matInput
           formControlName="name"
           placeholder="Descrição do produto"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por descrição do produto</mat-label>
    <input matInput
           formControlName="description"
           placeholder="Descrição do produto"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por código do produto</mat-label>
    <input matInput
           formControlName="sku"
           (keydown.space)="$event.preventDefault();"
           placeholder="Código do produto"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por peso mínimo (Kg)</mat-label>
    <input matInput
           formControlName="minWeight"
           type="number"
           placeholder="Peso"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por peso máximo (Kg)</mat-label>
    <input matInput
           formControlName="maxWeight"
           type="number"
           placeholder="Peso"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por altura mínima (m)</mat-label>
    <input matInput
           formControlName="minHeight"
           type="number"
           placeholder="Altura"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por altura máxima (m)</mat-label>
    <input matInput
           formControlName="maxHeight"
           type="number"
           placeholder="Altura"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por comprimento mínimo (m)</mat-label>
    <input matInput
           formControlName="minWidth"
           type="number"
           placeholder="Largura"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por comprimento máximo (m)</mat-label>
    <input matInput
           formControlName="maxWidth"
           type="number"
           placeholder="Largura"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por profundidade mínima (m)</mat-label>
    <input matInput
           formControlName="minDepth"
           type="number"
           placeholder="Largura"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filtrar por profundidade máxima (m)</mat-label>
    <input matInput
           formControlName="maxDepth"
           type="number"
           placeholder="Largura"
           autocomplete="off">
  </mat-form-field>

  <div class="buttons">
    <button mat-stroked-button class="gray-1" (click)="clear()">LIMPAR</button>
    <button mat-raised-button class="primary-2" (click)="apply()">APLICAR</button>
  </div>
</form>
