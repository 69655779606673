<div class="tool-tip"
     [ngStyle]="{'top.px':mouseY, 'left.px': mouseX > 473 ? mouseX-148 : mouseX+24}"
     [ngClass]="{show:showTooltip, hide:!showTooltip}"
>
  <div class="item" *ngFor="let t of tooltipValues">
    <span *ngIf="t.name && !t.levelName && !t.z">{{t.name}}</span>
    <span *ngIf="t.name && t.levelName">{{t.name}} - {{t.levelName}}</span>
    <span *ngIf="t.name && !t.levelName && t.z">{{t.name}} - {{t.z + 'cm'}}</span>
    <span *ngIf="t.error">{{t.error}}</span>
  </div>

</div>

<div class="zoom-buttons" *ngIf="!isMapEmpty">
  <button mat-raised-button color="primary" (click)="zoom('OUT')">-</button>
  <button mat-raised-button color="primary" (click)="zoom('IN')">+</button>
</div>

<div class="map-legend" [ngClass]="{'expand': toggleTip}" (click)="onToggleTip()" *ngIf="!isMapEmpty">
  <i class="uil uil-minus" *ngIf="!toggleTip"></i>
  <ng-container *ngIf="toggleTip">
    <div class="legend title">Para navegar pelo mapa utilize o mouse:</div>
    <div class="legend item"><img src="assets/icons/zoom-in.svg"> Zoom: botões</div>
    <div class="legend item"><img src="assets/icons/drag.svg"> Navegar: clique e arraste</div>
  </ng-container>
</div>

<div class="empty-map" *ngIf="isMapEmpty">
  NÃO EXISTE TAGS CADASTRADAS NO SISTEMA.
</div>
