import { Component, Input, OnInit } from '@angular/core';
import { ICrateData } from '../../../../models/Report';

@Component({
  selector: 'app-reports-table-modal',
  templateUrl: './reports-table-modal.component.html',
  styleUrls: ['./reports-table-modal.component.scss']
})
export class ReportsTableModalComponent implements OnInit {
  @Input() items: ICrateData[];
  displayedColumns = [];

  constructor() { }

  ngOnInit(): void {
    this.displayedColumns = [{
      label: 'RFID Pallet',
      size: 'medium',
      name: 'sku'
    }, {
      label: 'Posição esperada',
      size: 'small',
      name: 'description'
    }, {
      label: 'Posição encontrada',
      size: 'small',
      name: 'expected-qty'
    }, {
      label: 'Altura esperada',
      size: 'small',
      name: 'found-qty'
    }, {
      label: 'Altura encontrada',
      size: 'small',
      name: 'balance'
    }, {
      label: 'Status',
      size: 'medium',
      name: 'status'
    }];
  }

}
