<app-table-column name="checkbox" size="smallest">
  <mat-checkbox (change)="onCheckboxChange($event.source.value)"
                [value]="pallet"
                [checked]="selected"
                [disabled]="!pallet.productName"
                content></mat-checkbox>
</app-table-column>

<app-table-column name="oc" size="small">
  <div content>
    <ng-container *ngIf="pallet.buyOrders?.length">
      <span *ngFor="let oc of pallet.buyOrders">{{ oc }}</span>
    </ng-container>
    <span *ngIf="!pallet.buyOrders?.length">---</span>
  </div>
</app-table-column>

<app-table-column name="nfe" size="small" (click)="handleGotoOther($event)">
  <div content [ngClass]="{'red-text': pallet.invoiceStatus === 'DIVERGENT'}">
    {{ pallet.invoiceId || '---' }}
    <app-background-icon *ngIf="pallet.invoiceStatus === 'DIVERGENT'" icon="times"
                         backgroundColor="alert"
                         size="small">
    </app-background-icon>
  </div>
</app-table-column>

<app-table-column name="vendor-name" size="medium" (click)="handleGotoOther($event)">
  <span content>{{ pallet.vendorName || '---' }}</span>
</app-table-column>

<app-table-column name="product" size="medium" (click)="handleGotoOther($event)">
  <span content>{{ pallet.productName || '---' }}</span>
</app-table-column>

<app-table-column name="quantity" size="smallest" (click)="handleGotoOther($event)">
  <span content>{{ pallet.productQty || '---' }}</span>
</app-table-column>

<app-table-column name="last-destination" size="small" (click)="handleGotoOther($event)">
  <span content>{{ pallet.lastDestination || '---' }}</span>
</app-table-column>

<app-table-column name="forecast" size="small" (click)="handleGotoOther($event)">
  <span content>{{ handleDeliveryDate(pallet.buyOrders, pallet.deliveryDate) || '---' }}</span>
</app-table-column>

<app-table-column name="messages" size="smallest" (click)="handleComment($event)">
  <div content>
    <i *ngIf="!pallet.commentsQty" class="uil uil-comment"></i>
    <i *ngIf="pallet.commentsQty" class="uil uil-comment-lines"></i>
    <span>{{ pallet.commentsQty }}</span>
  </div>
</app-table-column>
