import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  constructor(private http: HttpClient) { }

  getGoods() {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/physical-inspection').toPromise<any>();
  }

  getGoodDetails(tagRfid) {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/physical-inspection/${tagRfid}`).toPromise();
  }
}
