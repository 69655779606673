import { Component, Input, OnInit } from '@angular/core';
import { toExcelFormat } from 'src/app/helpers/format-position';
import { IReportDataInventoryByCrate, translateState, translateType } from 'src/models/Report';
import { CrateStateEnum, translateState as translateCrateState } from 'src/models/Crate';

@Component({
  selector: 'app-pallet-details-modal',
  templateUrl: './pallet-details-modal.component.html',
  styleUrls: ['./pallet-details-modal.component.scss']
})
export class PalletDetailsModalComponent implements OnInit {
  @Input() reportData: IReportDataInventoryByCrate;
  translateState = translateState;
  translateType = translateType;
  toExcelFormat = toExcelFormat;
  translateCrateState = translateCrateState;
  foundHeight = 0;
  foundPosition = '';
  crateState = '';

  constructor() { }

  ngOnInit(): void {
    this.crateState = this.reportData.crate?.inconsistency?.state ?
      translateCrateState(CrateStateEnum[this.reportData.crate?.inconsistency?.state]) :
      translateCrateState(this.reportData.crate?.state);
    this.foundHeight = this.reportData.crate?.inconsistency?.z ?
      this.reportData.crate.inconsistency.z :
      this.reportData.crate.z;
    if (this.reportData.crate?.inconsistency?.state === CrateStateEnum.MISSING) {
      this.foundPosition = 'Pallet não encontrado';
    } else {
      this.foundPosition = this.reportData.crate?.inconsistency?.x && this.reportData.crate?.inconsistency?.y ?
        toExcelFormat(this.reportData.crate.inconsistency?.x, this.reportData.crate.inconsistency?.y) :
        toExcelFormat(this.reportData.crate.x, this.reportData.crate.y);
    }
  }

}
