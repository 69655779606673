<div class="header-modal">
  <p>Editar Apelido</p>
</div>

<mat-form-field>
  <input matInput placeholder="Apelido" type="text" [formControl]="aliasForm" [maxlength]="5">
</mat-form-field>

<div class="buttons">
  <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
  <button mat-raised-button class="primary-1" (click)="save()"
    [disabled]="aliasForm.pristine">
    confirmar
  </button>
</div>
