<app-header *ngIf="isLogged" [user]="user"></app-header>
<mat-drawer-container [hasBackdrop]="true" (backdropClick)="closeDrawer()">
  <mat-drawer-content [ngClass]="currentPage">
  <router-outlet></router-outlet>
  </mat-drawer-content>

  <mat-drawer #taskRequestDrawer
              class="task-request-drawer"
              position="end"
              [(opened)]="isOpen"
              [ngSwitch]="drawerType"
              [autoFocus]="false">
    <app-inspection-sidenav *ngSwitchCase="'INSPECTION'" [drawer]="taskRequestDrawer" [good]="drawerData"></app-inspection-sidenav>
    <app-stock-inventory-sidenav  #stockSidenav *ngSwitchCase="'STOCK_INVENTORY'" [drawer]="taskRequestDrawer" [data]="drawerData"></app-stock-inventory-sidenav>
    <app-stock-relocate-sidenav *ngSwitchCase="'STOCK_RELOCATE'" [drawer]="taskRequestDrawer" [data]="drawerData"></app-stock-relocate-sidenav>
    <app-add-product-on-product-picking-sidenav *ngSwitchCase="'PRODUCT_PICKING_RELOCATE'" [drawer]="taskRequestDrawer" [data]="drawerData"></app-add-product-on-product-picking-sidenav>
    <app-relocate-from-picking-position-sidenav *ngSwitchCase="'RELOCATE_FROM_PRODUCT_PICKING'" [drawer]="taskRequestDrawer" [data]="drawerData"></app-relocate-from-picking-position-sidenav>

    <app-stock-relocate-single-pallet-sidenav *ngSwitchCase="'STOCK_RELOCATE_SINGLE_PALLET'" [drawer]="taskRequestDrawer" [task]="drawerData"></app-stock-relocate-single-pallet-sidenav>
    <app-stock-inventory-by-tag-rfid-sidenav *ngSwitchCase="'STOCK_INVENTORY_BY_TAG_RFID'" [drawer]="taskRequestDrawer" [task]="drawerData"></app-stock-inventory-by-tag-rfid-sidenav>
    <app-packing-start-sidenav *ngSwitchCase="'START_PACKING'" [drawer]="taskRequestDrawer" [task]="drawerData"></app-packing-start-sidenav>
    <app-stock-pending-expedition-sidenav *ngSwitchCase="'MISPLACED_EXPEDITION'" [drawer]="taskRequestDrawer" [task]="drawerData"></app-stock-pending-expedition-sidenav>
    <app-relocate-load-dispatch-sidenav *ngSwitchCase="'RELOCATE_LOAD_DISPATCH'" [drawer]="taskRequestDrawer" [task]="drawerData"></app-relocate-load-dispatch-sidenav>
    <app-restock-picking-sidenav *ngSwitchCase="'EMPTY_PICKING_POSITION'" [drawer]="taskRequestDrawer" [task]="drawerData"></app-restock-picking-sidenav>
  </mat-drawer>
</mat-drawer-container>

<notifier-container></notifier-container>

<ng-template #nfeNotification let-notificationData="notification">
  <app-notification-nfe [data]="notificationData"></app-notification-nfe>
</ng-template>
<app-full-page-loader *ngIf="isLoaderOpen"></app-full-page-loader>
