import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {GroundPosition, MapViewComponent} from '../../../../shared/map-view/map-view.component';
import {
  drawColumns,
  drawGroundPosition,
  drawGroundPositionAtGroup,
  drawGroundPositionUnavailable,
  drawRows,
  getHeight,
  getWidth
} from './ground-cell';
import {GroundGroupsMapState} from '../setup-ground.component';
import * as PIXI from 'pixi.js';


@Component({
  selector: 'app-ground-groups-map',
  templateUrl: './ground-groups-map.component.html',
  styleUrls: ['./ground-groups-map.component.scss']
})
export class GroundGroupsMapComponent extends MapViewComponent implements OnInit, OnChanges{

  @Input()
  public map;
  @Input()
  selectedPositionIds;

  @Output()
  public positionClicked = new EventEmitter();

  @Input()
  public state: GroundGroupsMapState;
  @Input() selectedGroupId;

  public mouseX;
  public mouseY;

  public highlight;

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @HostListener('mousemove', ['$event']) onMouseMove(event) {
    const canvas = (this.elementRef.nativeElement as HTMLElement).querySelector('canvas');
    const canvasTop = canvas.getBoundingClientRect().top;
    const canvasLeft = canvas.getBoundingClientRect().left;
    this.mouseX = event.clientX - canvasLeft; // x position within the element.
    this.mouseY = event.clientY - canvasTop;
  }

  constructor(
    protected elementRef: ElementRef,
    protected ngZone: NgZone
  ) {
    super(elementRef, ngZone);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.state?.currentValue){
      this.state = changes.state?.currentValue;
      // this.drawMap(this.map);
      // this.updateMap(this.map)

      const {previousValue: previousState, currentValue: currentState} = changes.state;
      switch (previousState) {
        case GroundGroupsMapState.DEFAULT:
          switch (currentState) {
            case GroundGroupsMapState.GROUP_HIGHLIGHT:
              this.highlightSelection(this.map);
              break;
            case GroundGroupsMapState.ADDING_TAGS:
            case GroundGroupsMapState.DEFAULT:
              break;
          }
          break;
        case GroundGroupsMapState.GROUP_HIGHLIGHT:
          switch (currentState) {
            case GroundGroupsMapState.DEFAULT:
              this.blurSelection(this.map);
              break;
            case GroundGroupsMapState.GROUP_HIGHLIGHT:
            case GroundGroupsMapState.ADDING_TAGS:
              break;
          }
          break;
        case GroundGroupsMapState.ADDING_TAGS:
          switch (currentState) {
            case GroundGroupsMapState.DEFAULT:
              this.drawMap(this.map);
              break;
            case GroundGroupsMapState.GROUP_HIGHLIGHT:
            case GroundGroupsMapState.ADDING_TAGS:
              break;
          }
          break;
      }
    }

    if (changes.selectedPositionIds?.currentValue){
      this.selectedPositionIds = changes.selectedPositionIds?.currentValue;
      this.highlightSelection(this.map);
    }

    if (!changes.map?.previousValue && changes.map?.currentValue){
      const gradePositions = this.getMaxCoordinates(changes.map.currentValue);
      const maxWidth = getWidth(gradePositions.positionsX.length);
      const maxHeight = getHeight(gradePositions.positionsY.length);
      this.initMap(maxWidth, maxHeight);
      this.drawMap(changes.map.currentValue);
    }
    else if (changes.map?.currentValue){
    //  this.drawMap(changes.map.currentValue);
    }
  }

  ngOnInit(): void {

  }

  public blurSelection(groundPositions: GroundPosition[]) {
    if(this.highlight){
      this.highlight.destroy();
    }
  }
  public highlightSelection(groundPositions: GroundPosition[]){
    if(this.highlight){
      this.highlight.destroy();
    }
    this.highlight = new PIXI.Container();
    if(groundPositions && groundPositions.length>0){
      for (const groundPosition of groundPositions) {
        if(this.selectedPositionIds.has(groundPosition.rfId)){
          const elements = drawGroundPositionAtGroup(groundPosition);
          const area = elements[0];
          area.interactive = true;
          area.cursor = 'pointer';
          area.on('pointerdown', () => {
            switch (this.state) {
              case GroundGroupsMapState.ADDING_TAGS:
                this.positionClicked.emit(groundPosition);
                break;
            }
          });
          area.on('pointerup', () => {
            switch (this.state) {
              case GroundGroupsMapState.ADDING_TAGS:
                break;
            }
          });
          for (const e of elements) {
            this.highlight.addChild(e);
          }
        }
      }
      // @ts-ignore
      this.viewPort.addChild(this.highlight);
    }

  }

  public drawMap(groundPositions: GroundPosition[]) {
    if (groundPositions) {
      this.drawGrades(groundPositions);
      this.drawDefaultMap(groundPositions);
      this.scrollBox.update();
    }
  }

  drawDefaultMap(groundPositions: GroundPosition[]) {
    this.clearContent();
    let i =0;
    for (const groundPosition of groundPositions) {
      let elements;
      let area;
      if(groundPosition.groupId !== null){
          elements = drawGroundPositionUnavailable(groundPosition);
      } else{
          elements = drawGroundPosition(groundPosition);
      }
      area = elements[0];
      area.interactive = true;
      area.cursor = 'pointer';
      area.on('pointerdown', () => {
        switch (this.state) {
          case GroundGroupsMapState.ADDING_TAGS:
            this.positionClicked.emit(groundPosition);
            break;
        }
      });
      area.on('pointerup', () => {
        switch (this.state) {
          case GroundGroupsMapState.ADDING_TAGS:
            break;
        }
      });


      for (const e of elements) {
        // @ts-ignore
        this.viewPort.addChild(e);
      }

    }
  }

}
