import { Component, OnInit } from '@angular/core';
import {UserDataSourceService} from '../../core/data-sources/user-data-source.service';

@Component({
  selector: 'app-debug-tasks',
  templateUrl: './debug-tasks.component.html',
  styleUrls: ['./debug-tasks.component.scss']
})
export class DebugTasksComponent implements OnInit {

  allTasks;

  constructor(private user: UserDataSourceService) { }

  ngOnInit(): void {
    this.getTasks();
  }

  async getTasks() {
    try {
      this.allTasks = await this.user.getUserTasks();
    } catch (e) {
      console.log(e);
    }
  }

}
