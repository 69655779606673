<div class="page-header">
  <app-prev-next-button (click)="toDashboard()" direction="left" icon="estate"></app-prev-next-button>
  <h1>Gerenciamento de Produtos</h1>
</div>

<app-pending-task-widget (updated)="update()"></app-pending-task-widget>

<div class="section-wrap">
  <div class="filters-wrap">
    <div class="filters-btn"
         (click)="openFilters()"
         [matBadge]="numberOfFiltersApply"
         [matBadgeHidden]="filterToggle || numberOfFiltersApply === 0"
         matBadgeColor="warn"
         [ngClass]="{'blue-label': filterToggle}">
      <i class="uil uil-filter"></i>
      filtrar
    </div>
  </div>

  <div class="buttons">
    <button mat-raised-button
            class="secondary-2 add-product-btn"
            (click)="openAddProductModal()">
      ADICIONAR
    </button>

    <div class="update-button">
      <p>Atualizado às {{ actualHour }}</p>
      <button mat-raised-button (click)="update()">
        <i class="uil uil-sync"></i>
        <span>Atualizar</span>
      </button>
    </div>
  </div>
</div>

<app-product-management-filters *ngIf="filterToggle"
                                (valuesToFilter)="filter($event)"
                                [valuesApplied]="filtersApplied">
</app-product-management-filters>

<app-product-management-table [products]="products"
                              [isLoading]="isLoading"
                              [filterParams]="filtersApplied"
                              (itemClick)="openDetailsModal($event)"
                              (itemDelete)="deleteProduct($event)">
</app-product-management-table>

<app-full-page-loader *ngIf="fullPageLoading"></app-full-page-loader>
