import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {LayoutService} from '../../../core/data-sources/layout.service';
import {ActionService, TenantState} from '../../../core/services/action.service';

@Component({
  selector: 'app-setup-tags',
  templateUrl: './setup-tags.component.html',
  styleUrls: ['./setup-tags.component.scss']
})
export class SetupTagsComponent implements OnInit {
  @ViewChild('mapComponent', { static: true }) mapComponent: any;

  groups = [];
  map;

  constructor( private actionService: ActionService,
               private layoutService: LayoutService,
               private router: Router
  ) {}

  ngOnInit(): void {
    this.loadData();
  }
  async loadData(){
    this.layoutService.getMap().then(res => {
      this.map = res;
    });
  }

  async finish(){
    await this.actionService.setTenantState({state: TenantState.SETUP_GROUPS});
    await this.router.navigate(['/setup-ground']);
  }

  async toSetup(){
    await this.router.navigate(['/setup']);
  }

}
