<mat-tab-group mat-align-tabs="center"
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)">
  <mat-tab label="Pallet">
    <app-pallet-details
      [crateDetails]="data.itemDetails"
      [isMap]="true"
      (relocateClicked)="onRelocateClicked($event)">
    </app-pallet-details>
  </mat-tab>
  <mat-tab *ngIf="data.item.palletStatus === 'SURPLUS' || data.item.palletStatus === 'MISSING'" label="Conferência Física">
    <app-physical-inspection 
      [itemDetails]="data.itemDetails"
      [color]="data.item.palletStatus === 'SURPLUS' ? 'warning' : 'alert'">
    </app-physical-inspection>
  </mat-tab>
  <mat-tab label="Movimentações">
    <app-movement-details [crateId]="data.itemDetails.crate.id"></app-movement-details>
  </mat-tab>
  <mat-tab label="Detalhes dos produtos">
    <app-product-image-tab [products]="products"></app-product-image-tab>
  </mat-tab>
  <mat-tab label="Comentários">
    <app-in-details-comments [itemDetails]="data.itemDetails"></app-in-details-comments>
  </mat-tab>
</mat-tab-group>