import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderDataSourceService {

  constructor(private http: HttpClient) { }

  getTenant() {
    const domain = /^https?:\/\/(\b.+?\b)\..*$/.exec(location.origin)[1];
    return this.http.get(environment.baseUrl + `/api/v1/${domain}/tenantId`).toPromise<any>();
  }
}
