import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductManagementFiltersParams } from '../../../../models/Product';

@Component({
  selector: 'app-product-management-filters',
  templateUrl: './product-management-filters.component.html',
  styleUrls: ['./product-management-filters.component.scss']
})
export class ProductManagementFiltersComponent implements OnInit {

  @Output() valuesToFilter = new EventEmitter();
  @Input() valuesApplied: ProductManagementFiltersParams;

  filtersForm: FormGroup;
  constructor(private formBuilder: FormBuilder) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.setAppliedValues();
  }

  buildForm(): void {
    this.filtersForm = this.formBuilder.group({
      name: [null],
      description: [null],
      sku: [null],
      minWeight: [null],
      maxWeight: [null],
      minHeight: [null],
      maxHeight: [null],
      minWidth: [null],
      maxWidth: [null],
      minDepth: [null],
      maxDepth: [null]
    });
  }

  setAppliedValues() {
    if (this.valuesApplied) {
      const keys = Object.keys(this.valuesApplied);
      keys.forEach(k => {
        this.filtersForm.controls[k].setValue(this.valuesApplied[k]);
      });
    }
  }

  clear(): void {
    this.filtersForm.reset();
    this.valuesToFilter.emit();
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null || params[key] === '') {
        delete params[key];
      }
    });
  }

  apply(): void {
    const formControls = this.filtersForm.controls;
    const params: ProductManagementFiltersParams = {
      name: formControls.name.value,
      description: formControls.description.value,
      sku: formControls.sku.value,
      minWeight: formControls.minWeight.value,
      maxWeight: formControls.maxWeight.value,
      minHeight: formControls.minHeight.value,
      maxHeight: formControls.maxHeight.value,
      minWidth: formControls.minWidth.value,
      maxWidth: formControls.maxWidth.value,
      minDepth: formControls.minDepth.value,
      maxDepth: formControls.maxDepth.value
    };

    this.removeNullKeys(params);
    this.valuesToFilter.emit(params);
  }
}
