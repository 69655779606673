import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StockDataSourceService } from 'src/app/core/data-sources/stock-data-source.service';
import { toExcelFormat } from 'src/app/helpers/format-position';
import { findCrateLevel } from 'src/app/helpers/find-crate-level';
import * as moment from 'moment';

@Component({
  selector: 'app-out-order-details-modal',
  templateUrl: './out-order-details-modal.component.html',
  styleUrls: ['./out-order-details-modal.component.scss']
})
export class OutOrderDetailsModalComponent implements OnInit {
  level;
  constructor(
    public dialogRef: MatDialogRef<OutOrderDetailsModalComponent>,
    private stockService: StockDataSourceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.mapCratesSku();
  }

  mapCratesSku() {
    this.data.crates.forEach(async crate => {
      crate.details = await this.stockService.getStockDetails(crate.tagRfid);
      if (crate.details) this.level = findCrateLevel(crate.z, crate.details.levels);
      this.data.executionPlan.selectedPallets.forEach(pallet => {
        if (crate.id === pallet.palletId) {
          crate.sku = pallet.sku;
        }
      });
      if (crate.details.crate.skuGroups.length) {
        crate.pickingProductsSku = [];
        crate.details.crate.skuGroups.forEach(pallet => {
          if (crate.id === pallet.crateId) {
            crate.pickingProductsSku.push(pallet.sku);
          }
        });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  timestampToDate(timestamp) {
    return moment(timestamp).format('DD/MM/YYYY - HH:mm');
  }

  setExcelFormat(x, y) {
    return toExcelFormat(x, y);
  }

}
