import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PendingTaskService, PendingTaskTypeEnum } from '../../core/data-sources/pending-task.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pending-task-widget',
  templateUrl: './pending-task-widget.component.html',
  styleUrls: ['./pending-task-widget.component.scss']
})
export class PendingTaskWidgetComponent implements OnInit {
  @Input() pageTitle = '';
  @Output() updated = new EventEmitter();
  @Output() errorPendingTask = new EventEmitter();
  pendingTasks = [];
  pendingTaskOrigin;

  constructor(
    private router: Router,
    private pendingTaskService: PendingTaskService
  ) {}

  ngOnInit(): void {
    this.pendingTaskOrigin = this.router.url.split('/').pop();
    this.getPendingTask();
  }

  async getPendingTask() {
    try {
      await this.setPendingTask(this.pendingTaskOrigin);
    }
    catch (err) {
      this.errorPendingTask.emit();
    }
  }

  update() {
    this.updated.emit();
    this.getPendingTask();
  }

  async goTo() {
    await this.router.navigate(['pending-tasks'], { queryParams: { origin: this.pendingTaskOrigin, label: this.pageTitle } });
  }

  async setPendingTask(type: string) {
    switch (type) {
      case 'picking':
        this.pendingTasks = await this.pendingTaskService.getPendingTasks();
        this.pendingTasks = this.pendingTasks.filter(task => task.type.toLowerCase().includes('picking'));
        break;
      case 'product-management':
        this.pendingTasks = await this.pendingTaskService.getProductPendingTasks();
      break;
      default:
        this.pendingTasks = await this.pendingTaskService.getPendingTasks();
        this.pendingTasks = this.pendingTasks.filter(task => !task.type.toLowerCase().includes('picking') && task.type != PendingTaskTypeEnum.MISSING_PALLETS);
        break;
    }
  }
}
