import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StockDetailsInterface } from 'src/app/modals/stock-details-modal/stock-details-modal.component';

@Component({
  selector: 'app-map-pallet-details',
  templateUrl: './map-pallet-details.component.html',
  styleUrls: ['./map-pallet-details.component.scss']
})
export class MapPalletDetailsComponent implements OnInit {
  @Input() public data: StockDetailsInterface;
  @Output() relocateClicked = new EventEmitter();
  selected = new FormControl(0);
  highlightedProducts = [];
  isCommentChange = false;
  isDestinyChange = false;
  products: {
    name: string;
    sku: string;
  }[];

  constructor() { }

  ngOnInit(): void {
    this.parseProduct();
    this.selected.setValue(this.data.page);
    this.highlightedProducts = this.data.item.skuGroups.map(p => p.sku);
    this.data.itemDetails.comments = this.data.itemDetails.comments.filter((comment)=> !(comment.deletedAt != 0))
  }

  parseProduct() {
    this.products = this.data.item.skuGroups.map(p => {
      return {
        name: p.name,
        sku: p.sku
      };
    });
  }

  onRelocateClicked(event) {
    this.relocateClicked.emit(event);
  }

}
