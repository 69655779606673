import { Component, Input, OnInit } from '@angular/core';
import { FirebaseService } from '../../../core/services/firebase.service';
import { ErrorService } from '../../../core/services/error.service';

@Component({
  selector: 'app-product-image-tab',
  templateUrl: './product-image-tab.component.html',
  styleUrls: ['./product-image-tab.component.scss']
})
export class ProductImageTabComponent implements OnInit {

  @Input() products: {
    name: string;
    sku: string;
    productImageUrl?: string;
  }[];

  isLoading = false;

  constructor(private firebaseService: FirebaseService,
              private errorService: ErrorService) { }

  ngOnInit(): void {
    this.getProductImage();
  }

  async getProductImage() {
    try {
      this.isLoading = true;
      this.products.forEach(async (p) => {
        p.productImageUrl = await this.firebaseService.getProductImage(p.sku);
        this.isLoading = false;
      });
    } catch (e) {
      this.isLoading = false;
      this.errorService.openErrorSnackBar(e);
    }
  }

}
