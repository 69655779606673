<app-header-modal [color]="data.item.palletStatus === 'OK' ? 'secondary-2' :
                           data.item.palletStatus === 'MISSING' ? 'alert' : 'warning'"
                  title="Pedido N° 1002">
  <div class="status-header" status>
    <app-status-header-modal
      [icon]="data.item.palletStatus === 'OK' ? 'check' : data.item.palletStatus === 'SURPLUS' ? 'plus' : 'minus'"
      [color]="data.item.palletStatus === 'OK' ? 'success' : data.item.palletStatus === 'SURPLUS' ? 'warning' : 'alert'"
      [status]="data.item.palletStatus === 'OK' ? 'Conferido' : data.item.palletStatus === 'SURPLUS' ? 'Excesso' : 'Faltante'">
    </app-status-header-modal>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>

<mat-tab-group mat-align-tabs="center" [selectedIndex]="selected.value"
               (selectedIndexChange)="selected.setValue($event)">
  <mat-tab *ngIf="data.item.palletStatus === 'SURPLUS' || data.item.palletStatus === 'MISSING'" label="Conferência Física">
    <app-physical-inspection [itemDetails]="data.itemDetails"
                             (closeEvent)="close($event)"
                             [color]="data.item.palletStatus === 'SURPLUS' ? 'warning' : 'alert'"></app-physical-inspection>
  </mat-tab>
  <mat-tab label="Detalhes dos produtos">
    <app-product-image-tab [products]="products"></app-product-image-tab>
  </mat-tab>
  <mat-tab label="Nota Fiscal" *ngIf="showInvoiceTab">
    <app-in-details-nfe [nfe]="data.itemDetails"
                        [highlightedProducts]="highlightedProducts"
                        (destinationChanged)="onChangeDestination()"></app-in-details-nfe>
  </mat-tab>
  <mat-tab label="Ordem de compra" *ngIf="showOrdersTab">
    <app-in-details-buy-order [data]="data.itemDetails"></app-in-details-buy-order>
  </mat-tab>
  <mat-tab label="Comentários">
    <app-in-details-comments [itemDetails]="data.itemDetails"
                             (submitEvent)="submitComment($event)"
                             (deleteEvent)="deleteComment($event)"></app-in-details-comments>
  </mat-tab>
</mat-tab-group>
