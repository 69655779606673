import {Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import {TaskRequestSidenavService} from '../../../core/services/task-request-sidenav.service';
import {Subscription} from 'rxjs';

interface Good {
  name: string;
  quantity: string;
  orderNumber: string;
  invoiceNumber: string;
  palletType: string;
  tagRfid: string;
}
export enum Destination {
  STORAGE= 'STORAGE',
  QUALITY= 'QUALITY',
  STOCK_DIVERGENT = 'STOCK_DIVERGENT'
}

export interface InspectionSidenavData {
  goods: Good[];
  initialDestination: Destination;
}

@Component({
  selector: 'app-inspection-sidenav',
  templateUrl: './inspection-sidenav.component.html',
  styleUrls: ['./inspection-sidenav.component.scss']
})
export class InspectionSidenavComponent implements OnInit, OnDestroy, OnChanges {
  selectedDestination = Destination.STORAGE;
  destinationOptions = [];

  isCritical = false;
  usersHighlighted = [];

  selectOperatorError = false;

  @Input() drawer;
  @Input() good: InspectionSidenavData;
  openSubscription: Subscription;

  goods: Good[] = [];
  palletType: string;

  constructor(private sidenavService: TaskRequestSidenavService) {
    this.destinationOptions = [{label: 'Armazenagem', value: 'STORAGE'},
      {label: 'Controle de qualidade', value: 'QUALITY'}, {label: 'Estoque divergente', value: 'STOCK_DIVERGENT'}];
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.good.currentValue){
      const {goods, initialDestination, palletType} = changes.good.currentValue;
      this.goods = goods;
      this.selectedDestination = initialDestination;
      this.palletType = palletType;
    }
  }

  ngOnInit(): void {
    this.openSubscription = this.drawer.openedStart.subscribe(() => {
      this.selectOperatorError = false;
    });
  }

  ngOnDestroy() {
    this.openSubscription?.unsubscribe();
  }

  setUsers(users) {
    this.usersHighlighted = users;
  }

  setOperatorLengthError() {
    this.selectOperatorError = !this.usersHighlighted.length;
  }

  submit() {
    this.setOperatorLengthError();
    try {
      if (this.usersHighlighted.length) {
        const uniqueTags = new Set(this.goods.map(g=>g.tagRfid));
        const rfidTags = Array.from(uniqueTags);
        const data = {
          selectedDestination: this.selectedDestination,
          userIds: this.usersHighlighted,
          isCritical: this.isCritical,
          rfidTags
        };
        this.sidenavService.closeSidenav(data);
        this.drawer.close();
      }
    } finally {
      this.resetSidenav();
    }
  }

  close() {
    try {
      this.sidenavService.closeSidenav(false);
      this.drawer.close();
    } finally {
      this.resetSidenav();
    }
  }

  resetSidenav() {
    this.isCritical = false;
    this.usersHighlighted = [];
  }

  onCriticalChange(isChecked) {
    this.isCritical = isChecked;
  }


}
