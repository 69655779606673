<app-header-modal color="secondary-2" [title]="title">
  <i class="uil uil-times" (click)="close()" status></i>
</app-header-modal>

<form [formGroup]="productForm">
  <mat-form-field appearance="outline">
    <mat-label>SKU</mat-label>
    <input matInput type="text" formControlName="sku" autocomplete="off" (keydown.space)="$event.preventDefault();" required>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Nome</mat-label>
    <input matInput type="text" formControlName="name" autocomplete="off" required>
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Descrição</mat-label>
    <input matInput type="text" formControlName="description" autocomplete="off">
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Peso (Kg)</mat-label>
    <input matInput type="number" formControlName="weight" autocomplete="off">
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Altura (m)</mat-label>
    <input matInput type="number" formControlName="height" autocomplete="off">
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Largura (m)</mat-label>
    <input matInput type="number" formControlName="width" autocomplete="off">
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Profundidade (m)</mat-label>
    <input matInput type="number" formControlName="depth" autocomplete="off">
  </mat-form-field>
  <div class="file-upload">
    <label for="image-upload" class="custom-image-upload">escolha uma imagem</label>
    <input id="image-upload" type="file" (change)="onImageSelected($event)">
  </div>
  <section class="selected-image-label">
    {{imageName || "Nenhum arquivo selecionado"}}
  </section>

  <div class="buttons">
    <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
    <button mat-raised-button class="primary-1" (click)="createProduct()">
      <mat-spinner class="spinner" [diameter]="30" *ngIf="isLoading"></mat-spinner>
      <span *ngIf="!isLoading">{{ btnLabel }}</span>
    </button>
  </div>
</form>
