import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionService } from '../../../core/services/action.service';
import { TaskRequestSidenavService } from '../../../core/services/task-request-sidenav.service';
import { SideNavResponse } from '../../stock/side-nav/SideNav';
import { SidenavFooterAction } from '../../../shared/sidenav-footer/sidenav-footer.component';
import { Router } from '@angular/router';
import { toExcelFormat } from '../../../helpers/format-position';

@Component({
  selector: 'app-restock-picking-sidenav',
  templateUrl: './restock-picking-sidenav.component.html',
  styleUrls: ['./restock-picking-sidenav.component.scss']
})
export class RestockPickingSidenavComponent implements OnChanges {

  @Input() drawer;
  @Input() task;

  selectedUsers = [];
  selectOperatorError = false;
  noAvailablePalletsError = false;
  relocateForm: FormGroup;

  toExcelFormat = toExcelFormat;

  constructor(private formBuilder: FormBuilder,
              private actionService: ActionService,
              private sidenavService: TaskRequestSidenavService,
              private router: Router) {
    this.relocateForm = this.formBuilder.group({
      pallet: ['', Validators.required],
      product: ['nome não definido'],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.task.currentValue) {
      this.relocateForm.controls.product.setValue(changes.task.currentValue.product);
      this.noAvailablePalletsError = this.task.suggestedCrates.length === 0;
    }
  }

  selectUsers(users) {
    this.selectedUsers = users;
    this.selectOperatorError = this.selectedUsers.length === 0;
  }

  getCratesInfoString(product, last): string {
    if (last) {
      return 'SKU: ' + product.sku + ', quantidade: ' + product.quantity;
    } else {
      return 'SKU: ' + product.sku + ', quantidade: ' + product.quantity + '; ';
    }
  }

  parseTooltip(crate): string {
    let tooltipString = '';
    crate.skuGroups.forEach((product, i) => {
      if (i === crate.skuGroups.length) {
        tooltipString += 'SKU: ' + product.sku + ', quantidade: ' + product.quantity;
      } else {
        tooltipString += 'SKU: ' + product.sku + ', quantidade: ' + product.quantity + '; ';
      }
    });
    return tooltipString;
  }

  reset() {
    this.relocateForm.reset();
    this.selectedUsers = [];
    this.selectOperatorError = false;
  }

  close(event: SideNavResponse = SideNavResponse.CLOSED) {
    this.reset();
    this.drawer.close();
    this.sidenavService.closeSidenav(event);
  }

  async footerClicked(event){
    if (event === SidenavFooterAction.SUBMMITED){
      this.relocateForm.markAllAsTouched();
      if (this.relocateForm.valid && this.selectedUsers.length > 0) {
        await this.sendTask();
        this.close(SideNavResponse.SUBMMITED);
      } else {
        this.selectOperatorError = this.selectedUsers.length === 0;
      }
    } else {
      this.close(SideNavResponse.CLOSED);
    }
  }

  async sendTask() {
    const params = {
      type: 'RELOCATE_POSITION',
      userIds: this.selectedUsers,
      tagsRfid: [this.relocateForm.controls.pallet.value],
      taskData: {
        destiny: this.task.position
      }
    };
    await this.actionService.bulkSendTask(params);
  }

  toProductPicking(): void {
    this.router.navigate(['/product-picking']);
    this.close();
  }

}
