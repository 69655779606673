<h1>Notas fiscais de demonstracao</h1>

<div class="content" style="{'visibility': !loading}">
  <div class="invoices not-consumed">
    <h3>Notas fiscais nao processadas</h3>
    <ng-container *ngIf="notConsumedInvoices.length > 0">
      <div class="invoice" *ngFor="let invoice of notConsumedInvoices">
        <div class="data">
          <div class="number">
            <div class="label">numero</div>
            {{invoice.number}}
          </div>
          <div class="key">
            <div class="label">chave (codigo de barras)</div>
            {{invoice.key}}
          </div>
          <div class="id">
            <div class="label">id</div>
            {{invoice.id}}
          </div>
        </div>

        <div class="products">
          <div class="product" *ngFor="let product of invoice.products">
            <div class="quantity">
              {{product.quantity}} x
            </div>
            <div class="name">
              {{product.name}}
            </div>

            <div class="sku">
              ({{product.sku}})
            </div>
          </div>
        </div>

      </div>
    </ng-container>
    <ng-container *ngIf="notConsumedInvoices.length === 0">
      nao existem notas nao processadas
    </ng-container>



    </div>

  <div class="actions">
    <button mat-raised-button (click)="consume()" [disabled]="notConsumedInvoices.length === 0">processar notas</button>
  </div>
  <div class="invoices consumed">
    <h3>Notas fiscais processadas</h3>
    <ng-container *ngIf="consumedInvoices.length > 0">
    <div class="invoice" *ngFor="let invoice of consumedInvoices">
      <div class="data">
        <div class="number">
          <div class="label">numero</div>
          {{invoice.number}}
        </div>
        <div class="key">
          <div class="label">chave (codigo de barras)</div>
          {{invoice.key}}
        </div>
        <div class="id">
          <div class="label">id</div>
          {{invoice.id}}
        </div>
      </div>

      <div class="products">
        <div class="product" *ngFor="let product of invoice.products">
          <div class="quantity">
            {{product.quantity}} x
          </div>
          <div class="name">
            {{product.name}}
          </div>

          <div class="sku">
            ({{product.sku}})
          </div>
        </div>
      </div>

      <div class="buttons">
        <button mat-flat-button (click)="removeInvoice(invoice.id)">remove</button>
      </div>
    </div>
    </ng-container>
    <ng-container *ngIf="consumedInvoices.length === 0">
      nao existem notas processadas
    </ng-container>
  </div>

</div>
