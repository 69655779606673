import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskRequestSidenavService } from '../../../core/services/task-request-sidenav.service';
import { Subscription } from 'rxjs';
import { toExcelFormat } from '../../../helpers/format-position';
import { debounceTime } from 'rxjs/operators';
import { StockDataSourceService } from '../../../core/data-sources/stock-data-source.service';
import {
  ProductPickingDataSourceService
} from '../../../core/data-sources/product-picking-data-source.service';
import { GroundGroupsService } from '../../../core/services/ground-groups.service';
import { ActionService, SetProductToPickingPositionAction } from '../../../core/services/action.service';
import { ActionsEnum } from '../../../core/data-sources/action-data-source.service';

@Component({
  selector: 'app-add-product-on-product-picking-sidenav',
  templateUrl: './add-product-on-product-picking-sidenav.component.html',
  styleUrls: ['./add-product-on-product-picking-sidenav.component.scss']
})
export class AddProductOnProductPickingSidenavComponent implements OnInit, OnChanges, OnDestroy {

  @Input() data;
  @Input() drawer;
  @Output() changed = new EventEmitter();
  @ViewChild('groundGroupAuto') groundGroupAuto: any;

  relocateForm: FormGroup;

  productOptions = [];
  palletsOptions = [];

  usersHighlighted = [];

  productInputSubscription: Subscription;
  resetInputSubscription: Subscription;
  sectorInputSubscription: Subscription;
  addressInputSubscription: Subscription;
  openSubscription: Subscription;

  isProductChosen = false;
  selectOperatorError = false;
  noPickingSectorError = false;
  noPalletsError = false;

  groundGroups = [];

  groundPositions = [];
  filteredGroundPosition = [];

  excelToXY = new Map();

  minMaxTooltip = 'O mínimo indica a quantidade mínima do produto para que o sistema emita uma nova tarefa de' +
    ' realocação ou ordem de compra. O máximo indica a quantidade máxima de produtos permitida na posição, bloqueando' +
    ' adições de novos produtos.';

  constructor(private sidenavService: TaskRequestSidenavService,
              private formBuilder: FormBuilder,
              private stockService: StockDataSourceService,
              private productPickingService: ProductPickingDataSourceService,
              private groundGroupsService: GroundGroupsService,
              private actionService: ActionService) {
    this.buildProductForm();
  }

  ngOnInit(): void {
    this.handleValueChanges();
    this.openSubscription = this.drawer.openedStart.subscribe(async () => {
      this.getSectors();
      // this.relocateForm.controls.sector.setValue(this.groundGroups.filter(el => el.type === 'PICKING')[0]);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetForms();
  }

  ngOnDestroy(): void {
    this.productInputSubscription?.unsubscribe();
    this.resetInputSubscription?.unsubscribe();
    this.sectorInputSubscription?.unsubscribe();
    this.addressInputSubscription?.unsubscribe();
    this.openSubscription?.unsubscribe();
  }

  buildProductForm() {
    this.relocateForm = this.formBuilder.group({
      productSku: ['', Validators.required],
      pallet: [[], Validators.required],
      quantity: [''],
      groundGroup: ['', Validators.required],
      position: ['', Validators.required],
      min: [null, [Validators.required, Validators.min(0)]],
      max: [null, [Validators.required, Validators.min(0)]]
    });
  }

  handleValueChanges() {
    this.resetInputSubscription = this.relocateForm.controls.productSku.valueChanges.subscribe(() => {
      if (this.isProductChosen) {
        this.isProductChosen = false;
        this.relocateForm.controls.productSku.reset();
      }
    });
    this.productInputSubscription = this.relocateForm.controls.productSku.valueChanges.pipe(debounceTime(250))
      .subscribe(async (newValue) => {
        this.relocateForm.controls.groundGroup.reset();
        this.relocateForm.controls.position.reset();
        this.productOptions = await this.productPickingService.getProducts(newValue);
        if (newValue?.sku) {
          this.isProductChosen = true;
          await this.getPallets(newValue.sku);
        } else {
          this.palletsOptions = [];
        }
      });
    this.sectorInputSubscription = this.relocateForm.controls.groundGroup.valueChanges.subscribe((data) => {
      this.relocateForm.controls.position.reset();
      const {valid} = this.relocateForm;
      this.changed.emit({valid,
        data: {sector: data}}
      );
      this.addressInputSubscription = this.relocateForm.controls.position.valueChanges.subscribe((positionData) => {
        this.filterPositionGroup(positionData);
        let address = null;
        if (this.excelToXY.has(positionData)){
          const {x, y} = this.excelToXY.get(positionData);
          address = {x, y};
        }
        setTimeout(() => {
          const { valid } = this.relocateForm;
          this.changed.emit({valid,
            data: {address}}
          );
        }, 0);
      });
    });
    this.relocateForm.controls.min.valueChanges.subscribe((value) => {
      const max = this.relocateForm.controls.max;
      if (value) {
        if (max.value) { max.setValue(value); }
        max.setValidators([Validators.required, Validators.min(value)]);
      } else {
        max.setValidators([Validators.required, Validators.min(0)]);
      }
    });
  }

  private filterPositionGroup(value: any) {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      this.filteredGroundPosition = this.groundPositions.filter(option => option.formatted.toLowerCase().includes(filterValue));
    } else if (value) {
      const filterValue = value.formatted.toLowerCase();
      this.filteredGroundPosition = this.groundPositions.filter(option => option.formatted.toLowerCase().includes(filterValue));
    } else {
      this.filteredGroundPosition = [...this.groundPositions];
    }
  }

  setErrors() {
    this.selectOperatorError = !this.usersHighlighted.length;
  }

  displayedString(product) {
    if (product) {
      return product.name + ' - ' + product.sku;
    } else {
      return null;
    }
  }

  displayedGGString(gg) {
    if (gg) {
      return gg.name;
    } else {
      return null;
    }
  }

  displayPositionString(position) {
    if (position) {
      return position.formatted;
    }
    return null;
  }

  groundPositionOptionTooltip(isDisabled: boolean): string | null {
    if (isDisabled) {
      return 'É necessário remover os pallets desta localização antes.';
    }
    return null;
  }

  async getSectors() {
    const allGroundGroups = await this.groundGroupsService.getGroundGroups(true);
    this.groundGroups = allGroundGroups.filter(el => el.type === 'PICKING');
    if (!this.groundGroups.length) {
      this.noPickingSectorError = true;
    }
  }

  // groundPositionAutoClosed(event){
  //   const value = this.relocateForm.controls.position.value;
  //   const currentPositions = [...this.filteredGroundPosition.map(g =>g.formatted)];
  //   if (!currentPositions.includes(value)){
  //     this.relocateForm.controls.position.reset();
  //   }
  // }

  async getAddresses(selectedSector) {
    await this.filterAddresses(selectedSector);
    this.sortAddresses();
    this.filteredGroundPosition = [...this.groundPositions];
  }

  async filterAddresses(selectedSector) {
    const allGroundPositions = await this.stockService.getGroundPosition(true);
    const options = [];
    for (const item of allGroundPositions) {
      const formatted = toExcelFormat(item.x, item.y);
      const coordinates = { x: item.x, y: item.y };
      let disabled = false;
      if (
        !options.includes(item)
        && (item.group?.name === selectedSector.name || item.groupName === selectedSector.name)
      ) {
        // disabling
        for (const product of this.data.products) {
          for (const pp of product.pickingPositions) {
            if (pp.groundPosition.x === item.x && pp.groundPosition.y === item.y) {
              disabled = true;
            }
          }
        }
        options.push({coordinates, formatted, id: item.id, disabled});
        this.excelToXY.set(formatted, coordinates);
      }
    }
    this.groundPositions = [...options];
  }

  sortAddresses() {
    this.groundPositions.sort((a, b) => {
      if (a.coordinates.x === b.coordinates.x) {
        if (a.coordinates.y < b.coordinates.y) {
          return -1;
        }
      } else {
        if (a.coordinates.x === b.coordinates.x) {
          if (a.coordinates.y > b.coordinates.y){
            return 1;
          }
        }
      }
      if (a.coordinates.x < b.coordinates.x) {
        return -1;
      } else {
        if (a.coordinates.x > b.coordinates.x) {
          return 1;
        }
      }
      return 0;
    });
  }

  async getPallets(sku) {
    this.palletsOptions = await this.stockService.getPallets(sku);
    this.noPalletsError = this.palletsOptions.length === 0;
  }

  handlePosition(x, y) {
    return toExcelFormat(x, y);
  }

  handleQuantity(skuGroups) {
    let quantity = 0;
    for (const p of skuGroups) {
      quantity = (p.quantity * p.packSize) + quantity;
    }
    return quantity;
  }

  onChangeSector(event) {
    if (event.isUserInput) {
      this.getAddresses(event.source.value);
    }
  }

  setUsers(users) {
    this.usersHighlighted = users;
  }

  resetForms() {
    this.relocateForm.reset();
    this.usersHighlighted = [];
    this.productOptions = [];
    this.palletsOptions = [];
    this.noPickingSectorError = false;
    this.noPalletsError = false;
    this.selectOperatorError = false;
  }

  async submit() {
    this.relocateForm.markAllAsTouched();
    this.setErrors();
    if (this.relocateForm.valid && this.usersHighlighted.length) {
      const { x, y } = this.relocateForm.get('position').value.coordinates;
      const body: SetProductToPickingPositionAction = {
        type: ActionsEnum.SET_PRODUCT_TO_PICKING_POSITION,
        data: {
          sku: this.relocateForm.get('productSku').value.sku,
          groundPosition: { x, y },
          tagRfid: this.relocateForm.get('pallet').value.tagRfid,
          minQuantity: this.relocateForm.get('min').value,
          maxQuantity: this.relocateForm.get('max').value,
          userIds: this.usersHighlighted
        }
      };
      await this.actionService.setProductToPickingPosition(body);
      this.selectOperatorError = false;
      this.sidenavService.closeSidenav(true);
      this.drawer.close();
      this.resetForms();
    }
  }

  async close(event?) {
    this.resetForms();
    this.sidenavService.closeSidenav(false);
    this.drawer.close();
  }
}
