import { Component, OnInit } from '@angular/core';
import { PendingTask, PendingTaskService, PendingTaskTypeEnum } from '../../core/data-sources/pending-task.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pending-tasks',
  templateUrl: './pending-tasks.component.html',
  styleUrls: ['./pending-tasks.component.scss']
})
export class PendingTasksComponent implements OnInit {

  pendingTasks: PendingTask[] = [];
  isLoading: boolean;
  pendingTaskOrigin = {
    link: '',
    label: ''
  };

  constructor(private pendingTaskService: PendingTaskService, private activatedRoute: ActivatedRoute) {
    this.pendingTaskOrigin.link = activatedRoute.snapshot.queryParams.origin;
    this.pendingTaskOrigin.label = activatedRoute.snapshot.queryParams.label;
    this.getPendingTask();
  }

  ngOnInit(): void {
  }

  update() {
    this.getPendingTask();
  }

  async getPendingTask() {
    this.isLoading = true;
    try {
      await this.setPendingTask(this.pendingTaskOrigin.link);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  async setPendingTask(type: string) {
    switch (type) {
      case 'picking':
        this.pendingTasks = await this.pendingTaskService.getPendingTasks();
        this.pendingTasks = this.pendingTasks.filter(task => task.type.toLowerCase().includes('picking'));
        break;
      case 'product-management':
        this.pendingTasks = await this.pendingTaskService.getProductPendingTasks();
      break;
      default:
        this.pendingTasks = await this.pendingTaskService.getPendingTasks();
        this.pendingTasks = this.pendingTasks.filter(task => !task.type.toLowerCase().includes('picking') && task.type !== PendingTaskTypeEnum.MISSING_PALLETS);
        break;
    }
  }

}
