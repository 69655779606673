<div class="page-header">
  <app-prev-next-button (click)="toIn()" [direction]="'left'" [label]="'documentos de entrada / triagem'"></app-prev-next-button>
  <h1>Triagem</h1>
  <app-prev-next-button (click)="toInspection()" [direction]="'right'" [label]="'conferência'"></app-prev-next-button>
</div>

<div class="ctrl-bar">

  <div class="filters">
    <div class="search-input">
      <input [formControl]="searchTermFormControl" placeholder="Pesquisar OC, NFe, Produto, Razão social..." autocomplete="off">
      <i class="uil uil-search"></i>
    </div>

    <mat-select #select [value]="selectedFilter?.id" [matTooltip]="'Previsão de entrega'">
      <mat-option *ngFor="let filter of filters" [value]="filter.id">{{filter.name}}</mat-option>
      <mat-option [value]="'CUSTOM'" [disabled]="customFilterSelectedLabel">{{customFilterSelectedLabel ? customFilterSelectedLabel : "Customizar período"}}</mat-option>
      <mat-option [value]="'CUSTOM'" *ngIf="customFilterSelectedLabel">{{!customFilterSelectedLabel ? customFilterSelectedLabel : "Customizar período"}}</mat-option>
    </mat-select>
  </div>

  <div class="update-button">
    <p>Atualizado às {{actualHour}}</p>
    <button mat-raised-button (click)="update()">
      <i class="uil uil-sync"></i>
      <span>Atualizar</span>
    </button>
  </div>
</div>

<div class="content">
  <app-side-color-card [color]="isStorageHighlighted ? 'secondary-2' : 'in'" [highlight]="isStorageHighlighted">
    <app-screening-group
      content
      [title]="'armazenagem'"
      [windowSize]="4"
      [quantity]="filteredStorage?.length || filteredStorage?.length === 0 ? filteredStorage?.length : ' - '"
      [documents]="filteredStorage"
      [itemClick]="openModal"
      [statePage]="isLoading"
      (isSelected)="toggleHighlight($event, 'STORAGE')"
      (changeSector)="onSubmitChangeSector($event)">
      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
    </app-screening-group>
  </app-side-color-card>
</div>

<div class="content">
  <app-side-color-card [color]="isQualityHighlighted ? 'secondary-2' : 'in'" [highlight]="isQualityHighlighted">
    <app-screening-group
      content
      [title]="'controle de qualidade'"
      [windowSize]="4"
      [quantity]="filteredQuality?.length || filteredQuality?.length === 0 ? filteredQuality?.length : ' - '"
      [documents]="filteredQuality"
      [itemClick]="openModal"
      [statePage]="isLoading"
      (isSelected)="toggleHighlight($event, 'QUALITY')"
      (changeSector)="onSubmitChangeSector($event)">
      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
    </app-screening-group>
  </app-side-color-card>
</div>

<div class="content">
  <app-side-color-card [color]="isDivergentHighlighted ? 'secondary-2' : 'in'" [highlight]="isDivergentHighlighted">
    <app-screening-group
      content
      [title]="'estoque divergente'"
      [isDivergent]="true"
      [windowSize]="4"
      [quantity]="filteredDivergent?.length || filteredDivergent?.length === 0 ? filteredDivergent?.length : ' - '"
      [documents]="filteredDivergent"
      [itemClick]="openModal"
      [statePage]="isLoading"
      (isSelected)="toggleHighlight($event, 'DIVERGENT')"
      (changeSector)="onSubmitChangeSector($event)">
      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
    </app-screening-group>
  </app-side-color-card>
</div>

<app-full-page-loader *ngIf="isModalLoading"></app-full-page-loader>

