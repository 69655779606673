import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';

export enum SidenavFooterAction {
  CLOSE,
  SUBMMITED
}

@Component({
  selector: 'app-sidenav-footer',
  templateUrl: './sidenav-footer.component.html',
  styleUrls: ['./sidenav-footer.component.scss']
})
export class SidenavFooterComponent implements OnInit {

  @Output() clicked = new EventEmitter();
  @Input() disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.clicked.emit(SidenavFooterAction.CLOSE);
  }

  submit() {
    this.clicked.emit(SidenavFooterAction.SUBMMITED);
  }

}
