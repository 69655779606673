<ng-container>
  <app-table-column name="tagRfid" size="medium">
    <span content>{{ item.tagRfid || '---' }}</span>
  </app-table-column>
  <app-table-column name="expected-position" size="small">
    <span content>{{ expectedPosition || '---' }}</span>
  </app-table-column>
  <app-table-column name="found-position" size="small">
    <span content>{{ foundPosition || '---' }}</span>
  </app-table-column>
  <app-table-column name="expected-height" size="small">
    <span content>{{ item.z + 'cm' || '---' }}</span>
  </app-table-column>
  <app-table-column name="found-height" size="small">
    <span content>{{ foundHeight + 'cm' || '---' }}</span>
  </app-table-column>
  <app-table-column name="status" size="medium">
    <span content>
      <div class="status-icon-wrapper">
        <app-background-icon [backgroundColor]="iconBackgroundColor" [icon]="icon"></app-background-icon>
        {{ crateState || "---" }}
      </div>
    </span>
  </app-table-column>
</ng-container>

