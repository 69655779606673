import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {LoginService} from '../services/login.service';
import {SessionService} from '../services/session.service';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService,
              private authService: AuthService,
              private sessionService: SessionService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLoggedIn = this.authService.isLoggedIn();
    if (isLoggedIn) {
      const accessToken = this.sessionService.getSession().accessToken;
      const headers = {
        authorization: `Bearer ${accessToken}`,
      };
      req = req.clone({
        headers: new HttpHeaders(headers)
      });
      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }
}
