import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionService} from '../../../core/services/action.service';

@Component({
  selector: 'app-physical-inspection',
  templateUrl: './physical-inspection.component.html',
  styleUrls: ['./physical-inspection.component.scss']
})
export class PhysicalInspectionComponent implements OnInit {

  @Input() itemDetails;
  @Input() color;

  allPhysicalProductsDivergent = [];
  allReceivedProducts = [];
  productsNotOrdered = [];

  isCheckboxChecked = false;

  @Output() closeEvent = new EventEmitter();

  constructor(private actionService: ActionService) {}

  ngOnInit(): void {
    if (this.itemDetails.crate) {
      this.allReceivedProducts = this.itemDetails?.crate?.products;
    }
    this.handleDiff();
  }

  handleDiff() {
    const products = [];
    const receivedProductsMap = new Map();
    const receivedProductsList = this.itemDetails.relatedCrates.flatMap(c => c.skuGroups);
    for (const p of receivedProductsList) {
      if (receivedProductsMap.has(p.sku)) {
        receivedProductsMap.set(p.sku, receivedProductsMap.get(p.sku) + p.quantity * p.packSize);
      } else {
        receivedProductsMap.set(p.sku, p.quantity * p.packSize);
      }
    }

    for (const rp of this.allReceivedProducts) {
      for (const invoice of this.itemDetails.relatedInvoices) {
        let productFound = false;
        for (const ip of invoice.products) {
          if (rp.sku === ip.sku) {
            productFound = true;
            rp.quantity = (receivedProductsMap.get(rp.sku) || 0);
            rp.difference = (rp.quantity > ip.quantity ? '+'  : ' ') + (rp.quantity - ip.quantity);
          }
        }
        if (!productFound) {
          rp.quantity = (receivedProductsMap.get(rp.sku) || 0);
          this.handleNotOrdered(rp, invoice.products);
        }
      }
      products.push({ ...rp, difference: rp.difference });
    }
    this.allPhysicalProductsDivergent = [...products];
  }

  handleNotOrdered(rp, invoiceProducts) {
    if (!invoiceProducts.find(p => rp.name === p.name)) {
      rp.difference = '+' + rp.quantity.toString();
    }
  }

  onCheckboxChange(isChecked) {
    this.isCheckboxChecked = isChecked;
  }

  async setResolution() {
    try {
      const params = {
        id: this.itemDetails.invoice.id,
        correctionIssued: this.isCheckboxChecked
      };
      await this.actionService.setReceivedResolution(params);
    } catch (e) {
      // TODO handle error
    }
  }

  cancel() {
    this.closeEvent.emit();
  }

  async submit() {
    await this.setResolution();
    this.closeEvent.emit('SUBMITTED');
  }

}
