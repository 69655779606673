import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ISkuGroupsData } from 'src/models/Report';

@Component({
  selector: 'app-pallet-reports-table-item',
  templateUrl: './pallet-reports-table-item.component.html',
  styleUrls: ['./pallet-reports-table-item.component.scss']
})
export class PalletReportsTableItemComponent implements OnInit {
  @Input() item: ISkuGroupsData;
  @HostBinding('class.isOdd') @Input() isOdd;
  @HostBinding('class.danger-background') @Input() isMissing: boolean;
  balance: number;
  isWarningBackground = false;
  isAlertBackground = false;
  palletState = '';
  expectedQuantity: number;
  foundQuantity: number;

  constructor() { }

  ngOnInit(): void {
    this.setTableValues();
  }

  setTableValues() {
    this.balance = (this.item.inconsistency?.quantity * this.item.inconsistency?.packSize) - (this.item.quantity * this.item.packSize);
    this.expectedQuantity = (this.item.quantity * this.item.packSize);
    this.foundQuantity = this.item?.inconsistency ? (this.item.inconsistency.quantity * this.item.inconsistency.packSize) : (this.item.quantity * this.item.packSize);

    if (!this.item.inconsistency) {
      this.palletState = 'Encontrado';
      return;
    }

    if (this.balance !== 0 && this.item.quantity && !(Math.abs(this.balance) === (this.item.quantity * this.item.packSize))) {
      this.isWarningBackground = true;
      this.palletState = 'Quantidade incompatível';
      return;
    }

    if (Math.abs(this.balance) === (this.item.quantity * this.item.packSize)) {
      this.isAlertBackground = true;
      this.palletState = 'Perdido';
      return;
    }

    if ((!this.item.quantity && !this.item.packSize) && this.item.inconsistency.quantity) {
      this.isAlertBackground = true;
      this.palletState = 'Produto não esperado';
      return;
    }
  }

}
