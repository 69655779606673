import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StockDataSourceService } from '../../core/data-sources/stock-data-source.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { toExcelFormat } from '../../helpers/format-position';
import { ActionService } from 'src/app/core/services/action.service';
import { ActionsEnum } from '../../core/data-sources/action-data-source.service';

@Component({
  selector: 'app-manual-picking-modal',
  templateUrl: './manual-picking-modal.component.html',
  styleUrls: ['./manual-picking-modal.component.scss']
})
export class ManualPickingModalComponent implements OnInit {

  stockedPalletsWithProducts = [];
  form: FormGroup;

  constructor(
    private stockService: StockDataSourceService,
    private actionService: ActionService,
    public dialogRef: MatDialogRef<ManualPickingModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
    ) { }

  ngOnInit(): void {
    this.stockService.getStockCrates().then(res => {
      const crates = res.crates;
      this.stockedPalletsWithProducts = crates.filter(c => c.skuGroups.length);
    });
    this.form = this.formBuilder.group({
      number: [null, Validators.required],
      pallets: [null, Validators.required]
    });
  }

  handlePosition(x, y) {
    return toExcelFormat(x, y);
  }

  handleName(pallet){
    if (pallet.skuGroups.length > 0){
      const group = pallet.skuGroups[0];
      if(group.name){
        return `${group.name} (${group.sku})`;
      } else{
        return group.sku;
      }
    } else{
      return 'vazio';
    }
  }

  handleQuantity(skuGroups) {
    let quantity = 0;
    for (const p of skuGroups) {
      quantity = (p.quantity * p.packSize) + quantity;
    }
    return quantity;
  }

  close(){
    this.dialogRef.close();
  }

  submit(isSubmitted?) {
    if (isSubmitted) {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        const palletsIds = this.form.controls.pallets.value.map(p => p.id);
        const orderNo = this.form.controls.number.value;
        this.actionService.createInternalOutOrder({
          type: ActionsEnum.CREATE_INTERNAL_OUT_ORDER,
          data: {
            orderNo,
            cratesIds: palletsIds
          }
        }).then(_ => {
          this.dialogRef.close('SUBIMMITED');
        });
      }
    }
  }

}
