<div class="header-modal">
  <p>Você deseja confirmar a reserva dos seguintes pallets?</p>
</div>

<div class="pallets-list">
  <div class="header">
    <div class="rfid column">Tag RFID</div>
    <div class="position column">Posição</div>
    <div class="products column">Produtos</div>
    <div class="quantity column">Qtd</div>
  </div>

  <div class="item" *ngFor="let pallet of data; let odd = odd" [ngClass]="{'isOdd': odd}">
    <div class="rfid column">{{ pallet.tagRfid }}</div>
    <div class="position column">{{ formatPosition(pallet.x, pallet.y) }}</div>
    <div class="products column">
      <div class="pallet-product" *ngFor="let product of pallet.skuGroups">{{ product.name }}</div>
    </div>
    <div class="quantity column" *ngFor="let product of pallet.skuGroups">{{ product.quantity * product.packSize }}</div>
  </div>
</div>

<div class="buttons">
  <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
  <button mat-raised-button class="primary-1" (click)="close('SUBMITTED')">confirmar</button>
</div>
