<div class="page-header">
  <app-prev-next-button (click)="toDashboard()" direction="left" icon="estate"></app-prev-next-button>
  <h1>Gerenciamento de Produtos</h1>
</div>

<app-pending-task-widget
  [pageTitle]="'Gerenciamento de Produtos'"
  (updated)="update()">
</app-pending-task-widget>

<div class="section-wrap">
  <button mat-raised-button
          class="secondary-2 add-product-btn"
          (click)="openAddProductModal()">
    ADICIONAR
  </button>

  <div class="update-button">
    <p>Atualizado às {{ actualHour }}</p>
    <button mat-raised-button (click)="update()">
      <i class="uil uil-sync"></i>
      <span>Atualizar</span>
    </button>
  </div>
</div>

<app-product-management-table [products]="products"
                              [isLoading]="isLoading"
                              [appliedFilters]="filtersApplied"
                              (itemClick)="openDetailsModal($event)"
                              (itemDelete)="deleteProduct($event)"
                              (valuesToFilter)="filterData($event)">
</app-product-management-table>

<app-full-page-loader *ngIf="fullPageLoading"></app-full-page-loader>
