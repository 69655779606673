<app-header-modal color="secondary-2" [title]="title">
  <i class="uil uil-times" (click)="close()" status></i>
</app-header-modal>

<form [formGroup]="form">
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Nome</mat-label>
    <input matInput type="text" formControlName="firstName" autocomplete="off" required>
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Sobrenome</mat-label>
    <input matInput type="text" formControlName="lastName" autocomplete="off" required>
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Cargo</mat-label>
    <mat-select formControlName="type" required>
      <mat-option *ngFor="let role of roleOptions" [value]="role.value" [disabled]="data.disabledRole === role.value">{{ role.label }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Email</mat-label>
    <input matInput type="text" formControlName="email" autocomplete="new-password" minlength="6" required>
    <mat-error *ngIf="form?.controls?.email?.errors?.invalid">Email inválido</mat-error>
    <mat-error *ngIf="form?.controls?.email?.errors?.alreadyExist">Email já cadastrado</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Login</mat-label>
    <input matInput type="text" formControlName="username" autocomplete="new-password" required>
    <mat-error *ngIf="form?.controls?.username?.errors?.alreadyExist">Usuário já cadastrado</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Senha</mat-label>
    <input matInput
           #passwordInput="matInput"
           [type]="hide ? 'password' : 'text'"
           formControlName="password"
           autocomplete="new-password"
           required>
    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-pressed]="hide">
      <mat-icon [color]="passwordInput.errorState ? 'warning' : ''">
        {{hide ? 'visibility_off' : 'visibility'}}
      </mat-icon>
    </button>
    <mat-error>{{ passwordErrorText }}</mat-error>
  </mat-form-field>

  <div class="buttons">
    <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
    <button mat-raised-button class="primary-1" (click)="submit()">
      <mat-spinner class="spinner" [diameter]="30" *ngIf="isLoading"></mat-spinner>
      <span *ngIf="!isLoading">{{ btnLabel }}</span>
    </button>
  </div>
</form>
