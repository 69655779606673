import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserDataSourceService, UserModel } from '../../core/data-sources/user-data-source.service';
import { ActionService } from '../../core/services/action.service';
import { Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { filterUsers } from '../../helpers/filters';
import { MatDialog } from '@angular/material/dialog';
import { AddOrEditUserModalComponent } from '../../modals/add-or-edit-user-modal/add-or-edit-user-modal.component';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { IUser, UserRoles } from '../../../models/User';
import { UserDetailsModalComponent } from '../../modals/user-details-modal/user-details-modal.component';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { ErrorService } from '../../core/services/error.service';
import { UserService } from '../../core/services/user.service';

enum StatusEnum {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  NONE = 'NONE'
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  searchTermFormControl: FormControl;
  searchTermSubscription: Subscription;
  searchTerm: string;

  selectedStatus: StatusEnum | 'NONE' = StatusEnum.NONE;
  statusOptions = [
    { label: 'Online', value: StatusEnum.ONLINE },
    { label: 'Offline', value: StatusEnum.OFFLINE }
  ];

  users: IUser[] = [];
  usersCount = 0;
  filteredUsers = [];
  // logConfigTypes = Object.values(UserLogType);

  isFormOpen = false;

  fullPageIsLoading = false;

  @ViewChild('notification', { static: true }) notificationTmpl;
  notificationData: {type: string, title: string, message: string};

  constructor(private router: Router,
              private actionService: ActionService,
              private userDataSource: UserDataSourceService,
              private userService: UserService,
              private dialog: MatDialog,
              private snackbar: SnackBarService,
              private errorService: ErrorService) {
    this.searchTermFormControl = new FormControl();
  }

  ngOnInit(): void {
    this.updateListUsers();
    this.searchTermSubscription = this.searchTermFormControl.valueChanges.pipe(debounceTime(500)).subscribe(newValue => {
      this.searchTerm = newValue;
      this.filteredUsers = [...this.users];
      this.filter();
    });
  }

  ngOnDestroy(): void {
    this.searchTermSubscription?.unsubscribe();
  }

  openRegisterUserModal(): void {
    this.isFormOpen = !this.isFormOpen;
    const dialogRef = this.dialog.open(AddOrEditUserModalComponent, {
      panelClass: 'details-modal',
      autoFocus: false,
      data: { disabledRole: UserRoles.ADMIN }
    });
    dialogRef.afterClosed().subscribe(user => {
      if (user) {
        this.notificationData = {
          type: 'success',
          title: 'USUÁRIO CRIADO COM SUCESSO!',
          message: `O usuário <span>${user}</span> foi criado`
        };
        this.updateListUsers();
        this.snackbar.showFeedback(
          this.notificationTmpl,
          this.notificationData,
          '1'
        );
        // dismiss notification after 5 sec
        setTimeout(() => {
          this.snackbar.hideNotification('1');
        }, 5000);
      }
    });
  }

  deleteUser(event: MouseEvent, user) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: { label: `Tem certeza que deseja excluir o usuário ${user.firstName}?` }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(async (response) => {
      if (response) {
        try {
          this.fullPageIsLoading = true;
          const actionData = {
            id: user.id
          };
          await this.actionService.deleteUser(actionData);
          this.updateListUsers();
        } catch (e) {
          this.errorService.getErrorMessage(e);
        } finally {
          this.fullPageIsLoading = false;
        }
      }
    });
  }

  toDashboard() {
    this.router.navigate(['in']);
  }

  sortTable(): void {
    this.filteredUsers.sort((a, b) => {
      if (a.online && !b.online) {
        return -1;
      }
      if (!a.online && b.online) {
        return 1;
      }

      if (a.firstName + ' '  + a.lastName < b.firstName + ' ' + b.lastName) {
        return -1;
      }
      if (a.firstName + ' ' + a.lastName > b.firstName + ' ' + b.lastName) {
        return 1;
      }

      return 0;
    });
  }

  updateListUsers() {
    this.userDataSource.getAll().then(res => {
      // this.users = res.users.filter(user => user.type !== UserRoles.ADMIN);
      this.users = res.users;
      this.filteredUsers = [...this.users];
      this.usersCount = res.count;
      this.sortTable();
    });
  }

  translateRoles(role: UserRoles): string {
    switch (role) {
      case UserRoles.ADMIN:
        return 'Administrador';
      case UserRoles.MASTER:
        return 'Master';
      case UserRoles.OPERATOR:
        return 'Operador';
      case UserRoles.MANAGER:
        return 'Gestor';
      default:
        return role;
    }
  }

  filterTerm(): void {
    this.filteredUsers = filterUsers(this.filteredUsers, this.searchTerm);
    this.sortTable();
  }

  filter(): void {
    this.filteredUsers = [...this.users];
    if (this.selectedStatus === StatusEnum.NONE && this.searchTerm) {
      this.filterTerm();
      return;
    }

    if (this.selectedStatus === StatusEnum.NONE) {
      this.sortTable();
      return;
    }

    if (this.searchTerm) {
      this.filteredUsers = filterUsers(this.filteredUsers, this.searchTerm);
    }

    const selectedFilterIsOnline = this.selectedStatus === StatusEnum.ONLINE;
    this.filteredUsers = this.filteredUsers.filter(el => el.online === selectedFilterIsOnline);
    this.sortTable();
  }

  openUserDetailsModal(user: any){
    const dialogRef = this.dialog.open(UserDetailsModalComponent, {
      panelClass: 'details-modal',
      autoFocus: false,
      data: { user, disableEditAdmins: true }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res) {
        try {
          this.fullPageIsLoading = true;
          this.notificationData = {
            type: 'success',
            title: 'USUÁRIO EDITADO COM SUCESSO!',
            message: `O usuário <span>${user.username}</span> foi editado`
          };
          this.updateListUsers();
          this.snackbar.showFeedback(
            this.notificationTmpl,
            this.notificationData,
            '1'
          );
          // dismiss notification after 5 sec
          setTimeout(() => {
            this.snackbar.hideNotification('1');
          }, 5000);
        } catch (e) {
          this.errorService.openErrorSnackBar(e);
        } finally {
          this.fullPageIsLoading = false;
        }
      }
    });
  }

  disableDelete(selectedUser): boolean {
    const loggedUser = this.userService.getLoggedUser();
    return (
      selectedUser.type === UserRoles.MASTER
      || selectedUser.type === UserRoles.ADMIN
      || (loggedUser.type === UserRoles.MANAGER || loggedUser.type === UserRoles.OPERATOR)
    );
  }

  showDisabledTooltip(user: UserModel): string {
    if (user.type === UserRoles.ADMIN) {
      return 'Para deletar administradores, vá à tela de cadastro de administradores no setup do armazém.';
    }
    if (user.type === UserRoles.MASTER) {
      return 'Usuários do tipo MASTER não pode ser deletados diretamente pelo sistema.';
    }
    const loggedUser = this.userService.getLoggedUser();
    if (loggedUser.type === UserRoles.MANAGER || loggedUser.type === UserRoles.OPERATOR) {
      return 'Você não possui cargo para deletar os usuários.';
    }
  }
}
