<app-table-column name="task-id" size="small">
  <span content>{{ task.taskNo || '---' }}</span>
</app-table-column>

<app-table-column name="created-date" size="small">
  <span content>{{ handleDate(task.createdAt) || '---' }}</span>
</app-table-column>

<app-table-column name="responsible" size="small">
  <span content>{{ task?.user?.name || '---' }}</span>
</app-table-column>

<app-table-column name="users" size="small">
  <span content>
    {{ task?.eligibleFor[0]?.name + (task?.eligibleFor?.length > 1 ? ', ...' : '') }}
    <i class="uil uil-info-circle" *ngIf="task?.eligibleFor?.length > 1" [matTooltip]="handleUsers(task.eligibleFor)"></i>
  </span>
</app-table-column>

<app-table-column name="description" size="medium">
  <span content>{{ task.description || '---' }}</span>
</app-table-column>

<app-table-column name="start-date" size="small">
  <span content>{{ handleDate(task.startAt) || '---' }}</span>
</app-table-column>

<app-table-column name="type" size="medium">
  <span content>{{ translateType(task.type) || '---' }}</span>
</app-table-column>

<app-table-column name="tag-rfid" size="medium">
  <span content>{{ task.tagRfid || '---' }}</span>
</app-table-column>

<app-table-column name="status" size="small">
  <span content
        [ngClass]="{'alert': isPending, 'gray-2': task.status === 'IN_PROCESS', 'warning': task.status === 'QUEUE'}">
    {{ translateStatus(task.status) }}
  </span>
</app-table-column>

<app-table-column name="delete-button" size="button">
  <div [matTooltip]="this.tooltipMessage" content>
    <button (click)="deleteTask()" [disabled]="!!this.tooltipMessage">
      <i class="uil uil-trash"></i>
    </button>
  </div>
</app-table-column>
