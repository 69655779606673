<app-side-color-card [color]="color" [ngClass]="{'disabled':!step.show, 'done':done}">
  <div class="content" content [ngClass]="color">
    <div class="number">
      {{step.order}}
    </div>
    <div class="label">
      {{step.name}}
      <div class="done-badge">
        feito!
      </div>
    </div>
  </div>
</app-side-color-card>
