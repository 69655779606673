import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ICrateData } from '../../../../../models/Report';
import { toExcelFormat } from '../../../../helpers/format-position';
import { CrateStateEnum } from 'src/models/Crate';

@Component({
  selector: 'app-reports-table-modal-item',
  templateUrl: './reports-table-modal-item.component.html',
  styleUrls: ['./reports-table-modal-item.component.scss']
})
export class ReportsTableModalItemComponent implements OnInit {
  @Input() item: ICrateData;
  @HostBinding('class.isOdd') @Input() isOdd;
  toExcelFormat = toExcelFormat;
  foundPosition = '';
  expectedPosition = '';
  foundHeight = 0;
  crateState = '';
  icon = '';
  iconBackgroundColor = '';

  constructor() { }

  ngOnInit(): void {
    const state = this.item?.inconsistency?.state ? this.item?.inconsistency?.state : this.item.state;
    this.handleStatusAndSetIcon(state);
    this.foundHeight = this.item?.inconsistency?.z ? this.item.inconsistency.z : this.item.z;
    this.expectedPosition = toExcelFormat(this.item?.x, this.item?.y);
    this.foundPosition = this.item?.inconsistency?.x && this.item?.inconsistency?.y ?
      toExcelFormat(this.item?.inconsistency?.x, this.item?.inconsistency?.y) : this.expectedPosition;
  }

  handleStatusAndSetIcon(status: string) {
    switch (status) {
      case CrateStateEnum.FOUND:
        this.icon = 'check';
        this.iconBackgroundColor = 'success';
        this.crateState = 'Encontrado';
        break;
      case CrateStateEnum.FOUND_MISPLACED:
        this.icon = 'times';
        this.iconBackgroundColor = 'warning';
        this.crateState = 'Encontrado em lugar indevido';
        break;
      case CrateStateEnum.MISSING:
        this.icon = 'times';
        this.iconBackgroundColor = 'alert';
        this.crateState = 'Perdido';
        break;
      default:
        this.icon = 'check';
        this.iconBackgroundColor = 'success';
        this.crateState = 'Encontrado';
        break;
    }
  }

}
