<app-side-color-card [color]="color">
  <div class="content" content [ngClass]="color">
    <div>
      <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
    </div>
    <div class="number" *ngIf="!isLoading">
      {{number}}
    </div>
    <div class="label">
      {{label}}
    </div>
  </div>
</app-side-color-card>
