import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScreeningService {

  constructor(private http: HttpClient) { }

  getAllProducts(startDate, endDate) {
    const dateParams = {
      startDate,
      endDate
    };
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/screening', { params: dateParams }).toPromise<any>();
  }
}
