import {Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import * as PIXI from 'pixi.js';
import Application = PIXI.Application;
import {drawGroundPosition} from './ground-cell';
import { Viewport } from 'pixi-viewport';
import {MapViewComponent} from '../../../shared/map-view/map-view.component';
import {LayoutService} from '../../../core/data-sources/layout.service';

export interface GroundPosition {
  rfId: string;
  x: number;
  y: number;
  line: string;
  column: string;
  type: string;
  groupName: string;
}

@Component({
  selector: 'app-warehouse-map-view',
  templateUrl: './warehouse-map-view.component.html',
  styleUrls: ['./warehouse-map-view.component.scss']
})
export class WarehouseMapViewComponent extends MapViewComponent{


  @Input()
  public devicePixelRatio = window.devicePixelRatio || 1;

  @Input()
  public applicationOptions = {
    backgroundColor: 0x0000ff,
    width: 1000,
    height: 600,
  };

  constructor(
    protected elementRef: ElementRef,
    protected ngZone: NgZone,
    private layoutService: LayoutService
  ) {
    super(elementRef, ngZone);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.layoutService.getMap().then(res =>{
      this.drawMap(res);
    });
  }


  public yo(groundPosition: GroundPosition){
    console.log('ddd ' + groundPosition.x);
  }

  public drawMap(groundPositions: GroundPosition[]){

    for (const groundPosition of groundPositions){
      const elements = drawGroundPosition(groundPosition);
      const area = elements[0];
      area.on('pointerdown', () => {
        this.yo(groundPosition);
      });
      for(const e of elements){
        // @ts-ignore
        this.viewPort.addChild(e);
      }
    }
    this.viewPort.fit();
  }
}
