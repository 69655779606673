import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-tasks-empty',
  templateUrl: './pending-tasks-empty.component.html',
  styleUrls: ['./pending-tasks-empty.component.scss']
})
export class PendingTasksEmptyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
