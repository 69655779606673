import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-picking-group-sell-order-with-pallet-header',
  templateUrl: './picking-group-sell-order-with-pallet-header.component.html',
  styleUrls: ['./picking-group-sell-order-with-pallet-header.component.scss']
})
export class PickingGroupSellOrderWithPalletHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
