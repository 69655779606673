import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ActionService, TenantState} from '../../../core/services/action.service';
import {FormControl, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {TenantService} from '../../../core/services/tenant.service';

@Component({
  selector: 'app-setup-tenant',
  templateUrl: './setup-tenant.component.html',
  styleUrls: ['./setup-tenant.component.scss']
})
export class SetupTenantComponent implements OnInit, OnDestroy {


  tenantName;
  tenantId;
  levelsEnabled = false;
  levelsSwitchDisabled = false;

  orientation = new FormControl(0,[
    Validators.required, Validators.min(-361), Validators.max(361)]);

  orientationSubs;

  constructor(
    private actionsService: ActionService,
    private router: Router,
    private tenantsService: TenantService,
    private actionService: ActionService
  ) {
    this.tenantsService.get().then(res => {
      console.log(res);
      this.tenantName = res.name;
      this.tenantId = res.id;
      this.levelsEnabled = res.levelsEnabled;
      this.orientation.patchValue(res.orientation);
    });
    this.tenantsService.getLevels().then();
  }

  ngOnInit(): void {
    this.orientationSubs = this.orientation.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(async (value) => {
        const valid = this.orientation.valid;
        if(valid){
          const orientation = parseInt(value, 10);
          await this.actionService.setTenantOrientation({orientation});
        }
      });
  }

  async levelsChanged($event){
    const {checked} = $event;
    this.levelsSwitchDisabled = true;
    try{
      await this.actionService.enableTenantLevels({enabled: checked});
      await this.tenantsService.get();
    } catch (e) {
      console.log(e);
    } finally {
      this.levelsSwitchDisabled = false;
    }
  }

  async finish(){
    await this.actionsService.setTenantState({state: TenantState.SETUP_ADMINS});
    await this.router.navigate(['/setup-admins']);
  }
  async toSetup(){
    await this.router.navigate(['/setup']);
  }

  ngOnDestroy(): void {
    this.orientationSubs.unsubscribe();
  }
}
