import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-level-preview',
  templateUrl: './level-preview.component.html',
  styleUrls: ['./level-preview.component.scss']
})
export class LevelPreviewComponent implements OnInit, OnChanges {

  @Input()
  levels;

  items=[];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.levels.currentValue){
      const levels: any[] = changes.levels.currentValue.sort((a,b) => b.order - a.order);
      const items = [];
      for(let i =0;i<levels.length;i++){
        const l = levels[i];

        //espaço vem antes, pq desenha na tela de cima para baixo;
        if(i>0){
          const prev = levels[i-1];
          const hSpace = (prev.start - l.end) * 0.5;
          const speceItem = {
            height: hSpace + 'px',
            name: l.name,
            start: l.start,
            end: l.end,
            level: false
          };
          items.push(speceItem);
        }


        const h = (l.end - l.start) * 0.5;
        const item = {
          height: h + 'px',
          name: l.name,
          order: l.order,
          start: l.start,
          end: l.end,
          level:true
        };
        items.push(item);
      }
      this.items = items;
    }
  }

}
