import { Component, Input, OnInit } from '@angular/core';
import { ISkuGroupsData } from 'src/models/Report';

@Component({
  selector: 'app-pallet-reports-table',
  templateUrl: './pallet-reports-table.component.html',
  styleUrls: ['./pallet-reports-table.component.scss']
})
export class PalletReportsTableComponent implements OnInit {
  @Input() items: ISkuGroupsData[] = [];
  displayedColumns = [];

  constructor() { }

  ngOnInit(): void {
    this.displayedColumns = [
      {
        label: 'Código de produto',
        size: 'medium',
        name: 'sku'
      },
      {
        label: 'Descrição',
        size: 'medium',
        name: 'description'
      },
      {
        label: 'Quantidade esperada',
        size: 'small',
        name: 'expected-qty'
      },
      {
        label: 'Quantidade encontrada',
        size: 'small',
        name: 'found-qty'
      },
      {
        label: 'Saldo',
        size: 'small',
        name: 'balance'
      },
      {
        label: 'Status',
        size: 'medium',
        name: 'status'
      }
    ];
  }

}
