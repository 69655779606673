import { Injectable } from '@angular/core';

export interface Session{
  accessToken: string;
  exp: number;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  saveSession(session) {
    try {
      localStorage.setItem('session', JSON.stringify(session));
    } catch (err) {
      console.log(err);
    }
  }

  getSession() {
    return JSON.parse(localStorage.getItem('session'));
  }

  clearSessionStorage() {
    localStorage.clear();
  }
}
