import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {DispatchService} from '../../core/services/dispatch.service';
import { OutOrderDetailsModalComponent } from '../../modals/out-order-details-modal/out-order-details-modal.component';
import { ErrorService } from '../../core/services/error.service';

@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss']
})
export class DispatchComponent implements OnInit {

  allDocuments;

  actualHour;
  isLoading: boolean;
  isModalLoading = false;

  constructor(public dialog: MatDialog,
              private router: Router,
              private dispatchService: DispatchService,
              private errorService: ErrorService) {}

  ngOnInit(): void {
    this.update();
  }

  async getDocs() {
    this.isLoading= true;
    try{
      this.allDocuments = await this.dispatchService.getDocuments();
    } catch(err){

    } finally {
      this.isLoading = false;
    }
  }

  order() {
    this.allDocuments.issuedInvoices.sort((a, b) => {
      return a.issuedAt - b.issuedAt;
    });

    this.allDocuments.dispatching.sort((a, b) => {
      return a.issuedAt - b.issuedAt;
    });

    this.allDocuments.finalized.sort((a, b) => {
      return a.issuedAt - b.issuedAt;
    });
  }

  openDetailsModal = async (item) => {
    try {
      this.isModalLoading = true;
      const orderDetails = await this.dispatchService.getDispatchOrderDetails(item.outOrderNumber);
      const dialogRef = this.dialog.open(OutOrderDetailsModalComponent, {
        panelClass: 'details-modal',
        data: orderDetails,
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res === 'SUBMITTED') {
          this.update();
        }
      });
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.isModalLoading = false;
    }
  };

  async toPicking() {
    await this.router.navigate(['picking']);
  }

  update() {
    this.actualHour = moment().format('HH:mm');
    this.getDocs().then(() => {
      this.order();
    });
  }
}
