import { Component, Input, OnChanges } from '@angular/core';
import { setOccupationChartDataset } from 'src/app/helpers/chart-helper';
import { toExcelFormat } from 'src/app/helpers/format-position';
import { Pallet } from '../stock-levels-modal.component';

@Component({
  selector: 'app-row-column-details',
  templateUrl: './row-column-details.component.html',
  styleUrls: ['./row-column-details.component.scss']
})
export class RowColumnDetailsComponent implements OnChanges {
  @Input() occupationList = [];
  @Input() coords = '';
  @Input() pallets: Pallet[] = [];
  @Input() positions = [];
  tableData = [];
  showEmptyMsg = false;
  datasets = [{
    data: [0, 0],
    backgroundColor: ['#92B531', '#E8EBEF']
  }];
  chartLabels = ['Posições Ocupadas', 'Posições Livres'];

  constructor() { }

  ngOnChanges(): void {
    if (this.occupationList.length) {
      this.showEmptyMsg = false;
      this.datasets[0].data = setOccupationChartDataset(this.occupationList);
    } else {
      this.showEmptyMsg = true;
    }
    this.setTableData();
  }

  setTableData() {
    if (!this.positions.length) return;
    this.positions.map(pos => {
      this.tableData.push({
        rfid: pos.rfId,
        type: pos.type,
        groupName: pos?.group?.name,
        groupType: pos?.group?.type,
        coords: `${toExcelFormat(pos.x, pos.y)}`
      });
    });
  }

}
