import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SidenavFooterAction} from '../../../../shared/sidenav-footer/sidenav-footer.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActionService} from '../../../../core/services/action.service';
import {TaskRequestSidenavService} from '../../../../core/services/task-request-sidenav.service';
import {SidenavDestinationComponent} from '../../../../shared/sidenav-destination/sidenav-destination.component';
import {SideNavResponse} from '../SideNav';
import { getCoordinates } from '../../../../helpers/format-position';

@Component({
  selector: 'app-stock-relocate-single-pallet-sidenav',
  templateUrl: './stock-relocate-single-pallet-sidenav.component.html',
  styleUrls: ['./stock-relocate-single-pallet-sidenav.component.scss']
})
export class StockRelocateSinglePalletSidenavComponent implements OnInit, OnChanges {

  @Input() drawer;
  @Input() task;

  selectedUsers = [];
  selectOperatorError = false;

  dataDestination;

  relocateForm: FormGroup;

  @ViewChild('destination', {static: true}) destination: SidenavDestinationComponent;

  constructor(
    private formBuilder: FormBuilder,

    private actionService: ActionService,
    private sidenavService: TaskRequestSidenavService
  ) {
    this.relocateForm = this.formBuilder.group({
      rfid: ['', Validators.required],
      product: ['nome não definido'],
    });
  }

  destinationChanged($event){
    this.dataDestination = $event;
  }

  ngOnInit(): void {}

  selectUsers(users) {
    this.selectedUsers = users;
    this.selectOperatorError = this.selectedUsers.length === 0;
  }

  reset() {
    this.relocateForm.reset();
    this.selectedUsers = [];
    this.selectOperatorError = false;
    this.destination.reset();
  }

  close(event: SideNavResponse = SideNavResponse.CLOSED) {
    this.reset();
    this.drawer.close();
    this.sidenavService.closeSidenav(event);
  }

  async footerClicked(event){
    if (event === SidenavFooterAction.SUBMMITED){
      this.relocateForm.markAllAsTouched();
      if(
        ((this.dataDestination && this.dataDestination.valid) || this.task.taskType === 'NEGATIVE_Z_INDEX_CRATES_AT_PICKING') &&
        this.relocateForm.valid &&
        this.selectedUsers.length > 0
      ){
        await this.sendTask();
        this.close(SideNavResponse.SUBMMITED);
      } else{
        this.selectOperatorError = this.selectedUsers.length === 0;
      }

    } else{
      this.close(SideNavResponse.CLOSED);
    }
  }

  async sendTask() {
    let destination;
    destination = this.dataDestination.data.address;
    if (this.task.position) {
      destination = getCoordinates(this.task.position);
      destination.y--;
    }
    const params = {
      type: 'RELOCATE_POSITION',
      userIds: this.selectedUsers,
      tagsRfid: [this.relocateForm.controls.rfid.value],
      taskData: {
        destiny: {
          x: destination.x,
          y: destination.y
        }
      }
    };
    await this.actionService.bulkSendTask(params);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.task.currentValue){
      const {rfId, product} = changes.task.currentValue;
      this.relocateForm.controls.rfid.setValue(rfId);
      this.relocateForm.controls.product.setValue(product);
    }
  }
}
