import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ShowRoomService} from '../../core/services/show-room.service';
import {ActionService} from '../../core/services/action.service';

@Component({
  selector: 'app-fake-in-order-modal',
  templateUrl: './fake-in-order-modal.component.html',
  styleUrls: ['./fake-in-order-modal.component.scss']
})
export class FakeInOrderModalComponent implements OnInit {

  allInvoices = [];
  consumedInvoices = [];
  notConsumedInvoices = [];
  allInOrders = [];
  loading = false;
  constructor(public dialogRef: MatDialogRef<FakeInOrderModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private showRoomService: ShowRoomService,
              private actionsService: ActionService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }
  loadData(){
    const allInvoicesPromise = this.showRoomService.getAllInvoices();
    const consumedInvoicesPromise = this.showRoomService.getConsumedInvoices();
    const inOrdersPromise = this.showRoomService.getInOrders();
    Promise.all([allInvoicesPromise, consumedInvoicesPromise, inOrdersPromise]).then(res => {
      this.allInvoices = res[0];
      this.allInOrders = res[2];
      this.processNotConsumed(res[0], res[1]);
    });
  }

  processNotConsumed(allInvoices, consumedInvoices){
    this.notConsumedInvoices = [];
    this.consumedInvoices = [];

    const consumedIds = consumedInvoices.map(c => c.id);
    for (const i of allInvoices){
      if (!consumedIds.includes(i.id)){
        this.notConsumedInvoices.push({...i});
      } else{
        this.consumedInvoices.push({...i});
      }
    }
    console.log(this.consumedInvoices);
  }

  async removeInvoice(id){
    await this.actionsService.resetShowRoomInvoice({id}, false);
    this.loadData();
  }
  async consume(){
    this.loading = true;
    try{
      await this.actionsService.consumeShowRoomInvoices(false);
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
      this.loadData();
    }

  }
}
