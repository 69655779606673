import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import {SidebarService} from '../../services/sidebar.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarResolver implements Resolve<boolean> {

  constructor(private sidebarService: SidebarService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | any {
    if (route.data.section) {
      this.sidebarService.updateSection(route.data.section);
    } else {
      this.sidebarService.updateSection('');
    }
  }
}
