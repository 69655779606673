import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as d3 from 'd3';
import {toExcelFormat} from '../../../helpers/format-position';

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss']
})
export class HeatMapComponent implements OnInit, OnChanges {

  hostElement;
  width = 500;
  height = 500;
  margin = {left: 0, right: 0, top: 0, bottom: 0};
  svg;

  currentPosition = '';
  currentCount = 0;

  @Input() data;
  @Output() over = new EventEmitter();
  constructor(private elRef: ElementRef) {
    this.hostElement = this.elRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue){
      this.processData(changes.data.currentValue);
    }
  }

  ngOnInit(): void {}

  private processData(data) {
    const myGroups: any = Array.from(new Set(data.map(a => a.x))).sort((a: number, b: number) => a - b);
    const x = d3.scaleBand()
      .range([ 0, this.width ])
      .domain(myGroups);
    // tslint:disable-next-line:only-arrow-functions
    const myVars: any =  Array.from(new Set(data.map(a => a.y))).sort((a: number, b: number) => a - b);
    const y = d3.scaleBand()
      .range([ 0, this.height ])
      .domain(myVars);

    // const myColor = d3.scaleSequential()
    //   .interpolator(d3.interpolateRgb('#ccc', 'green'))
    //   .domain([0, Math.max(...data.map(d => d.count))]);

    // const myColor = d3.scalePow()
    //   .exponent(2)
    //   //@ts-ignore
    //   .range(['#ccc', 'green'])
    // .domain([0, Math.max(...data.map(d => d.count))]);

    const myColor = d3.scaleLog()
      .base(10)
      // @ts-ignore
      .range(['#ccc', 'green'])
      .domain([1, Math.max(...data.map(d => d.count))]);

// append the svg object to the body of the page
    this.svg = d3.select(this.hostElement)
      .selectAll('svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')');

    const tooltip2 = d3.select('#tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('pointer-events', 'none');


    const rects = this.svg.selectAll()
      // tslint:disable-next-line:only-arrow-functions
      .data(data, function(d: any) {return d.x + ':' + d.y; })
      .enter()
      .append('rect')
      // tslint:disable-next-line:only-arrow-functions
      .attr('x', function(d: any) { return x(d.x); })
      // tslint:disable-next-line:only-arrow-functions
      .attr('y', function(d: any) { return y(d.y); })
      .attr('width', x.bandwidth() )
      .attr('height', y.bandwidth() )
      // tslint:disable-next-line:only-arrow-functions
      .style('fill', function(d: any) { return myColor(d.count); } )
      .style('stroke-width', 1)
      .style('stroke', '#999')
      .style('opacity', 1)
      .on('mouseover', (d, i) => {
        this.currentPosition = toExcelFormat(i.x, i.y);
        this.currentCount = i.count;
        tooltip2.style('visibility', 'visible');
        this.over.emit({value: i, over: true});
      })
      .on('mousemove', (d, i) => {
        tooltip2.style('top', (d.pageY) + 'px').style('left', (d.pageX) + 'px');
      })
      .on('mouseout', (d, i) => {
        tooltip2.style('visibility', 'hidden');
        this.over.emit({value: i, over: false});
      });
    // const text = this.svg.selectAll()
    //   // tslint:disable-next-line:only-arrow-functions
    //   .data(data, function(d: any) {return d.x + ':' + d.y; })
    //   .enter()
    //   .append("text")
    //   .attr("x", function(d) { return x(d.x); })
    //   .attr("y", function(d) { return y(d.y); })
    //   .style("stroke","#fff")
    //   .text(function(d) { return toExcelFormat(d.x,d.y); });
  }
}
