import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import handlePaginationString from 'src/app/helpers/pagination';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() currentPage: number;
  @Input() pageCount: number;
  @Input() atFirstPage: boolean;
  @Input() atLastPage: boolean;
  @Output() nextPage = new EventEmitter();
  @Output() previousPage = new EventEmitter();

  getPaginationString = handlePaginationString;

  constructor() { }

  ngOnInit(): void {
  }

  getPreviousPage() {
    this.previousPage.emit(true);
  }

  getNextPage() {
    this.nextPage.emit(true);
  }

}
