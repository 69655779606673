import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {InService} from '../../../core/services/in.service';
import {ActionService} from '../../../core/services/action.service';

@Component({
  selector: 'app-in-details-nfe',
  templateUrl: './in-details-nfe.component.html',
  styleUrls: ['./in-details-nfe.component.scss']
})
export class InDetailsNfeComponent implements OnInit {

  @Input() nfe;
  @Input() highlightedProducts;

  @Output() itemClick = new EventEmitter();
  @Output() destinationChanged = new EventEmitter();

  options;
  productsStates: Record<string, string> = {};

  errorXML = false;

  constructor(public inService: InService,
              private actionsService: ActionService) { }

  ngOnInit(): void {
    this.options = [{label: 'Armazenagem', value: 'STORAGE'},
      {label: 'Controle de qualidade', value: 'QUALITY'},
      {label: 'Estoque divergente', value: 'STOCK_DIVERGENT'}];
  }

  timestampToDate(timestamp) {
    return moment(timestamp).format('DD/MM/YYYY - HH:mm');
  }

  saveState(product){
    this.productsStates[product.sku] = product.destiny;
  }

  itemClickHandler(invoice) {
    this.itemClick.emit({type: 'invoice', entity: invoice});
  }

  changeOption(event, product) {
    const {sku, destiny} = product;
    this.destinationChanged.emit();
    product.loading = true;
    this.actionsService.setDestination({invoiceId: this.nfe.invoice.id, sku, destiny})
      .then(() => product.loading = false)
      .catch((e) => {
        product.destiny = this.productsStates[product.sku];
        product.loading = false;
      });
  }

  async downloadXml() {
    try {
      await this.inService.getXml(this.nfe.invoice.key);
    } catch (e) {
      this.errorXML = true;
    }
  }
}
