<div class="container" *ngIf="!showEmptyMsg">
    <div class="row">
    <section class="left-side">
      <div class="position">
        <app-info-oc-or-nfe label="Coordenadas" [value]="coords"></app-info-oc-or-nfe>
      </div>
    </section>
    <mat-divider vertical></mat-divider>
    <div class="right-side">
      <app-doughnut-chart [datasets]="datasets" [labels]="chartLabels"></app-doughnut-chart>
      <app-common-list [list]="occupationList"></app-common-list>
    </div>
  </div>
  <div class="table" *ngIf="tableData.length">
    <div class="header">
      <app-table-header-column name="rfid" size="medium">
        <span content>RFID</span>
      </app-table-header-column>
      <app-table-header-column name="coords" size="smallest">
        <span content>Posição</span>
      </app-table-header-column>
      <app-table-header-column name="type" size="small">
        <span content>Tipo do chão</span>
      </app-table-header-column>
      <app-table-header-column name="groupName" size="small">
        <span content>Grupo</span>
      </app-table-header-column>
      <app-table-header-column name="groupType" size="small">
        <span content>Tipo do Grupo</span>
      </app-table-header-column>
    </div>

    <div class="item" *ngFor="let data of tableData">
      <app-table-column name="rfid" size="medium">
        <span content>{{ data.rfid || '---' }}</span>
      </app-table-column>
      <app-table-column name="coords" size="smallest">
        <span content>{{ data.coords || '---'}}</span>
      </app-table-column>
      <app-table-column name="type" size="small">
        <span content>{{ data.type || '---'}}</span>
      </app-table-column>
      <app-table-column name="groupName" size="small">
        <span content>{{ data.groupName || '---'}}</span>
      </app-table-column>
      <app-table-column name="groupType" size="small">
        <span content>{{ data.groupType || '---'}}</span>
      </app-table-column>
    </div>
  </div>
</div>

<p *ngIf="showEmptyMsg">Esta célula, coluna ou linha não tem posições.</p>