import { Component, OnInit } from '@angular/core';
import {DateRange} from '@angular/material/datepicker';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  selectedDateRange: DateRange<Date>;

  constructor(public dialogRef: MatDialogRef<DatePickerComponent>) { }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }

  _onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
      this.dialogRef.close(this.selectedDateRange);
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
  }

  closeDialog() {
    this.dialogRef.close(this.selectedDateRange);
  }


}
