<div class="align">
  <div class="status">
    <app-background-icon *ngIf="status !== 'Aguardando Comparação' && status !== 'Aguardando Correção' && status !== 'Aguardando NFe'"
                         [icon]="icon"
                         [backgroundColor]="color"></app-background-icon>
    <i *ngIf="status === 'Aguardando NFe'" class="uil uil-{{ icon }} {{ color }}"></i>
    <img *ngIf="status === 'Aguardando Comparação' || status === 'Aguardando Correção' && status !== 'Aguardando NFe'"
         src="assets/icons/{{ icon }}.svg" alt="">
    <p class="{{ color }}">{{ status }}</p>
  </div>
</div>
