import {Component, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter} from '@angular/core';
import {DestinationEnum} from '../../../core/services/action.service';

@Component({
  selector: 'app-screening-group',
  templateUrl: './screening-group.component.html',
  styleUrls: ['./screening-group.component.scss']
})
export class ScreeningGroupComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() quantity = 0;
  @Input() windowSize: number;
  @Input() itemClick: (item, page?) => void;
  @Input() isDivergent: boolean;

  @Input() documents;

  displayedDocuments;

  displayAll: boolean;
  @HostBinding('class.padding-adjust') displayShowMoreToggle: boolean;
  displayList: boolean;
  isEmpty: boolean;

  @Output() isSelected = new EventEmitter();
  @Output() changeSector = new EventEmitter();
  isAllSelected: boolean;
  highlightedProducts;
  highlightCard: boolean;

  selectedSector: DestinationEnum;
  displayChangeSectorForm: boolean;
  @Input() statePage: boolean;

  constructor() {
    this.displayShowMoreToggle = true;
    this.displayAll = false;
    this.displayList = true;
    this.isEmpty = true;
    this.isAllSelected = false;
    this.highlightedProducts = {};
    this.displayChangeSectorForm = false;
    this.highlightCard = false;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  update() {
    if (!this.displayAll) {
      this.displayedDocuments = this.documents?.slice(0, this.windowSize);
    } else {
      this.displayedDocuments = [...this.documents];
    }
    this.isEmpty = this.quantity === 0;
    this.displayShowMoreToggle = this.quantity > this.windowSize && this.displayList;
  }

  onCheckboxItemChange(event) {
    this.highlightedProducts[event] = !this.highlightedProducts[event];
    this.displayChangeSectorForm = Object.values(this.highlightedProducts).some(a => a === true);
    this.highlightCard = Object.values(this.highlightedProducts).some(a => a === true);
    const currentHighlightQuantity = Object.values(this.highlightedProducts).filter(v => v === true).length;
    this.isAllSelected = currentHighlightQuantity === this.documents.length;
    this.isSelected.emit(this.displayChangeSectorForm);
  }

  onSelectAll(isAllSelected) {
    for (const d of this.documents) {
      this.highlightedProducts[d.invoiceId.toString() + '/' + d.sku] = isAllSelected;
    }
    this.isAllSelected = Object.values(this.highlightedProducts).every(a => a === true);
    this.highlightCard = this.isAllSelected;
    this.displayChangeSectorForm = this.isAllSelected;
    this.isSelected.emit(this.displayChangeSectorForm);
  }

  handleSelectedCount() {
    const productsArray = Object.values(this.highlightedProducts);
    return productsArray.filter(p => p === true).length;
  }

  onSelectChange(sector) {
    this.selectedSector = sector;
  }

  async submitChangeSector() {
    const data = { products: this.getParamsFromObjectKeys() };
    this.highlightCard = false;
    this.displayChangeSectorForm = false;
    this.highlightedProducts = {};
    this.isSelected.emit(false);
    this.changeSector.emit(data);
  }

  getParamsFromObjectKeys() {
    const data = [];
    Object.entries(this.highlightedProducts).forEach(e => {
      const [id, value] = e;
      if (value) {
        const [invoiceId, sku] = id.split('/');
        data.push({ invoiceId, sku, destiny: this.selectedSector });
      }
    });
    return data;
  }

  toggleAllDocuments(){
    this.displayAll = !this.displayAll;
    this.update();
  }

  toggleList() {
    this.displayList = !this.displayList;
    this.displayAll = false;
    if (this.highlightCard) {
      this.displayChangeSectorForm = !this.displayChangeSectorForm;
    }
    this.update();
  }

  handleItemClick(item, page) {
    this.itemClick(item, page);
  }
}
