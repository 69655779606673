import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ICrate } from '../../../models/Crate';

export interface IOutOrder {
  crates: ICrate[];
  id: string;
  state: string;
}

@Injectable({
  providedIn: 'root'
})
export class OutOrderDataSourceService {

  constructor(private http: HttpClient) { }

  getOutOrders(id: string): Promise<IOutOrder> {
    return this.http.get<IOutOrder>(environment.baseUrl + `/api/v1/admin-desktop/out-order/${id}`).toPromise();
  }
}
