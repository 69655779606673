<app-table-header-column name="checkbox" size="smallest">
  <mat-checkbox [(ngModel)]="selected" (change)="onCheckboxChange($event.checked)" content></mat-checkbox>
</app-table-header-column>

<app-table-header-column name="oc" size="small">
  <span content>Pedido</span>
</app-table-header-column>

<app-table-header-column name="nfe" size="small">
  <span content>N° NFe</span>
</app-table-header-column>

<app-table-header-column name="vendor-name" size="medium">
  <span content>Razão Social</span>
</app-table-header-column>

<app-table-header-column name="product" size="medium">
  <span content>Produto</span>
</app-table-header-column>

<app-table-header-column name="quantity" size="smallest">
  <span content>Qtd</span>
</app-table-header-column>

<app-table-header-column name="last-destination" size="small">
  <span content>Último destino</span>
</app-table-header-column>

<app-table-header-column name="forecast" size="small">
  <span content>Previsão de entrega</span>
</app-table-header-column>

<app-table-header-column name="messages" size="smallest">
  <span content>Comentários</span>
</app-table-header-column>

