import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Product } from '../../../models/Product';
import { ErrorService } from '../../core/services/error.service';
import { FirebaseService } from '../../core/services/firebase.service';
import { AddOrEditProductModalComponent } from '../add-or-edit-product-modal/add-or-edit-product-modal.component';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-product-details-modal',
  templateUrl: './product-details-modal.component.html',
  styleUrls: ['./product-details-modal.component.scss']
})
export class ProductDetailsModalComponent implements OnInit {

  isLoading = false;

  moment = moment;

  constructor(public dialogRef: MatDialogRef<ProductDetailsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public product: Product,
              private errorService: ErrorService,
              private firebaseService: FirebaseService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getProductImage();
  }

  async getProductImage() {
    try {
      this.isLoading = true;
      this.product.imageUrl = await this.firebaseService.getProductImage(this.product.sku);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.errorService.openErrorSnackBar(e);
    }
  }

  openEditProductModal() {
    const dialogRef = this.dialog.open(AddOrEditProductModalComponent, {
      panelClass: 'details-modal',
      autoFocus: false,
      data: this.product
    });
    dialogRef.afterClosed().pipe(first()).subscribe(async (response) => {
      if (response) {
        this.dialogRef.close(true);
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
