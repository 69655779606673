export function toExcelFormat(x, y) {
  let column = '';
  while (x >= 0) {
    const mod = x % 26;
    const modX = 65 + mod;
    column = String.fromCharCode(modX) + column;
    x = (x - mod - 1) / 26;
  }
  return column + (y + 1);
}

export function getCoordinates(excelString: string): { x: number, y: number; } {
  const match = excelString.match(/([A-Z]+)(\d+)/);
  const columnLetters = match[1];
  const rowNumber = parseInt(match[2], 10);
  const columnNumber = columnToNumber(columnLetters);

  return { x: columnNumber, y: rowNumber };
}

export function columnToNumber(columnLetters: string): number {
  let columnNumber = 0;
  for (let i = 0; i < columnLetters.length; i++) {
    const charCode = columnLetters.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
    columnNumber = columnNumber * 26 + charCode;
  }
  return columnNumber - 1;
}
