<div class="ov column">
  Nº OV
</div>
<div class="timestamp column">
  Data/Hora de Entrada
</div>
<div class="document column">
  CNPJ
</div>
<div class="name column">
  Razão Social
</div>
<div class="products column">
  Produtos
</div>
<div class="quantity column">
  Qtd
</div>
<div class="tasks column">
  Andamento das Tarefas
</div>
<div class="status column">
  Status
</div>
<!--<div class="messages column">-->
<!--  Comentários-->
<!--</div>-->
