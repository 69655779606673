import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-picking-group-in-execution-header',
  templateUrl: './picking-group-in-execution-header.component.html',
  styleUrls: ['./picking-group-in-execution-header.component.scss']
})
export class PickingGroupInExecutionHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  isDev(): boolean {
    return environment.name === 'dev';
  }

}
