<div class="page-header">
  <app-prev-next-button (click)="toSetup()" direction="left" label="setup"></app-prev-next-button>
  <h1>Setup cadastro de administradores</h1>
</div>

<div class="page-content">
  <div class="buttons">
    <button mat-raised-button class="primary-1" (click)="openCreateUserModal()">
      Cadastrar novo usuário
    </button>
  </div>
  <app-side-color-card color="in">
    <div class="content" content >
      <div class="table-title">ADMINS CADASTRADOS ({{ this.admins.length }})</div>
      <app-table-header>
        <app-table-header-column name="status">
          <span content>Status</span>
        </app-table-header-column>
        <app-table-header-column name="colaborator">
          <span content>Colaborador</span>
        </app-table-header-column>
        <app-table-header-column name="role">
          <span content>Função</span>
        </app-table-header-column>
        <app-table-header-column name="username">
          <span content>Usuário</span>
        </app-table-header-column>
        <app-table-header-column name="delete-button" size="button"></app-table-header-column>
      </app-table-header>

      <app-table-items *ngIf="!isLoading">
        <app-table-item *ngFor="let admin of admins; let odd = odd" [isOdd]="odd" (click)="openDetailsModal(admin)">
          <app-table-column name="status">
            <div class="status-icon" [ngClass]="{'online': admin.online}" content></div>
          </app-table-column>
          <app-table-column name="colaborator">
            <span content>{{admin.firstName + ' ' + admin.lastName}}</span>
          </app-table-column>
          <app-table-column name="role">
            <span content>Administrador</span>
          </app-table-column>
          <app-table-column name="username">
            <span content>{{admin.username}}</span>
          </app-table-column>
          <app-table-column name="delete-button" size="button">
            <button (click)="deleteAdmin($event, admin)" content>
              <i class="uil uil-trash"></i>
            </button>
          </app-table-column>
        </app-table-item>
        <div class="empty-table" *ngIf="!admins.length">
          NÃO EXISTEM ADMINS CRIADOS NO MOMENTO.
        </div>
      </app-table-items>
      <div class="spinner" *ngIf="isLoading">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
    </div>
  </app-side-color-card>

  <div class="buttons">
    <button mat-raised-button class="in" (click)="finish()">
      Ir para próximo passo
    </button>
  </div>
</div>

<ng-template #notification>
  <app-notification [data]="notificationData" icon="check-circle"></app-notification>
</ng-template>

<app-full-page-loader *ngIf="fullPageIsLoading"></app-full-page-loader>
