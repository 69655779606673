import * as PIXI from 'pixi.js';
import {GroundPosition} from './warehouse-map-view.component';

const width = 100;
const height = 100;

const titleStyle = new PIXI.TextStyle({
  fontFamily: 'Arial',
  fontSize: 24,
  // fontStyle: 'italic',
  fontWeight: 'bold',
  fill: ['#ffffff'], // gradient
  // stroke: '#4a1850',
  // strokeThickness: 3,
  // dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 4,
  dropShadowAngle: Math.PI / 6,
  dropShadowDistance: 6,
  wordWrap: true,
  wordWrapWidth: 440,
  lineJoin: 'round',
});

const style = new PIXI.TextStyle({
  fontFamily: 'Arial',
  fontSize: 12,
  fontStyle: 'italic',
  fontWeight: 'bold',
  fill: ['#ffffff'], // gradient
  // stroke: '#4a1850',
  // strokeThickness: 3,
  // dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 4,
  dropShadowAngle: Math.PI / 6,
  dropShadowDistance: 6,
  wordWrap: true,
  wordWrapWidth: 440,
  lineJoin: 'round',
});


export function drawGroundPosition(ground: GroundPosition) {
  const graphics = new PIXI.Graphics();
  graphics.beginFill(0xDE3249);
  graphics.drawRect(ground.x * width, ground.y * height, width, height);
  graphics.endFill();
  graphics.hitArea = new PIXI.Rectangle(ground.x * width, ground.y * height, width, height);
  graphics.buttonMode = true;
  graphics.interactive = true;


  const title = new PIXI.Text(ground.column + ground.line, titleStyle);
  title.x = ground.x * width;
  title.y = ground.y * height;

  const basicText = new PIXI.Text("rfid: " + ground.rfId + "\n" + ground.groupName + "\n" + ground.type, style);
  basicText.x = ground.x * width;
  basicText.y = ground.y * height + 30;
  return [graphics, title, basicText];
}
