<div class="comment-section">

  <div class="all-comments" #scrollframe>
    <div class="empty" *ngIf="!itemDetails.comments || !itemDetails.comments.length">
      Ainda não há comentários para esse pedido.
    </div>
    <div class="comment-box" *ngFor="let comment of comments">
      <div class="image-box">
        <i
          class="uil uil-user in-details-icon-white"
        ></i>
      </div>
      <div class="text-section">
        <div class="top-section">
          <p class="name">{{ comment.user.firstName + ' ' + comment.user.lastName}}</p>
          <div class="info-comment">
            <p class="doc">{{ comment.stage === 'ENTRY' ? 'Documentos de entrada' : comment.stage === 'CHECKING' ?
              'Conferência' : comment.stage === 'STORED' ? 'Estoque' : comment.stage === 'SCREENING' ? 'Triagem' : ''}}
            <span *ngIf="comment.product">{{ ' - ' + comment?.product?.sku }}</span></p>
            <p class="date">{{ formatDate(comment.createdAt) }}</p>
          </div>
        </div>
        <div class="bottom-section">
          <p>{{ comment.value }}</p>
          <div *ngIf="comment.owner===loggedUserId" matTooltip="Comentários só podem ser apagados em até 48 horas após serem criados"
               [matTooltipDisabled]="!comment.toOldToDelete">
            <button (click)="delete(comment.id)" [disabled]="comment.toOldToDelete">
              <i class="uil uil-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="commentForm">
    <div class="background">
      <div class="input-content">
        <div class="image-box">
          <i
            class="uil uil-user in-details-icon-gray"
          ></i>
        </div>
        <div class="text-section-input">
          <input
            type="text"
            maxlength="255"
            formControlName="comment"
            placeholder="Adicionar comentário"
            required
          />
          <button (click)="submit()">Enviar</button>
        </div>
      </div>
    </div>
  </form>
</div>
