<div class="page-header">
  <app-prev-next-button (click)="toDashboard()" direction="left" icon="estate"></app-prev-next-button>
  <h1>Setup do Armazém</h1>
</div>
<div class="page-content">
  <app-setup-step
    *ngFor="let step of steps" (click)="toStep(step)"
    [color]="step.color"
    [step]="step"
    [done]="step.order <= currentStep"
  >
  </app-setup-step>
</div>
