<div class="header-modal">
  <p>Novo nível</p>
</div>


<form [formGroup]="form" (ngSubmit)="close('SUBMITTED')">


  <p class="error" *ngIf="!name.valid && name.touched">Obrigatório</p>
  <mat-form-field appearance="outline">
    <mat-label>Nome</mat-label>
    <input matInput formControlName="name" autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Tipo</mat-label>
    <mat-select formControlName="type">
      <mat-option *ngFor="let sector of sectorOptions" [value]="sector.value">{{ sector.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>
<div class="buttons">
  <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
  <button mat-raised-button class="primary-1" (click)="close('SUBMITTED')">confirmar</button>
</div>
