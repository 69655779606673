import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GroundGroupPositionLevelsDataSourceService} from '../../core/data-sources/ground-group-position-levels-data-source.service';
import {GroundPositionGroupDataSourceService} from '../../core/data-sources/ground-position-group-data-source.service';
import {EditSectorModalComponent} from '../../modals/edit-sector-modal/edit-sector-modal.component';
import {first} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AddLevelToGroupModalComponent} from '../../modals/add-level-to-group-modal/add-level-to-group-modal.component';
import {ActionService} from '../../core/services/action.service';
import {ConfirmModalComponent} from '../../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-ground-groups-levels',
  templateUrl: './ground-groups-details.component.html',
  styleUrls: ['./ground-groups-details.component.scss']
})
export class GroundGroupsDetailsComponent implements OnInit {

  groundPositionGroupId: string;
  levels:any[]=[];
  groupName: string;

  constructor(
    private actionService: ActionService,
    private groundPositionGroupDataSourceService: GroundPositionGroupDataSourceService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog  ) { }

  ngOnInit(): void {
    this.groundPositionGroupId = this.route.snapshot.paramMap.get('id');
    this.loadGroundPosition();
  }
  loadGroundPosition(){
    this.groundPositionGroupDataSourceService.getDetails(this.groundPositionGroupId).then(res => {
      this.levels = res.levels.sort((a,b)=>b.order - a.order);
      this.groupName = res.name;
    });
  }
  toGroundGroups(){
    this.router.navigate(['ground-groups']);

  }
  toDashboard() {
    this.router.navigate(['dashboard']);
  }

  openConfirmRemoveModal(item) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: { label: 'Você deseja remover este nível?' }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res === 'SUBMITTED') {
        const params = {
          id: item.id,
        };
        this.actionService.removeLevelToGroundGroup(params).then(() => {
          this.loadGroundPosition();
        });
      }
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(AddLevelToGroupModalComponent, {
      panelClass: 'confirm-modal',
      data: this.groundPositionGroupId
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res) {
        res.groundPositionGroupId = this.groundPositionGroupId;
        this.actionService.addLevelToGroundGroup(res).then(() => {
          this.loadGroundPosition();
        });
      }
    });
  }

}
