<mat-form-field>
  <mat-label>Filtrar por status</mat-label>
  <mat-select>
    <mat-option *ngIf="selectedStatus !== 'NONE'" (click)="selectStatus('NONE')">Todos</mat-option>
    <mat-option (click)="selectSuccess()" [value]="'Sucesso'">
      <div class="option">
        <div class="background success">
          <i class="uil uil-check"></i>
        </div>
        Sucesso
      </div>
    </mat-option>
    <mat-option (click)="selectDivergent()" [value]="'Divergente'">
      <div class="option">
        <div class="background alert">
          <i class="uil uil-times"></i>
        </div>
        Divergente
      </div>
    </mat-option>
    <mat-option (click)="selectBlocked()" [value]="'Bloqueado'">
      <div class="option">
        <div class="background alert">
          <i class="uil uil-padlock"></i>
        </div>
        Bloqueado
      </div>
    </mat-option>
    <mat-option (click)="selectWaiting()" [value]="'Aguardando NFe'">
      <div class="option">
        <img src="assets/icons/spinner-file.svg" alt="">
        Aguardando NFe
      </div>
    </mat-option>
    <mat-option (click)="selectWaitingComparison()" [value]="'Aguardando Comparação'">
      <div class="option">
        <img src="assets/icons/spinner-check.svg" alt="">
        Aguardando Comparação
      </div>
    </mat-option>
    <mat-option (click)="selectWaitingCorrection()" [value]="'Aguardando Correção'">
      <div class="option">
        <img src="assets/icons/spinner-edit.svg" alt="">
        Aguardando Correção
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
