import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-screening-group-header',
  templateUrl: './screening-group-header.component.html',
  styleUrls: ['./screening-group-header.component.scss']
})
export class ScreeningGroupHeaderComponent implements OnInit {

  @Output() selectAllEvent = new EventEmitter();
  @Input() selected;

  constructor() {
  }

  ngOnInit(): void {
  }

  onCheckboxChange(isChecked) {
    this.selectAllEvent.emit(isChecked);
  }
}
