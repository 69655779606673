import { Component, OnInit } from '@angular/core';
import {TasksService} from '../../core/services/tasks.service';
import {LedgerDataSourceService} from '../../core/data-sources/ledger-data-source.service';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss']
})
export class LedgerComponent implements OnInit {

  items = [];

  constructor(
    private ledgerDataSourceService: LedgerDataSourceService,
  ) { }

  async loadData(){
    this.items = await this.ledgerDataSourceService.getAll();
  }

  ngOnInit(): void {
    this.loadData();
  }

}
