import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { getCoordinates } from 'src/app/helpers/format-position';
import { CrateStateEnum } from 'src/models/Crate';
import { StockSortParams } from 'src/app/core/services/stock.service';
import * as moment from 'moment';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};
@Component({
  selector: 'app-stock-header-with-level',
  templateUrl: './stock-header-with-level.component.html',
  styleUrls: ['./stock-header-with-level.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class StockHeaderWithLevelComponent implements OnInit {
  @Input() valuesApplied;
  @Output() valuesToFilter = new EventEmitter();
  maxDate = new Date();
  dateFiltersForm = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null)
  });
  productSkuFormControl = new FormControl(null);
  levelNameFormControl = new FormControl(null);
  sectionFormControl = new FormControl(null);
  addressFormControl = new FormControl(null, Validators.pattern(/^[A-Za-z]{1,3}\d{1,7}$/));
  aliasFormControl = new FormControl(null);
  showCheckIcon = {};
  appliedFilters = {};
  sortDirection = '';
  sortParams: StockSortParams;
  quantityCount = null;
  stateValue = null;

  stateOptions: { label: string; value: CrateStateEnum }[] = [
    { label: 'Armazenado', value: CrateStateEnum.STORED },
    { label: 'Armazenado e divergente', value: CrateStateEnum.STORED_DIVERGENT },
    { label: 'Posição errada', value: CrateStateEnum.WRONG_POSITION },
    { label: 'Aguardando armazenamento', value: CrateStateEnum.AWAITING_STORAGE },
    { label: 'Aguardando separação', value: CrateStateEnum.AWAITING_PICKING },
    { label: 'Aguardando movimentação', value: CrateStateEnum.AWAITING_MOVING },
    { label: 'Movimentando', value: CrateStateEnum.MOVING },
    { label: 'Deslocado', value: CrateStateEnum.MISPLACED },
    { label: 'Deslocado e divergente', value: CrateStateEnum.MISPLACED_DIVERGENT },
    { label: 'Perdido', value: CrateStateEnum.MISSING },
    { label: 'Reservado', value: CrateStateEnum.RESERVED }
  ];

  constructor() { }

  ngOnInit(): void {
    this.setAppliedValues();
  }

  setAppliedValues() {
    if (this.valuesApplied?.quantity) {
      this.quantityCount = this.valuesApplied.quantity;
    }
    if (this.valuesApplied?.sku) {
      this.productSkuFormControl.setValue(this.valuesApplied.sku);
    }
    if (this.valuesApplied?.state) {
      this.stateValue = this.valuesApplied.state;
    }
  }

  applyFilters() {
    const startDate = this.dateFiltersForm.value.start ? moment(this.dateFiltersForm.value.start._d).valueOf() : null;
    const endDate = this.dateFiltersForm.value.end ? moment(this.dateFiltersForm.value.end._d).endOf('day').valueOf() : null;
    let x = null;
    let y = null;
    if (this.addressFormControl.value) {
      x = getCoordinates(this.addressFormControl.value.toUpperCase()).x;
      y = getCoordinates(this.addressFormControl.value.toUpperCase()).y - 1;
    }
    const params = {
      startDate: startDate,
      endDate: endDate,
      quantity: this.quantityCount,
      state: this.stateValue,
      section: this.sectionFormControl.value,
      levelName: this.levelNameFormControl.value,
      sku: this.productSkuFormControl.value,
      alias: this.aliasFormControl.value,
      x,
      y
    }
    this.removeNullKeys(params);
    this.valuesToFilter.emit({
      filterParams: Object.keys(params).some(key => params[key]) ? params : null,
      sortParams: this.sortParams
    });
  }

  sort(column: string, direction: string) {
    this.sortDirection = direction;
    this.showCheckIcon[column] = true;
    this.sortParams = {
      orderBy: column,
      sortBy: direction.toUpperCase()
    };
  }

  clearFilters() {
    this.appliedFilters = {};
    this.showCheckIcon = {};
    this.stateValue = null;
    this.sortDirection = '';
    this.dateFiltersForm.reset();
    this.productSkuFormControl.reset();
    this.levelNameFormControl.reset();
    this.sectionFormControl.reset();
    this.addressFormControl.reset();
    this.aliasFormControl.reset();
    this.valuesToFilter.emit();
  }

  changeQuantity(operator) {
    if (operator === 'PLUS') {
      return this.quantityCount++;
    } else if (operator === 'MINUS' && this.quantityCount > 0) {
      return this.quantityCount--;
    }
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      } else {
        this.setAppliedFilters(key);
      }
    });
  }

  setAppliedFilters(name) {
    switch (name) {
      case 'startDate':
      case 'endDate':
        this.appliedFilters['createdAt'] = true;
        break;
      case 'x':
      case 'y':
        this.appliedFilters['address'] = true;
        break;
      default:
        this.appliedFilters[name] = true;
        break;
    }
  }

}
