import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InspectionService } from '../../core/services/inspection.service';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InspectionDetailsProductModalComponent } from '../../modals/inspection-details-product-modal/inspection-details-product-modal.component';
import { Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { filterGoods, filterInspectionDocuments } from '../../helpers/filters';
import { MatSelect } from '@angular/material/select';
import { TaskRequestSidenavService } from '../../core/services/task-request-sidenav.service';
import { ActionService } from '../../core/services/action.service';
import { Destination } from './inspection-sidenav/inspection-sidenav.component';
import { ErrorService } from '../../core/services/error.service';

export enum InspectionComponentStatusOptions {
  OK = 'OK',
  SURPLUS = 'SURPLUS',
  MISSING = 'MISSING',
  NONE = 'NONE'
}

enum PalletState {
  RECEIVED = 'RECEIVED',
  AWAITING_STORAGE = 'AWAITING_STORAGE'
}

enum TaskBasedOnDestiny {
  STORAGE = 'RELOCATE_STORAGE',
  STOCK_DIVERGENT = 'RELOCATE_DIVERGENT',
  QUALITY = 'RELOCATE_QUALITY',
}

export enum InspectionPageState {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  INITIAL = 'INITIAL'
}

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit, OnDestroy, AfterViewInit {

  InspectionPageState = InspectionPageState;

  PalletState = PalletState;

  allGoods;
  filteredGoods;
  selectedGoodsTagRfids = new Set();

  searchTermFormControl: FormControl;
  searchTermSubscription: Subscription;
  searchTerm: string;

  selectSubscription;
  @ViewChild('select') select: MatSelect;

  selectedStatus: InspectionComponentStatusOptions = InspectionComponentStatusOptions.NONE;
  selectedFilter;

  actualHour;
  state: InspectionPageState = InspectionPageState.INITIAL;

  isLoadingModal = false;

  constructor(private router: Router,
    private inspectionService: InspectionService,
    public dialog: MatDialog,
    private sidenavService: TaskRequestSidenavService,
    private actionService: ActionService,
    private errorService: ErrorService) {
    this.searchTermFormControl = new FormControl();
  }

  ngOnInit(): void {
    this.update();
    this.searchTermSubscription = this.searchTermFormControl.valueChanges.pipe(debounceTime(500))
      .subscribe(newValue => {
        this.searchTerm = newValue;
        this.filter();
      });
  }

  ngOnDestroy(): void {
    this.selectSubscription?.unsubscribe();
    this.searchTermSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.selectSubscription = this.select.optionSelectionChanges.subscribe(event => {
      if (event.isUserInput) {
        this.selectedFilter = event.source.value;
        this.filter();
      }
    });
  }

  allGoodsIsChecked() {
    const eligible = this.filteredGoods?.filter(g => g.palletState === PalletState.RECEIVED);
    if (eligible && eligible.length !== 0) {
      return eligible.length === this.selectedGoodsTagRfids.size;
    }
    return false;
  }

  selectAllGoods() {
    const eligible = this.filteredGoods?.filter(g => g.palletState === PalletState.RECEIVED);

    if (eligible.length === this.selectedGoodsTagRfids.size) {
      this.selectedGoodsTagRfids.clear();
    } else {
      for (const e of eligible) {
        this.selectedGoodsTagRfids.add(e.tagRfid);
      }
    }

  }

  selectGood(good) {
    if (!this.selectedGoodsTagRfids.has(good.tagRfid)) {
      this.selectedGoodsTagRfids.add(good.tagRfid);
    } else {
      this.selectedGoodsTagRfids.delete(good.tagRfid);
    }

  }

  async getInspectionList() {
    this.state = InspectionPageState.LOADING;
    try {
      this.allGoods = await this.inspectionService.getGoods() || [];
    } catch (e) {
      console.log(e);
    } finally {
      this.state = InspectionPageState.LOADED;
    }
    this.filteredGoods = [...this.allGoods];
    this.filter();
  }

  openProductDetailsModal = async (item, event, page) => {
    try {
      this.isLoadingModal = true;
      const itemDetails = await this.inspectionService.getGoodDetails(item.tagRfid).then((r: any) => {
        if (r.relatedInvoices && r.relatedInvoices.length > 0) {
          r.invoice = r.relatedInvoices[0];
        }
        return r;
      });
      this.isLoadingModal = false;
      const dialogRef: MatDialogRef<InspectionDetailsProductModalComponent> = this.dialog.open(InspectionDetailsProductModalComponent, {
        panelClass: 'details-modal',
        data: { item, itemDetails, page },
        autoFocus: false
      });
      dialogRef.afterClosed().pipe(first()).subscribe(res => {
        if (res === 'SUBMITTED') {
          this.update();
        }
      });
    } catch (err) {
      this.isLoadingModal = false;
      console.log(err);
    }
  }

  openTaskRequest(good) {

    const goods = [];
    let palletType = 'PALLET';
    let initialDestination = Destination.STORAGE;

    if (this.selectedGoodsTagRfids.has(good.tagRfid)) {
      const selected = this.allGoods.filter(g => this.selectedGoodsTagRfids.has(g.tagRfid));
      for (const s of selected) {
        for (const p of s.products) {
          goods.push({
            name: p.name,
            quantity: p.quantity,
            tagRfid: s.tagRfid,
            orderNumber: s.buyOrders ? s.buyOrders[0] : null,
            invoiceNumber: s.invoicesId ? s.invoicesId[0] : null,
          });
        }
      }
      palletType = 'PALLET';
    } else {
      for (const p of good.products) {
        goods.push({
          name: p.name,
          quantity: p.quantity,
          tagRfid: good.tagRfid,
          orderNumber: good.buyOrders ? good.buyOrders[0] : null,
          invoiceNumber: good.invoicesId ? good.invoicesId[0] : null,
        });
      }
      initialDestination = good.destination;
      palletType = good.palletType;
    }

    const data = {
      data: {
        goods,
        palletType,
        initialDestination
      },
      type: 'INSPECTION'
    };


    this.sidenavService.toggle(data).then(async (res: any) => {
      const { rfidTags } = res;
      const promises = [];
      for (const rfidTag of rfidTags) {
        const params = {
          type: TaskBasedOnDestiny[res.selectedDestination],
          userIds: res.userIds,
          tagRfid: rfidTag
        };
        if (!res.isCritical && res.userIds) {
          promises.push(this.sendTask(params));
        }
      }
      try {
        await Promise.all(promises);
      } catch (error) {
        this.errorService.openErrorSnackBar(error);
      }
      await this.update();

      if (res !== true) {
        this.selectedGoodsTagRfids.clear();
      }
      // const params = {
      //   type: TaskBasedOnDestiny[res.selectedDestination],
      //   userIds: res.userIds,
      //   tagRfid: good.tagRfid
      // };
      // if (res.isCritical) {
      //   await this.sendCriticalTask(params);
      //   await this.update();
      // } else if (!res.isCritical && res.userIds){
      //   await this.sendTask(params);
      //   await this.update();
      // }
      //


    });
  }

  filter() {
    this.selectedGoodsTagRfids.clear();
    if (this.allGoods) {
      let goods = [...this.allGoods];
      if (this.searchTerm) {
        if (this.searchTerm === '-') {
          this.searchTerm = 'WITHOUT_INVOICE';
        }
        if (this.searchTerm.toUpperCase() === 'N/A') {
          this.searchTerm = 'WITHOUT_VENDOR';
        }
        goods = filterGoods(goods, this.searchTerm);
      }
      this.filteredGoods = filterInspectionDocuments(goods, this.selectedStatus, this.selectedFilter);
    }
  }

  statusSelected(status) {
    this.selectedStatus = status;
    this.filter();
  }

  getNumberOfRepetitions(destination) {
    const goods = this.allGoods?.filter(i => i.destination === destination);
    if (goods?.length < 10) {
      return '0' + goods?.length;
    } else {
      return goods?.length;
    }
  }

  async toScreening() {
    await this.router.navigate(['screening']);
  }

  async toStocking() {
    await this.router.navigate(['stock']);
  }

  async update() {
    await this.getInspectionList();
    this.actualHour = moment().format('HH:mm');
  }

  async sendTask(data) {
    await this.actionService.sendTask(data);
  }

  async sendCriticalTask(data) {
    await this.actionService.sendCriticalTask(data);
  }

  getButtonText(good) {
    let text = good.palletState === PalletState.RECEIVED ? 'ENVIAR' : 'SOLICITADO';
    if (this.selectedGoodsTagRfids.has(good.tagRfid)) {
      text += ` (${this.selectedGoodsTagRfids.size})`;
    }
    return text;
  }

  checkPalletType(palletType) {
    switch (palletType) {
      case 'PALLET':
        return 'pallet';
      case 'BIN':
        return 'bin';
      default:
        return '-';
    }
  }
}
