<div class="header-modal">
  <p>Selecione o novo setor:</p>
</div>

<mat-form-field>
  <mat-select [formControl]="newSectorFormControl">
    <mat-option *ngFor="let sector of sectorOptions" [value]="sector.id">{{ sector.name }}</mat-option>
  </mat-select>
</mat-form-field>

<div class="buttons">
  <button mat-stroked-button class="gray-2" (click)="close()">cancelar</button>
  <button mat-raised-button class="primary-1" (click)="close('SUBMITTED')">confirmar</button>
</div>
