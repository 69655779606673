import {Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-in-group',
  templateUrl: './in-group.component.html',
  styleUrls: ['./in-group.component.scss']
})
export class InGroupComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() quantity: number;
  @Input() windowSize: number;
  @Input() type: string;
  @Input() documents: [];
  @Input() statePage: boolean;
  @Input() itemTemplate: TemplateRef<any>;

  displayedDocuments;

  displayAll: boolean;
  displayShowMoreToggle: boolean;
  isEmpty: boolean;


  constructor() {
    this.quantity = 0;
    this.windowSize = 3;
    this.displayShowMoreToggle = true;
    this.displayAll = false;
    this.isEmpty = true;
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  update(){
    if(!this.displayAll){
      this.displayedDocuments = this.documents?.slice(0, this.windowSize);
    } else{
      this.displayedDocuments = [...this.documents];
    }
    this.quantity = this.documents?.length;

    this.isEmpty = this.quantity === 0;
    this.displayShowMoreToggle = this.quantity > this.windowSize;
  }

  toggle(event){
    this.displayAll = !this.displayAll;
    this.update();
  }

}
