import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  IItem,
  ProductPickingDataSourceService
} from '../../core/data-sources/product-picking-data-source.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ProductPickingDetailsModalComponent
} from '../../modals/product-picking-details-modal/product-picking-details-modal.component';
import { TaskRequestSidenavService } from '../../core/services/task-request-sidenav.service';
import { StockDataSourceService } from '../../core/data-sources/stock-data-source.service';
import * as moment from 'moment/moment';
import { ErrorService } from '../../core/services/error.service';

@Component({
  selector: 'app-product-picking',
  templateUrl: './product-picking.component.html',
  styleUrls: ['./product-picking.component.scss']
})
export class ProductPickingComponent implements OnInit {

  items: IItem[];

  isLoading = true;
  isModalLoading = false;

  actualHour;

  constructor(private route: Router,
              private productPickingDataSource: ProductPickingDataSourceService,
              private stockService: StockDataSourceService,
              private dialog: MatDialog,
              private sidenavService: TaskRequestSidenavService,
              private errorService: ErrorService) { }

  ngOnInit(): void {
    this.update();
  }

  getProducts(): void {
    this.productPickingDataSource.getItems().subscribe((values) => {
      this.items = values;
      this.isLoading = false;
    }, (error) => {
      this.errorService.openErrorSnackBar(error);
      this.isLoading = false;
    });
  }

  async openSidenav(type: string, item?: IItem): Promise<void> {
    const data = {
      type,
      products: this.items
    };
    this.sidenavService.toggle(data).then(async (res: any) => {
      this.update();
    });
  }

  async openDetailsModal(item, isComment): Promise<void> {
    try {
      this.isModalLoading = true;
      const dialogRef = this.dialog.open(ProductPickingDetailsModalComponent, {
        panelClass: 'details-modal',
        autoFocus: false,
        data: {
          item,
          tabIndex: isComment ? 99 : 0
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.update();
        }
      });
      this.isModalLoading = false;
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
      this.isModalLoading = false;
    }
  }

  update() {
    this.actualHour = moment().format('HH:mm');
    this.getProducts();
  }

  navigateTo(route: string) {
    this.route.navigate([route]);
  }

}
