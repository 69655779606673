<app-side-color-card>
  <div class="table" content>
    <div class="table-top">
      <h2>PRODUTOS CADASTRADOS ({{ paginationService.totalItems$ | async }})</h2>

      <app-paginator
        [currentPage]="paginationService.currentPage$ | async"
        [pageCount]="paginationService.pageCount$ | async"
        [atFirstPage]="paginationService.atFirstPage$ | async"
        [atLastPage]="paginationService.atLastPage$ | async"
        (previousPage)="getPreviousPage($event)"
        (nextPage)="getNextPage($event)">
      </app-paginator>
    </div>

    <app-product-management-table-header (filterValues)="onFilterValues($event)"></app-product-management-table-header>

    <app-table-items *ngIf="!isLoading">
      <app-table-item *ngFor="let product of products; let odd = odd" [isOdd]="odd" (click)="onItemClick(product)">
        <app-table-column name="createdAt">
          <span content>{{ formatDate(product.createdAt) }}</span>
        </app-table-column>
        <app-table-column name="name">
          <span content>{{ product.name }}</span>
        </app-table-column>
        <app-table-column name="description">
          <span content>{{ product.description || '-'}}</span>
        </app-table-column>
        <app-table-column name="sku">
          <span content>{{ product.sku }}</span>
        </app-table-column>
        <app-table-column name="weight" size="small">
          <span content>{{ product.weight ? product.weight + ' Kg' : '-'}}</span>
        </app-table-column>
        <app-table-column name="width" size="small">
          <span content>{{ product.width ? product.width + ' m' : '-'}}</span>
        </app-table-column>
        <app-table-column name="height" size="small">
          <span content>{{ product.height ? product.height + ' m' : '-'}}</span>
        </app-table-column>
        <app-table-column name="depth" size="small">
          <span content>{{ product.depth ? product.depth + ' m' : '-'}}</span>
        </app-table-column>
        <app-table-column name="delete-btn" size="button">
          <div content>
            <button (click)="openDeleteProductModal(product); $event.stopPropagation()">
              <i class="uil uil-trash"></i>
            </button>
          </div>
        </app-table-column>
      </app-table-item>
      <div class="empty-table" *ngIf="!products?.length">
        NÃO EXISTEM PRODUTOS NESTE MOMENTO.
      </div>
    </app-table-items>
    <div *ngIf="isLoading" class="loader">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
  </div>
</app-side-color-card>