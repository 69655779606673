import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentDataSourceService {

  constructor(private httpClient: HttpClient) { }

  postBuyOrderComment(orderNumber, value, stage) {
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      value,
      stage
    };
    return this.httpClient.post(environment.baseUrl + `/api/v1/admin-desktop/buy-orders/${orderNumber}/comment`,
      body, { headers }).toPromise<any>();
  }

  deleteBuyOrderComment(orderNumber, id) {
    return this.httpClient.delete(environment.baseUrl + `/api/v1/admin-desktop/buy-orders/${orderNumber}/comment/${id}`).toPromise<any>();
  }

  postInvoiceComment(invoiceId, value, stage) {
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      value,
      stage
    };
    return this.httpClient.post(environment.baseUrl + `/api/v1/admin-desktop/invoices/${invoiceId}/comment`, body, { headers }).toPromise();
  }

  deleteInvoiceComment(invoiceId, commentId) {
    return this.httpClient.delete(environment.baseUrl + `/api/v1/admin-desktop/invoices/${invoiceId}/comment/${commentId}`).toPromise<any>();
  }

  postInspectionComment(invoiceId, value, stage, productSku) {
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      value,
      stage,
      productSku
    };
    return this.httpClient.post(environment.baseUrl + `/api/v1/admin-desktop/invoices/${invoiceId}/comment`, body, { headers }).toPromise();
  }
  postEntryComment(entryId, value, stage, productSku) {
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      value,
      stage,
      productSku
    };
    return this.httpClient.post(environment.baseUrl + `/api/v1/admin-desktop/crates/${entryId}/comments`, body, { headers }).toPromise();
  }
  deleteEntryComment(entryId, commentId) {
    return this.httpClient.delete(environment.baseUrl + `/api/v1/admin-desktop/crates/${entryId}/comments/${commentId}`).toPromise<any>();
  }

  postStockComment(crateId, value, stage) {
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      value,
      stage
    };
    return this.httpClient.post(environment.baseUrl + `/api/v1/admin-desktop/crates/${crateId}/comments`, body, { headers }).toPromise();
  }

  deleteStockComment(crateId, commentId) {
    return this.httpClient.delete(environment.baseUrl + `/api/v1/admin-desktop/crates/${crateId}/comments/${commentId}`).toPromise<any>();
  }
}
