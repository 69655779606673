import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

interface PageHeaderLink {
 label: string;
 link: string;
}

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input() back: PageHeaderLink;
  @Input() forward: PageHeaderLink;
  @Input() title;



  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  async goBack(){
    await this.router.navigate([this.back.link]);
  }
  async goForward(){
    await this.router.navigate([this.forward.link]);
  }

}
