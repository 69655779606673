<div class="page-header">
  <app-prev-next-button (click)="toDashboard()" direction="left" icon="estate"></app-prev-next-button>
  <h1>Usuários</h1>
</div>

<div class="container-wrap">
  <div class="filters-wrap">
    <div class="search-input">
      <input [formControl]="searchTermFormControl"
             placeholder="Pesquisar por colaborador ou usuário"
             autocomplete="off">
      <i class="uil uil-search"></i>
    </div>

    <mat-form-field>
      <mat-label>Filtrar por status</mat-label>
      <mat-select [(ngModel)]="selectedStatus">
        <mat-option *ngIf="selectedStatus !== 'NONE'" value="NONE" (click)="filter()">Todos</mat-option>
        <mat-option *ngFor="let s of statusOptions" [value]="s.value" (click)="filter()">
          <div class="option">
            <div class="status-icon" [ngClass]="{'online': s.value === 'ONLINE'}"></div>
            {{ s.label }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <button mat-raised-button
          class="primary-1 new-user-btn"
          (click)="openRegisterUserModal()">
    CADASTRAR NOVO USUÁRIO
  </button>
</div>


<app-side-color-card>

  <div class="table" content >
    <div class="table-title">USUÁRIOS CADASTRADOS ({{ this.usersCount }})</div>
    <app-table-header>
      <app-table-header-column name="status" size="smallest">
        <span content>Status</span>
      </app-table-header-column>
      <app-table-header-column name="colaborator">
        <span content>Colaborador</span>
      </app-table-header-column>
      <app-table-header-column name="role" size="small">
        <span content>Função</span>
      </app-table-header-column>
      <app-table-header-column name="username">
        <span content>Usuário</span>
      </app-table-header-column>
      <app-table-header-column name="delete-button" size="button"></app-table-header-column>
    </app-table-header>

    <app-table-items >
      <app-table-item *ngFor="let user of filteredUsers; let odd = odd" [isOdd]="odd" (click)="openUserDetailsModal(user)">
        <app-table-column name="status" size="smallest">
          <div class="status-icon" [ngClass]="{'online': user.online}" content></div>
        </app-table-column>
        <app-table-column name="colaborator">
          <span content>{{user.firstName + ' ' + user.lastName}}</span>
        </app-table-column>
        <app-table-column name="role" size="small">
          <span content>{{translateRoles(user.type)}}</span>
        </app-table-column>
        <app-table-column name="username">
          <span content>{{user.username}}</span>
        </app-table-column>
        <app-table-column name="delete-button" size="button">
          <button (click)="deleteUser($event, user)"
                  [disabled]="disableDelete(user)"
                  [matTooltip]="showDisabledTooltip(user)"
                  content>
            <i class="uil uil-trash"></i>
          </button>
        </app-table-column>
      </app-table-item>
      <div class="empty-table" *ngIf="!filteredUsers.length">
        NÃO EXISTEM USUÁRIOS PARA ESSES FILTROS.
      </div>
    </app-table-items>
  </div>

</app-side-color-card>

<ng-template #notification>
  <app-notification [data]="notificationData" icon="check-circle"></app-notification>
</ng-template>

<app-full-page-loader *ngIf="fullPageIsLoading"></app-full-page-loader>
