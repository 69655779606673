import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { toExcelFormat } from '../../../helpers/format-position';
import { ChangeGroundPositionStateActionState } from '../../../core/services/action.service';

interface IPickingPosition {
  createdAt: number;
  groundPosition: {
    createdAt: number;
    groundPositionGroupId: string;
    id: string;
    rfId: string;
    state: ChangeGroundPositionStateActionState;
    tenantId: string;
    type: string;
    updatedAt: number;
    x: number;
    y: number;
  };
  groundPositionId: string;
  id: string;
  maxQuantity: number;
  minQuantity: number;
  quantity: number;
  sku: string;
  tenantId: string;
  updatedAt: number;
}

@Component({
  selector: 'app-pallets-details',
  templateUrl: './pallets-details.component.html',
  styleUrls: ['./pallets-details.component.scss']
})
export class PalletsDetailsComponent implements OnInit {

  @Input() product: any;
  pickingPositions: IPickingPosition[];
  @Output() relocate: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearPositionEvent: EventEmitter<any> = new EventEmitter<any>();

  toExcelFormat = toExcelFormat;

  constructor() {
  }

  ngOnInit(): void {
    this.pickingPositions = this.product.pickingPositions;
  }

  onRelocateClick(pickingPosition: IPickingPosition): void {
    const data = {
      pickingPosition,
      productName: this.product.name
    };
    this.relocate.emit(data);
  }

  onClearPositionClick(pickingPosition: IPickingPosition): void {
    this.clearPositionEvent.emit(pickingPosition);
  }
}
