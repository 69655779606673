import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {TasksService} from '../../core/services/tasks.service';
import {MatDialog} from '@angular/material/dialog';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../modals/confirm-modal/confirm-modal.component';
import {ActionService} from '../../core/services/action.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  allTasks;
  isLoading = true;

  constructor(private router: Router,
              private tasksService: TasksService,
              private dialog: MatDialog,
              private action: ActionService) {
    this.getTasks();
  }

  ngOnInit(): void {

  }

  async getTasks() {
    try {
      this.isLoading = true;
      this.allTasks = await this.tasksService.getTasks();
      this.order();
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  order() {
    this.allTasks.sort((a, b) => {
      return a.createdAt - b.createdAt;
    });
  }

  openConfirmDeleteModal(tasksIds) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: { label: 'Você deseja excluir esta tarefa?' }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res === 'SUBMITTED') {
        const params = {
          ids: tasksIds
        };
        this.action.deleteTask(params).then(() => {
          this.update();
        });
      }
    });
  }

  async toDashboard() {
    await this.router.navigate(['in']);
  }

  update() {
    this.getTasks();
  }
}
