import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { IItem } from '../../../core/data-sources/product-picking-data-source.service';
import { toExcelFormat } from '../../../helpers/format-position';

@Component({
  selector: 'app-product-picking-table-item',
  templateUrl: './product-picking-table-item.component.html',
  styleUrls: ['./product-picking-table-item.component.scss']
})
export class ProductPickingTableItemComponent implements OnInit {

  @Input() item: IItem;
  @Input() @HostBinding('class.isOdd') isOdd;
  @Output() itemClickEvent: EventEmitter<any> = new EventEmitter<any>();
  formatPosition = toExcelFormat;

  constructor() { }

  ngOnInit(): void {
  }

  parseTooltipString(pickingPosition: any[]): string {
    if (!pickingPosition.length) {
      return '';
    }

    let tooltip = '';
    pickingPosition.forEach((el, index) => {
      if (pickingPosition.length !== index + 1) {
        tooltip = tooltip + this.formatPosition(el.groundPosition.x, el.groundPosition.y) + ', ';
      } else {
        tooltip = tooltip + this.formatPosition(el.groundPosition.x, el.groundPosition.y);
      }
    });
    return tooltip;
  }

  itemClick(isComment: boolean = false) {
    this.itemClickEvent.emit(isComment);
  }

}
