<app-table-header-column name="name" size="medium">
  <span content>Nome</span>
</app-table-header-column>

<app-table-header-column name="sku" size="small">
  <span content>SKU</span>
</app-table-header-column>

<app-table-header-column name="quantity" size="smallest">
  <span content>Quantidade</span>
</app-table-header-column>

<app-table-header-column name="position" size="smallest">
  <span content>Posições</span>
</app-table-header-column>

<!--<app-table-header-column name="messages" size="smallest">-->
<!--  <span content>Comentários</span>-->
<!--</app-table-header-column>-->
