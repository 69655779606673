import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {TenantService} from '../../core/services/tenant.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  currentStep;

  steps = [
    {order: 1, name: 'configuração inicial do armazém', link: 'setup-tenant', color: 'in', id: 'tenant', show: true},
    {order: 2, name: 'cadastro de administradores', link: 'setup-admins', color: 'in', id: 'admins', show: true},
    {order: 3, name: 'cadastro de tags', link: 'setup-tags', color: 'primary-1', id: 'tags', show: true},
    {order: 4, name: 'demarcação de setores', link: 'setup-ground', color: 'primary-1', id: 'ground', show: true},
    {order: 5, name: 'definição de níveis (altura)', link: 'setup-levels', color: 'picking', id: 'levels', show: false},
    {order: 6, name: 'finalizar setup', link: 'setup-finish', color: 'out', id: 'finish', show: true}
  ];

  constructor(
    private router: Router,
    private tenantService: TenantService
  ) { }

  ngOnInit(): void {
    this.tenantService.get(true).then(res => {
      const tenant = res;
      const step = this.getStepById('levels');
      step.show = tenant.levelsEnabled;
      this.currentStep = this.calcCurrentStep(tenant.state);
    });
  }

  calcCurrentStep(state){
    switch (state) {
      case 'SETUP_TENANT_INITIAL_CONFIG':
        return 0;
      case  'SETUP_ADMINS':
        return 1;
      case  'SETUP_TAGS':
        return 2;
      case 'SETUP_GROUPS':
        return 3;
      case 'SETUP_LEVELS':
        return 4;
      case 'ONLINE':
      case 'OFFLINE':
        return 6;
      default:
        return -1;
    }
  }

  async toDashboard(){
    await this.router.navigate(['/dashboard']);
  }

  async toStep(step){
    if (step.show) {
      await this.router.navigate([step.link]);
    }
  }

  getStepById(id) {
    return this.steps.find(s => s.id === id);
  }
}
