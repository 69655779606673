<app-table-column name="name" size="medium" (click)="itemClick()">
  <span content>{{ item.name || item.description || '---' }}</span>
</app-table-column>

<app-table-column name="sku" size="small" (click)="itemClick()">
  <span content>{{ item.sku || '---' }}</span>
</app-table-column>

<app-table-column name="quantity" size="smallest" (click)="itemClick()">
  <span content>{{ item.quantity || 0 }}</span>
</app-table-column>

<app-table-column name="position" size="smallest" (click)="itemClick()" [matTooltip]="item.pickingPositions.length > 3 ? parseTooltipString(item.pickingPositions) : null">
  <div content>
    <span *ngFor="let position of item.pickingPositions; let i = index; let last = last">
      {{ (i < 2 && !last ? formatPosition(position.groundPosition.x, position.groundPosition.y) + ', ' : i < 2 && last ? formatPosition(position.groundPosition.x, position.groundPosition.y) : i === 2 ? formatPosition(position.groundPosition.x, position.groundPosition.y) : i === 3 ? '...' : ' ') || '---' }}
    </span>
  </div>
</app-table-column>

<!--<app-table-column name="messages" size="smallest" (click)="itemClick(true)">-->
<!--  <div content>-->
<!--    <i *ngIf="item.commentsQty === 0" class="uil uil-comment"></i>-->
<!--    <i *ngIf="item.commentsQty !== 0" class="uil uil-comment-lines"></i>-->
<!--    <span>{{ item.commentsQty }}</span>-->
<!--  </div>-->
<!--</app-table-column>-->
