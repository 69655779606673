<div class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Solicitação de tarefa" (closeEvent)="close()"></app-sidenav-header>

    <form [formGroup]="inventoryForm" class="new-inventory-task-config">
      <div class="card-header">Configurar nova tarefa de inventário</div>
      <div class="card-body" [ngClass]="{'height-transition': isProductToggle || isSectorToggle || isPalletToggle || isMissingPalletsToggle}">
        <p>Inventariar por:</p>
        <div class="buttons">
          <button
            mat-raised-button
            class="secondary-2"
            (click)="toggleOption('PRODUCT')"
            *ngIf="isProductToggle"
            [disabled]="data?.position">
            Produto
          </button>
          <button
            mat-stroked-button
            class="secondary-2"
            (click)="toggleOption('PRODUCT')"
            *ngIf="!isProductToggle"
            [disabled]="data?.position">
            Produto
          </button>
          <button
            mat-raised-button
            class="secondary-2"
            (click)="toggleOption('SECTOR')"
            *ngIf="isSectorToggle">
            Setor
          </button>
          <button
            mat-stroked-button
            class="secondary-2"
            (click)="toggleOption('SECTOR')"
            *ngIf="!isSectorToggle">
            Setor
          </button>
          <button
            mat-raised-button
            class="secondary-2"
            (click)="toggleOption('PALLET')"
            *ngIf="isPalletToggle"
            [disabled]="data?.position">
            Conteúdo por Pallet
          </button>
          <button
            mat-stroked-button
            class="secondary-2"
            (click)="toggleOption('PALLET')"
            *ngIf="!isPalletToggle"
            [disabled]="data?.position">
            Conteúdo por Pallet
          </button>
          <button
            mat-raised-button
            class="secondary-2"
            (click)="toggleOption('MISSING_PALLET')"
            *ngIf="isMissingPalletsToggle"
            [disabled]="data?.position">
            perdidos
          </button>
          <button
            mat-stroked-button
            class="secondary-2"
            (click)="toggleOption('MISSING_PALLET')"
            *ngIf="!isMissingPalletsToggle"
            [disabled]="data?.position">
            perdidos
          </button>
        </div>

        <div class="product-input" *ngIf="isProductToggle">
          <p>Digite o que deseja inventariar:</p>
          <mat-form-field>
            <input matInput
                   placeholder="SKU ou a descrição do produto"
                   formControlName="product"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayedString">
              <mat-option *ngFor="let product of products" [value]="product">{{ product.name + ' - ' + product.sku }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="inventory-sector" *ngIf="isSectorToggle">
          <p>Selecione o setor que deseja inventariar:</p>
          <mat-form-field>
            <mat-label>Setor</mat-label>
            <mat-select multiple [(value)]="sectorValue" [disabled]="data?.position"
              (selectionChange)="onChangeSector($event)" formControlName="sector">
              <mat-option *ngFor="let option of sectorOptions" [value]="option.groupId">{{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-checkbox color="primary" *ngIf="isSectorToggle"
          (change)="onCheckboxChange($event.checked)"
          formControlName="entireSector">
          Inventariar setor(es) completo(s)
        </mat-checkbox>

        <ng-container *ngIf="emptyGroundPositionError && groundPositionWithoutPallet.length">
          <p class="error" *ngFor="let pos of groundPositionWithoutPallet">
            Coordenada {{toExcelFormat(pos.x, pos.y)}} não possui pallet.
          </p>
        </ng-container>

        <div class="inventory-sector" *ngIf="isSectorToggle && showPositionSelect">
          <p>Selecione a posição que deseja inventariar:</p>
          <mat-form-field>
            <mat-label>Posição</mat-label>
              <ng-container *ngIf="showMultipleOptionsSelect">
                <mat-select multiple formControlName="position" (selectionChange)="onChangePosition($event)">
                  <mat-optgroup *ngFor="let group of multipleGroupOptions" [label]="group.name">
                    <mat-option *ngFor="let position of group.position" [value]="position.value">
                      {{position.viewValue}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
              </ng-container>
              <ng-container *ngIf="showSingleOptionSelect">
                <mat-select multiple [compareWith]="compareWithFn" formControlName="position"
                  (selectionChange)="onChangePosition($event)" [disabled]="data?.position">
                  <mat-option *ngFor="let option of singleGroupOptions" [value]="option.value">
                    {{ option.viewValue }}
                  </mat-option>
                </mat-select>
              </ng-container>
          </mat-form-field>
        </div>

        <div class="product-input" *ngIf="isPalletToggle">
          <p>Selecione o pallet que deseja inventariar:</p>
          <mat-form-field>
            <input matInput
                   placeholder="Pesquise pela Tag RFID"
                   formControlName="pallet"
                   [matAutocomplete]="palletAutocomplete">
            <mat-autocomplete #palletAutocomplete="matAutocomplete" [displayWith]="setDisplayedPalletName">
              <mat-option *ngFor="let pallet of palletOptions" [value]="pallet">
                Tag RFID: {{pallet.tagRfid}} - Posição: {{toExcelFormat(pallet.x, pallet.y)}} - Altura: {{ pallet.z >= 0 ? centimetersToMeters(pallet.z) + ' metros' : 'Conflitante' }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="product-input" *ngIf="isMissingPalletsToggle">
          <p>Selecione o pallet que deseja inventariar:</p>
          <!-- TODO: put this into a new component -->
          <mat-form-field>
            <input matInput
              placeholder="Pesquise pela Tag RFID"
              formControlName="missingPallets"
              [matAutocomplete]="missingPalletsAutocomplete"
              (keyup)="onInput($event)"
              (focus)="onAutocompleteFocus()"
            >
            <mat-autocomplete #missingPalletsAutocomplete="matAutocomplete" 
              [displayWith]="setDisplayedMissingPalletName"
              (opened)="onAutocompleteOpened()">
              <mat-option *ngFor="let pallet of missingPallets">
                <div (click)="onOptionClicked($event, pallet)">
                  <mat-checkbox color="primary" class="check"
                    [checked]="pallet.selected" (change)="toggleSelection(pallet)" (click)="$event.stopPropagation()">
                    Tag RFID: {{pallet.tagRfid}} / {{ pallet.alias ? 'Posição: ' + pallet.alias : 'Posição: --- (coordenada: ' + toExcelFormat(pallet.x, pallet.y) + ')'}} / Altura: {{ pallet.z >= 0 ? centimetersToMeters(pallet.z) + ' metros' : 'Conflitante' }}
                  </mat-checkbox>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <section class="selected-missing-pallets" *ngIf="selectedPallets.length">
          <p >Pallets selecionados:</p>
          <p *ngFor="let pallet of selectedPallets; let i = index" class="selected-pallets">
            Tag RFID: {{pallet.tagRfid}} / {{ pallet.alias ? 'Posição: ' + pallet.alias : 'Posição: --- (coordenada: ' + toExcelFormat(pallet.x, pallet.y) + ')'}} / Altura: {{ pallet.z >= 0 ? centimetersToMeters(pallet.z) + ' metros' : 'Conflitante' }}
            <button class="remove-btn" (click)="removeSelectedPallet(pallet)">
              <i class="uil uil-trash"></i>
            </button>
          </p>
        </section>

      </div>
    </form>

<!--    <mat-checkbox (change)="onCriticalChange($event.checked)"-->
<!--                  [checked]="isCritical">Tarefa crítica</mat-checkbox>-->

    <p class="error" *ngIf="sectorsWithoutProducts.length">
      O(s) setor(es)
      <span *ngFor="let sector of sectorsWithoutProducts; let last = last">&nbsp;{{ last ? sector.name : sector.name + ',' }}&nbsp;</span>
      não possuem produtos para realizar a ação.
    </p>

    <p class="error" *ngIf="palletWithoutProduct">O produto
      <span>{{ palletWithoutProduct.name + ' - ' + palletWithoutProduct.sku }}</span> não foi encontrado no armazém.
    </p>

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>

    <app-operators-table device="HANDSCANNER"
                         [usersHighlighted]="usersHighlighted"
                         (usersHighlightedEvent)="setUsers($event)"
                         [openedStart]="drawer.openedStart"
                         [isForklift]="false"
                         [isUserOnline]="true">
                      </app-operators-table>
  </div>

  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()" [disabled]="isSectorToggle && !sectorValue?.length">OK</button>
  </div>
</div>
